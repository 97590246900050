import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

import RingsLoader from './RingsLoader'

const useStyles = makeStyles(() => ({
  root: {
    background: 'white',
    bottom: '0',
    left: '0',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: '100000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const Loader = ({
  color = theme.colors.secondary.dark,
  show = false,
  size = '90px',
}) => {
  const classes = useStyles()

  return show
    ? (
      <Box className={classes.root}>
        <RingsLoader color={color} height={size} width={size} />
      </Box>
    )
    : null
}

Loader.propTypes = {
  color: PropTypes.string,
  show: PropTypes.bool,
  size: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Loader
