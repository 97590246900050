import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Paper,
  Typography,
} from '@material-ui/core'
import { useStoreState } from 'easy-peasy'

import Image from 'artkive/components/Image'
import Social from 'artkive/components/Social'
import Swipe from 'artkive/components/Swipe'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { AMOUNT_OF_ART } from 'artkive/utils/giftCard'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import ArtOfMe from 'images/ecom/checkout/BoxConfirmation-ArtofMe.jpg'
import Lifekive from 'images/ecom/checkout/BoxConfirmation-Lifekive.jpg'
import HowItWorks from 'images/ecom/giftcards/GC-HowItWorks-1.svg'
import HowItWorks2 from 'images/ecom/giftcards/GC-HowItWorks-2.svg'
import HowItWorks3 from 'images/ecom/giftcards/GC-HowItWorks-3.svg'

import { EGiftCardConfirmation, PhysicalGiftCardConfirmation } from './GiftCardConfirmationVariants'

const giftCardVariants = {
  physical: PhysicalGiftCardConfirmation,
  egift: EGiftCardConfirmation,
}

const GiftCardConfirmation = ({ params }) => {
  const { confirmation } = useStoreState(({ giftCardStore }) => giftCardStore)

  const isMobile = useMobileBreakpoint()

  const ConfirmationDetails = giftCardVariants[params.uuid] ?? giftCardVariants.physical

  return (
    <Box minHeight={'100vh'}>

      <ConfirmationDetails params={confirmation} />

      {/* how it works */}
      <Box py={10} textAlign={'center'} bgcolor={theme.colors.white.dark}>
        <Container>
          <Box mb={10}><Typography gutterBottom variant={'h4'}>How It Works</Typography></Box>

          <Grid container spacing={2}>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={HowItWorks} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}><Typography gutterBottom variant={'h6'}>Redeem Gift Card Online</Typography></Box>
                <Typography >Your gift card recipient can redeem their gift card during checkout.</Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={HowItWorks2} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}>
                  <Typography gutterBottom variant={'h6'}>Send Us {AMOUNT_OF_ART} Art Pieces</Typography>
                </Box>
                <Typography>
                  Using the Artkive box, your gift card recipient will send us {AMOUNT_OF_ART} art pieces.
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'}>
                <Box mb={2}><Image src={HowItWorks3} style={{ maxWidth: '100px' }} /></Box>
                <Box mb={2}><Typography gutterBottom variant={'h6'}>Receive Book</Typography></Box>
                <Typography>
                  After your gift card recipient has approved a digital proof of their book,
                  we&apos;ll print &amp; ship it to them.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={6}>
                <Swipe href={routes.faqs} target={'_blank'} underline={'none'} variant={'h6'}>View FAQs</Swipe>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* OTHER PRODUCTS */}
      <Box my={8} position={'relative'}>
        <Hidden smDown>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Image src={ArtOfMe} />
            </Grid>
            <Grid item xs={false} md={4}>
              <Box bgcolor={'primary.light'} height={'100%'}>&nbsp;</Box>
            </Grid>
          </Grid>
        </Hidden>

        <Box
          position={isMobile ? 'relative' : 'absolute'}
          top={isMobile ? '0' : '50%'}
          right={isMobile ? '0' : '10%'}
          style={{ transform: isMobile ? 'none' : 'translate(0, -50%)', maxWidth: '700px' }}
        >
          <Paper elevation={0}>
            <Box p={isMobile ? 2 : 10}>
              <Typography gutterBottom variant={'subtitle1'}>OTHER PRODUCTS</Typography>
              <Typography variant={'h1'}>Art of Me —</Typography>
              <Typography gutterBottom variant={'h3'}>Your Child&apos;s First Autobiography</Typography>
              <Box mb={4}>
                <Typography variant={'subtitle2'}>
                  Art of Me is a 21 page collection of art and writing prompts your child completes and we
                  turn into a book. It perfectly captures a snapshot of their childhood that you will cherish forever.
                </Typography>
              </Box>
              <Button color={'primary'} href={routes.artOfMe} target={'_blank'} size={'large'} variant={'contained'}>
                Learn More
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box my={8} position={'relative'}>
        <Hidden smDown>
          <Grid container spacing={4}>
            <Grid item xs={false} md={4}>
              <Box bgcolor={theme.colors.purple.light} height={'100%'}>&nbsp;</Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Image src={Lifekive} />
            </Grid>
          </Grid>
        </Hidden>

        <Box
          position={isMobile ? 'relative' : 'absolute'}
          top={isMobile ? '0' : '50%'}
          left={isMobile ? '0' : '10%'}
          style={{ transform: isMobile ? 'none' : 'translate(0, -50%)', maxWidth: '700px' }}
        >
          <Paper elevation={0}>
            <Box p={isMobile ? 2 : 10}>
              <Typography gutterBottom variant={'subtitle1'}>OTHER PRODUCTS</Typography>
              <Typography variant={'h1'}>Lifekive —</Typography>
              <Typography gutterBottom variant={'h3'}>Safeguard Your Legacy</Typography>
              <Box mb={4}>
                <Typography variant={'subtitle2'}>
                  Lifekive is an exclusive concierge service dedicated to celebrating and preserving your most
                  essential memories and moments.
                </Typography>
              </Box>
              <Button color={'primary'} href={routes.lifekive} target={'_blank'} size={'large'} variant={'contained'}>
                Learn More
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
      {/* OTHER PRODUCTS */}

      <Social />

    </Box>
  )
}

GiftCardConfirmation.propTypes = {
  params: PropTypes.object,
}

export default GiftCardConfirmation
