import React from 'react'
import PropTypes from 'prop-types'

import {
  Box, Button,
  Divider,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { FormatQuoteRounded } from '@material-ui/icons'

import { MIN_BOX_BOOK_NUMBER_OF_PAGES } from 'common/utils/bookSize.constants'
import Faqs from 'artkive/components/Faqs'
import Image from 'artkive/components/Image'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import ProductPriceTypography from 'artkive/components/PageSections/ProductPriceTypography'
import { TrackButton } from 'artkive/components/Tracker'
import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import { FRAMED_MOSAIC, GET_BOX_CLICK, NAVIGATION } from 'artkive/constants/tracker/mainNavigation'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { defaultHowItWorks } from 'artkive/stores/ecom/constants/data'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import ArtBooks from 'images/ecom/books/ArtBooksProductMobile.png'
import Bubbles from 'images/ecom/brand/bubbles.svg'
import sprite from 'images/ecom/brand/sprite.svg'
import About1 from 'images/ecom/mosaics/Mosaic-Focus-1.png'
import About2 from 'images/ecom/mosaics/Mosaic-Focus-2.png'
import About3 from 'images/ecom/mosaics/Mosaic-Focus-3.png'
import Customer from 'images/ecom/mosaics/Mosaic-Testimonial.png'
import Testimonial from 'images/ecom/mosaics/Mosaic-Testimonial-2.png'
import ProductLG from 'images/ecom/mosaics/Mosaics-lg.jpg'
import Product from 'images/ecom/mosaics/Mosaics-md.jpg'
import ProductMobile from 'images/ecom/mosaics/Mosaics-sm.jpg'

import DigitizedImagesAccessSection from '../Books/DigitizedImagesAccessSection'
import GetStartedWithBoxSection from '../Books/GetStartedWithBoxSection'
import YouWillAlsoLove from '../Books/YouWillAlsoLove'
import ProductDescriptionSection from '../GetStarted/ProductDescriptionSection'

import { FAQS, mosaicsProductBullets } from './Mosaics.content'

const useStyles = makeStyles(({ breakpoints }) => ({
  get_started: {
    background: 'linear-gradient(90deg, rgba(237,235,244,1) 50%, rgba(226,222,237,1) 50%);',
  },
  books_bg: {
    background: 'linear-gradient(0deg, rgba(255,242,209,1) calc(50% - 68px), rgba(227,242,253,1) calc(50% - 68px))',
  },
  aboutMosaic: {
    backgroundColor: '#FFFFFF',
  },
  mosaicPriceLabel: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',

    [breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}))

const Mosaics = ({ large_product, small_product }) => {
  usePageScrollTracker()
  const classes = useStyles()
  const muiTheme = useTheme()
  const isMobile = useMobileBreakpoint()

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
      {/* I CAN BE YOUR HERO BABY */}
      <ProductDescriptionSection
        image={(
          <picture>
            <source srcSet={ProductLG} media={muiTheme.breakpoints.up('xl').replace('@media ', '')} />
            <source
              srcSet={Product}
              media={muiTheme.breakpoints.up('md').replace('@media ', '')}
            />
            <source srcSet={ProductMobile} />
            <img src={Product} />
          </picture>
        )}
        productBg={theme.colors.bg.purple}
        descriptionBg={theme.colors.bg.yellow}
        imageStyle={'contain'}
      >
        <Typography gutterBottom variant={'h1'} align={isMobile ? 'center' : 'left'}>
          Framed Mosaics
        </Typography>

        <Box lineHeight={1.6} mb={4}>
          <Typography gutterBottom>
            Boost your little artist&apos;s confidence by putting their artwork
            front and center! Start by filling an Artkive Box with art, then we&apos;ll
            transform it into a beautiful mosaic that showcases your child&apos;s
            creativity and looks great in your home.
          </Typography>
        </Box>

        <Hidden mdUp>
          <Divider />
        </Hidden>

        {mosaicsProductBullets.map(({ text }, index) => (
          <ProductDescriptionItem key={index}>
            {text}
          </ProductDescriptionItem>
        ))}

        <Box className={classes.mosaicPriceLabel} mt={isMobile ? 5 : 2}>
          <Typography variant={'body1'}>
            <strong>
              <ProductPriceTypography>${large_product.price}</ProductPriceTypography> {large_product.name}
            </strong>
          </Typography>
        </Box>
        <Box className={classes.mosaicPriceLabel}>
          <Typography variant={'body1'}>
            <strong>
              <ProductPriceTypography>${small_product.price}</ProductPriceTypography> {small_product.name}
            </strong>
          </Typography>
        </Box>
        <Box mt={isMobile ? 1.5 : 2}>
          <Typography variant={'caption'} component={'div'}>
            *Price does not include Artkive Box
          </Typography>
          <Typography variant={'caption'} component={'div'}>
            **Prices do not include cost of digitizing images
          </Typography>
        </Box>

        <Box display={'flex'} alignItems={'center'} mt={5} style={{ justifyContent: isMobile ? 'center' : 'left' }}>
          <TrackButton
            color={'primary'}
            href={routes.boxCheckout}
            size={'large'}
            variant={'contained'}
            trackerEvent={GET_BOX_CLICK}
            trackerOptions={{
              section: 'get_mosaics_banner',
            }}
          >
            Get My Box
          </TrackButton>
        </Box>
      </ProductDescriptionSection>
      {/* I CAN BE YOUR HERO BABY */}

      {/* Testimonial */}
      <ContainedSection>
        <Grid container direction={isMobile ? 'column-reverse' : 'row'}>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              backgroundColor: muiTheme.palette.common.white,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box px={8} py={4}>
              <Box mb={4}><FormatQuoteRounded color={'primary'} fontSize={'large'} /></Box>
              <Typography gutterBottom variant={'body1'}>
                I arrived home from work today to the best package ever:
                our three beautiful framed mosaics! We had a house fire and the finished mosaics perfectly preserve
                our memories.
              </Typography>
              <Box alignItems={'center'} display={'flex'} mt={4}>
                <Image src={Customer} style={{ maxWidth: '45px' }} />
                <Box fontSize={'12px'} ml={2}>Kerry B. - Pelham, NH</Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Image src={Testimonial} style={{ objectFit: 'cover', minHeight: '100%' }} />
          </Grid>
        </Grid>
      </ContainedSection>

      {/* how it works */}
      <HowItWorks variant={'image'} title={'How It Works'} steps={defaultHowItWorks}>
        <Box justifyContent={'center'} display={'flex'} pt={10}>
          <Button
            color={'primary'}
            href={routes.checkout}
            size={'large'}
            style={{ paddingLeft: 72, paddingRight: 72 }}
            variant={'contained'}
          >
            Get My Box
          </Button>
        </Box>
      </HowItWorks>

      {/* about our mosaics */}

      <ContainedSection
        title={(
          <Typography gutterBottom variant={'h2'} align={'center'}>
            A whole new kind of gallery wall.
          </Typography>
        )}
        className={classes.aboutMosaic}
      >
        <Box position={'relative'} textAlign={'center'}>
          {/* accents */}
          <Box position={'absolute'} top={'-50px'} right={'-10px'} zIndex={1}>
            <Image src={sprite} style={{ width: '64px', transform: 'scaleX(-1)' }} />
          </Box>
          <Box position={'absolute'} bottom={'60px'} left={'-10px'} zIndex={0}>
            <Image src={Bubbles} style={{ width: '80px', transform: 'scaleX(-1)' }} />
          </Box>
          <Grid container style={{ position: 'relative', zIndex: '0' }}>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'} px={isMobile ? 0 : 4} mb={isMobile ? 6 : 0}>
                <Box mb={3}><Image src={About1} /></Box>
                <Typography variant={'h3'}>1. A solid wood gallery frame worthy of any room.</Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'} px={isMobile ? 0 : 4} mb={isMobile ? 6 : 0}>
                <Box mb={3}><Image src={About2} /></Box>
                <Typography variant={'h3'}>2. Professional matting helps your artwork shine. </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'} px={isMobile ? 0 : 4} mb={isMobile ? 6 : 0}>
                <Box mb={3}><Image src={About3} /></Box>
                <Typography variant={'h3'}>3. A brilliant way to showcase themed art, too!</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ContainedSection>

      <DigitizedImagesAccessSection />

      <GetStartedWithBoxSection />

      {/* faqs */}
      <Faqs faqs={FAQS} trackerEvent={FRAMED_MOSAIC.FAQ_ITEM_OPEN} />

      {/* Testimonial */}

      <YouWillAlsoLove image={ArtBooks}>
        <Box mb={2}>
          <Typography variant={'h3'}>Art Books</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            A glossy and vibrant hardcover book that showcases artwork and holds memories.
          </Typography>
        </Box>
        <Box alignItems={'center'} display={'flex'} mb={3} justifyContent={isMobile ? 'center' : 'flex-start'}>
          <Typography variant={'body1'}>
            <strong>
              Starting at <Typography variant={'h2'} component={'span'}>$75</Typography> for{' '}
              {MIN_BOX_BOOK_NUMBER_OF_PAGES}-pages Book
            </strong>
          </Typography>
        </Box>
        <TrackButton
          color={'primary'}
          href={routes.books}
          variant={'contained'}
          size={'large'}
          trackerEvent={NAVIGATION.ART_BOOK_CLICK}
          trackerOptions={{
            section: 'books_banner',
          }}
        >
          See Art Books
        </TrackButton>
      </YouWillAlsoLove>
    </Box>
  )
}

Mosaics.propTypes = {
  large_product: PropTypes.object.isRequired,
  small_product: PropTypes.object.isRequired,
}

export default withApp(Mosaics)
