import React from 'react'

import ReactMarkdown from 'react-markdown'
import { Link } from '@material-ui/core'

import useDebounce from 'common/hooks/useDebounce'

const MarkdownText = ({ source, ...rest }) => {
  const debouncedState = useDebounce(source)

  return (
    <ReactMarkdown
      allowedElements={['strong', 'link', 'a', 'p', 'list', 'ol', 'ul', 'li']}
      composing={true}
      components={{
        p: 'span',
        a: Link,
      }}
      {...rest}
    >
      {debouncedState}
    </ReactMarkdown>
  )
}

export default MarkdownText
