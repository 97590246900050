import { useCallback, useState } from 'react'

import addressValidationService from 'artkive/utils/addressValidationService'

const useAddressSuggestion = () => {
  const [suggestion, setSuggestion] = useState()

  const checkAddress = useCallback(async (address) => {
    const { error, response } = await addressValidationService.checkAddress(address)

    if (response) {
      setSuggestion(response)
      return {
        response,
      }
    }

    return {
      error,
    }
  }, [setSuggestion])

  const closeSuggestion = useCallback(() => {
    setSuggestion(null)
  }, [setSuggestion])

  return {
    suggestion,
    checkAddress,
    closeSuggestion,
  }
}

export default useAddressSuggestion
