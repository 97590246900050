import React from 'react'

import { Box, Container, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { compMembershipPeriod } from 'artkive/stores/ecom/constants/memberships'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import digitizedImagesAccess from 'images/ecom/home/digitizedImagesAccess.png'
import digitizedImagesAccessX1 from 'images/ecom/home/digitizedImagesAccess-x1.png'
import digitizedImagesAccessX2 from 'images/ecom/home/digitizedImagesAccess-x2.png'

const digitizedImagesBullets = [
  {
    text: `${compMembershipPeriod} months FREE access with the option to get a membership to extend`,
  },
  {
    text: 'Accessible on your Artkive account through the website and iOS App with the option to extend for long-term safekeeping',
  },
  {
    text: 'Order additional books or mosaics with your digital images',
  },
]

const useStyles = makeStyles(() => ({
  picture: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
  },
}))

const DigitizedImagesAccessSection = (props) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()
  const muiTheme = useTheme()

  return (
    <Box pt={isMobile ? 1 : theme.spacings.section} pb={isMobile ? 0 : theme.spacings.section} {...props}>
      <Container disableGutters={isMobile}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <picture>
              <source
                srcSet={`${digitizedImagesAccess}`}
                media={muiTheme.breakpoints.up('lg').replace('@media ', '')}
              />
              <source
                srcSet={`${digitizedImagesAccessX2}, ${digitizedImagesAccess} 2x`}
                media={muiTheme.breakpoints.up('md').replace('@media ', '')}
              />
              <img
                srcSet={`${digitizedImagesAccessX1}, ${digitizedImagesAccessX2} 2x`}
                src={digitizedImagesAccessX1} className={classes.picture}
              />
            </picture>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              px={isMobile ? 2 : 10}
              pt={4}
              pb={isMobile ? theme.spacings.section : 4}
              bgcolor={isMobile ? theme.colors.white.main : undefined}
            >
              <Typography variant={'h2'} align={isMobile ? 'center' : 'left'}>
                Digital Image Access
              </Typography>

              <Box lineHeight={1.6} mt={isMobile ? 1 : 3} mb={3}>
                <Typography gutterBottom variant={'subtitle1'} align={isMobile ? 'center' : 'left'}>
                  You will receive access to your digital images with your box book or mosaic.
                </Typography>
              </Box>

              {digitizedImagesBullets.map(({ text }, index) => (
                <ProductDescriptionItem key={index}>
                  {text}
                </ProductDescriptionItem>
              ))}

              <Box display={'flex'} justifyContent={isMobile ? 'center' : 'left'} mt={isMobile ? 4 : 6}>
                <TrackButton
                  color={'primary'}
                  href={routes.boxCheckout}
                  size={'large'}
                  variant={'contained'}
                  trackerEvent={GET_BOX_CLICK}
                  trackerOptions={{
                    section: 'get_mosaics_banner',
                  }}
                >
                  Get My Box
                </TrackButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default DigitizedImagesAccessSection
