import { MIN_AMOUNT_OF_PICS } from 'common/utils/conciergeDigitalInvoiceItem.constants'

// import CopyDiscount from 'images/ecom/pricing/addons/CopyDiscount.svg'
import Discount from 'images/ecom/pricing/addons/Discount.svg'
import ExtraCopies from 'images/ecom/pricing/addons/ExtraCopies.svg'
import ReturnArtImage from 'images/ecom/pricing/addons/ReturnArt.svg'
import RushImage from 'images/ecom/pricing/addons/Rush.svg'
import SecurityProtection from 'images/ecom/pricing/addons/SecurityProtection.svg'
import USBImage from 'images/ecom/pricing/addons/USB.svg'

export const returnArtAddon = {
  title: 'Art Return — ${price}',
  description: 'Have your original pieces shipped back to you.',
  image: ReturnArtImage,
}

export const returnUsbAddon = {
  title: 'USB — ${price}',
  description: 'USB drive with all of your high resolution images.',
  image: USBImage,
}

export const restAddons = [
  {
    title: 'Shipping Protection',
    description: 'Coverage for the very rare occurrence of package loss during UPS shipping of your art.',
    image: SecurityProtection,
  },
  {
    title: 'Rush Processing',
    description: 'Get your book or mosaic in half the normal processing time.',
    image: RushImage,
  },
]

export const productBullets = [
  { text: '24”x 17”x 4” crush-proof Artkive Box (holds 300-400 pieces of art)' },
  // { text: 'Re-sealable art protection bag' },
  // { text: 'Tips and tricks for organizing your art' },
  { text: 'Prepaid shipping label to send back your box' },
  { text: '2nd Payment Based on Number of Pieces Sent' },
]

export const costAddons = [
  {
    title: `Want Extra Copies?`,
    description: 'Get copies of your book for 60% OFF!',
    image: ExtraCopies,
  },
  {
    title: `Mosaic Discount`,
    description: 'Add a Framed Mosaic and get a $50 discount',
    image: Discount,
  },
]

export const BASE_PRICE = 39

export const PRICING = {
  box: {
    base: 39,
  },
  books: {
    base: 75,
    minPages: MIN_AMOUNT_OF_PICS,
  },
  digital: {
    base: 45,
  },
}
