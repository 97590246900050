import React, { useMemo } from 'react'

import { Route, Router, Switch } from 'wouter'
import staticLocationHook from 'wouter/static-location'
import browserLocationHook from 'wouter/use-location'

import checkoutTrackingMetaCreator from 'artkive/components/Checkout/checkoutTrackingMetaCreator'
import EmailListForm from 'artkive/components/EmailListForm'
import Footer from 'artkive/components/Footer'
import Navbar from 'artkive/components/Navbar'
import withApp from 'artkive/hoc/withApp'
import routes from 'artkive/utils/routes'

import GiftCardConfirmation from '../GiftCardConfirmation/GiftCardConfirmation'
import GiftCards from '../GiftCards/GiftCards'

import GiftCardCheckout from './GiftCardCheckout'

const isSSR = typeof window === 'undefined'

const rootBase = ''

const GiftCardCheckoutNestedRouting = (props) => {
  const useLocation = isSSR ? staticLocationHook(props.railsContext.pathname.replace(rootBase, '')) : browserLocationHook

  const productLinks = useMemo(() => ({
    landing: routes.giftCards,
    checkout: routes.giftCardCheckout,
    confirmation: routes.giftCardConfirmation,
  }), [])

  return (
    <Switch>
      <Route path={productLinks.landing}>
        <Navbar />
        <GiftCards {...props} productLinks={productLinks} />
        <EmailListForm zIndex={100} />
        <Footer />
      </Route>
      <Route path={`/giftcards/:uuid/checkout/:activeStep?`}>
        {(params) => (
          <Router base={`/giftcards/${params.uuid}/checkout`} hook={useLocation}>
            <GiftCardCheckout {...props} params={params} productLinks={productLinks} />
          </Router>
        )}
      </Route>
      <Route path={'/giftcards/:uuid/confirmation'}>
        {(params) => (
          <>
            <Navbar />
            <GiftCardConfirmation {...props} params={params} productLinks={productLinks} />
            <Footer />
          </>
        )}
      </Route>
    </Switch>
  )
}

export default withApp(GiftCardCheckoutNestedRouting, { trackingMetaCreator: checkoutTrackingMetaCreator })
