import React from 'react'

import { Slider } from '@material-ui/core'

import { MAX_NUMBER_OF_PAGES, MIN_BOX_BOOK_NUMBER_OF_PAGES } from 'common/utils/bookSize.constants'
import { withClickTracker } from 'artkive/components/Tracker'
import { ART_BOOK } from 'artkive/constants/tracker/mainNavigation'

const TrackSlider = withClickTracker(Slider, 'onChangeCommitted')

const PriceSlider = ({ ...rest }) => {
  return (
    <TrackSlider
      className={'test-book-price-slider'}
      step={1}
      min={MIN_BOX_BOOK_NUMBER_OF_PAGES}
      max={MAX_NUMBER_OF_PAGES}
      trackerEvent={ART_BOOK.SLIDER_INTERACTION}
      {...rest}
    />
  )
}

export default PriceSlider
