import React from 'react'

import { Box, Button, Grid, Hidden, Paper, Typography } from '@material-ui/core'

import Image from 'artkive/components/Image'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import ArtOfMe from 'images/ecom/checkout/BoxConfirmation-ArtofMe.jpg'
import Lifekive from 'images/ecom/checkout/BoxConfirmation-Lifekive.jpg'

const OtherProductsBox = () => {
  const isMobile = useMobileBreakpoint()

  return (
    <>
      <Box my={8} position={'relative'}>
        <Hidden smDown>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Image src={ArtOfMe} />
            </Grid>
            <Grid item xs={false} md={4}>
              <Box bgcolor={'primary.light'} height={'100%'}>&nbsp;</Box>
            </Grid>
          </Grid>
        </Hidden>

        <Box
          position={isMobile ? 'relative' : 'absolute'}
          top={isMobile ? '0' : '50%'}
          right={isMobile ? '0' : '10%'}
          style={{ transform: isMobile ? 'none' : 'translate(0, -50%)', maxWidth: '700px' }}
        >
          <Paper elevation={0}>
            <Box p={isMobile ? 2 : 10}>
              <Typography gutterBottom variant={'subtitle1'}>OTHER PRODUCTS</Typography>
              <Typography variant={'h1'}>Art of Me —</Typography>
              <Typography gutterBottom variant={'h3'}>Your Child&apos;s First Autobiography</Typography>
              <Box mb={4}>
                <Typography variant={'subtitle2'}>
                  Art of Me is a 21 page collection of art and writing prompts your child completes and we turn into a
                  book. It perfectly captures a snapshot of their childhood that you will cherish forever.
                </Typography>
              </Box>
              <Button color={'primary'} href={routes.artOfMe} target={'_blank'} size={'large'} variant={'contained'}>
                Learn More
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>

      <Box my={8} position={'relative'}>
        <Hidden smDown>
          <Grid container spacing={4}>
            <Grid item xs={false} md={4}>
              <Box bgcolor={theme.colors.purple.light} height={'100%'}>&nbsp;</Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Image src={Lifekive} />
            </Grid>
          </Grid>
        </Hidden>

        <Box
          position={isMobile ? 'relative' : 'absolute'}
          top={isMobile ? '0' : '50%'}
          left={isMobile ? '0' : '10%'}
          style={{ transform: isMobile ? 'none' : 'translate(0, -50%)', maxWidth: '700px' }}
        >
          <Paper elevation={0}>
            <Box p={isMobile ? 2 : 10}>
              <Typography gutterBottom variant={'subtitle1'}>OTHER PRODUCTS</Typography>
              <Typography variant={'h1'}>Lifekive —</Typography>
              <Typography gutterBottom variant={'h3'}>Safeguard Your Legacy</Typography>
              <Box mb={4}>
                <Typography variant={'subtitle2'}>
                  Lifekive is an exclusive concierge service dedicated to celebrating and preserving your most essential
                  memories and moments.
                </Typography>
              </Box>
              <Button color={'primary'} href={routes.lifekive} target={'_blank'} size={'large'} variant={'contained'}>
                Learn More
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

export default OtherProductsBox
