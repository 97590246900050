import { useCallback, useEffect, useRef } from 'react'

const useClosure = (fn, deps = []) => {
  const ref = useRef()

  useEffect(() => {
    ref.current = fn
  }, [fn, ...deps])

  return useCallback((...args) => {
    if (typeof ref.current !== 'function') {
      return
    }

    return ref.current(...args)
  }, [ref])
}

export default useClosure
