import React from 'react'

export const DashedBorder = (props) => {
  return (
    <div
      {...props}
      style={{
        color: 'currentColor',
        position: 'absolute',
        inset: 0,
        pointerEvents: 'none',
        ...props.style,
      }}
    >
      <svg
        xmlns={'http://www.w3.org/2000/svg'}
        width={'100%'}
        height={'100%'}
        overflow={'visible'}
      >
        <rect
          rx={8}
          ry={8}
          width={'100%'}
          height={'100%'}
          fill={'none'}
          stroke={'currentColor'}
          // strokeOpacity={'40%'}
          strokeWidth={'2'}
          strokeDasharray={'6, 8'}
          strokeLinecap={'round'}
        />
      </svg>
    </div>
  )
}
