import React from 'react'

import { Box, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const StyledButton = withStyles(({ breakpoints }) => ({
  root: {
    minWidth: 280,
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))(Button)

const SubmitButton = ({ ...rest }) => {
  return (
    <Box mt={6} display={'flex'} justifyContent={'space-around'}>
      <StyledButton
        color={'primary'}
        size={'large'}
        variant={'contained'}
        type={'submit'}
        {...rest}
      />
    </Box>
  )
}

export default SubmitButton
