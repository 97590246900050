import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { get } from 'react-hook-form'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import Faqs from 'artkive/components/Faqs'
import ImageContainer from 'artkive/components/ImageContainer'
import MarkdownText from 'artkive/components/MarkdownText'
import ContainerBox from 'artkive/components/PageSections/ContainerBox'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import Swipe from 'artkive/components/Swipe'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import topLines from 'images/ecom/get-started/lines.png'
import bottomLines from 'images/ecom/get-started/lines2.svg'

import ConfirmationCostsBox from './ConfirmationCostsBox'
import ConfirmationSocialBox from './ConfirmationSocialBox'
import OtherProductsBox from './OtherProductsBox'

const BoxGradient = withStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      backgroundColor: '#E3F2FD',
    },
  },
}), {
  withTheme: true,
})(ContainerBox)

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    minHeight: 'calc(100vh - 64px)',
    backgroundColor: 'rgb(245, 249, 250)',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '&$container': {
      padding: '60px',
      [breakpoints.down('sm')]: {
        marginTop: 50,
        padding: '0 80px',
      },
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  productContainer: {
    marginBottom: 64,
  },
  productImage: {
    flexBasis: '100%',
    width: '100%',
    height: 0,
    maxWidth: '100%',
    [breakpoints.down('sm')]: {
      width: 210,
    },
  },
  header: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontSize: '2rem',
    [breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginTop: '1.5rem',
      padding: '0 42px',
    },
  },
  subtitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  topLines: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '15%',
    transform: 'translate(-70%, -100%)',
  },
  bottomLines: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    height: '30%',
    transform: 'translate(30%, -10%)',
    [breakpoints.down('sm')]: {
      transform: 'translate(50%, -10%)',
    },
  },
}))


const OrderConfirmation = ({ productData, mosaicPricing }) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  const data = useMemo(() => ({
    cover_url: get(productData, 'cover_image.url', ''),
    preview_url: get(productData, 'preview_image.url', ''),
    header: get(productData, 'properties.confirmation.header', ''),
    subheader: get(productData, 'properties.confirmation.subheader', ''),
    faqs: get(productData, 'properties.confirmation.faq.items', null),
    next_steps: get(productData, 'properties.confirmation.next_steps', null),
    cost_breakdown: get(productData, 'properties.confirmation.cost_breakdown', false),
    other_products: get(productData, 'properties.confirmation.other_products', false),
    ugc: get(productData, 'properties.confirmation.ugc', false),
  }), [productData])

  return (
    <Box className={classes.root}>
      <BoxGradient p={isMobile ? 0 : 8} mb={isMobile ? 0 : 12} flex={1}>
        <Grid container spacing={isMobile ? 0 : 8} className={classes.productContainer}>
          <Grid item xs={12} md={6} className={classes.container}>
            <ImageContainer src={data.preview_url || data.cover_url} className={classes.productImage} ratio={5 / 4}>
              <img className={classes.topLines} src={topLines} />
              <img className={classes.bottomLines} src={bottomLines} />
            </ImageContainer>
          </Grid>

          <Grid item xs={12} md={6} className={classes.description}>
            {/* <Box color='secondary.dark' fontWeight='bold' mb={2}>Order #{params.orderId}</Box> */}
            <Box className={classes.header} px={1}>
              <Typography gutterBottom variant={'h2'} className={classes.title}>
                {data.header}
              </Typography>
              <Typography gutterBottom variant={'subtitle2'} className={classes.subtitle}>
                <MarkdownText source={data.subheader} />
              </Typography>
            </Box>

            {data.cost_breakdown && <ConfirmationCostsBox mosaicPricing={mosaicPricing} />}
          </Grid>
        </Grid>
      </BoxGradient>

      {!!data.next_steps && (
        <HowItWorks
          title={data.next_steps.title}
          steps={data.next_steps.items}
          variant={data.next_steps.variant}
        >
          {data.next_steps.show_help_link && (
            <Box mt={4} textAlign={'center'}>
              <Swipe color={'primary'} href={routes.lifekiveFaqs} target={'_blank'} underline={'none'} variant={'h6'}>
                View FAQs
              </Swipe>
            </Box>
          )}
        </HowItWorks>
      )}

      {data.ugc && <ConfirmationSocialBox />}

      {/* OTHER PRODUCTS */}
      {data.other_products && <OtherProductsBox />}
      {/* OTHER PRODUCTS */}

      {/* FAQS */}
      {!!data.faqs && (
        <Faqs bgcolor={theme.colors.white.dark} faqs={data.faqs} />
      )}

    </Box>
  )
}

OrderConfirmation.propTypes = {
  mosaicPricing: PropTypes.object.isRequired,
  productData: PropTypes.object,
}

export default OrderConfirmation
