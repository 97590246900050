import React from 'react'

import { Box, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#E0F5E9',
    border: '1px solid #208E46',
    padding: 24,
    borderRadius: 8,
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
  },
  icon: {
    marginRight: 8,
    color: '#208E46',
  },
}))

const FreeOrderPaper = () => {
  const classes = useStyles()

  return (
    <Paper className={classes.root} elevation={0}>
      <Box fontWeight={'bold'} fontSize={'1.25rem'} display={'flex'} alignItems={'center'} mb={1.5}>
        <InfoOutlinedIcon className={classes.icon} /> Your order balance is $0.
      </Box>
      <Typography>No payment required at this time.</Typography>
    </Paper>
  )
}

export default FreeOrderPaper
