import australia from './australia'
import canada from './canada'
import uk from './uk'
import us from './us'

export default {
  US: us,
  CA: canada,
  AU: australia,
  UK: uk,
}
