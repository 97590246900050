import React from 'react'
import PropTypes from 'prop-types'

import { AppBar, Box, Link, Toolbar } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import AppSnackbar from 'artkive/components/AppSnackbar'
import Image from 'artkive/components/Image'
import theme from 'artkive/utils/theme'

import artkiveLogo from 'images/ecom/nav/artkiveLogo.svg'

const TopBar = withStyles(() => ({
  root: {
    backgroundColor: theme.colors.white.main,
    boxShadow: theme.shadows[4],
  },
}))(AppBar)

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1 1',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.sky[100],
    [breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      paddingTop: '124px',
    },
  },
  logo: {
    width: 140,
    minWidth: 140,
    [breakpoints.up('md')]: {
      width: 200,
    },
  },
}))

const PageLayout = ({ error, setError, children }) => {
  const classes = useStyles()

  return (
    <>
      <Box position={'relative'} id={'Headless-navbar-react-component'}>
        <TopBar position={'fixed'}>
          <Toolbar>
            <Link href={'/'}><Image src={artkiveLogo} className={classes.logo} /></Link>
          </Toolbar>
        </TopBar>
      </Box>
      <Box className={classes.root}>
        <AppSnackbar
          variant={'error'}
          message={error}
          onClose={() => setError(null)}
        />

        {children}
      </Box>
    </>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  setError: PropTypes.func.isRequired,
  error: PropTypes.string,
}

export default PageLayout
