import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { newColors } from 'artkive/utils/theme'

const useStyles = makeStyles(({ spacing, palette }) => ({
  messageSection: {
    backgroundColor: newColors.yellow[100],
    borderRadius: 4,
    padding: spacing(1.5),
    marginTop: spacing(2),
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  text: {
    color: palette.secondary.dark,
    fontWeight: 500,
  },
  line: {
    paddingTop: spacing(0.5),
  },
}))

const DetailsGiftCard = ({ to, from, message }) => {
  const classes = useStyles()

  return (
    <div className={classes.messageSection}>
      {to && (
        <div>
          <Typography variant={'body2'} component={'span'}>To: </Typography>
          <Typography variant={'body2'} className={classes.text} component={'span'}>
            {to}
          </Typography>
        </div>
      )}
      {from && (
        <div className={classes.line}>
          <Typography variant={'body2'} component={'span'}>From: </Typography>
          <Typography variant={'body2'} component={'span'} className={classes.text}>
            {from}
          </Typography>
        </div>
      )}
      {message && (
        <Typography variant={'body2'} className={cl(classes.text, classes.line)}>{message}</Typography>
      )}
    </div>
  )
}

DetailsGiftCard.propTypes = {
  to: PropTypes.string,
  from: PropTypes.string,
  message: PropTypes.string,
}

export default DetailsGiftCard
