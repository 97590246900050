import * as Sentry from '@sentry/react'
import {
  action,
  computed,
  // debug,
  thunk,
  thunkOn,
} from 'easy-peasy'

import { findVariableStoreName } from 'artkive/hooks/useVariableStore'
import { STORE_NAME as BOX_STORE_NAME } from 'artkive/stores/ecom/stores/box.store'
import { PROMO_QUERY_FILED } from 'artkive/stores/product.constants'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import * as track from 'artkive/utils/tracker'
import { validateEmail } from 'artkive/utils/validations'

import validatePromoCode from '../api/validatePromoCode'

export const PROMO = PROMO_QUERY_FILED
const PROMO_CODE_DEFAULT_VALUE = 'artkive'
const PROMO_TYPE_PROMO = PROMO

const DEFAULT_STATE = {
  email: '',
  tracking: {},
  promo: {
    code: '',
    product_id: 0,
    requesting: false,
    revalidating: false,
    type: '',
    discountMethod: '',
    validated: false,
    productName: '',
  },
}

const promoStore = {
  // state
  ...DEFAULT_STATE,

  // computed
  promoCode: computed(({ promo: { code } }) => code),

  promoType: computed(({ promo: { type } }) => type),

  promoValidated: computed(({ promo: { validated } }) => validated),

  // actions
  resetPromo: action((state) => {
    state.promo = { ...DEFAULT_STATE.promo }
  }),

  setEmail: action((state, { email }) => {
    state.email = email
  }),

  setPromo: action((state, { code, type = 'user', ...rest }) => {
    if (code === PROMO_CODE_DEFAULT_VALUE && !!state.promo.code && state.promo.validated) {
      return
    }

    state.promo = {
      code: (code || '').trim(),
      requesting: true,
      type,
      validated: false,
      discountMethod: '',
      ...rest,
    }
  }),
  patchPromo: action((state, payload) => {
    state.promo = { ...state.promo, ...payload }
  }),

  setPromoDiscountMethod: action((state, { discountMethod }) => {
    state.promo.discountMethod = discountMethod
  }),

  setPromoValid: action((state, {
    requesting = false,
    validated = true,
  }) => {
    state.promo.requesting = requesting
    state.promo.revalidating = false
    state.promo.validated = validated
  }),

  // called by async thunk validate
  __validate: action((state, patch = {}) => {
    state.promo.requesting = true
    state.promo.revalidating = true
    state.promo.validated = false
    state.promo = {
      ...state.promo,
      ...patch,
    }
  }),

  setTracking: action((state, payload = {}) => {
    state.tracking = payload
  }),

  clearTracking: action((state) => {
    state.tracking = {}
  }),

  // thunks
  setPromoUrl: thunk(async ({ setPromo }, payload) => {
    if (payload?.code) {
      setPromo({ ...payload, type: 'url' })
    }
  }),

  sendPromoEmail: thunk(async ({ setEmail, setPromo, setPromoValid }, {
    onBefore,
    email,
    formType,
    onDone,
    onFail,
    product,
    type = PROMO_TYPE_PROMO,
  }) => {
    try {
      if (!validateEmail(email)) throw new Error(`The email ${email} is invalid. Please try again`)

      const code = PROMO_CODE_DEFAULT_VALUE
      const productName = BOX_PRODUCT_TYPE

      if (typeof onBefore === 'function') onBefore()

      // dispatches segment and internal api tracking requests
      track.captureEmail({ email, formType, kind: PROMO, product })

      // listeners bind to these events
      setEmail({ email })
      setPromo({ code, requesting: false, type, productName })
      setPromoValid(true)

      if (typeof onDone === 'function') onDone({
        code, email, formType, product, type, productName,
      })
    } catch (err) {
      console.error('Error: could not dispatch email or set promo code', err)

      Sentry.captureException(err)

      if (typeof onFail === 'function') onFail(err)
    }
  }),

  validate: thunk(async (actions, payload) => actions.__validate(payload)),

  checkPromoForFutureUse: thunk(async ({ resetPromo }, _payload, { getState }) => {
    try {
      const { code, product_id, productName } = getState().promo

      await validatePromoCode(code, product_id, productName)
    } catch (err) {
      await resetPromo()
    }
  }),

  tryToReusePromo: thunk(({ patchPromo, resetPromo }, payload, { getState }) => {
    const { productName } = getState().promo
    if (payload.code) {
      patchPromo(payload)
    } else if (payload.productName === productName) {
      const { code, ...rest } = payload
      patchPromo(rest)
    } else {
      resetPromo()
    }
  }),

  // listeners
  onSetPromo: thunkOn(
    (actions) => [
      actions.setPromo,
      actions.__validate,
    ],
    async ({ resetPromo, setPromoValid, setPromoDiscountMethod }, target, helpers) => {
      const state = helpers.getStoreState()
      const { code, revalidating, product_id, uuid, productName } = helpers.getStoreState().promoStore.promo
      const { payload } = target

      const modelName = findVariableStoreName(productName, uuid)

      const orderStore = modelName in state ? state[modelName] : state[BOX_STORE_NAME]
      const information = orderStore.information

      if (payload?.requesting === false) {
        return
      }

      if (!code || code === '')
        return setPromoValid({ requesting: false, validated: false })

      try {
        const data = await validatePromoCode(code, product_id, productName, information.email)

        setPromoValid({ requesting: false, validated: true })
        setPromoDiscountMethod({ discountMethod: data.discount_method })
      } catch (err) {
        if (revalidating) {
          resetPromo()
        } else {
          setPromoValid({ requesting: false, validated: false })
          setPromoDiscountMethod({ discountMethod: '' })
        }
      }
    },
  ),
}

export default promoStore
