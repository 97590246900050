import React from 'react'

import {
  Box,
  Dialog,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

/**
 * NOTE: doesn't support SSR
 */
const Preview = ({ onClose, fullScreen, ...props }) => {
  const previewLink = `${window.app.clientUrl}/preview`

  return (
    <Dialog fullScreen={fullScreen} {...props} aria-labelledby={'form-dialog-title'}>
      <Box position={'relative'}>
        <Close
          color={'primary'}
          fontSize={'large'}
          onClick={onClose}
          style={{ cursor: 'pointer', position: 'absolute', top: '1rem', right: '1rem' }}
        />
        <iframe
          border={'0'}
          width={'100%'}
          height={'100vh'}
          src={previewLink}
          style={{ minHeight: fullScreen ? '100vh' : '90vh' }}
          allowFullScreen
        ></iframe>
      </Box>
    </Dialog>
  )
}

export default Preview
