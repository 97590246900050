import React, { useEffect } from 'react'

import { Box, Container, Link, Typography } from '@material-ui/core'

import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import withApp from 'artkive/hoc/withApp'

import LatestBody, { LATEST_TOU_BOX_ID } from './LatestBody'
import PriorBody from './PriorBody'

const Tou = () => {
  usePageScrollTracker()

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://consent.cookiebot.com/8ccb5458-ee3e-4788-a788-01b997ce8367/cd.js'
    script.async = true
    script.id = 'CookieDeclaration'

    document.getElementById(LATEST_TOU_BOX_ID).appendChild(script)

    return () => {
      document.getElementById(LATEST_TOU_BOX_ID).removeChild(script)
    }
  }, [])

  return (
    <Box py={10}>
      <Container>
        <Box>
          <Typography align={'center'} variant={'h2'}>Terms of Use</Typography>
          <br />
          <ul>
            <li>
              <Link href={'#updated-terms-of-use'}>
                <strong>Updated Terms of Use</strong>
              </Link>
            </li>
            <li>
              <Link href={'#previous-terms-of-use'}>
                <strong>Previous Terms of Use May 24, 2022 - July 10, 2024</strong>
              </Link>
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <LatestBody />
          <PriorBody />
        </Box>
      </Container>
    </Box>
  )
}

export default withApp(Tou)
