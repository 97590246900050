import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import SummaryDetailsBox from 'artkive/components/Checkout/SummaryDetailsBox'
import { AddonPrice } from 'artkive/components/OrderSummary/PriceWithDiscount/index'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import formatPrice from 'artkive/utils/formatPrice'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

import Chip from './Chip'
import { List, ListItemDiscountedPrice, ListItemSecondaryActionDiscountedPrice, ListItemTextDiscountedPrice } from './DetailsList'

export const buildOrderDetailsList = (addOns, activeProcessing, price) => {
  const elements = []
  const order = [ADDONS.RETURN_ART, ADDONS.RETURN_USB, ADDONS.EXTRA_BOOKS]
  order.forEach((kind) => {
    addOns.forEach((addon) => {
      if (addon.kind === kind) {
        elements.push(addon)
      }
    })
  })

  if (activeProcessing) {
    elements.push({
      name: `${activeProcessing.name} Processing Time`,
      price: activeProcessing.price,
    })
  }

  if (price.shipping_protection_price) {
    elements.push({ name: 'Shipping Protection', price: price.shipping_protection_price })
  }

  addOns.forEach((addon) => {
    if (!order.includes(addon.kind) && addon.kind !== ADDONS.VIP_SUBSCRIPTION) {
      elements.push(addon)
    }
  })

  return elements
}

const BoxOrderDetailsSummary = ({ product }) => {
  const {
    price,
    processing,
    addOns,
    gift,
  } = useVariableState(product.type, product.uuid)

  const activeProcessing = processing && processing.find((p) => !!p.default)

  const items = useMemo(() => (
    buildOrderDetailsList(addOns, activeProcessing, price)
  ), [addOns, activeProcessing, price.shipping_protection_price])

  return (
    <SummaryDetailsBox
      title={
        <>
          Order Details
          {gift && <Chip label={'Gift Order'} />}
        </>
      }
    >
      <List>
        {items.map(({ name, price: itemPrice, quantity, kind }) => {
          const itemPriceWithDiscount = {
            base_price: Number(itemPrice),
            promo_discount_details: price.promo_discount_details,
          }
          const discountMethod = price.promo_discount_details?.discount_method
          const isUSBAddon = kind === ADDONS.RETURN_USB
          const showPriceWithDiscount =
            isUSBAddon && discountMethod === DISCOUNT_METHODS.FREE_USB
              || +itemPrice && discountMethod === DISCOUNT_METHODS.PERCENTAGE

          return (
            <ListItemDiscountedPrice key={name}>
              <ListItemTextDiscountedPrice>{name}{+quantity ? ` x ${quantity}` : ''}</ListItemTextDiscountedPrice>
              <ListItemSecondaryActionDiscountedPrice>
                {showPriceWithDiscount
                  ? (
                    <AddonPrice
                      enableUSBAddon={isUSBAddon && discountMethod === DISCOUNT_METHODS.FREE_USB}
                      isAddonSummary={true}
                      fullPriceInfo={itemPriceWithDiscount}
                    />
                  ) : +itemPrice
                    ? `+ ${formatPrice(itemPrice * (quantity ?? 1))}`
                    : 'FREE'
                }
              </ListItemSecondaryActionDiscountedPrice>
            </ListItemDiscountedPrice>
          )
        })}
      </List>
    </SummaryDetailsBox>
  )
}

BoxOrderDetailsSummary.propTypes = {
  product: PropTypes.object.isRequired,
}

export default BoxOrderDetailsSummary
