import React from 'react'
import PropTypes from 'prop-types'

import { lighten, makeStyles } from '@material-ui/core/styles'

const useIconStyles = makeStyles(() => ({
  root: ({ color }) => ({
    color: color,
    padding: '0.5rem',
    width: '2.25rem',
    height: '2.25rem',
    borderRadius: '50%',
    backgroundColor: lighten(color, 0.8),
    margin: '0 auto',
  }),
}))

const ColoredStageIcon = ({ color, icon: IconComponent }) => {
  const classes = useIconStyles({ color })
  return <IconComponent classes={classes} />
}

ColoredStageIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.object,
}

export default ColoredStageIcon
