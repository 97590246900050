import { Checkbox } from '@material-ui/core'

import withClickTracker from './withClickTracker'

const TrackCheckbox = withClickTracker(Checkbox, 'onChange', (_props, e) => {
  console.log(_props)
  return {
    action: e.target.checked ? 'check' : 'uncheck',
  }
})

TrackCheckbox.defaultProps = {
  trackAsync: true,
}

export default TrackCheckbox
