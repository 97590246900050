import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center',
    fontSize: '18px',
    color: theme.colors.black,
    letterSpacing: '0.03em',
    [muiTheme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
}))

export const OrderTrackingNumber = ({ orderNumber }) => {
  const classes = useStyles()
  return (
    <Typography variant={'subtitle1'} classes={classes}>
      <strong>Order Number:</strong> {orderNumber}
    </Typography>
  )
}

OrderTrackingNumber.propTypes = {
  orderNumber: PropTypes.number,
}
