import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Close, HelpOutline } from '@material-ui/icons'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

import Tooltip from './Tooltip'

const useStyles = makeStyles(({ spacing, palette }) => ({
  tipText: {
    fontSize: '.75rem',
    padding: spacing(2),
  },
  tooltip: {
    minWidth: 370,
    padding: '0 8px',
  },
  dialogContent: {
    fontSize: '1rem',
    padding: '8px 16px 24px',
    color: palette.secondary.main,
    fontWeight: 500,
  },
  anchor: {
    marginLeft: '4px',
  },
  small: {
    fontSize: '.75rem',
  },
  medium: {
    fontSize: '1rem',
  },
  large: {
    fontSize: '1.25rem',
  },
  dialogClose: {
    cursor: 'pointer', position: 'absolute', top: '1rem', right: '1rem', zIndex: '11',
  },
}))

const HelpTooltip = ({
  children,
  size,
  title,
  className,
  classes: givenClasses,
  ...rest
}) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMobileBreakpoint()

  const handleIconClick = useCallback(() => {
    if (!isMobile)
      return

    setIsOpen(true)
  }, [isMobile])

  return (
    <>
      <Tooltip
        arrow
        placement={'bottom'}
        title={(
          <Typography component={'div'} className={cl(classes.tipText, className)} {...rest}>
            {children}
          </Typography>
        )}
        classes={{
          tooltip: classes.tooltip,
        }}
      >
        <HelpOutline
          color={'action'}
          className={cl(givenClasses.anchor, classes.anchor, classes[size])}
          role={'button'}
          onClick={handleIconClick}
        />
      </Tooltip>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby={'form-dialog-title'}>
        <Box position={'relative'}>
          <Close
            color={'action'}
            fontSize={'medium'}
            onClick={() => setIsOpen(false)}
            className={classes.dialogClose}
          />

          <DialogContent className={classes.dialogContent}>
            <DialogTitle id={'form-dialog-title'} align={'center'}>
              {title}
            </DialogTitle>

            <Box textAlign={'left'} {...rest}>
              {children}
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>

  )
}

HelpTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  title: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
}

HelpTooltip.defaultProps = {
  size: 'small',
  title: null,
  className: null,
  classes: {},
}

export default HelpTooltip
