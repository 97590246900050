import { useEffect } from 'react'

import { useStoreRehydrated } from 'easy-peasy'

const useRehydratedEffect = (cb, deps) => {
  const rehydrated = useStoreRehydrated()

  useEffect(() => {
    if (!rehydrated)
      return

    cb()
  }, [rehydrated, ...deps])
}

export default useRehydratedEffect
