import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { newColors } from 'artkive/utils/theme'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    lineHeight: '1.4',
    display: 'flex',
    alignItems: 'center',

    [breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    },
  },
  container: {
    margin: ({ type }) => (
      type === 'addon'
        ? spacing(0, 0, 2.5)
        : spacing(4.5, 0, 1.5)
    ),

    [breakpoints.up('sm')]: {
      margin: spacing(5, 0, 2.5),
    },
  },
  note: {
    marginTop: spacing(0.5),
    color: newColors.grey[700],
  },
}))

const SectionHeader = ({ children, note, type = 'common' }) => {
  const classes = useStyles({ type })

  return (
    <div className={classes.container}>
      <Typography className={classes.root} variant={'h6'}>{children}</Typography>
      {note && (
        <Typography variant={'subtitle2'} className={classes.note}>{note}</Typography>
      )}
    </div>
  )
}

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  note: PropTypes.string,
  type: PropTypes.oneOf(['common', 'addon']),
}

export default SectionHeader
