import { useCallback } from 'react'

import { useStoreActions } from 'easy-peasy'
import { useLocation } from 'wouter'

import { MODALS } from 'artkive/components/Modals/constants'

export default () => {
  const { openModal } = useStoreActions(({ uiStore }) => uiStore)
  const [, setLocation] = useLocation()

  const open = useCallback((event) => {
    event.preventDefault()
    openModal({ name: MODALS.REFERRAL, setLocation })
  }, [openModal, setLocation])

  return open
}
