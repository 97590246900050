import React, { useState } from 'react'

import cl from 'classnames'
import { Box, Grid, Paper, Slider, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { getPrice } from 'common/utils/conciergeDigitalInvoiceItem'
import { MAX_AMOUNT_OF_PICS, MIN_AMOUNT_OF_PICS } from 'common/utils/conciergeDigitalInvoiceItem.constants'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import { TrackButton, withClickTracker } from 'artkive/components/Tracker'
import {
  ART_BOOK,
  GET_BOX_CLICK,
} from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { compMembershipPeriod } from 'artkive/stores/ecom/constants/memberships'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import digitalProduct from 'images/ecom/pricing/DigitalProduct.png'
import digitalProductX2 from 'images/ecom/pricing/DigitalProduct.png'
import digitalProductMobile from 'images/ecom/pricing/DigitalProduct-mobile.png'

const TrackSlider = withClickTracker(Slider, 'onChangeCommitted')

const digitizedImagesBullets = [
  { text: 'Professional photography, editing of each piece of art' },
  {
    text: `${compMembershipPeriod} Months Free access to your digital images on our website, the iOS ` +
      'app, and Free Shipping of your USB.',
  },
  { text: 'Cost is dependent on number of art pieces sent.' },
]

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(90deg, #fff 50%, #FFEFC7 50%);',
    height: 800,
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      background: theme.colors.white.main,
      height: 'auto',
    },
  },
  container: {
    padding: '0 16px',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
  },
  imageWrapper: {
    display: 'flex',
    paddingLeft: 30,
    [breakpoints.down('sm')]: {
      paddingLeft: 0,
      background: '#FFEFC7',
    },
  },
  picture: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
  },
  costHint: {
    '&&': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    height: 74,
    border: '2px solid #E6F1FC',
    borderRadius: 8,
    padding: '0 8px',
    textAlign: 'center',
    [breakpoints.up('md')]: {
      width: 120,
      textAlign: 'right',
    },
  },
  price: {
    fontSize: '1.5rem',
    lineHeight: 1.5,
  },
  sliderRail: {
    left: -16,
    right: -16,
    width: 'calc(100% + 32px)',
  },
  sliderTrack: {
    transform: 'translateX(-16px)',
  },
}))

const DigitalGallerySection = () => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()
  const muiTheme = useTheme()
  const [sliderValue, setSliderValue] = useState(MIN_AMOUNT_OF_PICS)

  const handleSliderChange = (e, value) => setSliderValue(value)

  return (
    <ContainedSection
      className={classes.wrapper}
      disableGutters={isMobile}
      py={0}
    >
      <Grid container direction={isMobile ? 'column-reverse' : 'row'}>
        <Grid item xs={12} md={6} className={classes.description}>
          <Box
            pr={isMobile ? 2 : 15}
            pl={2}
            py={isMobile ? 4 : 0}
            bgcolor={isMobile ? theme.colors.white.main : undefined}
          >
            <Typography variant={'h2'} align={isMobile ? 'center' : 'left'}>
              Digital Gallery
            </Typography>

            <Box mt={3} mb={4}>
              {digitizedImagesBullets.map(({ text }, index) => (
                <ProductDescriptionItem key={index}>
                  {text}
                </ProductDescriptionItem>
              ))}
            </Box>

            <Paper elevation={0} className={cl(classes.costHint)}>
              <Typography component={'span'}>{sliderValue} Pieces</Typography>
              <Typography component={'span'} style={{ padding: '0 12px' }}>—</Typography>
              <Typography variant={'h6'} className={classes.price}>
                {getPrice(sliderValue)}
              </Typography>
            </Paper>

            <Box px={2} my={3} flex={1} width={'100%'}>
              <TrackSlider
                value={sliderValue}
                step={1}
                min={MIN_AMOUNT_OF_PICS}
                max={MAX_AMOUNT_OF_PICS}
                trackerEvent={ART_BOOK.SLIDER_INTERACTION}
                onChange={handleSliderChange}
                classes={{
                  rail: classes.sliderRail,
                  track: classes.sliderTrack,
                }}
              />
            </Box>

            <Box mb={4}>
              <Typography variant={'caption'}>
                * Price does not include Artkive Box
              </Typography>
            </Box>

            <Box
              display={'flex'}
              justifyContent={isMobile ? 'center' : 'left'}
              mt={isMobile ? 4 : 6}
              mb={4}
            >
              <TrackButton
                color={'primary'}
                href={routes.boxCheckout}
                size={'large'}
                variant={'contained'}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{
                  section: 'get_digital_section',
                }}
              >
                Get My Box
              </TrackButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageWrapper}>
          <picture>
            <source
              srcSet={`${digitalProduct}`}
              media={muiTheme.breakpoints.up('lg').replace('@media ', '')}
            />
            <source
              srcSet={`${digitalProductX2}, ${digitalProduct} 2x`}
              media={muiTheme.breakpoints.up('md').replace('@media ', '')}
            />
            <img
              srcSet={`${digitalProductMobile}, ${digitalProductMobile} 2x`}
              src={digitalProductMobile}
              className={classes.picture}
            />
          </picture>
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

export default DigitalGallerySection
