import React from 'react'

import { useStoreActions, useStoreState } from 'easy-peasy'

import { MODALS } from 'artkive/components/Modals/constants'
import { Referral } from 'artkive/components/Modals/Referral'
import useIsomorphicLayoutEffect from 'artkive/hooks/useIsomorphicLayoutEffect'

const ModalManager = () => {
  const modal = useStoreState(({ uiStore }) => uiStore.modal)
  const openModalFromUrl = useStoreActions(({ uiStore }) => uiStore.openModalFromUrl)

  useIsomorphicLayoutEffect(openModalFromUrl, [])

  let modalContent
  switch (modal?.name) {
    case MODALS.REFERRAL:
      modalContent = <Referral open={true} {...modal.props} />
      break
  }

  useIsomorphicLayoutEffect(() => {
    openModalFromUrl()
  }, [])

  return (
    <>
      {modalContent}
    </>
  )
}

export default ModalManager
