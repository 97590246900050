import React from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

const CCInput = ({ inputRef, ...rest }) => {
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      format={'#### #### #### ####'}
    />
  )
}

CCInput.propTypes = {
  inputRef: PropTypes.func,
}

export default CCInput
