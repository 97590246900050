import request from 'common/utils/request'
import { BOX_PRODUCT_TYPE, PACKAGE_PRODUCT_TYPE } from 'artkive/stores/product.constants'

const validatePromoCode = async (code, product_id, product_type, email = null) => {
  let productName
  switch (product_type) {
    case BOX_PRODUCT_TYPE:
      productName = 'concierge_product'
      break
    case PACKAGE_PRODUCT_TYPE:
      productName = 'package_product'
      break
  }

  if (!code || !productName)
    return

  const { data } = await request.post(Routing.api_v2_promo_codes_validate(), {
    promo_code: { code, product_id, email, product_name: productName },
  })

  return data.data
}

export default validatePromoCode
