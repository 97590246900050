import React, { forwardRef } from 'react'

import cl from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    maxWidth: '100%',
    width: '100%',
  },
}))

const Image = forwardRef(({ className, ...props }, ref) => {
  const classes = useStyles()
  return <img className={cl(classes.root, className)} {...props} ref={ref} />
})

export default Image
