import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import { MobileOrderDetailsAddonPrice, MobileOrderDetailsPrice } from 'artkive/components/OrderSummary/PriceWithDiscount/index'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import formatNumber from 'artkive/utils/formatNumber'
import formatPrice from 'artkive/utils/formatPrice'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

import { buildOrderDetailsList } from './BoxOrderDetailsSummary'
import Chip from './Chip'
import { List, ListItem, ListItemDiscountedPrice, ListItemSecondaryAction, ListItemSecondaryActionDiscountedPrice, ListItemText, ListItemTextDiscountedPrice } from './DetailsList'
import MobileDetailsBox from './MobileDetailsBox'

const useStyles = makeStyles(({ palette, spacing }) => ({
  productSubtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  productSubtitlePrice: {
    color: palette.common.black,
  },
  productTotalLabel: {
    fontWeight: 500,
    lineHeight: 1.4,
  },
  listItemRoot: {
    padding: 0,
  },
  subtotal: {
    color: palette.common.black,
    fontWeight: 500,
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: palette.common.black,
    paddingRight: spacing(6),
  },
  discountText: {
    color: palette.primary.main,
  },
  price: {
    right: 0,
    position: 'relative',
    transform:'none',
    whiteSpace: 'nowrap',
  },
}))

const MobileOrderDetails = ({ product }) => {
  const classes = useStyles()

  const {
    price,
    qty,
    processing,
    addOns,
    gift,
  } = useVariableState(product.type, product.uuid)

  const activeProcessing = processing && processing.find((p) => !!p.default)
  const isPromoDiscount = !!price?.promo_discount_details?.code

  const items = useMemo(() => (
    buildOrderDetailsList(addOns, activeProcessing, price)
  ), [addOns, activeProcessing, price.shipping_protection_price, isPromoDiscount])

  return (
    <MobileDetailsBox
      editHref={`/${BOX_CHECKOUT_STEPS.ORDER_DETAILS}`}
      primaryText={
        <>
          {product.title}
          {gift && <Chip label={'Gift Order'} />}
        </>
      }
      secondaryText={
        <Box display={'flex'} gridGap={'4px'} alignItems={'center'}>
          <span className={classes.productTotalLabel}>Total:</span>
          <MobileOrderDetailsPrice fullPriceInfo={price} />
        </Box>
      }
      badgeProps={{ value: qty, image: product.image }}
    >
      <List>
        <ListItemDiscountedPrice>
          <ListItemTextDiscountedPrice primary={product.title} />
          <ListItemSecondaryActionDiscountedPrice>
            <MobileOrderDetailsAddonPrice
              productInAddon={true}
              fullPriceInfo={price}
            />
          </ListItemSecondaryActionDiscountedPrice>
        </ListItemDiscountedPrice>
        {items.map(({ name, price: itemPrice, quantity, kind }) => {
          const itemPriceWithDiscount = {
            base_price: Number(itemPrice),
            promo_discount_details: price.promo_discount_details,
          }
          const discountMethod = price.promo_discount_details?.discount_method
          const isUSBAddon = kind === ADDONS.RETURN_USB
          const showPriceWithDiscount =
            (isUSBAddon && discountMethod === DISCOUNT_METHODS.FREE_USB) ||
            (+itemPrice && discountMethod === DISCOUNT_METHODS.PERCENTAGE)
          return (
            <ListItemDiscountedPrice  key={name}>
              <ListItemTextDiscountedPrice>
                {name}
                {+quantity ? ` x ${quantity}` : ''}
              </ListItemTextDiscountedPrice>
              <ListItemSecondaryActionDiscountedPrice>
                {showPriceWithDiscount ? (
                  <MobileOrderDetailsAddonPrice
                    enableUSBAddon={
                      isUSBAddon && discountMethod === DISCOUNT_METHODS.FREE_USB
                    }
                    isAddonSummary={true}
                    fullPriceInfo={itemPriceWithDiscount}
                  />
                ) : +itemPrice ? (
                  `+ ${formatPrice(itemPrice * (quantity ?? 1))}`
                ) : (
                  'FREE'
                )}
              </ListItemSecondaryActionDiscountedPrice>
            </ListItemDiscountedPrice>
          )
        })}

        {!!price?.tax && (
          <ListItem>
            <ListItemText primary={'Tax'} />
            <ListItemSecondaryAction>
              ${formatNumber(price.tax)}
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </MobileDetailsBox>
  )
}

MobileOrderDetails.propTypes = {
  product: PropTypes.object.isRequired,
}

export default MobileOrderDetails
