import React, { useCallback, useState } from 'react'

import RedeemInfoModal from './RedeemInfoModal'

const RedeemBanner = () => {
  const [open, setOpen] = useState(false)
  const handleClick = useCallback(() => {
    setOpen(true)
  }, [])
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <RedeemInfoModal open={open} onClose={handleClose} />
      <div className={'gift-card-sub-nav'}>
        <div className={'gift-card-sub-nav-contents'}>
          <p>
            Already have a gift card? &nbsp;
            <span id={'redeem-request-link'} className={'redeem-request-link'} onClick={handleClick}>
              Redeem Here
            </span>
          </p>
        </div>
      </div>
    </>
  )
}

export default RedeemBanner
