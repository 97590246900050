import useGenericInformationForm from 'artkive/components/Checkout/useGenericInformationForm'

const watchableFields = [
  'email',
  'phone',
  'firstName',
  'lastName',
  'recorder',
  'recipientFirstName',
  'recipientLastName',
  'toEmail',
]

const useInformationForm = (product, onSubmit, defaultValues = {}) => {
  const [, methods] = useGenericInformationForm(product, watchableFields, {
    reValidateMode: 'onSubmit',
    defaultValues,
    onSubmit,
  })

  return methods
}

export default useInformationForm
