import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import ProductPriceTypography from 'artkive/components/PageSections/ProductPriceTypography'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Product from 'images/ecom/mosaics/Mosaics-md.jpg'
import ProductMobile from 'images/ecom/mosaics/Mosaics-sm.jpg'

import { mosaicsProductBullets } from '../Mosaics/Mosaics.content'

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    alignItems:'center',
    background: `linear-gradient(90deg, ${theme.colors.bg.purple} 50%, #fff 50%);`,
    height: 800,
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      background: theme.colors.white.main,
      height: 'auto',
    },
  },
  container: {},
  description: {
    display: 'flex',
    alignItems: 'center',
  },
  imageWrapper: {
    display: 'flex',
    position: 'relative',
    paddingRight: 40,
    [breakpoints.down('sm')]: {
      paddingRight: '5%',
      justifyContent: 'center',
      background: theme.colors.bg.purple,
      flexBasis: 0,
    },
  },
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    maxWidth: 'unset',
  },
  digital: {
    position: 'absolute',
    top: 50,
    left: 50,
  },
  artBackTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primary.dark,
    lineHeight: .8,
  },
  mosaicPriceLabel: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',

    [breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
}))

const MosaicsSection = ({ large_product, small_product }) => {
  const classes = useStyles()
  const muiTheme = useTheme()

  const isMobile = useMobileBreakpoint()

  return (
    <ContainedSection
      className={classes.wrapper}
      containerClassName={classes.container}
      disableGutters={isMobile}
      py={0}
    >
      <Grid container direction={isMobile ? 'column' : 'row'}>
        <Grid item xs={12} md={6} className={classes.imageWrapper}>
          <picture>
            <source
              srcSet={`${Product}`}
              media={muiTheme.breakpoints.up('md').replace('@media ', '')}
            />
            <source srcSet={ProductMobile} />
            <img src={Product} className={classes.image} />
          </picture>
        </Grid>
        <Grid item xs={12} md={6} className={classes.description}>
          <Box pl={isMobile ? 2 : 15} pr={2} py={isMobile ? 4 : 0}>
            <Typography variant={'h2'} align={isMobile ? 'center' : 'left'}>
              Framed Mosaics
            </Typography>

            <Box mt={3}>
              {mosaicsProductBullets.slice(0, 2).map(({ text }, index) => (
                <ProductDescriptionItem key={index}>
                  {text}
                </ProductDescriptionItem>
              ))}
            </Box>

            <Box className={classes.mosaicPriceLabel} mt={isMobile ? 5 : 2}>
              <Typography variant={'body1'}>
                <strong>
                  <ProductPriceTypography>${large_product.price}</ProductPriceTypography> {large_product.name}
                </strong>
              </Typography>
            </Box>
            <Box className={classes.mosaicPriceLabel}>
              <Typography variant={'body1'}>
                <strong>
                  <ProductPriceTypography>${small_product.price}</ProductPriceTypography> {small_product.name}
                </strong>
              </Typography>
            </Box>

            <Box mb={4} mt={1}>
              <Typography variant={'caption'} component={'div'}>
                *Price does not include Artkive Box
              </Typography>
              <Typography variant={'caption'} component={'div'}>
                **Prices do not include cost of digitizing images
              </Typography>
            </Box>

            <Box
              display={'flex'}
              justifyContent={isMobile ? 'center' : 'left'}
              mt={5}
              mb={4}
            >
              <TrackButton
                color={'primary'}
                href={routes.boxCheckout}
                size={'large'}
                variant={'contained'}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{
                  section: 'get_mosaics_section',
                }}
              >
                Get My Box
              </TrackButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

MosaicsSection.propTypes = {
  large_product: PropTypes.object.isRequired,
  small_product: PropTypes.object.isRequired,
}

export default MosaicsSection
