import React from 'react'

export const ContentCopy = () => {
  return (
    <svg
      width={'21'}
      height={'20'}
      viewBox={'0 0 21 20'}
      fill={'none'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <g clipPath={'url(#clip0_2549_1755)'}>
        <path
          d={
            'M13.8327 0.833008H3.83268C2.91602 0.833008 2.16602 1.58301 2.16602 2.49967V14.1663H3.83268V2.49967H13.8327V0.833008ZM16.3327 4.16634H7.16602C6.24935 4.16634 5.49935 4.91634 5.49935 5.83301V17.4997C5.49935 18.4163 6.24935 19.1663 7.16602 19.1663H16.3327C17.2493 19.1663 17.9993 18.4163 17.9993 17.4997V5.83301C17.9993 4.91634 17.2493 4.16634 16.3327 4.16634ZM16.3327 17.4997H7.16602V5.83301H16.3327V17.4997Z'
          }
          fill={'white'}
        />
      </g>
      <defs>
        <clipPath id={'clip0_2549_1755'}>
          <rect
            width={'20'}
            height={'20'}
            fill={'white'}
            transform={'translate(0.5)'}
          />
        </clipPath>
      </defs>
    </svg>
  )
}
