import React from 'react'
import PropTypes from 'prop-types'

import { Chip as MuiChip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CaptionSmallest from 'artkive/components/Typography/CaptionSmallest'
import { newColors } from 'artkive/utils/theme'

const useStyles = makeStyles(({ breakpoints }) => ({
  giftChip: {
    backgroundColor: newColors.violet[300],
    borderRadius: '4px',
    marginLeft: '12px',
    height: '21px',
    [breakpoints.up('sm')]: {
      borderRadius: '8px',
      height: '32px',
    },

    '& .MuiChip-label': {
      padding: '0 4px',
      [breakpoints.up('sm')]: {
        padding: '0 8px',
      },
    },
  },
}))

const Chip = ({ label }) => {
  const classes = useStyles()

  return (
    <MuiChip
      className={classes.giftChip}
      label={<CaptionSmallest>{label}</CaptionSmallest>}
    />
  )
}

Chip.propTypes = {
  label: PropTypes.string,
}

export default Chip
