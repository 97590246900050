import React from 'react'

import { SvgIcon } from '@material-ui/core'

const GiftIcon = (props) => (
  <SvgIcon {...props} viewBox={'0 0 24 24'}>
    <path d={'M3.483 11.245v9.237a.947.947 0 0 0 .943.947h15.459a.948.948 0 0 0 .946-.947v-9.237H3.483zM22.31 6.659v2.617a.944.944 0 0 1-.942.94H2.946A.946.946 0 0 1 2 9.272V6.659a.946.946 0 0 1 .946-.947h18.422a.946.946 0 0 1 .942.947z'} fill={'#3D4156'} />
    <path d={'M11.254 6.737c-1.333 0-3.172-1.123-3.755-1.705a2.446 2.446 0 1 1 3.463-3.457 7.406 7.406 0 0 1 1.345 2.202c.484 1.203.48 2.098-.01 2.59a1.426 1.426 0 0 1-1.043.37zM9.232 1.884a1.418 1.418 0 0 0-1.004 2.421c.823.823 2.983 1.69 3.34 1.333.11-.109.14-.59-.205-1.477a6.477 6.477 0 0 0-1.117-1.851 1.418 1.418 0 0 0-1.014-.426z'} fill={'#3D4156'} />
    <path d={'M13.054 6.741a1.41 1.41 0 0 1-1.039-.374 1.409 1.409 0 0 1-.374-1.029c0-1.234 1.028-3.086 1.71-3.757a2.432 2.432 0 0 1 1.73-.716 2.452 2.452 0 0 1 2.446 2.432 2.438 2.438 0 0 1-.718 1.735c-.677.679-2.516 1.707-3.75 1.71h-.005zm2.027-4.857a1.413 1.413 0 0 0-1.004.411c-.543.546-1.408 2.16-1.408 3.033a.488.488 0 0 0 .072.308.475.475 0 0 0 .313.077c.883 0 2.485-.867 3.024-1.408a1.42 1.42 0 0 0-.995-2.421h-.002z'} fill={'#3D4156'} />
  </SvgIcon>
)

export default GiftIcon
