import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import { TrackButton } from 'artkive/components/Tracker'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const HowItWorksButton = ({ href, trackerEvent, title, trackerOptions = {} }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <Box justifyContent={'center'} display={'flex'} mt={isMobile ? 6 : 8}>
      <TrackButton
        color={'primary'}
        variant={'contained'}
        href={href}
        trackerEvent={trackerEvent}
        trackerOptions={trackerOptions}
        size={'large'}
      >
        {title}
      </TrackButton>
    </Box>
  )
}

HowItWorksButton.propTypes = {
  href: PropTypes.string,
  trackerEvent: PropTypes.string,
  trackerOptions: PropTypes.object,
  title: PropTypes.node,
}

export default HowItWorksButton
