import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { FormProvider, useForm } from 'react-hook-form'

import { scrollToError } from 'common/hooks/useScrollToError'
import { fields as shippingFields } from 'artkive/components/Checkout/Sections/ShippingAddressFragment'
import SubmitButton from 'artkive/components/Checkout/SubmitButton'
import useFormToState from 'artkive/hooks/useFormToState'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'

import RecipientInformationFragment, { recipientContactFields } from '../RecipientInformationFragment'

// TODO: once we reenable address validation - it should also be added to this page
const RecipientInformation = ({ onSubmit, options, continueButtonText, setFormValidation }) => {
  const { sendGiftToRecipient, combinedInformation } = useVariableState(options.type, options.uuid)
  const { setStep, setOrderInformation } = useVariableActions(options.type, options.uuid)

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
    defaultValues: combinedInformation,
  })

  useFormToState(combinedInformation, setOrderInformation, methods)

  useEffect(() => {
    setFormValidation(() => async () => {
      let expressCheckoutRequiredFields = recipientContactFields
      if (sendGiftToRecipient) expressCheckoutRequiredFields = expressCheckoutRequiredFields.concat(shippingFields)

      const fields = expressCheckoutRequiredFields.map((field) => `recipientInformation.${field}`)
      const isValid = await methods.trigger(fields)
      if (!isValid) scrollToError(methods.formState.errors)
      return isValid
    })
    return () => setFormValidation(null)
  }, [setFormValidation, methods.trigger, sendGiftToRecipient])

  useEffect(() => {
    setStep({ stepName: BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS, payload: { isDirty: true } })
  }, [])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit, scrollToError)}>
        <RecipientInformationFragment options={options} />

        <SubmitButton>{continueButtonText}</SubmitButton>
      </form>
    </FormProvider>
  )
}

RecipientInformation.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  continueButtonText: PropTypes.string.isRequired,
  setFormValidation: PropTypes.func.isRequired,
}

export default RecipientInformation
