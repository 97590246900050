import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SectionGrid from 'artkive/components/Checkout/SectionGrid'
import SectionHeader from 'artkive/components/Checkout/SectionHeader'
import { colors, newColors } from 'artkive/utils/theme'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  selectorButton: {
    border: 'solid 1px',
    borderRadius: '30px',
    backgroundColor: '#FFF',
    borderColor: colors.sky[300],
    color: newColors.grey[900],
    padding: spacing(1),
    fontWeight: 600,

    '&:hover': {
      backgroundColor: newColors.grey[700],
    },
    [breakpoints.up('sm')]: {
      padding: spacing(1.5),
    },
  },
  activeSelectButton: {
    backgroundColor: newColors.grey[900],
    borderColor: newColors.grey[900],
    color: '#FFF',
  },
}))

const RecipientSelector = ({ title, value, onChange }) => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader>{title}</SectionHeader>

      <SectionGrid smSpacing={1}>
        <Grid item={true} xs={6}>
          <Button
            color={'primary'}
            fullWidth={true}
            onClick={() => onChange(false)}
            variant={'contained'}
            classes={{ root: cl(classes.selectorButton, { [classes.activeSelectButton]: !value }) }}
          >
            To Me
          </Button>
        </Grid>

        <Grid item={true} xs={6}>
          <Button
            color={'primary'}
            fullWidth={true}
            onClick={() => onChange(true)}
            variant={'contained'}
            classes={{ root: cl(classes.selectorButton, { [classes.activeSelectButton]: value }) }}
          >
            To Recipient
          </Button>
        </Grid>
      </SectionGrid>
    </>
  )
}

RecipientSelector.propTypes = {
  title: PropTypes.node.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default RecipientSelector
