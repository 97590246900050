import React, { useMemo, useState } from 'react'

import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import CloseIcon from '@material-ui/icons/Close'
import MenuIcon from '@material-ui/icons/Menu'

import useWindowSize from 'common/hooks/useWindowSize'
import Image from 'artkive/components/Image'
import MobileMenu from 'artkive/components/MobileMenu'
import OurProductsNavMenu from 'artkive/components/OurProductsNavMenu'
import {
  GET_BOX_CLICK,
  NAVIGATION,
  REFERRAL_MODAL,
} from 'artkive/constants/tracker/mainNavigation'
import useReferralCta from 'artkive/hooks/useReferralCta'
import routes from 'artkive/utils/routes'
import theme, { newColors } from 'artkive/utils/theme'

import artkiveLogo from 'images/ecom/nav/artkiveLogo.svg'

import { TrackButton, TrackLink, withClickTracker } from '../Tracker'

import AccentLink from './AccentLink'
import NavDropdownMenu, { MenuItem } from './NavDropdownMenu'

const useStyles = makeStyles((muiTheme) => ({
  offset: muiTheme.mixins.toolbar,
  navigationWrapper: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'stretch',
    fontSize: '1rem',
  },
  restMenu: {
    justifyContent: 'center',
    marginRight: -12,
    marginLeft: 12,
  },
  getPromoLink: {
    alignSelf: 'center',
    lineHeight: 1.5,
    fontWeight: 500,
    margin: '0 12px',
  },
  logo: {
    width: 140,
    minWidth: 140,
    [muiTheme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  logoWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    [muiTheme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
    '&>$logoBtn': {
      backgroundColor: 'unset',
    },
  },
  logoBtn: {
    [muiTheme.breakpoints.down('lg')]: {
      marginLeft: '-12px',
    },
  },
  menuButton: {
    position: 'absolute',
    left: 4,
  },
  getStarted: {
    height: 48,
    width: 160,
  },
}))

const TopBar = withStyles(() => ({
  root: {
    backgroundColor: theme.colors.white.main,
    boxShadow: theme.shadows[4],
  },
}))(AppBar)

const TrackAccentLink = withClickTracker(AccentLink)

const navigationOptions = () => {
  return {
    section: '',
  }
}

const useNavMenuAvailableCounter = () => {
  const { width } = useWindowSize()

  return useMemo(() => {
    // how many items should be shown for screen less than given value
    const visible = {
      600: 0,
      1060: 3,
      1216: 4, // 1436
      1374: 5,
      1433: 6,
    }

    const entries = Object.entries(visible)
    const last = entries.find(([w]) => w > width)

    return last ? last[1] : 8
  }, [width])
}

const MENU_ITEM_KEY = {
  OUR_PRODUCTS: 'our-products',
  PRICING: 'pricing',
  HOW_IT_WORKS: 'how-it-works',
  OUR_PROCESS: 'our-process',
  ABOUT_US: 'about-us',
  FAQS: 'faqs',
  CARDS_BY_ARTKIVE: 'cards-by-artkive',
  GIFT_CARDS: 'gift-cards',
  BLOG: 'blog',
  GET_PROMO: 'get-promo',
  MY_ACCOUNT: 'my-account',
  MY_ORDERS: 'my-orders',
  MY_PHOTOS: 'my-photos',
  ACCOUNT_DETAILS: 'account-details',
}
const visibleMenuItemKeys = [MENU_ITEM_KEY.OUR_PRODUCTS, MENU_ITEM_KEY.GET_PROMO, MENU_ITEM_KEY.MY_ACCOUNT]

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [mobileOpen, setMobileOpen] = useState(false)
  const visibleItemsCount = useNavMenuAvailableCounter()
  const openReferralModal = useReferralCta()
  const classes = useStyles()

  const menuItems = useMemo(() => [
    <MenuItem
      key={MENU_ITEM_KEY.OUR_PRODUCTS}
      trackerEvent={NAVIGATION.KEEPSAKES_CLICK}
      underline={'none'}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      component={TrackLink}
    >
      Our Products{' '}
      <Box
        component={'span'}
        display={'inline-flex'}
        alignItems={'center'}
        ml={'4px'}
      >
        {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Box>
    </MenuItem>,
    <MenuItem
      key={MENU_ITEM_KEY.PRICING}
      trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
      underline={'none'}
      href={routes.pricing}
      component={TrackLink}
    >
      Pricing
    </MenuItem>,
    <NavDropdownMenu
      key={MENU_ITEM_KEY.HOW_IT_WORKS}
      anchor={({ open, ...rest }) => (
        <MenuItem component={Button} {...rest}>
          How It Works
          <Box
            component={'span'}
            display={'inline-flex'}
            alignItems={'center'}
            ml={'4px'}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </MenuItem>
      )}
    >
      <MenuItem
        key={MENU_ITEM_KEY.OUR_PROCESS}
        trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
        underline={'none'}
        href={routes.ourProcess}
        component={TrackLink}
      >
        Our Process
      </MenuItem>
      <MenuItem
        key={MENU_ITEM_KEY.ABOUT_US}
        trackerEvent={NAVIGATION.ABOUT_US_CLICK}
        underline={'none'}
        href={routes.aboutUs}
        component={TrackLink}
      >
        About Us
      </MenuItem>
      <MenuItem
        key={MENU_ITEM_KEY.FAQS}
        trackerEvent={NAVIGATION.FAQS_CLICK}
        underline={'none'}
        href={routes.faqs}
        component={TrackLink}
      >
        FAQs
      </MenuItem>
    </NavDropdownMenu>,
    <MenuItem
      key={MENU_ITEM_KEY.CARDS_BY_ARTKIVE}
      trackerEvent={NAVIGATION.CARDS_CLICK}
      underline={'none'}
      href={routes.cardsByArtkive}
      component={TrackLink}
    >
      Cards by Artkive
    </MenuItem>,
    <MenuItem key={MENU_ITEM_KEY.GIFT_CARDS} href={routes.giftCards} component={TrackLink}>
      Gift Cards
    </MenuItem>,
    <MenuItem
      key={MENU_ITEM_KEY.BLOG}
      trackerEvent={NAVIGATION.BLOG_CLICK}
      underline={'none'}
      href={routes.blog}
      target={'_blank'}
      component={TrackLink}
    >
      Blog
    </MenuItem>,
    <TrackAccentLink
      key={MENU_ITEM_KEY.GET_PROMO}
      className={classes.getPromoLink}
      onClick={openReferralModal}
      trackerEvent={REFERRAL_MODAL.CTA_CLICK}
    >
      Get $20
    </TrackAccentLink>,
    <NavDropdownMenu
      key={MENU_ITEM_KEY.MY_ACCOUNT}
      anchor={({ open, ...rest }) => (
        <MenuItem component={Button} {...rest}>
          <AccountCircleIcon style={{ marginRight: '8px' }} />My Account
          <Box
            component={'span'}
            display={'inline-flex'}
            alignItems={'center'}
            marginLeft={'4px'}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Box>
        </MenuItem>
      )}
    >
      <MenuItem
        key={MENU_ITEM_KEY.MY_ORDERS}
        trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
        underline={'none'}
        href={routes.accountOrders}
        component={TrackLink}
      >
        My Orders
      </MenuItem>
      <MenuItem
        key={MENU_ITEM_KEY.MY_PHOTOS}
        trackerEvent={NAVIGATION.ABOUT_US_CLICK}
        underline={'none'}
        href={routes.accountPhotos}
        component={TrackLink}
      >
        My Photos
      </MenuItem>
      <MenuItem
        key={MENU_ITEM_KEY.ACCOUNT_DETAILS}
        trackerEvent={NAVIGATION.FAQS_CLICK}
        underline={'none'}
        href={routes.accountDetails}
        component={TrackLink}
      >
        Account Details
      </MenuItem>
    </NavDropdownMenu>,
  ], [isOpen])

  const filteredMenuItems = useMemo(() => {
    let hiddenMenuItems = []

    if (visibleItemsCount === menuItems.length) {
      return {
        visibleMenuItems: menuItems,
        hiddenMenuItems,
      }
    }

    const minItemsCount = visibleMenuItemKeys.length
    let itemsToAdd = visibleItemsCount - minItemsCount
    const items = menuItems.reduce((acc, item) => {
      const isAlwaysVisible = visibleMenuItemKeys.includes(item.key)
      if (isAlwaysVisible) {
        acc.push(item)
      } else {
        if (itemsToAdd > 0) {
          acc.push(item)
          itemsToAdd -= 1
        } else {
          // NOTE: "How It Works" menu item has nested menu items,
          // that's why we add only nested menu items into hiddenMenuItems for this element
          const isHowItWorksEl = item.key === MENU_ITEM_KEY.HOW_IT_WORKS

          if (isHowItWorksEl) {
            hiddenMenuItems.push(item.props.children)
          } else {
            hiddenMenuItems.push(item)
          }
        }
      }

      return acc
    }, [])

    return {
      visibleMenuItems: items,
      hiddenMenuItems,
    }
  }, [menuItems, visibleItemsCount])

  return (
    <Box position={'relative'} id={'Navbar-react-component'}>
      <TopBar position={'fixed'}>
        <Toolbar onMouseEnter={() => setIsOpen(false)}>
          <Hidden mdUp>
            <IconButton
              aria-label={'menu'}
              className={classes.menuButton}
              style={{ color: newColors.grey[700] }}
              edge={'end'}
              fontSize={'large'}
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              {mobileOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </Hidden>
          <Box display={'flex'} className={classes.logoWrapper}>
            <MenuItem
              href={'/'}
              onMouseEnter={() => setIsOpen(false)}
              trackerEvent={NAVIGATION.LOGO_CLICK}
              trackerOptions={navigationOptions}
              component={TrackLink}
              className={classes.logoBtn}
            >
              <Image src={artkiveLogo} className={classes.logo} />
            </MenuItem>
          </Box>
          <Hidden smDown>
            <Box bgcolor={'white'} className={classes.navigationWrapper}>
              {filteredMenuItems.visibleMenuItems}
              <Box display={'inline-flex'} alignItems={'center'} marginLeft={2}>
                <TrackButton
                  color={'primary'}
                  href={routes.boxCheckout}
                  variant={'contained'}
                  trackerEvent={GET_BOX_CLICK}
                  trackerOptions={{
                    section: 'header',
                  }}
                  className={classes.getStarted}
                >
                  Get My Box
                </TrackButton>
              </Box>
              {visibleItemsCount < menuItems.length && (
                <NavDropdownMenu
                  key={'more-menu'}
                  anchor={({ open, ...rest }) => (
                    <MenuItem
                      component={Button}
                      {...rest}
                      className={`${classes.restMenu} test-more-menu`}
                    >
                      <MenuIcon />
                    </MenuItem>
                  )}
                >
                  {filteredMenuItems.hiddenMenuItems}
                </NavDropdownMenu>
              )}
            </Box>
          </Hidden>
        </Toolbar>
      </TopBar>

      {/* keepsakes dropdown menu */}
      <Hidden smDown>
        <OurProductsNavMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </Hidden>

      <div className={classes.offset} />

      <Hidden mdUp>
        <MobileMenu isOpen={mobileOpen} onNavigate={() => setMobileOpen(false)} />
      </Hidden>
    </Box>
  )
}

export default Navbar
