import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Container } from '@material-ui/core'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

const ContainedSection = ({
  children,
  title,
  subtitle,
  disableGutters,
  containerClassName,
  titleWrapClassName,
  ...rest
}) => {
  const isMobile = useMobileBreakpoint()

  const spacing = isMobile ? theme.spacings.sectionMobile : theme.spacings.section
  const hasTitleSection = !!title || !!subtitle

  return (
    <Box py={spacing} {...rest}>
      <Container disableGutters={disableGutters || !isMobile} className={cl(containerClassName)}>
        {hasTitleSection && (
          <Box mb={isMobile ? 6 : 8} className={titleWrapClassName}>
            {title}
            {subtitle}
          </Box>
        )}

        {children}
      </Container>
    </Box>
  )
}

ContainedSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  disableGutters: PropTypes.bool,
  containerClassName: PropTypes.string,
  titleWrapClassName: PropTypes.string,
}

export default ContainedSection
