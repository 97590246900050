export const getPrice = (priceArray, planType) => {
  return priceArray.find((price) => price.version === planType)
}

export const buildPlanProduct = (plan, planType, price = null) => {
  price = price || getPrice(plan.pricing, planType)
  return {
    id: price?.id,
    title: plan.title,
    image: plan.image_url_path,
    type: 'membership',
    planType,
    price: price.trial_fee !== '0.0' ? parseInt(price?.trial_fee) : parseInt(price?.fee),
  }
}

export const buildCheckoutPrice = (product) => ({
  price: product.price,
  subtotal: product.price,
  total: product.price,
})
