import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import SubmitButton from 'artkive/components/Checkout/SubmitButton'
import { useVariableActions } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'

import OrderDetailsFragment from '../OrderDetailsFragment'

const OrderDetails = ({ product, onSubmit, options, continueButtonText }) => {
  const { setStep } = useVariableActions(BOX_PRODUCT_TYPE, product.uuid)

  useEffect(() => {
    setStep({ stepName: BOX_CHECKOUT_STEPS.ORDER_DETAILS, payload: { isDirty: true } })
  }, [])

  return (
    <>
      <OrderDetailsFragment product={product} options={options} />
      <SubmitButton onClick={onSubmit}>{continueButtonText}</SubmitButton>
    </>
  )
}

OrderDetails.propTypes = {
  continueButtonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  product: PropTypes.object,
  options: PropTypes.object,
}

export default OrderDetails
