import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { useForm } from 'react-hook-form'
import { useRecaptcha } from 'react-hook-recaptcha'

import useClosure from 'common/hooks/useClosure'

const recaptchaContainerId = 'recaptcha-container'

const useRecaptchaIfAvailable = (successCallback, enabled) => {
  if (enabled && typeof window !== 'undefined' && window.app.recaptchaSiteKey) {
    return useRecaptcha({
      containerId: recaptchaContainerId,
      sitekey: window.app.recaptchaSiteKey,
      size: 'invisible',
      successCallback,
    })
  }

  return {
    recaptchaLoaded: true,
    recaptchaWidget: { noCaptcha: true },
  }
}

/**
 * @param {object} props
 * @param {function} props.onSubmit
 * @param {boolean} props.enabled
 * @returns {object}
 */
const useFormWithRecaptcha = ({ onSubmit, enabled = true, ...formConfig }) => {
  const formMethods = useForm(formConfig)
  const formMethodsRef = useRef(formMethods)
  const { handleSubmit } = formMethodsRef.current

  useEffect(() => {
    formMethodsRef.current = formMethods
  }, [])

  const successCallback = useClosure((response) => (
    handleSubmit((data) => {
      // add recaptcha field to data
      return onSubmit({ ...data, 'g-recaptcha-response-data': response })
    })()
  ), [handleSubmit, onSubmit])

  const { recaptchaLoaded, recaptchaWidget } = useRecaptchaIfAvailable(successCallback, enabled)

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault?.()
    e.persist?.()
    if (recaptchaWidget === null) return
    if (recaptchaWidget.noCaptcha) return successCallback(null)

    window.grecaptcha.reset(recaptchaWidget)
    window.grecaptcha.execute(recaptchaWidget)
  }, [recaptchaWidget, successCallback])

  const resetRecaptcha = useCallback(() => {
    if (recaptchaWidget !== null) {
      window.grecaptcha.reset(recaptchaWidget)
    }
  }, [recaptchaWidget])

  const recaptchaComponent = useMemo(() => (
    <div id={recaptchaContainerId} />
  ), [recaptchaContainerId])

  return {
    ...formMethods,
    recaptchaLoaded,
    recaptchaComponent,
    handleFormSubmit,
    resetRecaptcha,
  }
}

export default useFormWithRecaptcha
