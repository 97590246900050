import React from 'react'
import PropTypes from 'prop-types'

import { Controller, useForm } from 'react-hook-form'
import { Box, Button, TextField } from '@material-ui/core'

import routes from 'artkive/utils/routes'
import { captureEmail } from 'artkive/utils/tracker'

import { SubTitle, Title, useFormStyles } from './common'
import { YourReferralLink } from './YourReferralLink'

export const LoginForm = ({ onSubmit }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'onChange' })
  const classes = useFormStyles()
  const watchedName = watch('name')
  const watchedEmail = watch('email')

  const prepareEventData = ({ name = null, email = null }) => {
    const currentName = name || watchedName || ''
    const currentEmail = email || watchedEmail
    const nameParts = currentName.split(' ')

    return {
      formType: 'referral modal',
      kind: 'referral',
      email: currentEmail,
      firstName: nameParts[0],
      lastName: nameParts.slice(1, nameParts.length).join(' '),
    }
  }

  const buildOnBlurHandler = (field) => (event) => {
    field.onBlur(event)
    captureEmail(prepareEventData({ [field.name]: field.value }))
  }

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formInner}>
        <Title>Give $20, Get $20!</Title>
        <SubTitle>
          Give $20 to a friend. For every friend that makes a purchase using
          your code, you’ll get $20 added to your account.
        </SubTitle>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name={'name'}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                label={'Your Name'}
                type={'text'}
                variant={'outlined'}
                fullWidth
                className={classes.textField}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                onBlur={buildOnBlurHandler(field)}
              />
            )}
          />
          <Controller
            control={control}
            name={'email'}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                {...field}
                inputRef={ref}
                label={'Your Email'}
                type={'email'}
                variant={'outlined'}
                fullWidth
                className={classes.textField}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                onBlur={buildOnBlurHandler(field)}
              />
            )}
          />
          <Button
            color={'primary'}
            variant={'contained'}
            type={'submit'}
            disabled={!isValid || !isDirty}
          >
            <span>Show my referral link</span>
          </Button>
        </form>
      </Box>
      <Box className={classes.form}>
        <YourReferralLink
          disabled
          label={'Your referral link:'}
          referralLink={`${location.origin}${routes.checkout}`}
          style={{ gridRow: 3 }}
        />
      </Box>
    </Box>
  )
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
