import React from 'react'

import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'

import DescriptionBubbles from 'images/ecom/get-started/bubbles.png'
import Product from 'images/ecom/get-started/Product.png'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: '#E6F6FF',
    padding: '0 0 60px 0',
    [breakpoints.up('md')]: {
      padding: '48px 0 48px 0',
    },
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,

    [breakpoints.up('md')]: {
      zIndex: 1,
      padding: '0 145px',
      flexDirection: 'row',
      '&::before': {
        background: `url(${DescriptionBubbles}) no-repeat`,
        backgroundSize: 'contain',
        position: 'absolute',
        content: '""',
        width: 120,
        height: 240,
        display: 'block',
        left: 0,
        top: -80,
        zIndex: -1,
      },
      '&::after': {
        background: `url(${DescriptionBubbles}) no-repeat`,
        backgroundSize: 'contain',
        position: 'absolute',
        content: '""',
        width: 120,
        height: 240,
        display: 'block',
        right: 10,
        bottom: -100,
        zIndex: -1,
      },
    },
  },
  image: {
    objectFit: 'contain',
    width: '100%',
    height: 'auto',
    maxWidth: 'unset',
    [breakpoints.up('md')]: {
      marginRight: '60px',
      width: '400px',
    },
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '36px 16px',
    [breakpoints.up('md')]: {
      padding: '0 24px',
      justifyContent: 'flex-start',
    },
  },
}))

const GetStartedFooter = () => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Box className={classes.root} pt={isMobile ? 0 : 6} pb={isMobile ? 8 : 6} overflow={'hidden'}>
      <Container className={classes.container} disableGutters={isMobile}>
        <img src={Product} className={classes.image} />
        <Box className={classes.description}>
          <Typography variant={'h2'} align={isMobile ? 'center': 'left'} gutterBottom>
            Get Started<br />
            With An Artkive Box
          </Typography>
          <Typography variant={'subtitle1'} align={isMobile ? 'center': 'left'}>
            It includes everything you need to send in your artwork
          </Typography>
          <Box display={'flex'} justifyContent={isMobile ? 'center' : 'left'} mt={4}>
            <TrackButton
              color={'primary'}
              href={routes.boxCheckout}
              size={'large'}
              variant={'contained'}
              trackerEvent={GET_BOX_CLICK}
              trackerOptions={{
                section: 'get_mosaics_banner',
              }}
            >
              Get My Box
            </TrackButton>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default GetStartedFooter
