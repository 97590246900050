import React from 'react'

import { renderToString } from 'react-dom/server'
import { ServerStyleSheets } from '@material-ui/core/styles'

const withStyledSsr = (Component) => (props, railsContext) => {
  if (railsContext.serverSide) {
    const materialSheets = new ServerStyleSheets()
    const html = renderToString(
      materialSheets.collect(
        <Component {...props} railsContext={railsContext} />,
      ),
    )
    const css = `<style>${materialSheets.toString()}</style>`

    return {
      renderedHtml: {
        componentHtml: html, componentCss: css,
      },
    }
  }
  return (clientProps) => {
    return <Component {...clientProps} />
  }
}

export default withStyledSsr
