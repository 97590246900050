import request from 'common/utils/request'

export const fetchOrderTrackingDetails = async (orderNumber, email) => {
  const query = new URLSearchParams({ email }).toString()
  try {
    const response = await request.get(`/api/v2/concierges/${orderNumber}?${query}`)
    return { response }
  } catch (error) {
    return { error }
  }
}
