import React from 'react'
import PropTypes from 'prop-types'

import { Box, Divider as MuiDivider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import globalTheme from 'artkive/utils/theme'

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: globalTheme.colors.sky[300],
  },
}))

const Divider = ({ my, ...rest }) => {
  const classes = useStyles()

  return (
    <Box my={my} {...rest}>
      <MuiDivider classes={{ root: classes.divider }} />
    </Box>
  )
}

Divider.propTypes = {
  my: PropTypes.number.isRequired,
}

Divider.defaultProps = {
  my: 4,
}

export default Divider
