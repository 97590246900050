import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Container, Divider, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import qs from 'query-string'

import useWindowLocation from 'common/hooks/useWindowLocation'
import AppSnackbar from 'artkive/components/AppSnackbar'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

import getAvailableStatus, { getSinteticHistoryRow } from './OrderStatuses'
import { specialists } from './OrderTracking.constants'
import { fetchOrderTrackingDetails } from './orderTrackingApi'
import OrderTrackingCurrentState from './OrderTrackingCurrentState'
import OrderTrackingForm from './OrderTrackingForm'
import OrderTrackingList from './OrderTrackingList'
import { OrderTrackingNumber } from './OrderTrackingStyled'

const useStyles = makeStyles((muiTheme) => ({
  wrapper: {
    backgroundColor: theme.colors.white.dark,
  },
  typography: {
    fontSize: 'inherit',
    color: theme.colors.black,
  },
  orderFormBox: {
    background: `linear-gradient(to bottom, #CCC5E0 65%, #FFF2D1 50%)`,
    width: '100%',
    // height: 226,
    paddingTop: '90px',
    paddingBottom: '120px',
  },
  orderDetailsBox: {
    width: '100%',
    // height: 226,
    paddingTop: '10px',
    paddingBottom: '120px',
    position: 'relative',
    [muiTheme.breakpoints.up('md')]: {
      background: `linear-gradient(to bottom, #CCC5E0 300px, transparent 10%)`,
      paddingTop: '90px',
    },
  },
  contentBox: {},
  orderDetails: {
    margin: '0 auto',
    boxShadow: '0px 3px 6px #00000029',
    padding: '45px 17px',
    [muiTheme.breakpoints.up('md')]: {
      padding: '75px 160px',
    },
  },
  formTitleContainer: {
    display: 'block',
  },
  formTitle: {
    color: theme.colors.white.main,
    textAlign: 'center',
    textShadow: '0px 2px 4px #0000004D',
    marginBottom: 10,
  },
  formSubtitle: {
    color: theme.colors.white.main,
    textAlign: 'center',
    fontSize: '18px',
    textShadow: '0px 2px 4px #0000004D',
    margin: '10px 10px 40px 10px',
    [muiTheme.breakpoints.up('md')]: {
      fontSize: '28px',
      margin: '10px 120px 80px 120px',
    },
  },
  divider: {
    margin: `${muiTheme.spacing(3)}px 0`,
  },
}))

const composeOrderHistory = (order) => {
  const icons = getAvailableStatus(order)
  const availableStatuses = icons.map(({ status }) => status)
  let completedList = [...order.order_statuses].reverse()

  completedList = getSinteticHistoryRow(completedList)

  const activeStepStatus = completedList.find(({ status }) => availableStatuses.includes(status))?.status
  const activeStepIndex = icons.findIndex(({ status }) => status === activeStepStatus)

  return {
    activeStatus: activeStepStatus && icons.find(({ status }) => status === activeStepStatus),
    statusHistory: icons.map((step, index) => {
      const stepIndex = completedList.findIndex(({ status }) => status === step.status)
      const stepData = completedList[stepIndex] || {}
      const isStepCompleted = index <= activeStepIndex && stepIndex > -1

      return {
        ...step,
        ...stepData,
        completed: isStepCompleted,
        active: isStepCompleted && stepIndex === 0,
      }
    }),
  }
}

const OrderTracking = () => {
  const [collapsed, setCollapsed] = useState(null)
  const [orderStatus, setOrderStatus] = useState(null)
  const [error, setError] = useState(null)
  const isMobile = useMobileBreakpoint()
  const { search, pathname, pushState } = useWindowLocation()

  const classes = useStyles()

  const toggleCollapsed = useCallback((index) => {
    setCollapsed((prevCollapsed) => prevCollapsed === index ? null : index)
  }, [setCollapsed])

  const fetchOrderDetails = async (values) => {
    const { response, error } = await fetchOrderTrackingDetails(values.orderNumber, values.email)

    if (response)
      setOrderStatus(response.data?.data)

    if (error) {
      const message = error.response?.data?.message || error.response?.data?.error?.toString() || error.message
      setError(message)
    }
  }

  useEffect(async () => {
    if (!search)
      return

    const values = qs.parse(search)
    await fetchOrderDetails(values)
  }, [search])

  const handleSubmit = useCallback(async (values) => {
    const params = new URLSearchParams(values).toString()

    pushState({}, 'Order tracking', `${pathname}?${params}`)
  }, [])

  const { activeStatus, statusHistory } = useMemo(() => {
    if (!orderStatus)
      return {}

    return composeOrderHistory(orderStatus)
  }, [orderStatus])

  const specialist = useMemo(() => {
    if (!orderStatus?.id || !orderStatus?.physical_book)
      return null

    return specialists[orderStatus.id % specialists.length]
  }, [orderStatus?.id, orderStatus?.physical_book])

  return (
    <div className={classes.wrapper}>
      <AppSnackbar
        variant={'error'}
        message={error}
        onClose={() => setError(null)}
      />

      {orderStatus ? (
        <Box className={classes.orderDetailsBox}>
          <Container maxWidth={'lg'} className={classes.formTitleContainer}>
            {isMobile ? (
              <>
                <OrderTrackingNumber orderNumber={orderStatus.id} />

                <Paper className={classes.orderDetails} elevation={3}>
                  <OrderTrackingCurrentState
                    activeStatus={activeStatus}
                    details={orderStatus.concierge_processing}
                    specialist={specialist}
                  />
                </Paper>
                <Paper className={classes.orderDetails} elevation={3} style={{ marginTop: 20 }}>
                  <Typography variant={'h6'} className={classes.typography}>
                    Status History
                  </Typography>
                  <OrderTrackingList
                    history={statusHistory}
                    collapsed={collapsed}
                    toggleCollapsed={toggleCollapsed}
                  />
                </Paper>
              </>
            ) : (
              <Paper className={classes.orderDetails} elevation={3}>
                <OrderTrackingNumber orderNumber={orderStatus.id} />
                <Divider className={classes.divider} />
                <Typography variant={'h6'} className={classes.typography}>
                  Status History
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={7}>
                    <OrderTrackingList
                      history={statusHistory}
                      collapsed={collapsed}
                      toggleCollapsed={toggleCollapsed}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <OrderTrackingCurrentState
                      activeStatus={activeStatus}
                      details={orderStatus.concierge_processing}
                      specialist={specialist}
                    />
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Container>
        </Box>
      ) : (
        <Box className={classes.orderFormBox}>
          <Container maxWidth={'lg'} className={classes.formTitleContainer}>
            <Typography variant={'h1'} className={classes.formTitle}>
              Track Your Order
            </Typography>
            <Typography variant={'subtitle2'} className={classes.formSubtitle}>
              We know how important your family’s art is, and we want you to know where you are in the Artkive Box
              process.
            </Typography>
            <Paper className={classes.orderDetails} elevation={3}>
              <OrderTrackingForm onSubmit={handleSubmit} error={error} />
            </Paper>
          </Container>
        </Box>
      )}
    </div>
  )
}

export default withApp(OrderTracking)
