import { useMemo } from 'react'

import { get } from 'react-hook-form'
import { capitalize } from '@material-ui/core/utils'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import request from 'common/utils/request'
import { BOX_PRODUCT_TYPE, PAYMENT_KINDS } from 'artkive/stores/product.constants'
import { USA_COUNTRY_KEY } from 'artkive/utils/addressValidationService'
import * as track from 'artkive/utils/tracker'

export const normalizePhoneNumber = (value) => value?.replace(/\D+/g, '')

export const NOOP = () => null

export const orderBox = async (payload) => {
  try {
    const response = await request.post(Routing.api_v2_orders_box_index(), { ...payload })

    return { response }
  } catch (error) {
    return { error }
  }
}

const addressTraits = (shippingInformation, normalize) => {
  if (!shippingInformation.shipping_zip) return undefined

  const traits = {
    city: shippingInformation.shipping_city,
    country: USA_COUNTRY_KEY,
    postalCode: shippingInformation.shipping_zip,
    state: shippingInformation.shipping_state,
  }
  if (normalize) {
    traits.city = traits.city?.replace(/\s/g, '')?.toLowerCase()
    traits.country = traits.country.toLowerCase()
    traits.state = traits.state?.toLowerCase()
  }
  return traits
}

// NOTE: normalization is required for FB conversion
const userTraits = (shippingInformation, normalize = true) => {
  const nameParts = (shippingInformation.name || '').split(' ')
  const { 0: firstName, [nameParts.length - 1]: lastName } = nameParts
  const traits = {
    phone: shippingInformation.phone_number,
    email: shippingInformation.email,
    name: shippingInformation.name,
    firstName: capitalize(firstName),
    lastName: capitalize(nameParts.length > 1 ? lastName : ''),
  }
  if (shippingInformation.shipping_zip) {
    traits.address = addressTraits(shippingInformation, normalize)
  }

  return traits
}

export const buildCheckoutUtmParameters = (tracking) => ({
  utm_source: tracking.utmSource,
  utm_campaign: tracking.utmCampaign,
  utm_medium: tracking.utmMedium,
  utm_content: tracking.utmContent,
  utm_term: tracking.utmTerm,
})

export const buildPaymentMethod = (payment, freeOrder) => {
  if (Object.keys(payment || {}).length === 0 || freeOrder) return { method: PAYMENT_KINDS.CREDIT_CARD }

  const expArray = payment.cardExp.split('/')

  return {
    method: PAYMENT_KINDS.CREDIT_CARD,
    name_on_card: payment.name,
    credit_card_number: payment.cardNumber.replace(/\s+/g, ''),
    exp_month: expArray[0],
    exp_year: `20${expArray[1]}`,
    security_code: payment.cardCvc,
  }
}

export const mapStateToAddress = (state) => ({
  phone_number: normalizePhoneNumber(state.phone),
  shipping_address: state.address,
  shipping_address_details: state.address2,
  shipping_city: state.city,
  shipping_country: state.country,
  shipping_state: state.state,
  shipping_zip: state.zipCode,
})

export const mapAppleContactToAddress = (shippingContact) => ({
  phone_number: normalizePhoneNumber(shippingContact.phoneNumber),
  shipping_address: shippingContact.addressLines?.join(' '),
  shipping_address_details: null,
  shipping_country: shippingContact.countryCode,
  shipping_city: shippingContact.locality,
  shipping_state: shippingContact.administrativeArea,
  shipping_zip: shippingContact.postalCode,
})

export const useProductMeta = (productData, productLinks, { isVipMembershipAvailable = false }) => useMemo(() => {
  const checkoutOptions = {
    id: productData.id,
    uuid: productData.uuid,
    type: BOX_PRODUCT_TYPE,
    cover_url: get(productData, 'cover_image.url', ''),
    preview_url: get(productData, 'preview_image.url', ''),
    name: get(productData, 'name', ''),
    back_link: `~${productLinks.landing}`,
    vip_membership: get(productData, 'properties.vip_membership', false),
    processing_time: get(productData, 'properties.processing_time', false),
    shipping_protection: get(productData, 'properties.shipping_protection', false),
    shipping_address: get(productData, 'properties.shipping_address', false),
    taxable: get(productData, 'properties.taxable', false),
    adjust_quantity: get(productData, 'properties.adjust_quantity', false),
    addon_sections: get(productData, 'properties.options.addon_sections', []),
    countries: get(productData, 'properties.countries', []),
    gift: get(productData, 'properties.checkout.gift_section', null),
    source: productData,
  }

  const availableOrderDetails = [
    ...checkoutOptions.addon_sections,
    checkoutOptions.processing_time,
    checkoutOptions.shipping_protection,
    checkoutOptions.gift,
  ].filter(Boolean)

  checkoutOptions.isOrderDetailsAvailable = availableOrderDetails.length > 1
  checkoutOptions.isSomeOrderDetailsAvailable = availableOrderDetails.length > 0
  checkoutOptions.forceExpressCheckoutOnFirstPage = availableOrderDetails.length <= 1

  checkoutOptions.hasSubscriptionAddon = (isVipMembershipAvailable && checkoutOptions.vip_membership) ||
    checkoutOptions.addon_sections.some(({ items }) => items.some(({ kind }) => kind === ADDONS.SUBSCRIPTION))

  return checkoutOptions
}, [productData, isVipMembershipAvailable, productLinks])

export const trackOrderPurchase = (order, paymentKind, shippingInformation, tracking) => {
  const { id, pricing_object, concierge_product_id } = order
  const { promo_discount, promo_discount_details, total } = pricing_object

  track.identifyUser(userTraits(shippingInformation, false))

  /**
   *  NOTE As we don't add taxes right now total doesn't include them at all. Some consumers like
   *  ShareASale requires total without taxes
   */
  track.purchase({
    amount: total,
    discount: promo_discount,
    orderId: id,
    name: shippingInformation.name,
    productId: concierge_product_id,
    product: BOX_PRODUCT_TYPE,
    promo: promo_discount_details.code,
    information: shippingInformation,
    paymentKind,
    tracking,
    context: { traits: userTraits(shippingInformation) },
  })
}

/**
 *
 * @param {object} productData
 * @param {string} kind
 * @returns {object|undefined}
 */
export function getProductAddonByKind(productData, kind) {
  let addOn
  productData.properties.options.addon_sections.find((section) => {
    addOn = section.items.find((addOnItem) => addOnItem.kind === kind)
    return addOn
  })
  return addOn
}
