import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container, Hidden, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { BackButton } from '../Header'

const useStyles = makeStyles(({ spacing }) => ({
  progressRoot: {
    backgroundColor: 'transparent',
    position: 'fixed',
    top: 53,
    zIndex: 5,
    height: 3,
    left: 0,
    width: '100%',
  },
  title: {
    fontSize: '1rem',
  },
  headerToolbar: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 5,
    height: spacing(7),
    boxShadow: '0px 0px 10px rgba(16, 35, 113, 0.1)',
    width: '100%',
  },
}))

const MobileHeader = ({ backButtonProps, title, progress }) => {
  const classes = useStyles()

  return (
    <Hidden mdUp>
      <Box
        alignItems={'center'}
        bgcolor={'white'}
        display={'flex'}
        fontWeight={600}
        justifyContent={'space-between'}
        pt={7}
        style={{ zIndex: 3 }}
      >
        <Container>
          <Box
            alignItems={'center'}
            bgcolor={'white'}
            display={'flex'}
            justifyContent={'space-between'}
            px={1}
            className={classes.headerToolbar}
          >
            <BackButton {...backButtonProps} icon />

            <Typography variant={'inherit'} className={classes.title}>
              {title}
            </Typography>
            <div style={{ width: 64 }} />
          </Box>


          {progress !== null && progress !== undefined && (
            <LinearProgress
              variant={'determinate'}
              className={classes.progressRoot}
              value={progress}
            />
          )}
        </Container>
      </Box>
    </Hidden>
  )
}

MobileHeader.propTypes = {
  backButtonProps: PropTypes.object,
  title: PropTypes.string,
  progress: PropTypes.number,
}

MobileHeader.defaultProps = {
  progress: null,
}

export default MobileHeader
