import axios from 'axios'

const request = axios.create()

if (typeof window !== 'undefined') {
  window.ajaxCounter = 0

  request.defaults.headers.common['X-CSRF-Token'] =
    typeof ReactOnRails !== 'undefined' ? ReactOnRails.authenticityToken() : null

  request.interceptors.request.use((config) => {
    window.ajaxCounter += 1
    return config
  })

  request.interceptors.response.use((response) => {
    window.ajaxCounter -= 1
    return response
  }, (error) => {
    window.ajaxCounter -= 1
    return Promise.reject(error)
  })
}

export default request
