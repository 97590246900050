import React from 'react'

import {
  Box,
  Card,
  Container,
  Grid,
  Slide,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import { NAVIGATION } from 'artkive/constants/tracker/mainNavigation'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import books from 'images/ecom/megamenu/Books.jpg'
import mosaics from 'images/ecom/megamenu/Mosaics.jpg'
import returnArt from 'images/ecom/megamenu/ReturnArt.svg'
import rush from 'images/ecom/megamenu/Rush.svg'
import usb from 'images/ecom/megamenu/USB.svg'

import { TrackLink } from '../Tracker'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    zIndex: 1000,
    boxShadow: theme.shadows[3],
  },
  card: {
    padding: 24,
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      textTransform: 'none',
      textDecoration: 'none',
      backgroundColor: theme.colors.white.main,
      boxShadow: theme.shadows[1],
    },
  },
  icon: {
    width: 90,
  },
  title: {
    color: theme.colors.black,
  },
  subtitle: {
    color: theme.colors.black,
  },
}))

const OurProductsNavMenu = ({ isOpen, setIsOpen }) => {
  const classes = useStyles()

  return (
    <Slide
      in={isOpen}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      className={classes.root}
      timeout={300}
    >
      <Box top={'64px'} position={'fixed'} left={'0'} width={'100%'} zIndex={'1000'} py={4}>
        <Container>
          <Grid container spacing={10}>
            <Grid item xs={12} md={7}>
              <Grid container spacing={10}>
                <Grid item xs={6}>
                  <Card
                    role={'button'}
                    elevation={0}
                    href={routes.books}
                    component={TrackLink}
                    trackerEvent={NAVIGATION.ART_BOOK_CLICK}
                    className={classes.card}
                  >
                    <Image src={books} />
                    <Box mt={3} textAlign={'center'}>
                      <Typography variant={'h6'}>
                        Art Books
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
                <Grid item xs={6}>

                  <Card
                    role={'button'}
                    elevation={0}
                    component={TrackLink}
                    href={routes.mosaics}
                    trackerEvent={NAVIGATION.MOSAIC_CLICK}
                    className={classes.card}
                  >
                    <Image src={mosaics} />

                    <Box mt={3} textAlign={'center'}>
                      <Typography variant={'h6'}>
                        Framed Mosaics
                      </Typography>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box alignItems={'center'} display={'flex'} flexDirection={'column'} height={'100%'} justifyContent={'center'}>
                <Box mb={2} textAlign={'center'}>
                  <Typography variant={'h6'} className={classes.title}>
                    Available Add-Ons
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      py={2}
                      textAlign={'center'}
                    >
                      <img src={returnArt} className={classes.icon} />
                      <Box mt={2}>
                        <Typography variant={'body1'} className={classes.subtitle}>
                          Artwork<br /> Return
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      py={2}
                      textAlign={'center'}
                    >
                      <img src={rush} className={classes.icon} />
                      <Box mt={2}>
                        <Typography variant={'body1'} className={classes.subtitle}>
                          Rush <br /> Processing
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      py={2}
                      textAlign={'center'}
                    >
                      <img src={usb} className={classes.icon} />
                      <Box mt={2}>
                        <Typography variant={'body1'} className={classes.subtitle}>
                          Digital <br /> Art Files
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Slide>
  )
}

export default OurProductsNavMenu
