import { useEffect } from 'react'

import useFormToState from 'artkive/hooks/useFormToState'
import useFormWithRecaptcha from 'artkive/hooks/useFormWithRecaptcha'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'

/**
 * @param {object} product
 * @param {array} watchableFields
 * @param {object} formProps = {}
 * @returns {array}
 */
const useGenericInformationForm = (product, watchableFields, formProps = {}) => {
  const methods = useFormWithRecaptcha({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
    ...formProps,
  })

  const { setValue } = methods

  const { setInformation } = useVariableActions(product.type, product.uuid)
  const { information } = useVariableState(product.type, product.uuid)

  useFormToState(information, setInformation, methods, { only: watchableFields })

  useEffect(() => {
    for (const field of watchableFields) {
      if (information[field]) {
        setValue(field, information[field], { shouldValidate: true })
      }
    }
  }, [watchableFields, information, setValue])

  return [information, methods]
}

export default useGenericInformationForm
