import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, IconButton } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { Chat, Email } from '@material-ui/icons'

import { TrackButton, withClickTracker } from 'artkive/components/Tracker'
import { REFERRAL_MODAL } from 'artkive/constants/tracker/mainNavigation'
import muiTheme from 'artkive/utils/muiTheme'
import theme from 'artkive/utils/theme'

import { SubTitle, Title, useFormStyles } from './common'
import { ContentCopy } from './ContentCopy'
import { YourReferralLink } from './YourReferralLink'

const makeShareData = ({ name, link, email = false }) => ({
  url: link,
  title: `I think you’ll love this (❤️, ${name})`,
  text: `Hi!

This is such a brilliant solution for all the kid art piling up! \
Artkive sends you a box, you send it back with all of your kids' artwork, \
they professionally photograph it all, and create beautiful hardcover books and frames! \
It’s such a great way to declutter and save the memories (and you can get your art back too, if you want).

Just use my personalized link when you purchase a box to get $20 off${email ? `:
${link}

Enjoy!
${name}
` : `.`}`,
})

// Using URLSearchParams will incorrectly encode the values in the case of mailto
const encode = (params) => {
  let encoded = []
  for (const key of Object.keys(params)) {
    encoded.push(`${key}=${encodeURIComponent(params[key])}`)
  }
  return encoded.join('&')
}

const ShareIconButton = styled('label')({
  cursor: 'pointer',
  fontSize: '13px',
  fontWeight: 500,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: muiTheme.spacing(1.5),
  color: theme.colors.black,

  '& .MuiIconButton-root': {
    color: theme.colors.sky[700],
    width: '48px',
    height: '48px',
    background: theme.colors.sky[300],
    border: `1px solid ${theme.colors.sky[500]}`,
  },

  '& .MuiIconButton-root:hover': {
    background: theme.colors.sky[300],
  },
})

const ShareButton = ({ onClick, label, children }) => {
  return (
    <ShareIconButton>
      <IconButton onClick={onClick}>{children}</IconButton>
      {label}
    </ShareIconButton>
  )
}

const TrackShareButton = withClickTracker(ShareButton)

const TrackButtonWithIcon = styled(TrackButton)({
  '& .MuiButton-startIcon': {
    marginRight: muiTheme.spacing(0.5),
  },
})

export const SharingForm = ({ name, link }) => {
  const classes = useFormStyles()
  const shareData = makeShareData({ name, link })
  const emailData = makeShareData({ name, link, email: true })
  const canShare = navigator.share && navigator.canShare(shareData)
  const canCopy = navigator.clipboard?.writeText
  const [copied, setCopied] = useState(false)

  const handleShare = () => navigator.share(shareData).catch(() => {})

  const handleEmail = () => {
    const url = new URL('mailto:')
    url.search = encode({ subject: emailData.title, body: emailData.text })
    const a = document.createElement('a')
    a.href = url.href
    a.click()
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopied(true)
      setTimeout(() => setCopied(false), 3000)
    })
  }

  return (
    <Box className={classes.formContainer}>
      <Box className={classes.formInner}>
        <Title>Hi{name && `, ${name}`}! Welcome To Our Referral Program</Title>
        <SubTitle>
          Give $20 to a friend. Select below to copy your referral code, or mail{' '}
          {canShare && 'or message'} it to friends.
        </SubTitle>
        <Box className={classes.form}>
          <YourReferralLink
            label={'Share your referral link:'}
            referralLink={link}
          />
          {canCopy && (
            <TrackButtonWithIcon
              color={'primary'}
              variant={'contained'}
              type={'button'}
              onClick={handleCopy}
              startIcon={<ContentCopy />}
              trackerEvent={REFERRAL_MODAL.COPY_CLICK}
              promiseCallback
              trackAsync
            >
              {copied ? 'Copied ✨' : 'Copy'}
            </TrackButtonWithIcon>
          )}
        </Box>
      </Box>
      <form
        onSubmit={(e) => e.preventDefault()}
        style={{
          display: 'flex',
          gap: muiTheme.spacing(2.5),
          alignSelf: 'start',
          justifyContent: 'center',
        }}
      >
        <TrackShareButton
          label={'Email'}
          onClick={handleEmail}
          trackerEvent={REFERRAL_MODAL.SHARE_CLICK}
          trackerOptions={{ method: 'email' }}
        >
          <Email />
        </TrackShareButton>
        {canShare && (
          <TrackShareButton
            label={'Text'}
            onClick={handleShare}
            trackerEvent={REFERRAL_MODAL.SHARE_CLICK}
            trackerOptions={{ method: 'text' }}
          >
            <Chat />
          </TrackShareButton>
        )}
      </form>
    </Box>
  )
}

SharingForm.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}
