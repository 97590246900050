const defaultDeepCheck = (key, value) => {
  return typeof value === 'object'
}

const flatten = function(obj, deepCheck = defaultDeepCheck) {
  const result = {}

  for (let key in obj) {
    if (deepCheck(key, obj[key])) {
      const childObj = flatten(obj[key], deepCheck)

      for (let childObjKey in childObj) {
        result[`${key}.${childObjKey}`] = childObj[childObjKey]
      }
    } else {
      result[key] = obj[key]
    }
  }
  return result
}

export default flatten
