import React from 'react'
import PropTypes from 'prop-types'

import SummaryDetailsBox from 'artkive/components/Checkout/SummaryDetailsBox'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'

import Chip from './Chip'
import GiftSummaryDetails from './GiftSummaryDetails'

const GiftSummary = ({ options }) => {
  const { sendGiftToRecipient } = useVariableState(options.type, options.uuid)

  return (
    <SummaryDetailsBox
      title={(
        <>
          Gift Information
          {options.shipping_address && (
            <Chip label={sendGiftToRecipient ? 'Deliver to Recipient' : 'Deliver to Me'} />
          )}
        </>
      )}
      editHref={`/${BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS}`}
    >
      <GiftSummaryDetails options={options} />
    </SummaryDetailsBox>
  )
}

GiftSummary.protoTypes = {
  options: PropTypes.object.isRequired,
}

export default GiftSummary
