import React from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import { Button, Grid, TextField } from '@material-ui/core'

import validations from 'artkive/utils/validations'

const OrderTrackingForm = ({ onSubmit }) => {
  // form hooks
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    orderNumber: '',
    email: '',
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <TextField
            error={!!errors.orderNumber}
            helperText={errors?.orderNumber?.message}
            fullWidth
            label={'Box Order Number'}
            {...register('orderNumber', validations.orderNumber)}
            variant={'outlined'}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            error={!!errors.email}
            helperText={errors?.email?.message}
            fullWidth
            label={'Email Address'}
            {...register('email', validations.email)}
            type={'email'}
            variant={'outlined'}
          />
        </Grid>
        <Grid item xs={12} md={4} container>
          <Button color={'primary'} fullWidth={true} type={'submit'} size={'large'} variant={'contained'}>
            Track It
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

OrderTrackingForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default OrderTrackingForm
