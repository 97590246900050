import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import OptionListItem from 'artkive/components/Checkout/OptionListItem'
import CheckboxControl from 'artkive/components/Checkout/Sections/CheckboxControl'
import { AddonPrice } from 'artkive/components/OrderSummary/PriceWithDiscount/index'
import Qty from 'artkive/components/Qty'
import HelpTooltip from 'artkive/components/Tooltip/HelpTooltip'
import formatNumber from 'artkive/utils/formatNumber'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

const useBulletStyles = makeStyles(({ spacing }) => ({
  listItem: {
    lineHeight: '140%',
    marginTop: spacing(0.5),
  },
  listItemCaption: {
    fontWeight: 500,
    paddingLeft: spacing(1.5),
    textIndent: -spacing(1.5),
  },
  marker: {
    marginRight: spacing(1),
  },
  list: {
    listStyleType: 'none',
    paddingInlineStart: 0,
  },
}))

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  helpIcon: {
    marginTop: '2px',
  },
  checkboxLabel: {
    marginRight: 0,
  },
  priceDescription: {
    fontSize: 10,

    [breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
  qty: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: spacing(2),
    [breakpoints.up('md')]: {
      paddingLeft: spacing(6),
    },
  },
}))

const Bullets = ({ bullets }) => {
  const classes = useBulletStyles()

  return (
    <Box component={'ul'} my={0} className={classes.list}>
      {bullets.map(({ text }) => (
        <li className={classes.listItem} key={text}>
          <Typography variant={'caption'} className={classes.listItemCaption} component={'div'}>
            <span className={classes.marker}>•</span>
            {text}
          </Typography>
        </li>
      ))}
    </Box>
  )
}

const AddonItem = ({ item, getAddon, setAddon, lockedAddons, formatPrice, highlightable, dense, discountInfo }) => {
  const classes = useStyles()
  const addOn = useMemo(() => (
    getAddon(item)
  ), [item, getAddon])
  const [checked, setChecked] = useState(!!addOn)
  const [quantity, setQuantity] = useState(addOn?.quantity ?? 1)

  const disabled = !!lockedAddons[item.kind]

  // NOTE: addOn selection can be changed outside of this component
  useEffect(() => setChecked(!!addOn), [addOn])

  const handleChecked = ({ target }) => {
    setChecked(target.checked)
    setAddon({ addon: item, checked: target.checked, quantity })
  }

  const handleQuantity = (quantity) => {
    setQuantity(quantity)
    setAddon({ addon: item, checked, quantity })
  }

  const itemPriceWithDiscount = {
    base_price: item.price,
    promo_discount_details: discountInfo,
  }
  const isUSBAddon =
    item.kind === ADDONS.RETURN_USB && discountInfo?.discount_method === DISCOUNT_METHODS.FREE_USB

  return (
    <OptionListItem
      label={
        <Box display={'flex'} alignItems={'center'}>
          <CheckboxControl
            checked={checked}
            onChange={handleChecked}
            disabled={disabled}
            classes={{ root: classes.checkboxLabel }}
            label={
              <>
                <Box display={'flex'} alignItems={'flex-start'}>
                  <Typography variant={'body2'}>{item.name}</Typography>
                  {!!item.tip && (
                    <HelpTooltip
                      size={'large'}
                      title={item.name}
                      classes={{ anchor: classes.helpIcon }}
                    >
                      <Typography
                        variant={'body1'}
                        styles={{ fontSize: '14px' }}
                      >
                        {item.tip}
                      </Typography>
                    </HelpTooltip>
                  )}
                </Box>
              </>
            }
          />
          {(item.quantity_min || item.quantity_max) && (
            <Qty
              setQty={handleQuantity}
              qty={quantity}
              disabled={!checked}
              minQty={item.quantity_min}
              maxQty={item.quantity_max}
              className={classes.qty}
            />
          )}
        </Box>
      }
      secondaryLabel={
        item.bullets && (
          <Box ml={4.5}>
            <Bullets bullets={item.bullets} />
          </Box>
        )
      }
      price={!discountInfo && formatPrice(item.price * quantity)}
      priceWithDiscountsNode={
        !!discountInfo && (
          <AddonPrice
            isAddonSelected={checked}
            fullPriceInfo={itemPriceWithDiscount}
            enableUSBAddon={isUSBAddon}
          />
        )
      }
      active={checked}
      component={'div'}
      className={cl({ dense, highlightable })}
      priceDescription={
        item.price_description && (
          <Box textAlign={'right'} pt={0.5}>
            <Typography
              variant={'caption'}
              className={classes.priceDescription}
            >
              {item.price_description}
            </Typography>
          </Box>
        )
      }
    />
  )
}

AddonItem.propTypes = {
  item: PropTypes.object.isRequired,
  getAddon: PropTypes.func.isRequired,
  setAddon: PropTypes.func.isRequired,
  highlightable: PropTypes.bool,
  formatPrice: PropTypes.func,
  lockedAddons: PropTypes.object,
  dense: PropTypes.bool,
  discountInfo: PropTypes.shape({
    discount_method: PropTypes.string,
    amount: PropTypes.number,
  }),
}

AddonItem.defaultProps = {
  highlightable: true,
  formatPrice: (price) => +price ? `+ $${formatNumber(price)}` : `FREE`,
  lockedAddons: {},
  dense: false,
}

export default AddonItem
