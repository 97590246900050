import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Fade,
  Grid,
  Paper,
  Radio,
  Slide,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Image from 'artkive/components/Image'
import theme from 'artkive/utils/theme'

const useStyles = makeStyles({
  carousel: {
    backgroundColor: theme.colors.purple.main,
    minHeight: '200px',
    position: 'relative',
    overflow: 'hidden',
  },
})

const Carousel = ({ slides }) => {
  const muiTheme = useTheme()
  const classes = useStyles()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  // state
  const [activeSlide, setActiveSlide] = useState(slides[0].id)

  return (
    <Grid container>
      {/* <Hidden smDown> */}
      <Grid item xs={12} md={3} className={classes.carousel}>
        {slides.map((slide) => (
          <Fade
            key={slide.id}
            delay={3000}
            in={activeSlide === slide.id}
            position={activeSlide === slide.id ? 'relative' : 'absolute'}
          >
            <Box
              alignItems={'center'}
              display={'flex'}
              height={'100%'}
              justifyContent={'center'}
              overflow={'hidden'}
              position={'relative'}
              px={10}
            >
              <Image src={slide.image} style={{ maxWidth: isMobile ? '150px' : '100%' }} />
            </Box>
          </Fade>
        ))}
      </Grid>
      {/* </Hidden> */}
      <Grid item xs={12} md={9}>
        <Paper elevation={0}>
          <Box
            alignItems={'center'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            overflow={'hidden'}
            position={'relative'}
            py={4}
            px={isMobile ? 2 : 8}
          >
            {slides.map((slide) => (
              <Slide
                direction={'left'}
                in={activeSlide === slide.id}
                key={slide.id}
                position={activeSlide === slide.id ? 'relative' : 'absolute'}
              >
                <Box
                  display={'flex'}
                  height={'100%'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  overflow={'hidden'}
                  position={'relative'}
                >
                  <Box my={2}>
                    <Typography variant={'subtitle2'} color={'textSecondary'}>
                      IN THE NEWS
                    </Typography>
                  </Box>
                  <Box mb={2}><Typography variant={'h6'}>{slide.title}</Typography></Box>
                  <Box mb={2}><Typography gutterBottom >{slide.body}</Typography></Box>
                </Box>
              </Slide>
            ))}
          </Box>
          <Box display={'flex'} ml={6} pb={4}>
            {slides.map((slide) => (
              <Radio
                color={'primary'}
                checked={activeSlide === slide.id}
                key={slide.id}
                onClick={() => setActiveSlide(slide.id)}
                size={'small'}
              />
            ))}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

Carousel.propTypes = {
  slides: PropTypes.array,
}

export default Carousel
