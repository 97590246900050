export const BOX_PRODUCT_TYPE = 'box'
export const MEMBERSHIP_PRODUCT_TYPE = 'membership'
export const GIFT_CAD_PRODUCT_TYPE = 'giftCard'
export const PACKAGE_PRODUCT_TYPE = 'package'

/* TODO replace with some env setup */
export const DEFAULT_PRODUCT_ID = 4

export const PROMO_QUERY_FILED = 'promo'

export const PRODUCT_TYPE_QUERY_FILED = 'productType'

export const PAYMENT_KINDS = {
  CREDIT_CARD: 'credit_card',
  APPLE_PAY: 'apple_pay',
  PAYPAL: 'paypal',
}

export const CARD_TYPE = {
  PHYSICAL: 'PhysicalGiftCardOrder',
  E: 'EGiftCardOrder',
}
