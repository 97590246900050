import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Container,
  Paper,
  Slide,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { withClickTracker } from 'artkive/components/Tracker'
import { REFERRAL_MODAL } from 'artkive/constants/tracker/mainNavigation'
import useReferralCta from 'artkive/hooks/useReferralCta'
import routes from 'artkive/utils/routes'

import bubbles from 'images/ecom/get-started/bubbles.png'

import AccentLink from '../Navbar/AccentLink'

import MenuLink from './MenuLink'

const TrackAccentLink = withClickTracker(AccentLink)

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1.125rem',
    lineHeight: 1.4,
    overflowY: 'scroll',
    height: '100vh',
  },
  icon: {
    fontSize: 24,
  },
  getStartedWrapper: {
    position: 'relative',
    zIndex: 1,
    '&::before': {
      position: 'absolute',
      content: '" "',
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '99px 192px',
      transform: 'scaleX(-1)',
      width: 99,
      height: 192,
      left: -56,
      top: 0,
      zIndex: 0,
    },
    '&::after': {
      position: 'absolute',
      content: '" "',
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '99px 192px',
      width: 99,
      height: 192,
      right: -58,
      top: 0,
      zIndex: 0,
    },
  },
}))

const headerPadding = '48px'

const MobileMenu = ({ isOpen = false, onNavigate }) => {
  const classes = useStyles()
  const openReferralModal = useReferralCta()

  const handleGet20Click = useCallback((event) => {
    onNavigate?.(event)
    openReferralModal(event)
  }, [onNavigate, openReferralModal])

  return (
    <Slide direction={'down'} in={isOpen} timeout={600} style={{ zIndex: '1000' }}>
      <Box top={headerPadding} position={'fixed'} left={0} width={'100%'}>
        <Paper elevation={0} className={classes.root}>
          <Container>
            <Box py={headerPadding} textAlign={'center'}>
              <Box mb={2}>
                <MenuLink isPrimary={true} href={routes.books} variant={'h5'}>Art Books</MenuLink>
              </Box>
              <Box mb={2}>
                <MenuLink isPrimary={true} href={routes.mosaics} variant={'h5'}>Framed Mosaics</MenuLink>
              </Box>
              <Box mb={4}>
                <MenuLink isPrimary={true} href={routes.cardsByArtkive} variant={'h5'}>Cards by Artkive</MenuLink>
              </Box>
              <Box mb={2}><MenuLink href={routes.pricing}>Pricing</MenuLink></Box>
              <Box mb={2}><MenuLink href={routes.ourProcess}>Our Process</MenuLink></Box>
              <Box mb={2}><MenuLink href={routes.giftCards}>Gift Cards</MenuLink></Box>
              <Box mb={2}><MenuLink href={routes.blog} target={'_blank'}>Blog</MenuLink></Box>
              <Box mb={5}>
                <TrackAccentLink
                  key={'get-20'}
                  className={classes.navLink}
                  onClick={handleGet20Click}
                  trackerEvent={REFERRAL_MODAL.CTA_CLICK}
                >
                  Get $20
                </TrackAccentLink>
              </Box>
              <Box mb={2.5}>
                <MenuLink
                  component={Button}
                  size={'large'}
                  startIcon={<AccountCircleIcon />}
                  href={routes.accountOrders}
                >
                  My Account
                </MenuLink>
              </Box>
              <Box className={classes.getStartedWrapper} mt={5}>
                <Button
                  color={'primary'}
                  href={routes.boxCheckout}
                  variant={'contained'}
                  size={'large'}
                  style={{ zIndex: 1 }}
                >
                  Get My Box
                </Button>
              </Box>
            </Box>
          </Container>
        </Paper>
      </Box>
    </Slide>
  )
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  onNavigate: PropTypes.func,
}

export default MobileMenu
