import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Link, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Check } from '@material-ui/icons'
import { Link as RouteLink } from 'wouter'

import generatePath from 'common/utils/generatePath'
import { TrackButton } from 'artkive/components/Tracker'
import { MEMBERSHIP } from 'artkive/constants/tracker/mainNavigation'
import formatNumber from 'artkive/utils/formatNumber'
import { getPrice } from 'artkive/utils/subscription'
import theme from 'artkive/utils/theme'

import popularLabel from 'images/ecom/brand/popularLabel.svg'

import { PlanTypes } from './Plans.constants'

const useStyles = makeStyles(() => ({
  fontWeight: 'bold',
  root: {
    boxShadow: '0px 4px 12px rgba(20, 34, 108, 0.1)',
    borderRadius: 8,
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
  },
  checkIcon: {
    marginRight: 12,
  },
  mostPopularLabel: {
    backgroundImage: `url(${popularLabel})`,
    padding: '6px 20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    transform: 'translateX(-50%)',
    left: '50%',
    position: 'absolute',
    top: -1,
    color: '#202126',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 1.5,
  },
  planChip: {
    backgroundColor: theme.colors.white.main,
    fontWeight: 500,
    fontSize: '18px',
    height: 50,
    borderRadius: 25,
    border: '1px solid #D6D6D6',
  },
  active: {
    color: theme.colors.secondary.dark,
  },
  planImage: {
    width: 140,
    height: 140,
    borderRadius: '50%',
    '&$card': {
      width: 100,
      height: 100,
    },
  },
  card: {},
}))

export const BulletItem = ({ children, icon, disabled }) => {
  return (
    <Box display={'flex'} my={1} color={disabled ? theme.colors.grey.med : theme.colors.grey.dark}>
      {icon || <Check color={disabled ? theme.colors.secondary.light : 'primary'} />}
      <Typography variant={'body1'} style={{ marginLeft: 12 }} color={'inherit'}>
        {children}
      </Typography>
    </Box>
  )
}

BulletItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
}

const PlanCard = ({ plan, planType, setPlanType, variant = 'card', className }) => {
  const classes = useStyles()

  const isMonthlyPlan = planType === PlanTypes.MONTHLY

  const priceString = (price, plan) => {
    let cost
    if (price.trial_fee !== '0.0') {
      cost = price.trial_fee
    } else {
      cost = price.fee
    }

    cost = cost.replace('.0', '')

    cost = parseInt(cost)
    if (plan !== planType) {
      cost = cost / 12
    }
    return `$${formatNumber(cost)}/${plan === PlanTypes.ANNUAL ? 'year' : 'month'}`
  }

  const price = getPrice(plan.pricing, planType)

  const mainPlanPrice = variant === 'card' ? PlanTypes.MONTHLY : PlanTypes.ANNUAL
  const pricing = price ? (
    <Box color={theme.colors.black} textAlign={'center'} mt={1}>
      <Typography variant={'h6'} component={variant === 'card' ? 'div' : 'span'}>
        {priceString(getPrice(plan.pricing, planType), mainPlanPrice)}
      </Typography>

      {!isMonthlyPlan && (
        <Typography variant={'caption'} color={'secondary'}>
          {variant === 'card' && (
            <>
              {priceString(price, planType)} -
            </>
          )} BILLED ANNUALLY
        </Typography>
      )}
    </Box>
  ) : (
    <Box color={theme.colors.black} textAlign={'center'}>
      <Typography variant={'caption'}>
        {isMonthlyPlan ? PlanTypes.ANNUAL : PlanTypes.MONTHLY} ONLY
      </Typography>
      <Link
        onClick={() => setPlanType(isMonthlyPlan ? PlanTypes.ANNUAL : PlanTypes.MONTHLY)}
        style={{ fontWeight: 'bold' }}
        underline={'none'}
        variant={'b'}
      >
        See Pricing
      </Link>
    </Box>
  )

  const checkoutLink = generatePath('/plans/:uuid/checkout', {
    uuid: price.id,
  })

  return (
    <Box className={className} height={'100%'}>
      <Paper elevation={0} className={cl(classes.root)}>
        <Box
          px={variant === 'card' ? 2 : 6}
          pt={5}
          pb={3}
          height={'100%'}
          position={'relative'}
          display={'flex'}
          flexDirection={variant === 'card' ? 'column' : 'row'}
          alignItems={'center'}
        >
          {plan.title.includes('creator') && (
            <Box className={classes.mostPopularLabel}>
              Most Popular
            </Box>
          )}

          <Box mb={2} textAlign={'center'} p={variant === 'card' ? 0 : 0}>
            <img
              src={plan.image_url_path}
              className={cl(classes.planImage, { [classes.card]: variant === 'card' })}
            />
          </Box>

          <Box
            mb={2}
            textAlign={variant === 'card' ? 'center' : 'left'}
            px={6}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <Typography variant={'h3'} style={{ textTransform: 'capitalize' }} gutterBottom>
              {plan.title}
            </Typography>

            {variant === 'row' && (
              <>
                {pricing}
                <Box textAlign={'center'} mt={2}>
                  <TrackButton
                    color={'primary'}
                    size={'large'}
                    variant={'contained'}
                    trackerEvent={MEMBERSHIP.CHECKOUT_CLICK}
                    trackerOptions={{
                      planType,
                      plan: plan.title,
                    }}
                    component={RouteLink}
                    to={checkoutLink}
                  >
                    Checkout
                  </TrackButton>
                </Box>
              </>
            )}
          </Box>

          <Box ml={variant === 'card' ? 0 : 4} flexGrow={1}>
            {plan?.bullets?.map((bullet, i) => (
              <BulletItem disabled={false} key={i}>
                {bullet}
              </BulletItem>
            ))}

            {plan?.annualBullets?.map((bullet, index) => (
              <BulletItem disabled={isMonthlyPlan} key={index}>
                {bullet}
              </BulletItem>
            ))}
          </Box>

          {variant === 'card' && (
            <>
              {pricing}
              <Box textAlign={'center'} mt={3}>
                <TrackButton
                  color={'primary'}
                  size={'large'}
                  variant={'contained'}
                  trackerEvent={MEMBERSHIP.CHECKOUT_CLICK}
                  trackerOptions={{
                    planType,
                    plan: plan.title,
                  }}
                  component={RouteLink}
                  to={checkoutLink}
                >
                  Checkout
                </TrackButton>
              </Box>
            </>
          )}

        </Box>
      </Paper>
    </Box>
  )
}

PlanCard.propTypes = {
  plan: PropTypes.object,
  planType: PropTypes.string,
  onCheckout: PropTypes.func,
  setPlanType: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'row']),
  className: PropTypes.string,
}

export default PlanCard
