import React, { useMemo } from 'react'

import { persist } from 'easy-peasy'
import { Route, Router, Switch } from 'wouter'
import staticLocationHook from 'wouter/static-location'
import browserLocationHook from 'wouter/use-location'

import NoSsr from 'common/components/NoSsr'
import generatePath from 'common/utils/generatePath'
import checkoutTrackingMetaCreator from 'artkive/components/Checkout/checkoutTrackingMetaCreator'
import EmailListForm from 'artkive/components/EmailListForm'
import Footer from 'artkive/components/Footer'
import Navbar from 'artkive/components/Navbar'
import withApp from 'artkive/hoc/withApp'
import packageStore, { persistOptions } from 'artkive/stores/ecom/stores/package.store'

import ProductNotFound from '../ProductNotFound/ProductNotFound'

import Checkout from './Routes/Checkout'
import GetStarted from './Routes/GetStarted'
import OrderConfirmation from './Routes/OrderConfirmation'

const isSSR = typeof window === 'undefined'

const rootBase = ''

const CheckoutNestedRouting = (props) => {
  const useLocation = isSSR ? staticLocationHook(props.railsContext.pathname.replace(rootBase, '')) : browserLocationHook

  const productLinks = useMemo(() => ({
    landing: generatePath('/packages/:uuid', props.params),
    checkout: generatePath('/packages/:uuid/checkout', props.params),
    confirmation: generatePath('/packages/:uuid/confirmation', props.params),
  }), [])

  return (
    <Switch>
      <Route path={'/packages/not-found'}>
        <Navbar />
        <ProductNotFound {...props}  />
        <EmailListForm zIndex={100} />
        <Footer />
      </Route>
      <Route path={productLinks.landing}>
        <GetStarted {...props} productLinks={productLinks} />
      </Route>
      <Route path={`${productLinks.checkout}/:rest?`} >
        <NoSsr>
          <Router base={productLinks.checkout} hook={useLocation}>
            <Checkout {...props} productLinks={productLinks} />
          </Router>
        </NoSsr>
      </Route>
      <Route path={productLinks.confirmation}>
        <NoSsr>
          <Navbar />
          <OrderConfirmation {...props} productLinks={productLinks} />
          <Footer />
        </NoSsr>
      </Route>
    </Switch>
  )
}

const injectDynamicPackageStore = (store, { params }) => {
  const modelName = ['packageStore', params?.uuid].filter(Boolean).join('_')
  const state = store.getState()

  if (params && !(modelName in state)) {
    console.log('add new model', modelName)
    store.addModel(modelName, persist(packageStore, persistOptions))
  }
}

export default withApp(CheckoutNestedRouting, {
  trackingMetaCreator: checkoutTrackingMetaCreator,
  injectStoreModel: injectDynamicPackageStore,
})
