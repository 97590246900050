import React from 'react'
import PropTypes from 'prop-types'

import { Box, TextField } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import muiTheme from 'artkive/utils/muiTheme'
import theme from 'artkive/utils/theme'

import { breakPoint, useFormStyles } from './common'
import { DashedBorder } from './DashedBorder'

const CopyableTextField = styled(TextField)({
  '& .MuiInput-root': {
    borderRadius: 8,
  },
  '& .MuiInput-root::before': {
    display: 'none',
  },
  '& .MuiInput-root::after': {
    display: 'none',
  },
  '& .MuiInput-input': {
    borderRadius: 'inherit',
    textAlign: 'center',
    fontWeight: 600,
    color: theme.colors.black,
    background: theme.colors.sky[300],
  },
  '& .MuiInputBase-input': {
    padding: muiTheme.spacing(2.0625, 1.75),

    [breakPoint]: {
      padding: muiTheme.spacing(1.75, 1.75, 1.875),
    },
  },
})

const DashedOutlinedTextField = (props) => {
  return (
    <Box style={{ position: 'relative' }}>
      <CopyableTextField {...props}></CopyableTextField>
      <DashedBorder
        style={{ color: props.style && props.style.borderColor }}
      ></DashedBorder>
    </Box>
  )
}

export const YourReferralLink = ({ referralLink, disabled, label }) => {
  const classes = useFormStyles()

  return (
    <>
      <span
        style={{
          textAlign: 'center',
          alignSelf: 'end',
          fontWeight: 600,
          color: theme.colors.black,
        }}
      >
        {label}
      </span>

      <Box style={{ filter: disabled ? 'blur(3px)' : 'none' }}>
        <DashedOutlinedTextField
          name={'promo'}
          type={'text'}
          value={referralLink}
          fullWidth
          className={classes.textField}
          style={{ borderColor: theme.colors.sky[500] }}
          InputProps={{
            readOnly: true,
            disabled: disabled,
            className: classes.input,
          }}
          onClick={(event) => event.target.select()}
        ></DashedOutlinedTextField>
      </Box>
    </>
  )
}

YourReferralLink.propTypes = {
  referralLink: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

YourReferralLink.defaultProps = {
  disabled: false,
}
