import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Button, Link as MUILink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Link } from 'wouter'

import { BACK_CLICK } from 'artkive/constants/tracker/mainNavigation'

import { withClickTracker } from '../Tracker'

const useBackStyles = makeStyles((muiTheme) => ({
  root: {
    boxShadow: 'unset',
    fontWeight: 600,
    fontSize: 16,

    '&:hover': {
      backgroundColor: 'unset',
      color: muiTheme.palette.primary.main,
    },
  },
  startIcon: {
    marginRight: 0,
  },
  icon: {
    fontSize: 24,
  },
}))

const TrackedButton = withClickTracker(Button)

const WrappedLink = forwardRef((props, _ref) => <Link {...props} />)

const WrappedMUILink = forwardRef((props, _ref) => <MUILink {...props} />)

const BackButton = ({ icon, href, ...props }) => {
  const classes = useBackStyles()
  const component = href.startsWith('~/') ? WrappedLink : WrappedMUILink

  return (
    <TrackedButton
      variant={'text'}
      size={'large'}
      role={'link'}
      classes={{
        root: classes.root,
        startIcon: classes.startIcon,
      }}
      trackerEvent={BACK_CLICK}
      component={component}
      href={href}
      {...props}
      startIcon={<ArrowBackIosIcon color={icon ? 'action' : 'inherit'} className={classes.icon} />}
      disableRipple={true}
    >
      <span>{!icon && 'Back'}</span>
    </TrackedButton>
  )
}

BackButton.propTypes = {
  icon: PropTypes.bool,
  href: PropTypes.string,
}

export default BackButton
