import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { useVariableState } from 'artkive/hooks/useVariableStore'
import { USA_COUNTRY_KEY } from 'artkive/utils/addressValidationService'

import DetailsGiftCard from './DetailsGiftCard'
import { List, ListItem, ListItemText } from './DetailsList'

const addressLine = (address) => {
  const line = [address.address, address.address2, address.city, address.state].filter(Boolean).join(', ') + ` ${address.zipCode}`
  if (!address.country || address.country === USA_COUNTRY_KEY) return line

  return `${line}, ${address.country}`
}

const Description = withStyles({
  root: {
    fontSize: '0.75rem',
    marginBottom: 8,
  },
})(Typography)

const GiftSummaryDetails = ({ options }) => {
  const { sendGiftToRecipient, recipientInformation } = useVariableState(options.type, options.uuid)

  const { giftMessageTo, giftMessageFrom, giftMessage } = recipientInformation
  const hasGiftMessage = Boolean(giftMessageTo && giftMessageFrom && giftMessage)

  return (
    <>
      <Description variant={'caption'} component={'p'}>Recipient Information</Description>

      <List>
        <ListItem>
          <ListItemText>
            {recipientInformation.recipientFirstName} {recipientInformation.recipientLastName}
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            {[recipientInformation.recipientEmail, recipientInformation.recipientPhone].filter(Boolean).join(', ')}
          </ListItemText>
        </ListItem>
        {sendGiftToRecipient && (
          <ListItem>
            <ListItemText>{addressLine(recipientInformation)}</ListItemText>
          </ListItem>
        )}
      </List>
      {hasGiftMessage && (
        <DetailsGiftCard to={giftMessageTo} from={giftMessageFrom} message={giftMessage} />
      )}
    </>
  )
}

GiftSummaryDetails.propTypes = {
  options: PropTypes.object.isRequired,
}

export default GiftSummaryDetails
