import React from 'react'
import PropTypes from 'prop-types'

import { Controller, FormProvider } from 'react-hook-form'
import { Box, Button, Divider, Grid, Hidden, TextField, Typography } from '@material-ui/core'

import {
  RecipientAddressFragment,
  RecipientNameFragment,
  ShippingAddressFragment,
} from 'artkive/components/Checkout/Sections'
import useInformationForm from 'artkive/components/Checkout/useInformationForm'
import OrderPriceSummary from 'artkive/components/OrderSummary/OrderPriceSummary'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { CARD_TYPE } from 'artkive/stores/product.constants'

const OrderDetails = ({ onSubmit, product }) => {
  const isMobile = useMobileBreakpoint()
  const { handleFormSubmit, ...methods } = useInformationForm(product, onSubmit)
  const { formState: { errors, isValid } } = methods
  const isEGift = product.cardType === CARD_TYPE.E

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit}>
        <Typography variant={'h6'} mt={4}>
          Recipient {isEGift ? 'Information' : 'Address'}
        </Typography>

        <RecipientNameFragment />

        {isEGift ? (
          <RecipientAddressFragment errors={errors} />
        ) : (
          <ShippingAddressFragment />
        )}

        <Typography variant={'h6'} mt={4}>
          Personal Note
        </Typography>

        <Grid item xs={12}>
          <Controller
            name={'note'}
            render={({ field }) => (
              <TextField
                error={!!errors.note}
                helperText={errors?.note?.message}
                multiline
                fullWidth={true}
                minRows={4}
                margin={isMobile ? 'dense' : 'normal'}
                label={'Your Personal Note'}
                {...field}
                variant={'outlined'}
              />
            )}
          />
        </Grid>

        <Hidden mdUp>
          <Box my={2}><Divider /></Box>
          <OrderPriceSummary product={product} />
        </Hidden>

        <Box mt={6}>
          <Button
            color={'primary'}
            fullWidth={isMobile}
            size={'large'}
            type={'submit'}
            variant={'contained'}
            disabled={!isValid}
          >
            Continue To Your Info
          </Button>
        </Box>
      </form>

    </FormProvider>
  )
}

OrderDetails.propTypes = {
  onSubmit: PropTypes.func,
  product: PropTypes.object,
}

export default OrderDetails
