import { MAX_AMOUNT_OF_PICS, MIN_AMOUNT_OF_PICS, MIN_PRICE, PER_ITEM_DIGITAL_PRICE } from 'common/utils/conciergeDigitalInvoiceItem.constants'

const priceSchema = [
  { lower: MIN_AMOUNT_OF_PICS + 1, upper: MAX_AMOUNT_OF_PICS, price: PER_ITEM_DIGITAL_PRICE },
]

export const getPrice = (value) => {
  let total = MIN_PRICE
  priceSchema.forEach(({ lower, upper, price }) => {
    if (value >= lower) {
      total += Math.min(value - lower + 1, upper - lower + 1) * price
    }
  })

  return `$${Math.round(total)}`
}
