import React from 'react'

import NoSsr from './NoSsr'

const withNoSsr = (WrappedComponent) => {
  return (props) => {
    return (
      <NoSsr>
        <WrappedComponent {...props} />
      </NoSsr>
    )
  }
}

export default withNoSsr
