import {
  MAX_NUMBER_OF_PAGES,
  MIN_BOX_BOOK_NUMBER_OF_PAGES,
  MIN_NUMBER_OF_PAGES,
} from 'common/utils/bookSize.constants'

export const FAQS = [
  {
    id: 1,
    title: 'How many pieces of art fit in a book?',
    body: `You can print from ${MIN_NUMBER_OF_PAGES} images to ${MAX_NUMBER_OF_PAGES} images in ` +
     'a single book. Each page contains 1 piece of art to emulate the look and feel of a gallery ' +
     'art book. Please click [HERE](#preview) to view a sample book!',
  },
  {
    id: 2,
    title: 'Can I put more than one piece of art on a page?',
    body: 'No, each page features one piece of art. The books look really fantastic this way. If you want multiple small pieces on one page of the book, you can collage them onto a single piece of paper prior to sending in your art. ',
  },
  {
    id: 3,
    title: 'Can I customize my book?',
    body: 'For Artkive Box customers, the book creation is done for you by our book designers. We professionally photograph and edit the art you send in, and we create a preview of your book. You can move images around to change the order, change the cover art and title, select which info will print (child name, age/grade, date) and add captions. Our book builder and preview tool is interactive so you can personalize your book after we’ve sent your book proof.',
  },
  {
    id: 4,
    title: 'How long does it take to print and ship?',
    body: 'Printing a book takes 4 business days, and standard shipping takes approximately 2-5 business days depending on where you live.',
  },
  {
    id: 5,
    title: 'How does payment work?',
    body: "When using the Artkive Box service to create a book, you'll first purchase a box which will be used to send us the artwork. Once we receive your Artkive Box, we will photograph ALL of the artwork you sent in, and you will be invoiced based on the amount of artwork you sent, and the number of books you’d like. Please see the pricing slider above to get an estimate per book.",
  },
]

export const homeBookProductBullets = [
  { text: 'Professional photography, editing of each piece of art, and a digital proof to review' },
  {
    text: `Books range from ${MIN_BOX_BOOK_NUMBER_OF_PAGES}-${MAX_NUMBER_OF_PAGES} images and come ` +
      'in two sizes: 8"x8" or 11"x8.5"',
  },
  { text: 'Premium matte printing and ability to add custom cover and captions' },
  { text: 'Access to your digital images on our website and the iOS app.' },
]


export const bookProductBullets = [
  ...homeBookProductBullets,
  // {
  //   text: (
  //     <>
  //       <strong>Additionally!</strong> Access to the digital version of all your images on the website, and iOS App
  //     </>
  //   )
  // },
]
