import React from 'react'

import { Controller } from 'react-hook-form'
import cl from 'classnames'
import { Box, FormControlLabel, List, Radio } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import OptionListItem from 'artkive/components/Checkout/OptionListItem'

const useProcessingStyles = makeStyles(({ spacing }) => ({
  radioRoot: {
    padding: 0,
    alignSelf: 'flex-start',
  },
  root: {
    marginLeft: 0,
  },
  label: {
    marginLeft: spacing(1.5),
  },
}))

const RecorderSuggestion = ({ name, item, value: radioValue, dense }) => {
  const classes = useProcessingStyles()
  const labelId = `recorder-member-label-${item.name}`

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value, ...props } }) => (
        <OptionListItem
          label={(
            <FormControlLabel
              control={
                <Radio
                  onChange={onChange}
                  checked={value === radioValue}
                  color={'primary'}
                  inputProps={{ 'aria-labelledby': labelId }}
                  name={'recorder'}
                  id={'recorder'}
                  className={classes.radioRoot}
                  value={radioValue}
                  {...props}
                />
              }
              label={(
                <Box textAlign={'left'} fontWeight={'fontWeightBold'} width={120}>
                  {item.name}
                </Box>
              )}
              classes={{
                root: classes.root,
                label: classes.label,
              }}
            />
          )}
          className={cl({ dense, highlightable: true })}
          active={value === radioValue}
        />
      )}
    />
  )

}

const RecordingMemoriesSection = ({ name }) => {
  const handleRecorderChange = (item) => {
    console.log(item)
    // onChange && onChange(item)
  }
  return (
    <Box my={2}>
      <List disablePadding>
        <RecorderSuggestion
          item={{
            default: true,
            name: 'Me',
          }}
          onChange={handleRecorderChange}
          name={name}
          value={'me'}
        />
        <RecorderSuggestion
          item={{
            default: false,
            name: 'Someone Else',
          }}
          onChange={handleRecorderChange}
          name={name}
          value={'else'}
        />
      </List>
    </Box>
  )
}

export default RecordingMemoriesSection
