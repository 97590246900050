import { get } from 'react-hook-form'
import { action, createStore, persist, thunkOn } from 'easy-peasy'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

import { BOX_PRODUCT_TYPE, PACKAGE_PRODUCT_TYPE } from '../product.constants'

import BoxStore, { persistOptions as boxPersistOptions, STORE_NAME as BOX_STORE_NAME } from './stores/box.store'
import CookieStore from './stores/cookie.store'
import EnvStore from './stores/env.store'
import GiftCardStore from './stores/giftCard.store'
import PlanStore from './stores/plan.store'
import PromoStore from './stores/promo.store'
import UiStore from './stores/ui.store'
import UserStore from './stores/user.store'
import customStorage, { DEFAULT_STORAGE_KEY, DEFAULT_STORAGE_VERSION } from './customStorageEngine'

const boxStores = (actions) => {
  const stores = Object.keys(actions).filter((key) => key.includes(BOX_STORE_NAME))
  return stores.map((key) => actions[key])
}

const ecomStore = createStore({
  // stores
  [BOX_STORE_NAME]: persist(BoxStore, boxPersistOptions),

  giftCardStore: persist(GiftCardStore, { deny: ['payment', 'price'], storage: customStorage }),

  planStore: persist(PlanStore, { deny: ['payment'], storage: customStorage }),

  cookieStore: CookieStore,

  envStore: EnvStore,

  promoStore: persist(PromoStore, { storage: customStorage }),

  uiStore: UiStore,

  userStore: persist(UserStore, { storage: customStorage }),

  setBoxCheckoutAddonsUrl: action(() => {}),

  // bindings
  onPromoCodeChange: thunkOn(
    (stores) => [
      stores.promoStore.setPromo,
      stores.promoStore.resetPromo,
    ],
    async (actions, target, helpers) => {
      const store = helpers.getStoreState()
      const promo = store.promoStore.promo
      const { code: promoCode, productName } = promo

      for (const storeKey in actions) {
        if (
          (storeKey.includes(BOX_STORE_NAME) && productName === BOX_PRODUCT_TYPE) ||
          (storeKey.includes('packageStore') && productName === PACKAGE_PRODUCT_TYPE)
        ) {
          if (store[storeKey].promoCode !== promoCode) {
            actions[storeKey].setPromoCode(promoCode)
          }
        }
      }
    },
  ),

  onPromoDiscountMethodChange: thunkOn(
    ({ promoStore }) => [
      promoStore.setPromoDiscountMethod,
      promoStore.resetPromo,
    ],
    async (actions, target, helpers) => {
      const { discountMethod } = helpers.getStoreState().promoStore.promo

      if (discountMethod === DISCOUNT_METHODS.FREE_USB) {
        boxStores(actions).forEach((store) => {
          store.setAddonPessimistic({ checked: true, kind: ADDONS.RETURN_USB })
        })
      }
    },
  ),

  onPromoEmailChange: thunkOn(
    ({ promoStore }) => [promoStore.setEmail],
    async (actions, target, helpers) => {
      const { promoStore: { email }, userStore: { user } } = helpers.getStoreState()

      if (email !== user.email) actions.userStore.setUser({ email })
    },
  ),

  onUserChange: thunkOn(
    ({ userStore }) => [
      userStore.setUser,
    ],
    async (actions, target, helpers) => {
      const user = helpers.getStoreState().userStore.user

      if (Object.keys(user).length === 0 || target.payload.onThunkOn) return

      actions[BOX_STORE_NAME].setInformation({ ...user })
      actions.giftCardStore.setInformation({ ...user })
      actions.planStore.setInformation({ ...user })
    },
  ),

  onSetBoxCheckoutAddonsUrl: thunkOn(
    (actions) => [actions.setBoxCheckoutAddonsUrl],
    (actions) => {
      const params = new URLSearchParams(location.search)
      boxStores(actions).forEach((store) => {
        if (params.get('returnArt') === 'true') {
          store.setAddonPessimistic({ checked: true, kind: ADDONS.RETURN_ART })
        }
        if (params.get('returnUsb') === 'true') {
          store.setAddonPessimistic({ checked: true, kind: ADDONS.RETURN_USB })
        }
      })
    },
  ),

  onUserInfoChange: thunkOn(
    (stores) => (
      Object.values(stores).filter((store) => 'setInformation' in store).map((store) => store.setInformation)
    ),
    async (actions, target, helpers) => {
      const state = helpers.getStoreState()
      const user = state.userStore.user

      const normalizeInfo = (info) => {
        let res = {}
        const userKeys = Object.keys(user)

        Object.entries(info).forEach(([k, v]) => {
          if (userKeys.includes(k)) res[k] = v
        })

        return res
      }

      const storeGetter = target.type.replace('@action.', '').replace('.setInformation', '') + '.information'
      const information = normalizeInfo(get(state, storeGetter))

      if (JSON.stringify(user) !== JSON.stringify(information)) {
        actions.userStore.setUser({ ...information, onThunkOn: true })
      }
    },
  ),
}, {
  version: DEFAULT_STORAGE_VERSION,
  name: DEFAULT_STORAGE_KEY,
})

export default ecomStore
