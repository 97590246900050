import React from 'react'

import { Box, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'

import SwipeImg from 'images/ecom/brand/Swipe.svg'

const CustomLink = withStyles(() => ({
  root: {
    fontWeight: 'bold',
    margin: 0,
    padding: 0,
  },
}))(Link)

const Swipe = ({ children, ...props }) => (
  <CustomLink {...props}>
    <Box display={'inline-flex'} flexDirection={'column'}>
      {children}
      <Image src={SwipeImg} />
    </Box>
  </CustomLink>
)

export default Swipe
