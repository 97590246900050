import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import formatNumber from 'artkive/utils/formatNumber'
import theme from 'artkive/utils/theme'

import scribbleBlue from 'images/ecom/brand/scribble-blue.svg'
import scribbleOrange from 'images/ecom/brand/scribble-orange.svg'

import Addons from './Addons'
import BooksSection from './BooksSection'
import DigitalGallerySection from './DigitalGallerySection'
import GiftCardsSection from './GiftCardsSection'
import MosaicsSection from './MosaicsSection'
import { costAddons, restAddons, returnArtAddon, returnUsbAddon } from './Pricing.constants'
import TopSection from './TopSection'
import TransformSection from './TransformSection'

const useStyles = makeStyles(({ breakpoints }) => ({
  pageHeader: {
    backgroundColor: theme.colors.sky[300],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 64,
    [breakpoints.up('md')]: {
      paddingTop: 96,
    },
  },
  addonsScribble1: {
    position: 'absolute',
    transform: 'rotate(183deg)',
    bottom: -30,
    left: -30,
    [breakpoints.up('md')]: {
      right: -30,
      left: 'unset',
      bottom: -10,
    },
  },
  addonsScribble2: {
    position: 'absolute',
    transform: 'rotate(341deg)',
    top: -20,
    right: -30,
    [breakpoints.up('md')]: {
      right: -40,
      top: 0,
    },
  },
  addonsScribble3: {
    position: 'absolute',
    transform: 'rotate(341deg)',
    bottom: -30,
    left: -30,
    display: 'none',
    [breakpoints.up('md')]: {
      display: 'block',
      right: -30,
      bottom: -10,
    },
  },
}))

const Pricing = ({ book_pricing, giftcard_pricing, box_pricing, mosaic_pricing }) => {
  const classes = useStyles()
  const addons = useMemo(() => ([
    { ...returnArtAddon, title: returnArtAddon.title.replace('{price}', formatNumber(box_pricing.return_art_price)) },
    { ...returnUsbAddon, title: returnUsbAddon.title.replace('{price}', formatNumber(box_pricing.return_usb_price)) },
    ...restAddons,
  ]), [])

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
      <Box className={classes.pageHeader}>
        <Typography variant={'h1'}>Pricing Page</Typography>
      </Box>

      <TopSection />

      <TransformSection mosaicPrice={mosaic_pricing.small_product.price} />

      <BooksSection pricing={book_pricing} />

      <ContainedSection
        title={
          <Typography variant={'h2'} align={'center'}>
            Add-Ons
          </Typography>
        }
      >
        <Addons addons={costAddons}>
          <img src={scribbleOrange} className={classes.addonsScribble1} />
        </Addons>
      </ContainedSection>

      <MosaicsSection {...mosaic_pricing} />

      <DigitalGallerySection />

      <GiftCardsSection pricing={giftcard_pricing} />

      <ContainedSection
        title={
          <Typography variant={'h2'} align={'center'}>
            Add-Ons
          </Typography>
        }
      >
        <Addons addons={addons}>
          <img src={scribbleBlue} className={classes.addonsScribble2} />
          <img src={scribbleOrange} className={classes.addonsScribble3} />
        </Addons>
      </ContainedSection>
    </Box>
  )
}

Pricing.propTypes = {
  book_pricing: PropTypes.object.isRequired,
  giftcard_pricing: PropTypes.object.isRequired,
  box_pricing: PropTypes.object.isRequired,
  mosaic_pricing: PropTypes.object.isRequired,
}

export default Pricing
