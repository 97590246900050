import {
  action,
  computed,
  thunk,
} from 'easy-peasy'

import request from 'common/utils/request'
import reset from 'artkive/stores/ecom/helpers/reset'
import { CARD_TYPE, GIFT_CAD_PRODUCT_TYPE } from 'artkive/stores/product.constants'

import CardImg from 'images/ecom/giftcards/Card.png'
import ECardImg from 'images/ecom/giftcards/EGiftCardProductMini.png'

import { DEFAULT_STATE as USER_STATE } from './user.store'

const GIFT_CARD_SHIPPING = 0
const fullName = computed(({ information }) => [information.firstName, information.lastName].filter(Boolean).join(' '))

export const PHYSICAL_GIFTCARD_PRODUCT = {
  image: CardImg,
  msrp: 0,
  price: 0,
  title: 'Gift Card',
  type: GIFT_CAD_PRODUCT_TYPE,
  cardType: CARD_TYPE.PHYSICAL,
}

export const E_GIFTCARD_PRODUCT = {
  image: ECardImg,
  msrp: 0,
  price: 0,
  title: 'E-Gift Card',
  type: GIFT_CAD_PRODUCT_TYPE,
  cardType: CARD_TYPE.E,
  isTaxable: false,
}

export const GIFT_CHECKOUT_STEPS = {
  INFORMATION: 'information',
  SHIPPING: 'shipping',
  PAYMENT: 'payment',
}

const DEFAULT_STATE = {
  information: { ...USER_STATE.user },
  payment: {},
  product: { ...PHYSICAL_GIFTCARD_PRODUCT },
  products: {
    physical: { ...PHYSICAL_GIFTCARD_PRODUCT },
    egift: { ...E_GIFTCARD_PRODUCT },
  },
  qty: 1,
  steps: [
    {
      alias: GIFT_CHECKOUT_STEPS.SHIPPING,
      name: 'Recipient Information',
      index: 0,
      isActive: true,
      isComplete: false,
      isVisible: true,
    },
    {
      alias: GIFT_CHECKOUT_STEPS.INFORMATION,
      name: 'Your Contact Information',
      index: 1,
      isActive: false,
      isComplete: false,
      isVisible: true,
    },
    {
      alias: GIFT_CHECKOUT_STEPS.PAYMENT,
      name: 'Payment',
      index: 2,
      isActive: false,
      isComplete: false,
      isVisible: true,
    },
  ],
  confirmation: {},
}

const resetter = reset({ defaultState: DEFAULT_STATE })

const giftCardStore = {
  // state
  ...DEFAULT_STATE,

  // computed
  activeStep: computed((state) => state.steps.find((step) => !!step.isActive)),

  visibleSteps: computed((state) => state.steps.filter((step) => !!step.isVisible)),

  fromName: fullName,

  fullName,

  price: computed((state) => ({
    processing_price: 0,
    shipping_price: GIFT_CARD_SHIPPING,
    subtotal: state.qty * state.product.price,
    tax: 0,
    total: (state.qty * state.product.price) + GIFT_CARD_SHIPPING,
  })),

  // actions
  setInformation: action((state, payload = {}) => {
    state.information = { ...state.information, ...payload }

    if (payload.fromName) {
      const [first, ...last] = payload.fromName.split(' ')

      state.information.firstName = first
      state.information.lastName = last.join(' ')
    }

    if (payload.toName) {
      const [first, ...last] = payload.toName.split(' ')

      state.information.recipientFirstName = first
      state.information.recipientLastName = last.join(' ')
    }
  }),

  setPrice: action((state, payload) => {
    state.price = { ...state.price, ...payload }
  }),

  setPayment: action((state, payload) => {
    state.payment = { ...state.payment, ...payload }
  }),

  setPromoCode: action((state, payload) => {
    state.promoCode = payload
  }),

  setProduct: action((state, payload) => {
    state.product = { ...state.product, ...payload }
  }),

  setConfirmation: action((state, payload) => {
    console.log('setConfirmation', payload)
    state.confirmation = { ...payload }
  }),

  setProducts: action((state, payload) => {
    state.products = { ...payload }
  }),

  setRecipient: action((state, payload) => {
    state.recipient = { ...state.recipient, ...payload }
  }),

  setQty: action((state, payload) => {
    state.qty = payload
  }),

  setStepActive: action((state, { stepName }) => {
    const alias = stepName.toLowerCase()

    state.steps = state.steps.map((step) => step.alias.toLowerCase() === alias
      ? { ...step, isActive: true }
      : { ...step, isActive: false })
  }),

  setStep: action((state, { stepName, payload }) => {
    const alias = stepName.toLowerCase()
    console.log('setStep', alias)
    state.steps = state.steps.map((step) => step.alias.toLowerCase() === alias
      ? { ...step, ...payload }
      : step)
  }),

  // thunks
  placeOrder: thunk(async (actions, payload) => {
    try {
      const response = await request.post(Routing.api_v2_orders_gift_cards(), { ...payload })

      return { response }
    } catch (error) {
      return { error }
    }
  }),

  fetchProducts: thunk(async (actions) => {
    try {
      const [physicalResponse, eGiftResponse] = await Promise.all([
        request.post('/api/v2/orders/gift_cards/pricing', {
          type: PHYSICAL_GIFTCARD_PRODUCT.cardType,
        }),
        request.post('/api/v2/orders/gift_cards/pricing', {
          type: E_GIFTCARD_PRODUCT.cardType,
        }),
      ])

      actions.setProducts({
        physical: { ...PHYSICAL_GIFTCARD_PRODUCT, ...physicalResponse.data.data },
        egift: { ...E_GIFTCARD_PRODUCT, ...eGiftResponse.data.data },
      })
    } catch (error) {
      return { error }
    }
  }),

  ...resetter,
}

export default giftCardStore
