import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Divider from 'artkive/components/Checkout/Divider'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import formatPrice from 'artkive/utils/formatPrice'
import theme from 'artkive/utils/theme'

import  { OrderSummaryPrice } from './PriceWithDiscount/index'

const useStyles = makeStyles(({ palette }) => ({
  header: {
    backgroundColor: '#FDE4A5',
  },
  content: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FEF6E1;',
  },
  listItemRoot: {
    padding: 0,
  },
  listRoot: {
    padding: 0,
  },
  refundText: {
    color: theme.colors.green.main,
  },
  subtotal: {
    color: palette.common.black,
    fontWeight: 500,
  },
  totalPrice: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    color: palette.common.black,
    fontWeight: 'bold',
  },
}))

const OrderPriceSummary = ({ product }) => {
  const classes = useStyles()
  const { price, vipSubscription } = useVariableState(product.type, product.uuid)
  const fragments = []
  const isBoxProduct = product.type === BOX_PRODUCT_TYPE
  const isPromoDiscount = !!price?.promo_discount_details?.code

  // just push empty fragment, because we moved this logic to PriceWithDiscount component,
  // but need to show subtotal price
  if (isPromoDiscount) {
    fragments.push(
      <></>,
    )
  }

  if (isBoxProduct && vipSubscription) {
    fragments.push(
      <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} my={2} key={'refaund'}>
        <Typography className={classes.refundText}>Artkive Box Cost Refund </Typography>
        <Typography className={classes.refundText}>
          {price?.promo_discount_details?.discount_method}{formatPrice(price?.promo_discount) || 0}
        </Typography>
      </Box>,
    )
  }

  if (price?.tax) {
    // NOTE: currently this fields represents exact tax amount to be charged
    fragments.push(
      <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} my={2} key={'tax'}>
        <Typography>Tax</Typography>
        <Typography>{formatPrice(price.tax)}</Typography>
      </Box>,
    )
  }

  return (
    <>
      {!!fragments.length && (
        <Box alignItems={'center'} display={'flex'} justifyContent={'space-between'} my={2}>
          <Typography variant={'body2'} className={classes.subtotal}>Subtotal{' '}</Typography>
          <Typography className={classes.subtotal}>{formatPrice(price?.before_discount)}</Typography>
        </Box>
      )}

      {!!fragments.length && (
        <>
          {fragments}
          <Divider />
        </>
      )}

      <Box mt={3} mb={6}>
        <Typography variant={'h3'} className={classes.totalPrice}>
          <span>Total:</span>
          <OrderSummaryPrice fullPriceInfo={price} />
        </Typography>
      </Box>
    </>
  )
}

OrderPriceSummary.propTypes = {
  product: PropTypes.object,
}

export default OrderPriceSummary
