import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    rowGap: 16,
    position: 'relative',
    [muiTheme.breakpoints.up('md')]: {
      rowGap: 24,
      columnGap: 60,
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  title: {
    marginBottom: 4,
    [muiTheme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
  },
  addon: {
    border: '1px solid #EBEBEB',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    display: 'flex',
    alignItems:'center',
    padding: '20px 20px',
    [muiTheme.breakpoints.up('md')]: {
      padding: '20px 30px',
    },
  },
  image: {
    width: 75,
    height: 75,
    marginRight: 16,
    [muiTheme.breakpoints.up('md')]: {
      width: 100,
      height: 100,
      marginRight: 40,
    },
  },
}))

const Addons = ({ addons, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {addons.map((addon, index) => (
        <Box className={classes.addon} key={index}>
          <img src={addon.image} className={classes.image} />
          <Box>
            <Typography variant={'h3'} className={classes.title}>
              {addon.title}
            </Typography>
            <Typography variant={'body2'}>{addon.description}</Typography>
          </Box>
        </Box>
      ))}
      {children}
    </div>
  )
}

Addons.propTypes = {
  addons: PropTypes.array,
  children: PropTypes.node,
}


export default Addons
