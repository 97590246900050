import PropTypes from 'prop-types'

import { Link } from '@material-ui/core'

import withClickTracker from './withClickTracker'

const TrackLink = withClickTracker(Link)

TrackLink.propTypes = {
  trackerEvent: PropTypes.string,
  trackerOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}
export default TrackLink
