export function flattenObject(nestedObj, parentKey = '', flatObj = {}) {
  for (const key of Object.keys(nestedObj)) {
    const value = nestedObj[key]
    const newKey = parentKey ? `${parentKey}.${key}` : key

    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      flattenObject(value, newKey, flatObj)
    } else {
      flatObj[newKey] = value
    }
  }

  return flatObj
}
