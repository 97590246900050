import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import ProductPriceTypography from 'artkive/components/PageSections/ProductPriceTypography'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'

import BooksPreview from 'images/ecom/pricing/books.png'
import DigitalPreview from 'images/ecom/pricing/digital.png'
import MosaicsPreview from 'images/ecom/pricing/Mosaics.jpg'

import { PRICING } from './Pricing.constants'

const useStyles = makeStyles(({ breakpoints }) => ({
  gridContainer: {
    position: 'relative',
    zIndex: '0',
  },
  container: {},
  card: {
    textAlign: 'center',
    padding: '0',
    display: 'flex',
    [breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  image: {
    width: 120,
    [breakpoints.up('md')]: {
      marginBottom: 24,
      width: '100%',
    },
    '& img': {
      borderRadius: '8px',
    },
  },
  title: {
    fontSize: '1.25rem',
  },
  price: {
    fontSize: '1.125rem',
    [breakpoints.up('md')]: {
      fontSize: '1.5rem',
    },
  },
  priceText: {
    fontSize: '0.875rem',
    fontWeight: 600,
    [breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  moreBtn: {
    alignSelf: 'baseline',
    marginTop: 4,
    [breakpoints.up('md')]: {
      marginTop: 32,
      alignSelf: 'center',
    },
    [breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  description: {
    flex: 1,
    marginLeft: 16,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      alignItems: 'center',
      textAlign: 'center',
      marginLeft: 0,
      marginTop: 12,
    },
  },
  additional: {
    textAlign: 'center',
    marginTop: 64,
    [breakpoints.up('md')]: {
      maxWidth: 860,
      margin: '96px auto 0 auto',
    },
  },
}))

const TransformSection = ({ mosaicPrice }) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  return (
    <ContainedSection
      title={
        <Typography variant={'h2'} align={'center'}>
          We Can Transform Your Art Into:
        </Typography>
      }
      containerClassName={classes.container}
      pt={isMobile ? 6 : 8}
    >
      <Grid container className={classes.gridContainer} spacing={4}>
        <Grid xs={12} md={4} item>
          <Box className={classes.card}>
            <Box className={classes.image}>
              <Image src={BooksPreview} />
            </Box>
            <Box className={classes.description}>
              <Typography variant={'h3'} className={classes.title}>
                Art Books
              </Typography>
              <Box
                alignItems={'center'}
                display={'flex'}
                mt={isMobile ? 0.5 : 1.5}
              >
                <Typography variant={'body1'} className={classes.priceText}>
                  Starting at&nbsp;
                  <Hidden smDown>
                    <br />
                  </Hidden>
                  <ProductPriceTypography className={classes.price}>
                    ${PRICING.books.base}
                  </ProductPriceTypography>
                </Typography>
              </Box>
              <TrackButton
                color={'primary'}
                href={routes.books}
                size={isMobile ? 'small' : 'large'}
                variant={isMobile ? 'text' : 'outlined'}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{
                  section: 'get_books_banner',
                }}
                className={classes.moreBtn}
              >
                Learn More
              </TrackButton>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box className={classes.card}>
            <Box className={classes.image}>
              <Image src={MosaicsPreview} />
            </Box>
            <Box className={classes.description}>
              <Typography variant={'h3'} className={classes.title}>
                Framed Mosaics
              </Typography>
              <Box
                alignItems={'center'}
                display={'flex'}
                mt={isMobile ? 0.5 : 1.5}
              >
                <Typography variant={'body1'} className={classes.priceText}>
                  Starting at&nbsp;
                  <Hidden smDown>
                    <br />
                  </Hidden>
                  <ProductPriceTypography className={classes.price}>
                    ${mosaicPrice}
                  </ProductPriceTypography>
                </Typography>
              </Box>
              <TrackButton
                color={'primary'}
                href={routes.mosaics}
                size={isMobile ? 'small' : 'large'}
                variant={isMobile ? 'text' : 'outlined'}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{
                  section: 'get_books_banner',
                }}
                className={classes.moreBtn}
              >
                Learn More
              </TrackButton>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={4} item>
          <Box className={classes.card}>
            <Box className={classes.image}>
              <Image src={DigitalPreview} />
            </Box>
            <Box className={classes.description}>
              <Typography variant={'h3'} className={classes.title}>
                Digital Gallery
              </Typography>
              <Box
                alignItems={'center'}
                display={'flex'}
                mt={isMobile ? 0.5 : 1.5}
              >
                <Typography
                  variant={'body1'}
                  align={'center'}
                  className={classes.priceText}
                >
                  Starting at&nbsp;
                  <Hidden smDown>
                    <br />
                  </Hidden>
                  <ProductPriceTypography className={classes.price}>
                    ${PRICING.digital.base}
                  </ProductPriceTypography>
                </Typography>
              </Box>
              <TrackButton
                color={'primary'}
                href={routes.digital}
                size={isMobile ? 'small' : 'large'}
                variant={isMobile ? 'text' : 'outlined'}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{
                  section: 'get_books_banner',
                }}
                className={classes.moreBtn}
              >
                Learn More
              </TrackButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.additional}>
        <Typography variant={'h3'} style={{ marginBottom: 16 }}>
          Keep in Mind
        </Typography>
        <Typography>
          Each piece of art sent in will be photographed and included in your
          order. Removing images from a book or mosaic proof will not change the
          cost of your order. If you decide you don't want to print a book or
          mosaic, once the art has been photographed, you are responsible for
          paying for the digital gallery which is the minimum required payment.
        </Typography>
      </Box>
    </ContainedSection>
  )
}

TransformSection.propTypes = {
  mosaicPrice: PropTypes.number.isRequired,
}

export default TransformSection
