import React from 'react'

import { StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import { StoreProvider } from 'easy-peasy'
import { Router } from 'wouter'
import staticLocationHook from 'wouter/static-location'
import browserLocationHook from 'wouter/use-location'

import ErrorBoundary from 'artkive/components/ErrorBoundary'
import withHydration from 'artkive/hoc/withHydration'
import withStyledSsr from 'artkive/hoc/withStyledSsr'
import useKlaviyoFormCatch from 'artkive/hooks/useKlaviyoFormCatch'
import store from 'artkive/stores/ecom/ecom.store'
import muiTheme from 'artkive/utils/muiTheme'

const isSSR = typeof window === 'undefined'

/**
 * https://developers.facebook.com/community/threads/320013549791141/
 *
 * Facebook script is not loaded properly inside FB/Instagram WebView browser. The callback called before init.
 */
if (!isSSR) {
  window._AutofillCallbackHandler = window._AutofillCallbackHandler || function() {}
}

const withApp = (Component, options = {}) => {
  const HydratedComponent = withHydration(Component, options)

  const PreppedComponent = ({ env, ...props }) => {
    useKlaviyoFormCatch()
    const useLocation = isSSR ? staticLocationHook(props.railsContext.pathname.replace('', '')) : browserLocationHook

    return (
      <StoreProvider store={store}>
        <ErrorBoundary env={env}>
          <ThemeProvider theme={muiTheme}>
            <StylesProvider>
              <Router base={''} hook={useLocation}>
                <HydratedComponent {...props} env={env} />
              </Router>
            </StylesProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </StoreProvider>
    )
  }

  return withStyledSsr(PreppedComponent)
}

export default withApp
