import React from 'react'

import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Link,
  Paper,
  Slider,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { Check, FormatQuoteRounded } from '@material-ui/icons'

import { getPrice } from 'common/utils/conciergeDigitalInvoiceItem'
import {
  MAX_AMOUNT_OF_PICS,
  MIN_AMOUNT_OF_PICS,
  MIN_PRICE,
  USB_PRICE,
} from 'common/utils/conciergeDigitalInvoiceItem.constants'
import Image from 'artkive/components/Image'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import useScrollToSlider from 'artkive/hooks/useScrollToSlider'
import { compMembershipPeriod } from 'artkive/stores/ecom/constants/memberships'
import { HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Testimonial from 'images/ecom/digital/Degitization-Testimonial.png'
import Product from 'images/ecom/digital/Digitization.png'
import Customer from 'images/ecom/digital/Digitization-Testimonial.png'

const ValueLabelComponent = ({ children, open, value }) => {
  return (
    <>
      {children}
      <Box
        position={'absolute'}
        open={open}
        style={{ top: '60%', ...children.props.style, transform: 'translate(-50%, 0)' }}
        width={'200px'}
      >
        <Paper elevation={0}>
          <Box p={4} textAlign={'center'}>
            <Typography gutterBottom >{value} Art Pieces</Typography>
            <Typography gutterBottom variant={'h6'}>{getPrice(value)}</Typography>
            <Hidden mdDown>
              <Button color={'primary'} href={routes.boxCheckout} variant={'contained'}>Get My Box</Button>
            </Hidden>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

const Digital = () => {
  const muiTheme = useTheme()
  const isMobile = useMobileBreakpoint()
  const sliderRef = useScrollToSlider()

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>

      {/* I CAN BE YOUR HERO BABY */}
      <Box mb={10}>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item>
            <Hidden mdUp><Image src={Product} /></Hidden>
            <Hidden smDown>
              <Box
                style={{
                  backgroundImage: `url(${Product})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  minHeight: '680px',
                }}
                height={'100%'}
                width={'100%'}
              >
                &nbsp;
              </Box>
            </Hidden>
          </Grid>
          <Grid xs={12} md={6} item style={{ position: 'relative' }}>
            <Box
              position={isMobile ? 'relative' : 'absolute'}
              left={isMobile ? '0' : '-5%'}
              top={isMobile ? '0' : '50%'}
              style={{ transform: isMobile ? 'translate(0, 0)' : 'translate(0, -50%)' }}
            >
              <Paper elevation={0}>
                <Box px={isMobile ? 4 : 10} py={4}>
                  <Typography gutterBottom variant={'h2'}>Art &amp; Craft Digitization</Typography>
                  <Box lineHeight={1.6} mb={4}>
                    <Typography gutterBottom>
                      We’ll take your child's art— and carefully photograph, edit, and preserve your
                      precious memories onto a USB drive and in the cloud.
                    </Typography>
                  </Box>
                  <Box display={'flex'} my={2}>
                    <Check color={'primary'} />&nbsp;
                    <Typography >Professional Photography &amp; Editing of Your Artwork</Typography>
                  </Box>
                  <Box display={'flex'} my={2}>
                    <Check color={'primary'} />&nbsp;
                    <Typography >USB Drive Loaded with Your Art (+${USB_PRICE})</Typography>
                  </Box>
                  <Box display={'flex'} my={2}>
                    <Check color={'primary'} />&nbsp;
                    <Typography>
                      {compMembershipPeriod} Months Free Organizer Membership (Access Your Artwork Online)
                    </Typography>
                  </Box>
                  <Box alignItems={'center'} display={'flex'} mt={4}>
                    <Box fontSize={'12px'}>Starting At:</Box>
                    <Typography variant={'h6'}>&nbsp; ${MIN_PRICE}</Typography>
                  </Box>
                  <Box mb={4}>
                    <Typography style={{ textTransform: 'uppercase', fontSize: '10px' }}>
                      &nbsp;Price does not include artkive box or USB
                    </Typography>
                  </Box>

                  <Button
                    color={'primary'}
                    fullWidth={true}
                    href={routes.boxCheckout}
                    size={'large'}
                    variant={'contained'}
                  >
                    Get My Box
                  </Button>
                </Box>
              </Paper>
            </Box>
            <Hidden smDown>
              <Box bgcolor={theme.colors.yellow.med} height={'100%'} width={'100%'}>&nbsp;</Box>
            </Hidden>
          </Grid>
        </Grid>
      </Box>

      {/* pricing */}
      <Box mt={4} py={4} mb={10} pb={10} id={'slider'} ref={sliderRef}>
        <Container>
          <Box textAlign={'center'} mb={4}>
            <Typography variant={'h2'}>How Much Does Digitization Cost?</Typography>
          </Box>
          <Box textAlign={'center'} mb={2}>
            <Typography >Cost is dependent on number of art pieces sent with Artkive Box.</Typography>
          </Box>
          <Box textAlign={'center'} mb={4} fontSize={'12px'}>
            If you have more than {MAX_AMOUNT_OF_PICS} pieces please{' '}
            <Link href={`mailto:${HELP_EMAIL}`}>email us</Link> for a quote.
          </Box>
          <Box px={10}>
            <Slider
              defaultValue={MIN_AMOUNT_OF_PICS}
              ValueLabelComponent={ValueLabelComponent}
              aria-labelledby={'discrete-slider'}
              valueLabelDisplay={'auto'}
              step={1}
              min={MIN_AMOUNT_OF_PICS}
              max={MAX_AMOUNT_OF_PICS}
            />
            <Hidden mdUp>
              <Box mt={8}>&nbsp;</Box>
              <Box mt={10} textAlign={'center'}>
                <Button color={'primary'} href={routes.boxCheckout} variant={'contained'}>Get My Box</Button>
              </Box>
            </Hidden>
          </Box>
        </Container>
      </Box>

      {/* Testimonial */}
      <Container>
        <Box py={10}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={8}
              style={{
                backgroundColor: muiTheme.palette.common.white,
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box px={8} py={4}>
                <Box mb={4}><FormatQuoteRounded color={'primary'} fontSize={'large'} /></Box>
                <Typography gutterBottom variant={'subtitle2'}>
                  I was planning to photograph all the artwork myself, but just didn't have the time.
                  I&apos;m so happy I used Artkive instead!  I love having such professional, high-quality pics of the
                  artwork to easily send our other family members and include in family photo albums.
                </Typography>
                <Box alignItems={'center'} display={'flex'} mt={4}>
                  <Image src={Customer} style={{ maxWidth: '45px' }} />
                  <Box fontSize={'12px'} ml={2}>Selma, Marco&apos;s mom</Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Image src={Testimonial} />
            </Grid>
          </Grid>
        </Box>
      </Container>

    </Box>
  )
}

export default withApp(Digital)
