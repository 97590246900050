import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, NoSsr, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'

import ExitIntent from 'artkive/components/Modals/ExitIntent'
import { DISCOUNT_BANNER } from 'artkive/constants/tracker/mainNavigation'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import { TrackButton, TrackLink, withClickTracker } from '../Tracker'

// constants
const FOOTER_PROMPT_COOKIE_NAME = 'FOOTER_HIDE_PROMPT'
const FOOTER_PROMPT_COOKIE_VALUE = 'true'

const TrackClickIcon = withClickTracker(Close)

const CheckoutPrompt = () => (
  <Box style={{
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }}>
    <Typography style={{
      color: theme.colors.white.main,
      marginRight: '10px',
    }}>
      Box discount applied!
    </Typography>

    <TrackButton
      color={'primary'}
      href={routes.boxCheckout}
      variant={'contained'}
      style={{
        color: theme.colors.white.main,
        marginLeft: '10px',
      }}
      trackerEvent={DISCOUNT_BANNER.CTA_CLICK}
    >
      Save Now
    </TrackButton>
  </Box>
)

const EmailPrompt = ({ setOpen }) => {
  const { setCookie } = useStoreActions(({ cookieStore }) => cookieStore)

  const handleCloseClick = () => {
    setCookie({
      key: FOOTER_PROMPT_COOKIE_NAME,
      options: { expires: 3 },
      value: FOOTER_PROMPT_COOKIE_VALUE,
    })
  }

  return (
    <Box align={'center'} py={1} style={{ color: theme.colors.white.main }}>
      <Typography variant={'body1'} style={{
        color: theme.colors.white.main,
        fontSize: '0.9rem',
        fontWeight: '500',
      }}>
        Want $10 off Artkive Box? <TrackLink
          style={{
            color: theme.colors.secondary.med,
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={(evt) => {
            evt.preventDefault()

            setOpen(true)
          }}
          trackerEvent={DISCOUNT_BANNER.CTA_CLICK}
        >
          Save now!
        </TrackLink>
      </Typography>

      <Box style={{
        cursor: 'pointer',
        marginTop: '-13px',
        position: 'absolute',
        right: '15px',
        top: '50%',
      }}>
        <TrackClickIcon
          onClick={handleCloseClick}
          trackerEvent={DISCOUNT_BANNER.CLOSE_BANNER_CLICK}
        />
      </Box>
    </Box>
  )
}

const EmailStickyCta = ({ showPrompt, showValidated, zIndex }) => {
  const { cookies } = useStoreState(({ cookieStore }) => cookieStore)
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(showValidated || (!cookies[FOOTER_PROMPT_COOKIE_NAME] && showPrompt))
  }, [cookies, showPrompt, showValidated])

  return (
    <NoSsr>
      <ExitIntent open={open} onClose={() => setOpen(false)} />

      <Box bgcolor={theme.colors.primary.main} py={1} style={{
        bottom: '0',
        color: theme.colors.white.main,
        left: '0',
        overflow: 'hidden',
        position: 'fixed',
        right: '0',
        textAlign: 'center',
        transform: `translate(0, ${show ? '0px' : '100%'})`,
        transition: `transform 0.6s ${show ? 'ease-in 0.6s' : 'ease-out 0s'}`,
        userSelect: show ? 1 : 0,
        zIndex: zIndex,
      }}>
        {showValidated && <CheckoutPrompt />}

        {!showValidated && showPrompt && <EmailPrompt setOpen={setOpen} />}
      </Box>
    </NoSsr>
  )
}

EmailStickyCta.propTypes = {
  showPrompt: PropTypes.bool.isRequired,
  showValidated: PropTypes.bool.isRequired,
  zIndex: PropTypes.number.isRequired,
}

export default EmailStickyCta
