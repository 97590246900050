import Press from 'images/ecom/home/Press.png'
import Press2 from 'images/ecom/home/Press-2.png'
import Press3 from 'images/ecom/home/Press-3.png'

export const SLIDES = [
  {
    id: 0,
    image: Press,
    title: '2021 GH Storage Award Winner: Superb Artwork Collector',
    body: 'What parent doesn\'t want to save all their kid\'s artful creations? But there isn\'t space.Enter Artkive.The company sends you a box to fill with your child\'s drawings. Then it professionally photographs them and has you proof the images before turning them into a hardcover book.',
  },
  {
    id: 1,
    image: Press2,
    title: 'The Most Adorable Solution For Saving All Of Your Kid’s Art',
    body: 'Anyone with school-aged kiddos knows that the projects stack up, and as much as you would love to proudly display each painting or drawing, it just becomes a bit of a cluttered mess. This is where Artkive comes in.',
  },
  {
    id: 2,
    image: Press3,
    title: '13 Products That Finally Helped My Family Get Organized',
    body: 'Artkive is a genius solution to the too-much-artwork problem. You order a box which you then fill up with the artwork and sentimental papers you want to save. It then gets mails back to Artkive, who turns the art into a beautiful book.',
  },
]

export const VIP_MEMBERSHIP_PLAN_ID = 4

export const PlanTypes = {
  ANNUAL: 'ANNUAL',
  MONTHLY: 'MONTHLY',
}
