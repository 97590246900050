import { List as MuiList, ListItem as MuiListItem, ListItemSecondaryAction as MuiListItemSecondaryAction, ListItemText as MuiListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const List = withStyles({
  root: {
    marginTop: -2,
    marginBottom: -2,
    padding: 0,
    fontWeight: 500,
  },
})(MuiList)

export const ListItem = withStyles({
  root: {
    padding: 0,
  },
})(MuiListItem)

export const ListItemText = withStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    marginTop: 2,
    marginBottom: 2,
  },
  primary: {
    fontSize: '0.875rem',
    color: palette.common.black,
    paddingRight: spacing(6),
    lineHeight: 1.4,

    [breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
}))(MuiListItemText)

export const ListItemSecondaryAction = withStyles(({ palette }) => ({
  root: {
    right: 0,
    color: palette.common.black,
  },
}))(MuiListItemSecondaryAction)

export const ListItemDiscountedPrice = withStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '6px 0',
    [breakpoints.up('md')]: {
      padding: '8px 0',
    },
  },
}))(ListItem)

export const ListItemTextDiscountedPrice = withStyles(() => ({
  root: {
    margin: 0,
  },
}))(ListItemText)

export const ListItemSecondaryActionDiscountedPrice = withStyles(() => ({
  root: {
    position: 'relative',
    top: 0,
    transform: 'none',
    whiteSpace: 'nowrap',
  },
}))(ListItemSecondaryAction)
