import React from 'react'

import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ breakpoints }) => ({
  detailsContainer: {
    maxWidth: '520px',
    position: 'relative',
    width: '100%',
    padding: '1rem',
    [breakpoints.up('md')]: {
      padding: '2rem',
    },
  },
}))

const BoxPurchaseDetails = () => {
  const classes = useStyles()

  return (
    <Box className={classes.detailsContainer}>
      <Typography variant={'h2'} align={'center'}>
        Your Boxes Have Shipped!
      </Typography>
      <Box mt={3}>
        <Typography
          variant={'caption'}
          align={'center'}
          component={'div'}
        >
          The window to change box quantity has passed and your order
          has gone to our fulfillment center. You can still send artwork
          for multiple children or books in the same box and save any
          extra boxes for a future order. We look forward to receiving
          your art.
        </Typography>
      </Box>
      <Box mt={6} display={'flex'} justifyContent={'center'}>
        <Button
          color={'primary'}
          size={'large'}
          href={'/'}
          variant={'contained'}
        >
          Go to Home Page
        </Button>
      </Box>
    </Box>
  )
}

export default BoxPurchaseDetails
