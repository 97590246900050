import valid from 'card-validator'

import regex from 'common/utils/regex'

export const CHILD_NAME_MAX_LENGTH = 25
export const CAPTION_MAX_LENGTH = 50
export const LABEL_MAX_LENGTH = 25
export const TAG_MAX_LENGTH = 25
export const MIN_TEXT_VALUE_LENGTH = 2

export const validateEmail = (email = '') => new RegExp(validations.email.pattern.value).test(email)

const validations = {
  email: {
    required: 'Email is required',
    pattern: {
      value: regex.email,
      message: 'Please enter a valid email',
    },
  },
  confirmEmail: (getValues) => ({
    validate: (value) => {
      return value === getValues('email') || 'Emails don\'t match'
    },
  }),
  createPassword: {
    required: 'Password is required',
    pattern: {
      value: regex.password,
      message: 'Password should contain at least 6 symbols, one number and one letter',
    },
  },
  password: {
    required: 'Password is required',
  },
  promo: {
    required: 'A valid code is required',
    minLength: {
      value: 1,
      message: 'Valid codes should have at least 1 character',
    },
  },
  name: {
    required: 'Name is required',
    validate: (value) => value.split(' ').length > 1 || 'Please specify both first and last name',
    pattern: {
      value: regex.name,
      message: 'Your name should contain English characters, - \' , and .',
    },
  },
  cardName: {
    required: 'Name is required',
    pattern: {
      value: regex.cardName,
      message: 'Please specify first and last name',
    },
  },
  zipCode: (getValues) => ({
    required: 'A postal code is required',
    pattern: {
      value: regex.zipCode[getValues('country')],
      message: 'Please specify correct postal code',
    },
  }),
  confirmPassword: (getValues) => ({
    required: 'Please re-enter your password',
    validate: (value) => {
      return value === getValues('password') || 'Passwords don\'t match'
    },
  }),
  title: {
    required: 'Title is required',
    minLength: {
      value: 2,
      message: 'Title should have at least 2 characters',
    },
    maxLength: {
      value: CAPTION_MAX_LENGTH,
      message: 'Title cannot be longer than 50 characters',
    },
    pattern: {
      value: regex.title,
      message: 'Title cannot contain non-English characters',
    },
  },
  cardNumber: {
    required: 'Please enter your card number',
    validate: (value) => {
      if (!value) {
        return 'Please add card number'
      }

      const numberValidation = valid.number(value)

      if (!numberValidation.isPotentiallyValid || !numberValidation.isValid) {
        return 'Invalid card number'
      }

      return true
    },
  },
  cardExp: {
    required: 'Please enter your cards expiration date',
    // pattern: {
    //   value: regex.cardExpiry,
    //   message: 'Invalid expiration date MM/YY',
    // },
    validate: (value) => {
      if (!value) {
        return 'Please enter exp date as MM/YY'
      }

      if (!/\d\d\s*\/\s*\d\d/.test(value)) {
        return 'Invalid exp date, please enter as MM/YY'
      }

      const [month, year] = value.split(`/`).map((v) => parseInt(v.trim()))

      if (month < 1 || month > 12) {
        return 'Invalid exp date, please enter as MM/YY'
      }

      const currentYear = new Date().getUTCFullYear() % 100
      const currentMonth = new Date().getUTCMonth() + 1 // NOTE: Jan is 0
      // NOTE: the date should be strictly in the past for the card to be considered expired
      if (year < currentYear || year === currentYear && month < currentMonth) {
        return 'Card expired'
      }

      return true
    },
  },
  cardCvc: {
    required: 'Please enter your cards CVC number',
    pattern: {
      value: regex.cardCvc,
      message: 'Invalid CVC number',
    },
  },
  address: {
    required: 'Street address is required',
    minLength: {
      value: 5,
      message: 'Street address should have at least 5 characters',
    },
  },
  country: {
    required: 'Country is required',
  },
  state: {
    required: 'State is required',
  },
  city: {
    required: 'City is required',
    minLength: {
      value: MIN_TEXT_VALUE_LENGTH,
      message: 'City should have at least 2 characters',
    },
  },
  lastName: {
    required: 'Last name is required',
    minLength: {
      value: MIN_TEXT_VALUE_LENGTH,
      message: 'Last name should have at least 2 characters',
    },
    pattern: {
      value: regex.name,
      message: 'Last name cannot contain non-English characters',
    },
  },
  firstName: {
    required: 'First name is required',
    minLength: {
      value: MIN_TEXT_VALUE_LENGTH,
      message: 'First name should have at least 2 characters',
    },
    pattern: {
      value: regex.name,
      message: 'First name cannot contain non-English characters',
    },
  },
  orderNumber: {
    required: 'Please enter order number',
    minLength: {
      value: 1,
      message: 'Order number should have at least 2 characters',
    },
  },
}

export default validations
