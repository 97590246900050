export const trackAddShopperConversion = ({ orderId, value, email }) => {
  if (!window.published) return

  try {
    /* eslint-disable */
    window.AddShoppersWidgetOptions = { loadCss: false, pushResponse: false }
    window.AddShoppersConversion = {
      order_id: `${orderId}`,
      value: `${value}`,
      email: `${email}`,
      currency: 'USD',
    }

    !function(){
      var t=document.createElement('script')
      t.type='text/javascript',
      t.async=!0,
      t.id='AddShoppers',
      t.src=`https://shop.pe/widget/widget_async.js#${app.ADD_SHOOPERS_ID}`,
      document.getElementsByTagName('head')[0].appendChild(t)
    }()
    /* eslint-enable */
  } catch (e) {
    console.error(e)
  }
}
