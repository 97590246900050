import { Paper, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

export const ReturnArtTip = withStyles(() => ({
  root: {
    backgroundColor: '#F5F9FA',
    padding: '12px 16px',
    fontWeight: 600,
    fontSize: '1rem',
    marginTop: 16,
  },
}))(Paper)


export const SubtitleTypography = withStyles(() => ({
  root: {
    fontWeight: 600,
    fontSize: '1.25rem',
    color: theme.colors.black,
  },
}))(Typography)
