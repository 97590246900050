export default [
  { value: 'UKC', label: 'North East' },
  { value: 'UKD', label: 'North West' },
  { value: 'UKE', label: 'Yorkshire and the Humber' },
  { value: 'UKF', label: 'East Midlands' },
  { value: 'UKG', label: 'West Midlands' },
  { value: 'UKH', label: 'East of England' },
  { value: 'UKI', label: 'Greater London' },
  { value: 'UKJ', label: 'South East' },
  { value: 'UKK', label: 'South West' },
]
