import { useEffect, useState } from 'react'

import * as track from 'artkive/utils/tracker'

function getCurrentLocation() {
  const location = typeof window === 'undefined' ? {} : window.location
  return {
    pathname: location.pathname,
    search: location.search,
    hash: location.hash,
  }
}

/**
 * @type {Array<() => void>}
 */
const listeners = []

/**
 * Notifies all location listeners. Can be used if the history state has been manipulated
 * in by another module. Effectifely, all components using the 'useLocation' hook will
 * update.
 */
export function notify() {
  listeners.forEach((listener) => listener())
}

function useWindowLocation() {
  const [{ pathname, search, hash }, setLocation] = useState(getCurrentLocation())

  useEffect(() => {
    setLocation(getCurrentLocation())
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', handleChange)
    return () => window.removeEventListener('popstate', handleChange)
  }, [])

  useEffect(() => {
    listeners.push(handleChange)
    return () => listeners.splice(listeners.indexOf(handleChange), 1)
  }, [])

  function handleChange() {
    setLocation(getCurrentLocation())
  }

  function updateHistory(args, cb) {
    const originalPathname = window.location.pathname
    const newPathname = args[args.length - 1]

    if (newPathname !== originalPathname) {
      cb()
      window.dispatchEvent(new Event('popstate'))
      notify()
      track.page()
    }
  }

  function pushState(...args) {
    updateHistory(args, () => window.history.pushState(...args))
  }

  function replaceState(...args) {
    updateHistory(args, () => window.history.replaceState(...args))
  }

  function redirect(path) {
    window.location = path
  }

  return {
    pushState,
    replaceState,
    redirect,
    pathname,
    search,
    hash,
  }
}

export default useWindowLocation
