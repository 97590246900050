import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import { useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'

import Chip from './Chip'
import GiftSummaryDetails from './GiftSummaryDetails'
import MobileDetailsBox from './MobileDetailsBox'

const MobileGiftSummary = ({ options }) => {
  const { sendGiftToRecipient } = useVariableState(options.type, options.uuid)

  return (
    <MobileDetailsBox
      mt={1.5}
      editHref={`/${BOX_CHECKOUT_STEPS.RECIPIENT_INFORMATION}`}
      primaryText={
        <>
          <Typography variant={'h6'} component={'span'}>Gift Information</Typography>
          {options.shipping_address && (
            <Chip label={sendGiftToRecipient ? 'Deliver to Recipient' : 'Deliver to Me'} />
          )}
        </>
      }
    >
      <GiftSummaryDetails options={options} />
    </MobileDetailsBox>
  )
}

MobileGiftSummary.propTypes = {
  options: PropTypes.object.isRequired,
}

export default MobileGiftSummary
