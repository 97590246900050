import React, { forwardRef } from 'react'

import useTracker from './useTracker'

const defaultStaticOptions = () => ({})

const withClickTracker = (Component, eventHandler = 'onClick', staticTrackerOptions = defaultStaticOptions) => {
  const component = forwardRef((props, ref) => {
    const { trackerEvent, trackerOptions, trackAsync, promiseCallback = false, ...rest } = props
    const [trackAction] = useTracker()

    const submitEvent = (e, ...args) => {
      const staticOptions = typeof staticTrackerOptions === 'function' ? staticTrackerOptions(props, e, ...args) : staticTrackerOptions
      const options = typeof trackerOptions === 'function' ? trackerOptions(props, e, ...args) : trackerOptions
      const trackOptions = Object.assign(staticOptions, options)

      return trackAction(trackerEvent, trackOptions)
    }

    // NOTE: in some cases like with clipboards we have to use promises instead of async-await
    const handleTrackEvent = promiseCallback
      ? (e, ...args) => {
        if (trackerEvent) {
          const trackPromise = submitEvent(e, ...args)
          // For components like Checkbox we can avoid sync events tracking
          if (!trackAsync) {
            trackPromise.then(() => rest[eventHandler] && rest[eventHandler](e, ...args))
          }
        }
        if (!trackerEvent || trackAsync) {
          rest[eventHandler] && rest[eventHandler](e, ...args)
        }
      }
      : async (e, ...args) => {
        if (trackerEvent) {
          const trackPromise = submitEvent(e, ...args)
          // NOTE: For components like Checkbox we can avoid sync events tracking
          if (!trackAsync) {
            await trackPromise
          }
        }

        rest[eventHandler] && rest[eventHandler](e, ...args)
      }

    const componentProps = {
      ...rest,
      [eventHandler]: handleTrackEvent,
    }

    return <Component ref={ref} {...componentProps} />
  })

  component.displayName = `${Component.displayName}:withClickTracker`
  return component
}

export default withClickTracker
