import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import routes from 'artkive/utils/routes'

const modalClass = 'redeem-info-modal'

const RedeemInfoModal = ({ open, onClose }) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.keyCode === 27) {
        onClose()
      }
    }

    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [onClose])

  const handleOverlayClick = useCallback(({ target }) => {
    if (target.classList.contains(modalClass)) {
      onClose()
    }
  }, [onClose])

  return (
    <div
      className={classnames('modal', modalClass, { show: open, hidden: !open })}
      onClick={handleOverlayClick}
    >
      <div className={'modal-wrapper'}>
        <div className={'modal-close-times'} onClick={onClose}>
          <i className={'fa fa-times'}></i>
        </div>

        <div className={'row'}>
          <div className={'col-sm-12'}>
            <h3 className={'text-center margin-bottom'}>
              Redemption Instructions
            </h3>
          </div>

          <div className={'col-sm-12'}>
            <div className={'padding'} id={'modal-text'}>
              <p>
                You will now be directed to the Artkive Box checkout page.
                Find or scratch off the code from the back of your gift card.
                Enter that code into the promo code field.
              </p>
            </div>
          </div>

          <div className={'col-sm-12 text-center margin-top'}>
            <a href={routes.boxCheckout}>
              <button className={'purple-btn'}>
                Redeem Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

RedeemInfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default RedeemInfoModal
