import React from 'react'
import PropTypes from 'prop-types'

import BasePriceWithDiscount from './BasePriceWithDiscount'
import calculateAddonViewPrices from './calculateAddonViewPrices'

const AddonPrice = ({
  fullPriceInfo,
  qty,
  isAddonSelected,
  isAddonSummary,
  enableUSBAddon,
  productInAddon,
  showEachSuffix,
}) => {
  const { finalPrice, discountLabel, price } = calculateAddonViewPrices({
    fullPriceInfo,
    qty,
    enableUSBAddon,
    productInAddon,
    showEachSuffix,
  })
  const discountMethod = fullPriceInfo.promo_discount_details?.discount_method

  const priceLabelClassName = enableUSBAddon
    ? 'priceLabelWithDiscountAddonUSB'
    : 'priceLabelWithDiscountAddon'
  const priceClassName = 'priceAddon'
  const discountBoxClassName = enableUSBAddon
    ? 'discountBoxAddonUSB'
    : 'discountBoxAddon'
  const discountLabelClassName = enableUSBAddon
    ? 'discountLabelAddonUSB'
    : 'discountLabelAddon'

  const mainBoxDirection = enableUSBAddon ? 'row' : 'column'
  const alignMainBox = enableUSBAddon ? 'center' : 'flex-end'
  return (
    <BasePriceWithDiscount
      finalPrice={finalPrice}
      price={price}
      discountLabel={discountLabel}
      priceLabelClassName={priceLabelClassName}
      discountMethod={discountMethod}
      priceClassName={priceClassName}
      enableUSBAddon={enableUSBAddon}
      discountBoxClassName={discountBoxClassName}
      discountLabelClassName={discountLabelClassName}
      isAddonSummary={isAddonSummary}
      isAddonSelected={isAddonSelected}
      mainBoxDirection={mainBoxDirection}
      alignItemsMainBox={alignMainBox}
    />
  )
}

AddonPrice.propTypes = {
  fullPriceInfo: PropTypes.object.isRequired,
  qty: PropTypes.number,
  isAddonSelected: PropTypes.bool,
  isAddonSummary: PropTypes.bool,
  enableUSBAddon: PropTypes.bool,
  productInAddon: PropTypes.bool,
  showEachSuffix: PropTypes.bool,
}

export default AddonPrice
