import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { sum } from 'lodash/math'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(4, 60px)',
    gap: '2px',
  },
  currentCaption: {
    fontSize: 14,
    color: '#102371',
    fontWeight: 'bold',
  },
  vipCaption: {
    fontSize: 14,
    color: '#F49220',
    fontWeight: 'bold',
  },
  prev: {
    color: '#707070',
    textDecoration: 'line-through',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  actual: {
    color: '#102371',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  subText: {
    fontSize: 12,
  },
  secondaryText: {
    fontSize: 12,
    color: '#707070',
  },
  box: {
    flexDirection: 'column',
    flex: 1,
    fontWeight: 600,
  },
}))

const VipBox = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: ({ isVip }) => isVip ? '#FBCE5F' : '#FEF6E1',
    padding: '0 8px',
    textAlign: 'center',
  },
}), {
  withTheme: true,
})(Box)

const VipBoxMembershipDetails = ({ product, processing }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  const conciergeProcessing = processing.find((p) => !!p.default)

  const processingString = [
    conciergeProcessing.processing_start_range,
    conciergeProcessing.processing_end_range,
  ].filter(Boolean).join(' - ')

  const processingVipString = [
    conciergeProcessing.processing_start_range / 2,
    conciergeProcessing.processing_end_range / 2,
  ].filter(Boolean).join(' - ')

  const pricing = [
    + product.price * 2,
    + conciergeProcessing.price,
    36,
  ]

  return (
    <Box px={isMobile ? 0 : 6} py={0}>
      <Grid container>
        <Grid item xs={6} className={classes.root}>
          <Box className={cl(classes.content, classes.currentCaption)}>
            Single Artkive Box
          </Box>
          <VipBox>
            <strong>1 Artkive Box</strong>
            <span className={classes.secondaryText}>
              ${product.price} value
            </span>
          </VipBox>
          <VipBox>
            <span><strong>{processingString} weeks</strong> order processing</span>
          </VipBox>
          <VipBox>
            <strong>6 Months</strong> storage
          </VipBox>
        </Grid>
        <Grid item xs={6} className={classes.root}>
          <Box className={cl(classes.content, classes.vipCaption)}>
            VIP Box Membership
          </Box>
          <VipBox isVip>
            <span><strong>2 Artkive Boxes</strong> / year</span>
            <span className={classes.secondaryText}>
              ${product.price * 2} value
            </span>
          </VipBox>
          <VipBox isVip>
            <span><strong>{processingVipString} weeks</strong> order processing</span>
            <span className={classes.secondaryText}>
              ${conciergeProcessing.price} value
            </span>
          </VipBox>
          <VipBox isVip>
            <span><strong>Unlimited</strong> image storage</span>
            <span className={classes.secondaryText}>
              $36 value
            </span>
          </VipBox>
        </Grid>
      </Grid>
      <Box display={'flex'} alignItems={'center'}>
        <Box className={cl(classes.content, classes.box)}>
          <Typography className={classes.actual}>
            $39
          </Typography>
          <span className={classes.secondaryText}>
            total cost
          </span>
        </Box>
        <Box className={cl(classes.content, classes.box)}>
          <Box>
            <Typography component={'span'} className={classes.prev}>
              ${sum(pricing)}
            </Typography>
            &nbsp;
            <Typography component={'span'} className={classes.actual}>
              $39 <span className={classes.subText}>/ year</span>
            </Typography>
          </Box>
          <span className={classes.secondaryText}>
            total cost
          </span>
        </Box>
      </Box>
    </Box>

  )
}

VipBoxMembershipDetails.propTypes = {
  product: PropTypes.object,
  processing: PropTypes.array,
}

export default VipBoxMembershipDetails
