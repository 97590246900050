import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

const useStyles = makeStyles(() => ({
  root: {
    color: theme.colors.primary.dark,
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: 700,
    fontSize: ({ size }) => size === 'big' ? '2rem' : '1.75rem',
    lineHeight: ({ size }) => size === 'big' ? 1.4 : 1.6,
  },
}))

const ProductPriceTypography = ({ children, className, size = 'big' }) => {
  const classes = useStyles({ size })

  return (
    <Typography variant={'body1'} component={'span'} className={cl(classes.root, className)}>
      {children}
    </Typography>
  )
}

ProductPriceTypography.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['big', 'small']),
}

export default ProductPriceTypography
