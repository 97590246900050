import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Container,
  Grid,
  Hidden,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Image from 'artkive/components/Image'
import Social from 'artkive/components/Social'
import withApp from 'artkive/hoc/withApp'
import ContactsDetails from 'artkive/pages/Contact/ContactDetails'
import theme from 'artkive/utils/theme'

import Dots from 'images/ecom/brand/dots-blue.svg'
import ContactUs from 'images/ecom/contact/ContactUs.jpg'

const useStyles = makeStyles((muiTheme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    minHeight: '180px',
    [muiTheme.breakpoints.up('md')]: {
      minHeight: '400px',
    },
  },
  paperWrap: {
    left: 0,
    bottom: '-100%',
    position: 'absolute',
    width: '100%',
    boxShadow: '0 10px 25px 0 rgba(11, 39, 158, 0.1)',
  },
}))

const Contact = ({ phone }) => {
  const muiTheme = useTheme()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))
  const classes = useStyles()

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
      <Box bgcolor={theme.colors.purple.med}>
        <Container className={classes.container}>
          <Typography style={{ marginTop: isMobile ? '0' : '-15%' }} variant={'h1'}>
            Contact Us
          </Typography>
          <Hidden smDown>
            <Paper elevation={0} className={classes.paperWrap}>
              <Box px={6} py={8}>
                <Grid container>
                  <Grid xs={12} md={6} item>
                    <Box textAlign={'center'} position={'relative'} px={8}>
                      <Box position={'relative'} style={{ zIndex: '2' }}><Image src={ContactUs} /></Box>
                      <Box
                        position={'absolute'}
                        left={'-12%'}
                        top={'18%'}
                        style={{ transform: 'rotate(90deg)', zIndex: '1' }}
                      >
                        <Image src={Dots} />
                      </Box>
                    </Box>
                  </Grid>
                  <ContactsDetails phone={phone} />
                </Grid>
              </Box>
            </Paper>
          </Hidden>
        </Container>
      </Box>
      <Container>
        <Box pb={10}>
          <Hidden mdUp>
            <Paper elevation={0}>
              <Box px={4} pb={4}>
                <Grid container>
                  <Grid xs={12} md={6} item>
                    <Box textAlign={'center'} position={'relative'} py={8}>
                      <Box position={'relative'} style={{ zIndex: '2' }}><Image src={ContactUs} /></Box>
                      <Box
                        position={'absolute'}
                        left={'-32%'}
                        top={'25%'}
                        style={{ transform: 'rotate(90deg)', zIndex: '1' }}
                      >
                        <Image src={Dots} />
                      </Box>
                    </Box>
                  </Grid>
                  <ContactsDetails phone={phone} />
                </Grid>
              </Box>
            </Paper>
          </Hidden>
          <Hidden smDown>
            <Box my={10} py={10}>&nbsp;</Box>
          </Hidden>
        </Box>
      </Container>
      <Box mt={isMobile ? 0 : 10}>
        <Social />
      </Box>
    </Box>
  )
}
Contact.propTypes = {
  phone: PropTypes.string.isRequired,
}

export default withApp(Contact)
