import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Dialog, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Close from '@material-ui/icons/Close'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useLocation } from 'wouter'

import request from 'common/utils/request'
import AppSnackbars from 'artkive/components/AppSnackbar'
import { HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import mosaicsImg from 'images/ecom/referral/mosaic.png'
import productBooksImg from 'images/ecom/referral/productBooks.png'
import squigglySvg from 'images/ecom/referral/squiggly.svg'

import { breakPoint } from './common'
import { DashedBorder } from './DashedBorder'
import { LoginForm } from './LoginForm'
import { SharingForm } from './SharingForm'

const useStyles = makeStyles((muiTheme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(25, 1fr)',
    minHeight: '100%',
  },

  leftBox: {
    position: 'relative',
    backgroundColor: alpha(theme.colors.purple.dark, 0.64),
    gridColumn: 'span 13',
    [breakPoint]: {
      display: 'none',
    },
  },

  list: {
    position: 'relative',
    color: 'white',
    counterReset: 'referral-steps',
    listStyle: 'none',
    margin: 0,
    padding: muiTheme.spacing(4, 2.5),
    fontSize: 22,
    lineHeight: '140%',
    fontWeight: 600,

    '& li': {
      counterIncrement: 'referral-steps',
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gap: muiTheme.spacing(3),
      alignItems: 'start',
      marginBottom: muiTheme.spacing(3),
    },

    '& li::before': {
      content: 'counter(referral-steps)',
      display: 'flex',
      backgroundColor: theme.colors.purple.darker,
      margin: 'auto 0',
      borderRadius: '100%',
      width: 54,
      height: 54,
      fontSize: 24,
      fontWeight: 700,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },

  accent: {
    color: theme.colors.purple.darker,
    fontWeight: 800,
  },

  rightBox: {
    backgroundColor: theme.colors.white.dark,
    color: theme.colors.grey.dark,
    fontSize: 16,
    fontWeight: 500,
    gridColumn: 'span 12',
    display: 'grid',
    minHeight: '100%',
    gridAutoRows: 'auto min-content',
    [breakPoint]: {
      gridColumn: 'span 25',
      padding: muiTheme.spacing(2),
    },
  },
}))

function toHref(path) {
  return new URL(path, location.origin).href
}

export const Referral = ({ open }) => {
  const classes = useStyles()
  const isMobile = useMediaQuery(() => breakPoint)
  const [, setLocation] = useLocation()

  const firstName = useStoreState(({ userStore }) => userStore.user?.firstName)
  const boxReferralLink = useStoreState(
    ({ userStore }) => userStore.user?.boxReferralLink,
  )
  const [name, setName] = useState(null)
  const [link, setLink] = useState(null)
  const [error, setError] = useState(null)

  const closeModal = useStoreActions(({ uiStore }) => uiStore.closeModal)

  const handleClose = () => {
    if (document.referrer.startsWith(location.origin)) {
      return history.back()
    }
    closeModal({ setLocation })
  }

  useEffect(() => {
    firstName && setName(firstName)
    boxReferralLink && setLink(toHref(boxReferralLink))
  }, [firstName, boxReferralLink])

  const handleSubmit = async (user) => {
    try {
      let { first_name, box_referral_link } = await request
        .post('/api/v2/referrals/link', { user })
        .then((res) => res.data.data)
      setName(first_name)
      setLink(toHref(box_referral_link))
    } catch (error) {
      let message =
        error?.response?.data?.message ??
        `Something went wrong, please contact support at ${HELP_EMAIL}.`
      setError(message)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        PaperProps={{ style: { maxWidth: 1000 } }}
        fullScreen={isMobile}
      >
        <Close
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            color: theme.colors.grey.dark,
            cursor: 'pointer',
          }}
          role={'button'}
          onClick={handleClose}
        />
        <Box className={classes.container}>
          <Box className={classes.leftBox} p={2}>
            <Box
              style={{
                position: 'relative',
                height: 648,
              }}
            >
              <DashedBorder style={{ color: alpha(theme.colors.purple.dark, 0.4) }} />
              <ol className={classes.list}>
                <li>
                  <span>Share your referral link with a friend.</span>
                </li>
                <li>
                  <span>
                    They’ll get <span className={classes.accent}>$20 off</span>{' '}
                    their Artkive Box purchase.
                  </span>
                </li>
                <li>
                  <span>
                    You get <span className={classes.accent}>$20 off</span> your
                    second payment (or cash out after 10 referrals). Yay, fewer
                    piles and more memories!
                  </span>
                </li>
              </ol>
            </Box>
            <img
              src={mosaicsImg}
              style={{
                position: 'absolute',
                width: 733 / 2,
                height: 684 / 2,
                right: 0.5,
                bottom: 0,
              }}
            />
            <img
              src={productBooksImg}
              style={{
                position: 'absolute',
                width: 705 / 2,
                height: 570 / 2,
                bottom: 0,
                left: 0,
              }}
            />
            <img
              src={squigglySvg}
              style={{
                position: 'absolute',
                bottom: 22,
                left: 9,
              }}
            />
          </Box>
          <Box className={classes.rightBox} sx={{ px: 6, py: 8, pb: 3 }}>
            {!link && <LoginForm onSubmit={handleSubmit} />}
            {link && <SharingForm name={name} link={link} />}
            <Box
              mt={5}
              style={{ fontSize: 14, lineHeight: '140%', textAlign: 'center' }}
            >
              By sharing this promotion, you agree to our
              <br />
              <Link href={routes.privacy}>
                <strong>Privacy Policy</strong>
              </Link>{' | '}
              <Link href={routes.tou}>
                <strong>Terms of Use</strong>
              </Link>
            </Box>
          </Box>
        </Box>
        <AppSnackbars
          variant={'error'}
          message={error}
          onClose={() => setError(null)}
        />
      </Dialog>
    </>
  )
}

Referral.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

Referral.defaultProps = {
  open: false,
  onClose: () => {},
}
