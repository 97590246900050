import React from 'react'
import PropTypes from 'prop-types'

import { FormHelperText, MenuItem, Select as MuiSelect } from '@material-ui/core'

const Select = ({ error, options, ...rest }) => {
  return (
    <>
      <MuiSelect error={!!error} {...rest}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </MuiSelect>
      {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.any, value: PropTypes.any })).isRequired,
  error: PropTypes.object,
}

export default Select
