import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import ApplePayIcon from './ApplePayIcon'

const useStyles = makeStyles(({ spacing }) => ({
  applePayButton: {
    display: 'flex',
    cursor: 'pointer',
    overflow: 'hidden',
    appearance: 'none',
    mozAppearance: 'none',
    webkitAppearance: 'none',
    border: 'none',
    padding: `${spacing(1.5)}px`,
    borderRadius: '8px',
    backgroundColor: 'black',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: '0',
    },
  },
  label: {
    fontFamily: 'Montserrat',
    fontSize: 16,
    fontWeight: '700',
    lineHeight: '22px',
    paddingBottom: 1,
    paddingRight: 6,
    letterSpacing: 0,
  },
  light: {
    backgroundColor: 'white',
    '& $label': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  dark: {
    backgroundColor: ({ disabled }) => disabled ? '#9EA0AB' : 'black',
    '& $label': {
      color: 'white',
    },
    '&:hover': {
      backgroundColor: ({ disabled }) => disabled ? '#9EA0AB' : '#111111',
    },
  },
}))

const ApplePayButton = forwardRef(({
  title,
  theme,
  ['aria-label']: ariaLabel,
  children,
  disabled,
  ...extraProps
}, ref) => {
  const classes = useStyles({ disabled })

  return (
    <button
      className={cl(classes.applePayButton, classes[theme])}
      {...extraProps}
      aria-label={ariaLabel}
      ref={ref}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {children && (
          <span className={classes.label}>
            {children}
          </span>
        )}
        <ApplePayIcon
          style={{ width: 32 * 1.6, height: 13.15 * 1.6 }}
          fill={theme === 'light' ? 'black' : 'white'}
        />
      </div>
    </button>
  )
})
/* eslint-enable no-useless-computed-key */

ApplePayButton.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
  'aria-label': PropTypes.string,
}

ApplePayButton.defaultProps = {
  theme: 'dark',
  'aria-label': 'Pay with Apple Pay',
  children: null,
  disabled: false,
}

export default ApplePayButton
