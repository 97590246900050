import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Collapse, ListItem, ListItemIcon, Paper, Typography } from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { format } from 'date-fns'

import theme from 'artkive/utils/theme'

import ColoredStageIcon from './ColoredStageIcon'

const useTrackingListStyles = makeStyles((muiTheme) => ({
  root: {
    position: 'relative',
  },
  descriptionRoot: {
    backgroundColor: lighten(muiTheme.palette.info.main, 0.8),
    color: theme.colors.primary.dark,
    padding: '1.5rem',
    marginLeft: '9rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
  },
  infoIcon: {
    fontSize: '1rem',
    marginLeft: '0.5rem',
  },
  statusIcon: {
    minWidth: 75,
  },
  transitionDate: {
    minWidth: 75,
    textAlign: 'left',
  },
}))

const useListItemStyles = makeStyles({
  root: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    '&.Mui-disabled': {
      pointerEvents: 'unset',
      opacity: 1,
    },
    '&.Mui-disabled > *:not(svg)': {
      filter: 'grayscale(1)',
      opacity: 0.5,
    },
  },
})

const OrderTrackingList = ({ history, collapsed, toggleCollapsed }) => {
  const classes = useTrackingListStyles()
  const listItemClasses = useListItemStyles()

  return (
    <div className={classes.root}>
      {history.map(({ icon, color, title, description, completed, transitioned_at }, index) => (
        <Fragment key={index}>
          <ListItem
            button
            onClick={() => !!description && toggleCollapsed(index)}
            classes={listItemClasses}
            disabled={!completed}
          >
            <Typography variant={'body1'} className={classes.transitionDate}>
              {transitioned_at ? format(new Date(transitioned_at), 'MM/dd/yy') : 'TBD'}
            </Typography>

            <ListItemIcon className={classes.statusIcon}>
              <ColoredStageIcon icon={icon} color={color} />
            </ListItemIcon>
            <Typography variant={'body1'}>
              {title}
            </Typography>
            {!!description && (
              <InfoOutlinedIcon color={'primary'} className={classes.infoIcon} />
            )}
          </ListItem>
          <Collapse in={collapsed === index} timeout={'auto'} unmountOnExit>
            <Paper className={classes.descriptionRoot} elevation={0}>
              {description}
            </Paper>
          </Collapse>
        </Fragment>
      ))}
    </div>
  )
}

OrderTrackingList.propTypes = {
  history: PropTypes.array,
  collapsed: PropTypes.number,
  toggleCollapsed: PropTypes.func,
}

export default OrderTrackingList
