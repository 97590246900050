import { GENERAL_ERROR_MESSAGE } from 'common/utils/data/messages'
import request from 'common/utils/request'
import { USA_COUNTRY_KEY } from 'artkive/utils/addressValidationService'

// NOTE: solution is borrowed from https://gist.github.com/ioan-ghisoi-cko/0c5ba87696e5c4f39379e9e3e71da5d1

const config = {
  payments: {
    supportedNetworks: ['amex', 'masterCard', 'maestro', 'visa', 'mada'],
  },
  shop: {
    shopName: 'Artkive',
    shopLocalization: {
      currencyCode: 'USD',
      countryCode: USA_COUNTRY_KEY,
    },
  },
}

const buildSessionConfig = (price, requestAddress) => ({
  ...config.shop.shopLocalization,
  ...config.payments,
  merchantCapabilities: ['supports3DS'],
  shippingType: 'shipping',
  requiredBillingContactFields: ['postalAddress'],
  requiredShippingContactFields: requestAddress ? ['postalAddress', 'phone', 'email'] : ['email'],
  total: buildTotalItem(price),
})

const buildTotalItem = ({ total }) => ({ type: 'final', label: config.shop.shopName, amount: total })

const buildLineItems = ({ total, tax, shipping_price }) => {
  const items = [{ type: 'final', label: 'Subtotal', amount: total - tax - shipping_price }]
  if (tax !== 0) {
    items.push({ type: 'final', label: 'Tax', amount: tax })
  }

  return items
}

/**
 * This method calls your backend server with the Apple Pay validation URL.
 * On the backend, a POST request will be done to this URL with the Apple Pay certificates
 * and the outcome will be returned
 *
 * @param {string} appleUrl The Apple Pay validation URL generated by Apple
 * @return {object} The session payload or error
 */
const _validateApplePaySession = async (url) => {
  try {
    const { data } = await request.post(Routing.api_v2_apple_pay(), { url })

    return data
  } catch (error) {
    return { error: error?.response?.data?.error || GENERAL_ERROR_MESSAGE }
  }
}

/**
 * This is the main method of the script, since here we handle all the Apple Pay
 * events. Here you are able to populate your shipping methods, react to  shipping methods
 * changes, and many other interaction that the user has with the Apple Pay pup-up.
 *
 * @param {object} Apple Pay Session (the one generate on the button click)
 */
const _handleApplePayEvents = function(appleSession, onAddressChange, submitHandler, setError) {
  // This is the first event that Apple triggers. Here you need to validate the
  // Apple Pay Session from your Back-End
  appleSession.onvalidatemerchant = async (event) => {
    const merchantSession = await _validateApplePaySession(event.validationURL)
    if (merchantSession.error) {
      setError(merchantSession.error)
    } else {
      appleSession.completeMerchantValidation(merchantSession)
    }
  }

  // This method is triggered before populating the shipping methods.
  appleSession.onshippingcontactselected = async (event) => {
    const price = await onAddressChange(event.shippingContact)
    // 1. perform a back-end request to update initial price for shipping with known country code
    // 2. Update total and line items based on the shipping methods
    appleSession.completeShippingContactSelection(
      ApplePaySession.STATUS_SUCCESS,
      [],
      buildTotalItem(price),
      buildLineItems(price),
    )
  }

  // This method is triggered when a user select one of the shipping options.
  // Here you generally want to keep track of the transaction amount
  appleSession.onshippingmethodselected = async (event) => {
    const price = await onAddressChange(event.shippingContact)
    // 1. perform a back-end request to update initial price for shipping with known country code
    // 2. Update total and line items based on the shipping methods
    appleSession.completeShippingMethodSelection(
      ApplePaySession.STATUS_SUCCESS,
      buildTotalItem(price),
      buildLineItems(price),
    )
  }

  /**
   * It gets triggered after the user has confirmed the transaction with the Touch ID or Face ID.
   * Besides getting all the details about the customer (email, address ...) you also get the Apple Pay payload
   * needed to perform a payment.
   */
  appleSession.onpaymentauthorized = async (event) => {
    const data = await submitHandler(event.payment)
    if (data.error) {
      appleSession.completePayment(ApplePaySession.STATUS_FAILURE)
    } else {
      appleSession.completePayment(ApplePaySession.STATUS_SUCCESS)
    }
  }
}

/**
 * Starts the Apple Pay session using a configuration
 */
export const startApplePaySession = function(requestAddress, price, onAddressChange, onSubmit, setError) {
  const applePaySession = new ApplePaySession(6, buildSessionConfig(price, requestAddress))
  _handleApplePayEvents(applePaySession, onAddressChange, onSubmit, setError)

  try {
    applePaySession.begin()
  } catch (e) {
    console.log(e)
  }
}

export const isApplePayAvailable = function() {
  return app.applePayToggle && window.ApplePaySession && ApplePaySession.canMakePayments()
}
