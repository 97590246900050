import React from 'react'
import PropTypes from 'prop-types'

import { OLD_HELP_EMAIL } from 'artkive/utils/company'

export const EmailLink = () => (
  <a href={`mailto:${OLD_HELP_EMAIL}`}>{OLD_HELP_EMAIL}</a>
)

const Link = ({ href, children }) => (
  <a href={href} target={'_blank'} rel={'noreferrer'}>{children || href}</a>
)

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Link
