import formatPrice from 'artkive/utils/formatPrice'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

const calculateOrderSummaryPrices = ({ fullPriceInfo = {}, showEachSuffix }) => {
  const { promo_discount_details, total = 0, before_discount = 0, promo_discount = 0 } = fullPriceInfo
  const { discount_method: discountMethod, amount } = promo_discount_details || {}

  let discountLabel

  switch (discountMethod) {
    case DISCOUNT_METHODS.FLAT:
      discountLabel = `$${amount} OFF`
      break
    case DISCOUNT_METHODS.FLAT_PER_ITEM:
      discountLabel = `$${Math.abs(promo_discount)} OFF${showEachSuffix ? '/ea' : ''}`
      break
    case DISCOUNT_METHODS.PERCENTAGE:
      discountLabel = `-${amount * 100}%`
      break
    case DISCOUNT_METHODS.FREE_USB:
      discountLabel = 'FREE USB'
      break
    default:
      discountLabel = null
  }

  return {
    finalPrice: formatPrice(total),
    price: formatPrice(before_discount),
    discountLabel,
  }
}

export default calculateOrderSummaryPrices
