import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ImageContainer from 'artkive/components/ImageContainer'
import MarkdownText from 'artkive/components/MarkdownText'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  gridWrapper: {
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridItem: {
    position: 'relative',
    padding: '0 32px',
    borderRadius: 8,
    flexGrow: 1,
    flexBasis: 0,
    [breakpoints.down('sm')]: {
      padding: '0',
      '&:not(:first-child)': {
        marginTop: spacing(6),
      },
    },
  },
  gridItemAfter: {
    position: 'absolute',
    left: '0',
    top: '60px',
    width: 120,
    transform: 'translate(-50%, -50%)',
    [breakpoints.down('md')]: {
      width: 100,
    },
  },
  howItWorksImage: {
    maxHeight: 110,
    width: 'auto',
  },
  howItWorksDescription: {
    lineHeight: 1.6,
    fontWeight: 500,
  },
  badge: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    backgroundColor: theme.colors.white.med,
    borderRadius: '50%',
    width: 48,
    height: 48,
    color: theme.colors.black,
    fontWeight: 700,
    fontSize: 20,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(1) translate(-50%, 50%)',
    transformOrigin: '100% 0%',
    boxShadow: theme.shadows[1],
  },
  image: {
    overflow: 'hidden',
    borderRadius: 8,
  },
}))

const HowItWorks = ({ steps }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Grid container className={classes.gridWrapper}>
      {steps.map((step, index) => (
        <Box key={index} className={classes.gridItem}>
          <Box>
            <Box mb={5} position={'relative'}>
              <ImageContainer src={step.image?.url || step.image} ratio={3 / 2} className={classes.image} />
              <Box className={classes.badge}>{index + 1}</Box>
            </Box>
            <Box mb={2} height={isMobile ? 'auto' : 64} alignItems={'center'} display={'flex'} justifyContent={'center'}>
              <Typography variant={'h3'} align={'center'}>
                {step.title}
              </Typography>
            </Box>
            <Typography className={classes.howItWorksDescription} align={'center'}>
              <MarkdownText source={step.body} />
            </Typography>
          </Box>
        </Box>
      ))}
    </Grid>
  )
}

export default HowItWorks
