import React, { useMemo, useState } from 'react'

import cl from 'classnames'
import {
  Box,
  ButtonGroup,
  Chip,
  Grid, Hidden,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

import popularLabel from 'images/ecom/brand/popularLabel.svg'
import bubbles from 'images/ecom/get-started/bubbles.png'

import PlanCard from './PlanCard'
import { PlanTypes } from './Plans.constants'

const useStyles = makeStyles(() => ({
  fontWeight: 'bold',
  root: {
    fontWeight: 'bold',
  },
  card: {
    boxShadow: '0px 4px 12px rgba(20, 34, 108, 0.1)',
    borderRadius: 8,
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
  },
  checkIcon: {
    marginRight: 12,
  },
  mostPopularLabel: {
    backgroundImage: `url(${popularLabel})`,
    padding: '6px 20px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    transform: 'translateX(-50%)',
    left: '50%',
    position: 'absolute',
    top: 0,
    color: '#202126',
    fontWeight: 600,
    fontSize: '14px',
  },
  planChip: {
    backgroundColor: theme.colors.white.main,
    fontWeight: 600,
    fontSize: '18px',
    height: 50,
    borderRadius: 25,
    padding: '12px 14px',
    border: `1px solid ${theme.colors.sky[300]}`,
    '&:hover': {
      border: `1px solid ${theme.colors.sky[700]}`,
      zIndex: 1,
    },
  },
  active: {
    backgroundColor: theme.colors.sky[300],
    color: theme.colors.sky[700],
  },
  mainSection: {
    backgroundColor: '#E3F2FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  planImage: {
    width: 75,
    height: 75,
    borderRadius: '50%',
  },
  vipCard: {
    position: 'relative',
    zIndex: 1,
    '&::before': {
      zIndex: -1,
      position: 'absolute',
      content: "' '",
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '62px 125px',
      width: 62,
      height: 125,
      left: -30,
      bottom: -45,
    },
    '&::after': {
      zIndex: -1,
      position: 'absolute',
      content: "' '",
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '62px 125px',
      width: 62,
      height: 125,
      right: -30,
      top: -45,
    },
  },
  subtitle: {
    maxWidth: 640,
    margin: '0 auto',
  },
}))

const Plans = ({ subscriptions, subscription_plans }) => {
  const isMobile = useMobileBreakpoint()

  const [planType, setPlanType] = useState(PlanTypes.ANNUAL)

  const classes = useStyles()

  const isMonthlyPlan = planType === PlanTypes.MONTHLY

  const plans = useMemo(() => {
    return subscriptions.map((sub) => ({
      ...sub,
      bullets: sub?.bullet_points?.split('||'),
      annualBullets: sub?.annual_bullet_points?.split('||'),
      pricing: subscription_plans.filter(({ subscription_id }) => subscription_id === sub.id),
    }))
  }, [subscriptions, subscription_plans])

  const vipSubscription = plans[plans.length - 1]

  return (
    <Box bgcolor={theme.colors.white.dark}>
      <Box py={8} className={classes.mainSection}>
        <Hidden mdUp>
          <Box mb={3}>
            <Typography variant={'h1'} component={'h2'}>
              Artkive Box Vip
            </Typography>
          </Box>
        </Hidden>

        {vipSubscription && (
          <PlanCard
            planType={PlanTypes.ANNUAL}
            plan={vipSubscription}
            setPlanType={setPlanType}
            variant={isMobile ? 'card' : 'row'}
            className={classes.vipCard}
          />
        )}
      </Box>

      <ContainedSection>
        <Box mb={isMobile ? 5 : 6} textAlign={'center'}>
          <Box mb={2}>
            <Typography variant={isMobile ? 'h2' : 'h1'} component={'h1'}>
              Memberships
            </Typography>
          </Box>
          <Typography variant={'body1'} className={classes.subtitle}>
            Join the family! We have memberships for both DIY and Artkive Box customers with great perks.
          </Typography>
        </Box>

        <Box mb={6} textAlign={'center'}>
          <ButtonGroup color={'secondary'}>
            <Chip
              label={'Pay Monthly'}
              onClick={() => setPlanType(PlanTypes.MONTHLY)}
              className={cl(classes.planChip, isMonthlyPlan && classes.active)}
            />
            <Chip
              label={'Pay Annually'}
              onClick={() => setPlanType(PlanTypes.ANNUAL)}
              className={cl(classes.planChip, !isMonthlyPlan && classes.active)}
            />
          </ButtonGroup>
        </Box>

        <Box mb={4}>
          <Grid container spacing={4}>
            {plans.slice(0, -1).map((plan) => (
              <Grid item xs={12} md key={plan.id} style={{ position: 'relative', zIndex: '2' }}>
                <PlanCard
                  planType={planType}
                  plan={plan}
                  setPlanType={setPlanType}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </ContainedSection>
    </Box>
  )
}

export default Plans
