import React from 'react'
import PropTypes from 'prop-types'

import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CheckoutProductPreview from 'artkive/components/OrderSummary/CheckoutProductPreview'
import Qty from 'artkive/components/Qty'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { CARD_TYPE } from 'artkive/stores/product.constants'
import formatNumber from 'artkive/utils/formatNumber'
import theme from 'artkive/utils/theme'

const usePreviewStyles = makeStyles(() => ({
  container: {
    width: '100%',
  },
  root: {
    paddingLeft: 12,
  },
}))

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    [breakpoints.down('sm')]: {
      boxShadow: '0px 4px 10px rgba(16, 35, 113, 0.08)',
    },
  },
  header: {
    backgroundColor: '#FDE4A5',
  },
  content: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FEF6E1;',
  },
  textRoot: {
    marginLeft: spacing(2),
  },
  productTitle: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    color: palette.secondary.dark,
    fontWeight: 'bold',
  },
  productSubtitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  productSubtitlePrice: {
    color: theme.colors.black,
  },
  refundText: {
    color: theme.colors.green.main,
  },
  subtotal: {
    color: theme.colors.black,
    fontWeight: 500,
  },
  container: {
    width: '100%',
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.colors.black,
  },
  itemRoot: {
    paddingLeft: 12,
  },
  image: {
    width: 54,
    height: 40,
    objectFit: 'cover',
    borderRadius: 2,
  },
  price: {
    fontSize: '14px',
    fontWeight: 600,
  },
  arrow: {
    padding: 4,
  },
}))

const GiftCardProductPreview = ({ product }) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()
  const previewClasses = usePreviewStyles()
  const { setQty } = useVariableActions(product.type, product.uuid)
  const { qty, price } = useVariableState(product.type, product.uuid)

  const showQtyField = product.cardType !== CARD_TYPE.E
  const formattedPrice = price && formatNumber(price.total)

  if (!isMobile)
    return <CheckoutProductPreview product={product} />

  return (
    <Paper elevation={0} className={classes.root}>
      <ListItem
        component={'div'}
        ContainerComponent={'div'}
        classes={previewClasses}
        dense
      >
        <ListItemAvatar>
          <img src={product.image} className={classes.image} />
        </ListItemAvatar>
        <ListItemText
          primary={`${product.title}`}
          secondary={(
            <>
              Total: <span className={classes.productSubtitlePrice}>
              ${formattedPrice}
              </span>
            </>
          )}
          classes={{
            root: classes.textRoot,
            primary: classes.productTitle,
            secondary: classes.productSubtitle,
          }}
        />
        <ListItemSecondaryAction>
          {showQtyField && (
            <Qty setQty={setQty} qty={qty} className={classes.qty} trackerEvent={GIFT_CARDS.AMOUNT_CHANGE} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

    </Paper>
  )
}

GiftCardProductPreview.propTypes = {
  product: PropTypes.object,
}

export default GiftCardProductPreview
