import qs from 'query-string'

export const USA_COUNTRY_KEY = 'US'

const allTrimString = (str) => str.replace(/\s+/g, ' ').replace(/^\s+|\s+$/, '')

const trimAndLowerCase = (value) => allTrimString(`${value}`).toLowerCase()

class AddressValidationService {
  ADDRESS_TYPE = {
    BILLING: 'billing',
    SHIPPING: 'shipping',
    MILITARY: 'military',
  }
  COUNTRIES = [USA_COUNTRY_KEY]
  VALIDATION_FIELDS = [
    'address',
    'city',
    'state',
    'zipCode',
  ]

  compareAddresses = (source, dest) => {
    if (!source || !dest) {
      return false
    }

    return this.VALIDATION_FIELDS.every((key) => trimAndLowerCase(source[key]) === trimAndLowerCase(dest[key]))
  }

  checkAddress = async () => ({
    error: null,
    response: null,
  })

  // checkAddress = async (address) => {
  //   try {
  //     const query = `auth-id=${window.app.SMARTY_STREETS_KEY}&candidates=1&` +
  //       `match=invalid&${this.mapAddressUrl(address)}`
  //     const url = `https://us-street.api.smartystreets.com/street-address?${query}`

  //     const { data } = await axios.get(url)
  //     if (!data) {
  //       throw new Error('No serveice response')
  //     }

  //     const suggestion = this.mapAddressSuggestion(data[0])

  //     if (!this.compareAddresses(address, suggestion)) {
  //       return {
  //         error: null,
  //         response: {
  //           user: { ...address },
  //           ...suggestion,
  //           address2: address.address2,
  //         },
  //       }
  //     } else {
  //       return {
  //         error: null,
  //         response: null,
  //       }
  //     }
  //   } catch (error) {
  //     return { error: null }
  //   }
  // }

  mapAddressUrl = (address) => {
    const urlAddress = {
      street: address.address,
      city: address.city,
      state: address.state,
      // secondary: address.address2,
      zipcode: address.zipCode,
    }

    return qs.stringify(urlAddress)
  }

  mapAddressSuggestion = (address) => {
    const isMilitary = !address.metadata
      || typeof address.metadata.zip_type === 'string'
      && address.metadata.zip_type.toLowerCase() === this.ADDRESS_TYPE.MILITARY
    const isValid = !!address.components && !isMilitary

    if (!isValid) {
      return
    }

    const addressRes = {
      city_name: address.components.city_name || '',
      extra_secondary_designator: address.components.extra_secondary_designator || '',
      extra_secondary_number: address.components.extra_secondary_number || '',
      plus4_code: address.components.plus4_code || '',
      primary_number: address.components.primary_number || '',
      secondary_designator: address.components.secondary_designator || '',
      secondary_number: address.components.secondary_number || '',
      state_abbreviation: address.components.state_abbreviation || '',
      street_name: address.components.street_name || '',
      street_postdirection: address.components.street_postdirection || '',
      street_predirection: address.components.street_predirection || '',
      street_suffix: address.components.street_suffix || '',
      zipcode: address.components.zipcode || '',
    }

    const mappedAddress = {
      address: this.removeWhitespaces([
        addressRes.primary_number,
        addressRes.street_predirection,
        addressRes.street_name,
        addressRes.street_suffix,
        addressRes.street_postdirection,
      ].join(' ')),
      city: addressRes.city_name,
      country: this.COUNTRIES[0],
      state: addressRes.state_abbreviation,
      zipCode:
        [addressRes.zipcode, addressRes.plus4_code]
          .filter((w) => !!w).join('-'),
    }

    const street2 = this.removeWhitespaces([
      addressRes.secondary_designator,
      addressRes.secondary_number,
      addressRes.extra_secondary_designator,
      addressRes.extra_secondary_number,
    ].join(' '))

    if (street2) {
      mappedAddress.address2 = street2
    }

    return mappedAddress
  }

  removeWhitespaces = (string) => string.replace(/\s\s+/g, ' ').trim();
}

export default new AddressValidationService()
