import { action, computed } from 'easy-peasy'

export const DEFAULT_STATE = {
  user: {
    country: '',
    state: '',
    city: '',
    address: '',
    address2: '',
    zipCode: '',
    email: '',
    firstName: '',
    lastName: '',
    isValid: false,
    phone: '',
    boxReferralLink: '',
    billingZipCode: '',
  },
}

const userStore = {
  // state
  ...DEFAULT_STATE,

  // computed
  fullName: computed(({ user: { firstName, lastName } }) => `${firstName} ${lastName}`),

  // actions
  setUser: action((state, payload = {}) => {
    const allowed = Object.keys(DEFAULT_STATE.user)
    const update = {}

    Object.entries(payload).forEach(([k, v]) => {
      if (allowed.includes(k)) update[k] = v
    })

    if (payload.name) {
      const [first, ...last] = payload.name.split(' ')

      update.firstName = first
      update.lastName = last.join(' ')
    }

    update.boxReferralLink = payload.box_referral_link

    state.user = { ...state.user, ...update }
  }),
}

export default userStore
