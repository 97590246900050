import { format as dateFnsFormat } from 'date-fns'
import numeral from 'numeral'

const dateFormats = {
  mdy: 'MM-dd-yyyy',
  date_short_time_dashed: 'MM-dd-yyyy H-m',
  ymd: 'yyyy-MM-dd',
}

export const formatCurrency = (value) => {
  // TODO: format without an external dependency
  return numeral(value).format('$0,0.00')
}

const formatNumber = (value, _opts) => {
  if (value % 1 === 0) {
    return value
  }

  if (value < 10) {
    return Number(value.toFixed(2))
  } else {
    return Math.round(value)
  }
}

const formatByte = (value, _opts) => {
  const normalizedValue = value / 1000
  const scale = Math.log10(normalizedValue)
  if (scale < 3) {
    return `${formatNumber(normalizedValue, {})}KB`
  } else if (scale < 6) {
    return `${formatNumber(normalizedValue / 1000, {})}MB`
  } else {
    return `${formatNumber(normalizedValue / 1000000, {})}GB`
  }
}

export const formatDate = (value, { format = null, type = 'ymd' }) => (
  dateFnsFormat(value, format || dateFormats[type] || dateFormats.ymd)
)

export default (value, type, opts = {}) => {
  switch (type) {
    case 'byte':
      return formatByte(value, opts)
    case 'date':
      return formatDate(value, opts)
    default:
      return value
  }
}
