export default function toNestedObject(flatObj) {
  const nestedObj = {}

  for (const key in flatObj) {
    const keys = key.split('.')
    let currentLevel = nestedObj

    keys.forEach((part, index) => {
      if (index === keys.length - 1) {
        currentLevel[part] = flatObj[key]
      } else {
        if (!currentLevel[part]) {
          currentLevel[part] = {}
        }
        currentLevel = currentLevel[part]
      }
    })
  }

  return nestedObj
}
