import { FormControlLabel } from '@material-ui/core'

import withClickTracker from './withClickTracker'

const TrackFormControlLabel = withClickTracker(FormControlLabel, 'onChange', (_props, e) => {
  return {
    action: e.target.checked ? 'check' : 'uncheck',
  }
})

export default TrackFormControlLabel
