import React from 'react'

import { persist } from 'easy-peasy'
import { Route, Router, Switch } from 'wouter'
import staticLocationHook from 'wouter/static-location'
import browserLocationHook from 'wouter/use-location'

import NoSsr from 'common/components/NoSsr'
import checkoutTrackingMetaCreator from 'artkive/components/Checkout/checkoutTrackingMetaCreator'
import EmailListForm from 'artkive/components/EmailListForm'
import Footer from 'artkive/components/Footer'
import Navbar from 'artkive/components/Navbar'
import withApp from 'artkive/hoc/withApp'
import { findVariableStoreName } from 'artkive/hooks/useVariableStore'
import BoxStore, { persistOptions } from 'artkive/stores/ecom/stores/box.store'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'

import GetStarted from '../GetStarted/GetStarted'
import OrderConfirmation from '../OrderConfirmation/OrderConfirmation'
import ProductNotFound from '../ProductNotFound'

import Checkout from './Checkout'

const isSSR = typeof window === 'undefined'

const rootBase = ''

/**
 * Box checkout flow
 */
const CheckoutNestedRouting = (props) => {
  const useLocation = isSSR ? staticLocationHook(props.railsContext.pathname.replace(rootBase, '')) : browserLocationHook
  const { productLinks } = props

  return (
    <Switch>
      <Route path={'/box-checkout/not-found'}>
        <Navbar />
        <ProductNotFound {...props}  />
        <EmailListForm zIndex={100} />
        <Footer />
      </Route>
      <Route path={productLinks.landing}>
        <GetStarted {...props} />
      </Route>
      <Route path={`${productLinks.checkout}/:rest?`} >
        <NoSsr>
          <Router base={productLinks.checkout} hook={useLocation}>
            <Checkout {...props} />
          </Router>
        </NoSsr>
      </Route>
      <Route path={productLinks.confirmation}>
        <NoSsr>
          <Navbar />
          <OrderConfirmation {...props} />
          <Footer />
        </NoSsr>
      </Route>
    </Switch>
  )
}

const injectDynamicBoxStore = (store, { params }) => {
  const modelName = findVariableStoreName(BOX_PRODUCT_TYPE, params?.uuid)
  const state = store.getState()

  if (!(modelName in state)) {
    console.log('add new model', modelName)
    store.addModel(modelName, persist(BoxStore, persistOptions))
  }
}

export default withApp(CheckoutNestedRouting, {
  trackingMetaCreator: checkoutTrackingMetaCreator,
  injectStoreModel: injectDynamicBoxStore,
})
