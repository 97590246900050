import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

import usePriceWithDiscountsStyles from './styles'

const BasePriceWithDiscount = ({
  finalPrice,
  price,
  discountLabel,
  priceLabelClassName,
  priceClassName,
  discountBoxClassName,
  discountLabelClassName,
  isAddonSummary = false,
  isAddonSelected = false,
  isProductDescription = false,
  discountMethod,
  isMobileOrderDetails,
  isMobileOrderDetailsAddon,
  isProductPreview,
  enableUSBAddon,
  mainBoxDirection = 'column',
  mainBoxGap = '0',
  alignItemsMainBox = 'initial',
}) => {
  const classes = usePriceWithDiscountsStyles()
  const isFreeUsb = discountMethod === DISCOUNT_METHODS.FREE_USB

  return (
    <Box
      display={'flex'}
      gridGap={mainBoxGap}
      flexDirection={mainBoxDirection}
      alignItems={alignItemsMainBox}
    >
      {isProductDescription ? (
        <Typography
          variant={'h2'}
          component={'div'}
          color={'primary'}
          style={{ lineHeight: 1.4 }}
        >
          {finalPrice}
        </Typography>
      ) : (
        <Typography
          variant={'body2'}
          className={cl(classes[priceLabelClassName], {
            [classes.priceLabelWithDiscountAddonSummary]: isAddonSummary,
            [classes.priceLabelWithDiscountAddonSelected]: isAddonSelected,
          })}
        >
          {finalPrice}
        </Typography>
      )}
      {!!discountLabel && (
        <Box
          display={'flex'}
          alignItems={'center'}
          ml={isMobileOrderDetails ? '4px' : '0'}
          mt={
            isMobileOrderDetailsAddon ||
            isProductPreview ||
            isMobileOrderDetails ||
            enableUSBAddon
              ? '0'
              : '2px'
          }
        >
          <Typography
            variant={'body2'}
            className={cl(classes[priceClassName], {
              [classes.priceLabelWithDiscountOrderSummaryUSBDiscount]:
                isFreeUsb,
            })}
          >
            {price}
          </Typography>
          <Box className={classes[discountBoxClassName]}>
            <Typography
              variant={'body2'}
              className={classes[discountLabelClassName]}
            >
              {discountLabel}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}

BasePriceWithDiscount.propTypes = {
  finalPrice: PropTypes.string.isRequired,
  price: PropTypes.string,
  discountLabel: PropTypes.string,
  priceLabelClassName: PropTypes.oneOf([
    'priceLabelWithDiscountAddon',
    'priceLabelWithDiscount',
    'priceLabelWithDiscountOrderSummary',
    'priceLabelWithDiscountAddonSummary',
    'priceLabelWithDiscountAddonSelected',
    'priceLabelMobileOrderDetailsAddon',
  ]).isRequired,
  priceClassName: PropTypes.oneOf([
    'priceAddon',
    'price',
    'priceOrderSummary',
    'priceProductDescription',
    'priceMobileOrderDetails',
    'priceMobileOrderDetailsAddon',
  ]).isRequired,
  discountBoxClassName: PropTypes.oneOf([
    'discountBoxAddon',
    'discountBox',
    'discountBoxOrderSummary',
    'discountBoxProductDescription',
    'discountBoxMobileOrderDetails',
    'discountBoxMobileOrderDetailsAddon',
  ]).isRequired,
  discountLabelClassName: PropTypes.oneOf([
    [
      'discountLabelAddon',
      'discountLabel',
      'discountLabelOrderSummary',
      'discountLabelProductDescription',
      'discountLabelMobileOrderDetails',
      'discountLabelMobileOrderDetailsAddon',
    ],
  ]).isRequired,
  isAddonSummary: PropTypes.bool,
  isAddonSelected: PropTypes.bool,
  mainBoxGap: PropTypes.string,
  discountMethod: PropTypes.oneOf(Object.values(DISCOUNT_METHODS)).isRequired,
  isProductDescription: PropTypes.bool,
  isMobileOrderDetails: PropTypes.bool,
  isMobileOrderDetailsAddon: PropTypes.bool,
  isProductPreview: PropTypes.bool,
  enableUSBAddon: PropTypes.bool,
  alignItemsMainBox: PropTypes.string,
  mainBoxDirection: PropTypes.string.isRequired,
}

export default BasePriceWithDiscount
