import catalinaImage from 'images/ecom/tracking/Catalina.jpg'
import codyImage from 'images/ecom/tracking/Cody.jpg'
import jossueImage from 'images/ecom/tracking/Jossue.jpg'

export const specialists = [
  {
    name: 'Catalina',
    url: catalinaImage,
  },
  {
    name: 'Cody',
    url: codyImage,
  },
  {
    name: 'Jossue',
    url: jossueImage,
  },
]

export const DEFAULT_STATE = {
  addOns: [
    { type: 'RETURN_ART' },
    { type: 'RETURN_USB' },
  ],
  information: {},
  payment: {},
  price: {},
  promoCode: typeof sessionStorage !== 'undefined' ? sessionStorage.getItem('promoCode') : '',
  processing: [],
  qty: 1,
  steps: [
    { title: 'Order Placed', alias: 'order' },
    { title: 'Waiting for Artwork', alias: '' },
    { title: 'Artwork Received', alias: '' },
    { title: 'Artwork Photograph - Payment Pending', alias: '' },
    { title: 'Payment Complete - In Editing', alias: '' },
  ],
  shippingProtection: false,
  shippingProtectionPrice: 0,
  tax: 0,
}

export const ORDER_STATUS = {
  ORDER_PLACED: 'Order Placed',
  LABELS_CREATED: 'Labels Created',
  MAIL_LABEL_SENT: 'Mailing label sent',
  WAITING_FOR_ART: 'Waiting for Artwork',
  ART_RECEIVED: 'Artwork Received',
  AWAITING_INFO: 'Awaiting Info',
  PHOTOGRAPHY_STARTED: 'Photography Started',
  ART_PHOTOGRAPHED: 'Art Photographed',
  PAYMENT_PENDING: 'Payment Pending',
  PAYMENT_COMPLETE: 'Payment Complete',
  EDITING: 'Editing',
  EDITING_COMPLETE: 'Editing Complete',
  ARTKIVE_TO_ACCOUNT: 'Artkive to Account',
  DIGITAL_SENT: 'Digital Sent',
  EBOOK_CREATED: 'eBook Created',
  EBOOK_DELIVERED: 'eBook Delivered',
  BOOK_READY_FOR_PRINT: 'Book - Ready to Print', // NOTE: aliased by Book Printed
  BOOK_PRINTED: 'Book Printed', // NOTE: aliased by Book Printed
  PENDING_RECYCLE: 'Pending Recycle',
  READY_TO_RETURN: 'Ready to Return',
  READY_TO_RECYCLE: 'Ready To Recycle',
  ART_RETURNED: 'Art Returned',
  ART_RECYCLED: 'Art Recycled',
  ORDER_COMPLETED: 'Order Completed',
  DORMANT: 'Dormant',
}
