import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FormProvider } from 'react-hook-form'

import { scrollToError } from 'common/hooks/useScrollToError'
import { GridSpacer } from 'artkive/components/Checkout/SectionGrid'
import SectionHeader from 'artkive/components/Checkout/SectionHeader'
import ContactInformationFragment from 'artkive/components/Checkout/Sections/ContactInformationFragment'
import PersonalNameFragment from 'artkive/components/Checkout/Sections/PersonalNameFragment'
import ShippingAddressFragment, {
  fields as shippingFields,
} from 'artkive/components/Checkout/Sections/ShippingAddressFragment'
import SubmitButton from 'artkive/components/Checkout/SubmitButton'
import AddressValidation from 'artkive/components/Modals/AddressValidation'
import PaymentInformationFragment from 'artkive/components/PaymentInformationFragment'
import useFormToState from 'artkive/hooks/useFormToState'
import useFormWithRecaptcha from 'artkive/hooks/useFormWithRecaptcha'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import addressValidationService from 'artkive/utils/addressValidationService'
import formatNumber from 'artkive/utils/formatNumber'

import OrderDetailsFragment from '../OrderDetailsFragment'
import RecipientInformationFragment, { recipientContactFields } from '../RecipientInformationFragment'

export function showContactInformation(gift, sendGiftToRecipient) {
  return !gift || sendGiftToRecipient
}

// Box checkout shipping and payment fields
const ShippingAndPayment = ({
  onSubmit,
  product,
  options,
  isLoading,
  setError,
  captcha,
  setFormValidation,
}) => {
  const [suggestion, setSuggestion] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const {
    payment,
    price,
    freeOrder,
    gift,
    sendGiftToRecipient,
    combinedInformation,
  } = useVariableState(product.type, product.uuid)
  const { setStep, setInformation, setOrderInformation } = useVariableActions(product.type, product.uuid)

  const handleSubmit = useCallback(async (values) => {
    if (!values.isValid && options.shipping_address) {
      const { error, response } = await addressValidationService.checkAddress(values)

      if (response) {
        setShowModal(true)
        return setSuggestion(response)
      } else if (error) {
        return setError(error)
      }
    }

    onSubmit(values)
  }, [options, onSubmit])

  const {
    recaptchaLoaded,
    recaptchaComponent,
    handleFormSubmit,
    ...methods
  } = useFormWithRecaptcha({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    shouldUnregister: true,
    defaultValues: combinedInformation,
    enabled: captcha,
    onSubmit: handleSubmit,
  })

  useFormToState(combinedInformation, setOrderInformation, methods)

  const { setValue, formState: { isValid }, watch, reset, getValues } = methods
  const isConfirmDisabled = !watch('tos') || !(isValid || freeOrder)

  const handleAddressSelection = useCallback(async (values) => {
    await setInformation(values)
    onSubmit(Object.assign(getValues()))
  }, [onSubmit, getValues])

  useEffect(() => {
    if (!options.forceExpressCheckoutOnFirstPage) {
      setFormValidation(null)
      return
    }

    setFormValidation(() => async () => {
      let expressCheckoutRequiredFields = []
      if (gift) {
        expressCheckoutRequiredFields = recipientContactFields
        if (sendGiftToRecipient) {
          expressCheckoutRequiredFields = expressCheckoutRequiredFields.concat(shippingFields)
        }
        expressCheckoutRequiredFields = expressCheckoutRequiredFields.map((name) => `recipientInformation.${name}`)
      }

      if (expressCheckoutRequiredFields.length >= 0) {
        const isValid = await methods.trigger(expressCheckoutRequiredFields)
        if (!isValid) scrollToError(methods.formState.errors)
        return isValid
      }
    })
    return () => setFormValidation(null)
  }, [options, methods.trigger, setFormValidation, sendGiftToRecipient, gift])

  useEffect(() => {
    const paymentValues = {
      cardExp: '',
      cardCvc: '',
      cardNumber: '',
      name: '',
      zipCode: '',
      ...payment,
      tos: payment.tos || false,
    }
    reset(paymentValues, { keepValues: true })
    setStep({ stepName: BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT, payload: { isDirty: true } })
  }, [reset, setValue])

  return (
    <>
      {options.forceExpressCheckoutOnFirstPage && (
        <OrderDetailsFragment product={product} options={options} />
      )}

      <FormProvider {...methods}>
        <form onSubmit={handleFormSubmit}>
          {recaptchaComponent}

          {options.forceExpressCheckoutOnFirstPage && gift && (
            <RecipientInformationFragment options={options} />
          )}

          {showContactInformation(gift, sendGiftToRecipient) && (
            <>
              <SectionHeader>{gift ? 'Your ' : ''}Contact Information</SectionHeader>
              <PersonalNameFragment />
              <GridSpacer />
              <ContactInformationFragment />
            </>
          )}

          {options.shipping_address && !gift && (
            <>
              <SectionHeader>Shipping Address</SectionHeader>
              <ShippingAddressFragment countries={options.countries} />
            </>
          )}

          <SectionHeader>Payment Information</SectionHeader>
          <PaymentInformationFragment disabled={freeOrder} />

          <SubmitButton disabled={isLoading || isConfirmDisabled || !recaptchaLoaded}>
            Place Order {!!price.total && (
              <>
                • ${formatNumber(price.total)}
              </>
            )}
          </SubmitButton>
        </form>

        {suggestion && (
          <AddressValidation
            isOpen={showModal}
            onSubmit={handleAddressSelection}
            setIsOpen={setShowModal}
            suggestion={suggestion}
          />
        )}
      </FormProvider>
    </>
  )
}

ShippingAndPayment.propTypes = {
  product: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  captcha: PropTypes.bool.isRequired,
  setFormValidation: PropTypes.func.isRequired,
}

export default ShippingAndPayment
