import formatPrice from 'artkive/utils/formatPrice'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

const calculateProductPreviewPrices = ({ fullPriceInfo = {}, qty = 1 }) => {
  const { promo_discount_details, base_price = 0 } = fullPriceInfo
  const { discount_method: discountMethod, amount = 0 } = promo_discount_details || {}
  const baseTotal = base_price * qty
  const formattedBaseTotal = formatPrice(baseTotal)

  let finalPrice
  let discountLabel

  switch (discountMethod) {
    case DISCOUNT_METHODS.FLAT:
      finalPrice = formatPrice(baseTotal - amount < 0 ? 0 : baseTotal - amount)
      discountLabel = `$${amount} OFF`
      break
    case DISCOUNT_METHODS.FLAT_PER_ITEM:
      finalPrice = formatPrice(baseTotal - amount * qty < 0 ? 0 : baseTotal - amount * qty)
      discountLabel = `$${amount} OFF/ea`
      break
    case DISCOUNT_METHODS.PERCENTAGE:
      finalPrice = formatPrice(baseTotal * (1 - amount < 0 ? 0 : 1 - amount))
      discountLabel = `-${amount * 100}%`
      break
    case DISCOUNT_METHODS.FREE_USB:
      finalPrice = formattedBaseTotal
      discountLabel = null
      break
    default:
      finalPrice = formattedBaseTotal
      discountLabel = null
  }

  return {
    finalPrice,
    price: formattedBaseTotal,
    discountLabel,
  }
}

export default calculateProductPreviewPrices
