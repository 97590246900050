import React from 'react'

import {
  Box,
  Button,
  Container,
  Typography,
} from '@material-ui/core'
import { useStoreState } from 'easy-peasy'

import { withNoSsr } from 'common/components/NoSsr'
import getDate from 'artkive/utils/getDate'
import routes from 'artkive/utils/routes'

const ordinal = function(d) {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1: return 'st'
    case 2: return 'nd'
    case 3: return 'rd'
    default: return 'th'
  }
}

const PlansConfirmation = () => {
  const { confirmation } = useStoreState(({ planStore }) => planStore)

  const { lastFour, message, title, price, planType } = confirmation
  const date = new Date()
  const day = date.getDate()

  return (
    <Box py={10}>
      <Container maxWidth={'md'}>
        <Box textAlign={'center'} style={{ minHeight: '60vh' }}>
          <Box mb={2}>
            <Typography gutterBottom variant={'h3'}>
              {message}
            </Typography>
          </Box>

          <Box mb={2}>
            <Typography gutterBottom>
              Your new {title} plan is ${price} per {planType === 'MONTHLY' ? 'month' : 'year'} effective on {getDate()}
            </Typography>
          </Box>

          <Box mb={6}>
            <Typography gutterBottom>
              You will be automatically charged ${price} on {planType === 'MONTHLY' ? `the ${day}${ordinal(day)} of every month` : `${date.getMonth() + 1}/${day} annually`} on your card ending in {lastFour}.
            </Typography>
          </Box>

          <Box mt={3}>
            <Button color={'primary'} href={routes.login} variant={'contained'}>
              Go To My Account
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default withNoSsr(PlansConfirmation)
