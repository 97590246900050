import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { capitalize } from '@material-ui/core/utils'

import { BOX_CHECKOUT, GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_PRODUCT_TYPE, CARD_TYPE, MEMBERSHIP_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import getDate from 'artkive/utils/getDate'

import Qty from '../Qty'

import OrderProductPreview from './OrderProductPreview'

const useStyles = makeStyles(() => ({
  qty: {
    padding: '0',
    justifySelf: 'flex-end',
  },
}))

const defaultOptions = {
  adjust_quantity: true,
}

const CheckoutProductPreview = ({ product, options, ratio }) => {
  const classes = useStyles({ ratio })
  const { setQty } = useVariableActions(product.type, product.uuid)
  const { qty, price } = useVariableState(product.type, product.uuid)
  const isMembershipProduct = product.type === MEMBERSHIP_PRODUCT_TYPE
  const showQtyField = !isMembershipProduct && product.cardType !== CARD_TYPE.E && options.adjust_quantity
  const isBoxProduct = product.type === BOX_PRODUCT_TYPE

  const productTitle = capitalize(options.name || product.title || '')

  return (
    <OrderProductPreview
      image={product.image}
      title={isMembershipProduct ? `${productTitle} Membership` : productTitle}
      ratio={ratio}
      fullPriceInfo={price}
      qty={qty}
      price={(
        <>
          ${product.price}
          {isMembershipProduct && (
            <span className={classes.membershipPlan}>
              {product.planType === 'MONTHLY' ? ' /month' : ' /year'}
            </span>
          )}
        </>
      )}
    >
      {isMembershipProduct && (
        <Typography className={classes.effective}>Effective {getDate()}</Typography>
      )}

      {showQtyField && (
        <Qty
          setQty={setQty}
          qty={qty}
          className={classes.qty}
          trackerEvent={isBoxProduct ? BOX_CHECKOUT.AMOUNT_CHANGE : GIFT_CARDS.AMOUNT_CHANGE}
        />
      )}
    </OrderProductPreview>
  )
}

CheckoutProductPreview.propTypes = {
  product: PropTypes.object.isRequired,
  options: PropTypes.object,
  ratio: PropTypes.number,
}

CheckoutProductPreview.defaultProps = {
  options: defaultOptions,
  ratio: 4 / 3,
}

export default CheckoutProductPreview
