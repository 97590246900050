import React, { createContext, useCallback, useMemo, useRef } from 'react'

import EventEmitter from 'eventemitter3'

import * as track from 'artkive/utils/tracker'

export const TrackerContext = createContext({
  // getTrackingMeta: () => {},
})

export const TrackerContextProvider = ({ productTrackingData, children }) => {
  const capturedHistory = useRef([])
  const trackingEvents = useRef(new EventEmitter())
  const trackingData = useRef({})

  const getTrackingMeta = useCallback((rest = {}) => {
    return {
      ...productTrackingData,
      ...trackingData.current,
      ...rest,
    }
  }, [])

  const dispatchTrackingData = useCallback((rest) => {
    track.captureEmail({
      ...getTrackingMeta(),
      ...rest,
    })
  }, [])

  const cacheTrackingData = useCallback((values, rehidrate = false) => {
    trackingData.current = {
      ...trackingData.current,
      ...values,
    }

    const currentDataJson = JSON.stringify(trackingData.current)
    const isDataCaptured = capturedHistory.current.find((jsonData) => jsonData === currentDataJson)

    console.debug('dispatchTrackingData', trackingData.current)
    if (!isDataCaptured && !rehidrate) {
      dispatchTrackingData(trackingData.current)
      capturedHistory.current.push(currentDataJson)
    }
  }, [])

  const trackingContextValue = useMemo(() => {
    const subscribe = (eventName, callback) => {
      trackingEvents.current.on(eventName, callback)
    }
    const unsubscribe = (eventName, callback) => {
      trackingEvents.current.off(eventName, callback)
    }
    const fire = (eventName, data = {}) => {
      trackingEvents.current.emit(eventName, data)
    }

    return {
      subscribe,
      unsubscribe,
      fire,
      cacheTrackingData,
      getTrackingMeta,
    }
  }, [cacheTrackingData])

  return (
    <TrackerContext.Provider value={trackingContextValue}>
      {children}
    </TrackerContext.Provider>
  )
}
