import React from 'react'

import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    boxShadow: '0px 4px 10px rgba(16, 35, 113, 0.08)',
    borderRadius: 8,
  },
  header: {
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),

    [breakpoints.up('md')]: {
      padding: spacing(1, 3),
    },
  },
  title: {
    marginLeft: spacing(1),
  },
  content: {
    borderRadius: '0 0 8px 8px',
    position: 'relative',
    overflow: 'hidden',
    padding: spacing(2),

    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },
}))

const UpgradesCard = (props) => {
  const { icon, title, children } = props
  const classes = useStyles(props)
  const isMobile = useMobileBreakpoint()

  return (
    <Box mb={isMobile ? 2 : 3} className={classes.root}>
      <Box bgcolor={'primary.light'} className={classes.header}>
        {icon}
        <Box fontWeight={'bold'} fontSize={'1rem'} className={classes.title} display={'inline-flex'}>
          {title}
        </Box>
      </Box>
      <Paper elevation={0} className={classes.content}>
        {children}
      </Paper>
    </Box>
  )
}

export default UpgradesCard
