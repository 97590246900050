import React from 'react'
import PropTypes from 'prop-types'

import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SummaryDetailsBox from 'artkive/components/Checkout/SummaryDetailsBox'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import { CARD_TYPE } from 'artkive/stores/product.constants'

const useStyle = makeStyles(({ palette }) => ({
  note: {
    background: '#FEF6E1',
    padding: '12px',
    marginTop: 16,
    color: palette.common.black,
  },
  content: {
    color: palette.common.black,
    fontSize: 14,
  },
}))

const RecipientDetailsSummary = ({ product }) => {
  const classes = useStyle()
  const { information } = useVariableState(product.type, product.uuid)

  const addressLine1 = [
    information.address,
    information.address2,
    information.city,
    information.state,
    information.zipCode,
  ].filter(Boolean).join(', ')

  return (
    <SummaryDetailsBox title={'Recipient Information'}>
      <Typography variant={'body1'} className={classes.content}>
        {information.recipientFirstName} {information.recipientLastName}<br />
        {[information.toEmail].join(', ')}<br />
        {product.cardType === CARD_TYPE.E ? (
          information.deliveryDate
        ) : (
          <>
            {addressLine1}
          </>
        )}
      </Typography>

      {information.note && (
        <Paper className={classes.note} elevation={0}>
          {information.note}
        </Paper>
      )}
    </SummaryDetailsBox>
  )
}

RecipientDetailsSummary.propTypes = {
  product: PropTypes.object,
}

export default RecipientDetailsSummary
