import { action } from 'easy-peasy'

const envStore = {
  // state
  env: {},

  // actions
  setEnv: action((state, payload = {}) => {
    state.env = { ...state.env, ...payload }
  }),
}

export default envStore
