import React from 'react'

import cl from 'classnames'
import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'

import bubbles from 'images/ecom/get-started/bubbles.png'
import lines from 'images/ecom/get-started/lines_3.svg'
import HeroCover from 'images/ecom/home/HeroCover.png'

const useStyles = makeStyles(({ breakpoints }) => ({
  heroWrapper: {
    [breakpoints.up('md')]: {
      background: 'linear-gradient(90deg, #DBF1FF 40%, #E3F4FF 40%)',
    },
  },
  heroImageGridWrapper: {
    width: '100%',
  },
  heroImageWrap: {
    background: 'linear-gradient(90deg, #DBF1FF 40%, #E3F4FF 40%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  heroImage: {
    maxWidth: '100%',
    maxHeight: '266px',
    [breakpoints.up('md')]: {
      maxHeight: '500px',
    },
  },
  buttonGutter: {
    position: 'relative',
    '&::after': {
      position: 'absolute',
      content: '" "',
      background: `url(${lines}) no-repeat`,
      backgroundSize: '135px 124px',
      transform: 'translate(0, 0)',
      width: 135,
      height: 124,
      right: 32,
      bottom: 48,
      [breakpoints.down('sm')]: {
        right: -4,
        bottom: 16,
        transform: 'translate(0, 0)',
        width: 96,
        height: 88,
        backgroundSize: '96px 88px',
      },
    },
  },
  bubblesGutter: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    '&::before': {
      zIndex: 0,
      position: 'absolute',
      content: '" "',
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '62px 125px',
      width: 62,
      height: 125,
      left: -10,
      bottom: 0,
      transform: 'scaleX(-1)',
      [breakpoints.up('md')]: {
        left: -30,
        bottom: -45,
        transform: 'none',
      },
    },
    '&::after': {
      zIndex: 0,
      position: 'absolute',
      content: '" "',
      background: `url(${bubbles}) no-repeat`,
      backgroundSize: '62px 125px',
      width: 62,
      height: 125,
      right: -10,
      top: 15,

      [breakpoints.up('md')]: {
        right: -20,
        top: -30,
      },
    },
  },
  textBoxWrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '28px 16px 48px 16px',
    [breakpoints.up('md')]: {
      textAlign: 'left',
      padding: '0 32px',
    },
  },
}))

const HeroSection = () => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  return (
    <ContainedSection className={classes.heroWrapper} py={0} disableGutters>
      <Grid container>
        <Grid
          item
          sm={12}
          md={5}
          lg={6}
          className={cl(classes.heroImageGridWrapper, classes.bubblesGutter)}
        >
          <Box className={classes.heroImageWrap}>
            <picture>
              <img src={HeroCover} className={classes.heroImage} />
            </picture>
          </Box>
        </Grid>
        <Grid item sm={12} md={7} lg={6} className={classes.buttonGutter}>
          <Box className={classes.textBoxWrap}>
            <Box mb={2}>
              <Typography variant={'h1'} style={{ textTransform: 'capitalize' }}>
                The #1 solution for preserving and celebrating kids&apos; art!
              </Typography>
            </Box>
            <Typography
              variant={isMobile ? 'body1' : 'subtitle1'}
              color={'textSecondary'}
            >
              We transform your piles of kid art into Art Books and Framed
              Mosaics.
            </Typography>
            <Box mt={isMobile ? 3 : 6}>
              <TrackButton
                color={'primary'}
                variant={'contained'}
                href={routes.boxCheckout}
                trackerEvent={GET_BOX_CLICK}
                trackerOptions={{ section: 'home_start_banner' }}
                size={'large'}
              >
                Get My Box
              </TrackButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

export default HeroSection
