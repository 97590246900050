import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import DescriptionBubbles from 'images/ecom/get-started/bubbles.png'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    background: ({ productBg, descriptionBg }) => productBg
      // eslint-disable-next-line max-len
      ? `linear-gradient(90deg, ${productBg} 50%, #F6F9FA 50%, #F6F9FA calc(50% + 26px), ${descriptionBg} calc(50% + 26px))`
      : 'unset',
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '&$singleVariant': {
      height: '100%',
      [breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
  },
  imageWrapper: {
    backgroundColor: ({ productBg }) => productBg ?? 'unset',
    boxSizing: 'border-box',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  productImage: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '&.contain': {
      paddingBottom: '66%',

      [breakpoints.down('sm')]: {
        paddingBottom: '75%',
      },

      '& img': {
        objectFit: 'contain',
      },
    },
    '&.cover': {
      paddingBottom: '74.87%',

      '& img': {
        objectFit: 'cover',
      },

      [breakpoints.up('md')]: {
        paddingBottom: '66.66%',
      },
    },
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
  imagePadding: {
    width: '100%',
  },
  contentWrapper: {
    flexGrow: 1,
    marginLeft: spacing(0),
    position: 'relative',
    '&$singleVariant': {
      marginLeft: 80,
      position: 'relative',
    },
    [breakpoints.down('md')]: {
      '&$singleVariant': {
        backgroundColor: 'unset',
        marginLeft: 0,
      },
    },
    [breakpoints.up('md')]: {
      position: 'relative',
    },
    [breakpoints.down('sm')]: {
      backgroundColor: 'unset',
    },
  },
  singleVariant: {},
  wrapper: {
    height: '100%',
    display: 'flex',
    '&$singleVariant': {
      height: 'auto',
      position: 'absolute',
      left: spacing(-8.5),
      top: '50%',
      maxWidth: '100%',
      transform: 'translate(0, -50%)',
      [breakpoints.down('md')]: {
        position: 'relative',
        left: 0,
        top: 0,
        transform: 'translate(0, 0)',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    [breakpoints.up('xl')]: {
      height: 'auto',
      position: 'absolute',
      left: spacing(-10),
      top: '50%',
      maxWidth: '100%',
      transform: 'translate(0, -50%)',
    },
    [breakpoints.down('md')]: {
      position: 'relative',
      left: 0,
      top: 0,
      transform: 'translate(0, 0)',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [breakpoints.up('md')]: {
      '&.gutter::before': {
        background: `url(${DescriptionBubbles}) no-repeat`,
        backgroundSize: 'contain',
        position: 'absolute',
        content: '""',
        width: 120,
        height: 240,
        display: 'block',
        right: -60,
        bottom: 40,
        zIndex: -1,
      },
    },
  },
  content: {
    flex: 1,
    zIndex: 1,
    padding: `${spacing(4)}px ${spacing(10)}px`,
    maxWidth: 660,
    [breakpoints.down('lg')]: {
      padding: `${spacing(4)}px ${spacing(5)}px`,
    },
    [breakpoints.down('sm')]: {
      height: 'auto',
      padding: `${spacing(4)}px ${spacing(2)}px`,
    },
    '$singleVariant &': {
      padding: `${spacing(4)}px ${spacing(10)}px`,
      [breakpoints.down('sm')]: {
        padding: `${spacing(4)}px ${spacing(2)}px`,
      },
    },
  },
}))

const ProductDescriptionSection = ({
  children,
  image,
  single,
  productBg,
  descriptionBg,
  overImage,
  className,
  descriptionClassName,
  imageStyle = 'cover',
  gutter = true,
  ...rest
}) => {
  const classes = useStyles({ productBg, descriptionBg })

  return (
    <Box
      className={cl(classes.container, className, {
        [classes.singleVariant]: single,
      })}
      {...rest}
    >
      <Box
        className={classes.imageWrapper}
        minWidth={single ? '45%' : '50%'}
        position={'relative'}
      >
        <Box className={cl(classes.productImage, imageStyle)}>
          {image}
        </Box>
        {overImage}
      </Box>
      <Box className={cl(classes.contentWrapper)}>
        <Box
          className={cl(classes.wrapper, {
            gutter,
          })}
        >
          <Paper
            elevation={0}
            className={cl(classes.content, descriptionClassName)}
          >
            {children}
          </Paper>
        </Box>
      </Box>
    </Box>
  )
}

ProductDescriptionSection.propTypes = {
  children: PropTypes.node,
  image: PropTypes.node,
  single: PropTypes.bool,
  gutter: PropTypes.bool,
  productBg: PropTypes.string,
  className: PropTypes.string,
  descriptionClassName: PropTypes.string,
  descriptionBg: PropTypes.string,
  overImage: PropTypes.node,
  imageStyle: PropTypes.oneOf(['contain', 'cover']),
}

export default ProductDescriptionSection
