import React from 'react'

import {
  Box,
  Container,
  Grid,
  Hidden,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import Social from 'artkive/components/Social'
import Swipe from 'artkive/components/Swipe'
import { withClickTracker } from 'artkive/components/Tracker'
import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import { NAVIGATION } from 'artkive/constants/tracker/mainNavigation'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import AboutUs from 'images/ecom/about/AboutUs-Hero.jpg'
import HeroMobile from 'images/ecom/about/AboutUs-Hero-Mobile.jpg'
import OurStory from 'images/ecom/about/AboutUs-OurStory-1.png'
import OurStory2 from 'images/ecom/about/AboutUs-OurStory-2.png'
import Located from 'images/ecom/about/Located.png'

import Carousel from './Carousel'

const TrackSwipe = withClickTracker(Swipe)

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    minHeight: '100vh',
  },
}))

const About = () => {
  usePageScrollTracker()
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {/* I CAN BE YOUR HERO BABAY */}
      <Box
        style={{
          backgroundImage: `url(${isMobile ? HeroMobile : AboutUs})`,
          backgroundSize: 'cover',
          backgroundPosition: isMobile ? 'bottom' : 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
        }}
      >
        <Container
          style={{
            alignItems: isMobile ? 'flex-start' : 'center',
            display: 'flex',
            minHeight: isMobile ? '100vh' : '450px',
            position: 'relative',
          }}
        >
          <Grid container spacing={8}>
            <Hidden smDown>
              <Grid item xs={12} md={5}></Grid>
            </Hidden>

            <Grid height={'100%'} item xs={12} md={7}>
              <Box pt={isMobile ? 2 : 0} mb={4} textAlign={'center'}>
                <Typography style={{ color: 'white' }} variant={'h3'}>
                  Family is at the core <br />
                  of everything we do.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Hidden smDown>
            <Paper
              elevation={0}
              style={{
                left: '0',
                bottom: '-12%',
                position: 'absolute',
                width: '100%',
              }}
            >
              <Box p={4} style={{ boxShadow: '5px 10px 30px 0px rgba(18, 38, 109, 0.10)' }}>
                <Grid container>
                  <Grid xs={12} md={3} item>
                    <Box textAlign={'center'}>
                      <Typography variant={'h5'}>2012</Typography>
                      <Typography variant={'body1'}>
                        When It All Began
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={3} item>
                    <Box textAlign={'center'}>
                      <Typography variant={'h5'}>20 Million</Typography>
                      <Typography variant={'body1'}>
                        Pieces of Art Transformed
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={3} item>
                    <Box textAlign={'center'}>
                      <Typography variant={'h5'}>430,000</Typography>
                      <Typography variant={'body1'}>
                        Happy Families Served
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={3} item>
                    <Box textAlign={'center'}>
                      <Typography variant={'h5'}>100,000</Typography>
                      <Typography variant={'body1'}>
                        Keepsakes Created
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Hidden>
        </Container>
      </Box>
      {/* I CAN BE YOUR HERO BABAY */}

      {/* This is a story all about how */}
      <Box pb={10}>
        <Hidden mdUp>
          <Paper elevation={0}>
            <Box p={4}>
              <Grid container spacing={2}>
                <Grid xs={12} md={3} item>
                  <Box textAlign={'center'}>
                    <Typography variant={'h5'}>2012</Typography>
                    <Typography variant={'body1'}>When It All Began</Typography>
                  </Box>
                </Grid>
                <Grid xs={12} md={3} item>
                  <Box textAlign={'center'}>
                    <Typography variant={'h5'}>20 Million</Typography>
                    <Typography variant={'body1'}>
                      Pieces of Art Transformed
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} md={3} item>
                  <Box textAlign={'center'}>
                    <Typography variant={'h5'}>430,000</Typography>
                    <Typography variant={'body1'}>
                      Happy Families Served
                    </Typography>
                  </Box>
                </Grid>
                <Grid xs={12} md={3} item>
                  <Box textAlign={'center'}>
                    <Typography variant={'h5'}>100,000</Typography>
                    <Typography variant={'body1'}>Keepsakes Created</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Hidden>

        <Hidden smDown>
          <Box my={6}>&nbsp;</Box>
        </Hidden>

        <Box my={10}>
          <Typography align={'center'} gutterBottom variant={'h2'}>
            Our Story
          </Typography>
        </Box>

        {/* family */}
        <Box my={4}>
          <Grid container spacing={10}>
            <Hidden mdUp>
              <Grid item xs={12} md={6}>
                <Image src={OurStory} />
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box px={isMobile ? 2 : 10}>
                <Box mb={2}>
                  <Typography gutterBottom variant={'subtitle1'}>
                    ARTKIVE BEGINNINGS
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography gutterBottom variant={'h5'}>
                    Family First
                  </Typography>
                </Box>
                <Typography gutterBottom>
                  Jedd Gold and Jeff Lipp are more than co-founders.
                  They&apos;re also cousins. And they&apos;re fellow dads who
                  share a passion for innovation, quality, and community. <br />
                  <br />
                  Jedd and Jeff grew up on the East Coast and found themselves
                  both living in Los Angeles pursuing entrepreneurial endeavors.
                  With complementary skill sets and a similar problem — what to
                  do with all that art that was piling up inside their homes —
                  they came together to find a solution.
                </Typography>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={6}>
                <Image src={OurStory} />
              </Grid>
            </Hidden>
          </Grid>
        </Box>

        {/* idea */}
        <Box my={4}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Image src={OurStory2} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box px={isMobile ? 2 : 10}>
                <Box mb={2}>
                  <Typography gutterBottom variant={'subtitle1'}>
                    ARTKIVE NOW
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Typography gutterBottom variant={'h5'}>
                    Dynamic Duo
                  </Typography>
                </Box>
                <Typography gutterBottom>
                  Jedd&apos;s focus is on the customer experience and
                  identifying new ways to grow. He shares a mutual love of
                  musical theater with his two daughters, and serves on the
                  board of Food Forward, which is committed to providing
                  underserved populations with healthy food options.
                  <br />
                  <br /> Jeff makes sure Artkive runs like a well-oiled machine.
                  When he isn&apos;t working closely with the outstanding operations
                  and production teams, he&apos;s with his wife and kids or in a yoga
                  class. He&apos;s on the board of UCLA Health Sound Body Sound Mind,
                  which promotes confidence and a healthy lifestyle among youth.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* This is a story all about how */}

      {/* Location */}
      <Box bgcolor={theme.colors.secondary.light}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Image src={Located} />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            style={{
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box px={isMobile ? 2 : 10} py={4}>
              <Box mb={4}>
                <Typography gutterBottom variant={'h2'}>
                  Located in sunny Los Angeles
                </Typography>
              </Box>
              <Typography gutterBottom>
                The Artkive Studio: our 15,000 square foot creative studio slash
                warehouse slash office in Los Angeles where all of the magic
                happens. It&apos;s usually buzzing at all hours of the day while
                our photographers, editors, and book designers transform your
                art into keepsakes.
              </Typography>

              <Box mt={4}>
                <TrackSwipe
                  href={routes.contact}
                  underline={'none'}
                  trackerEvent={NAVIGATION.CONTACT_US_CLICK}
                  trackerOptions={{ section: 'contact_us_banner' }}
                >
                  Get In Touch
                </TrackSwipe>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Carousel */}
      <Container>
        <Box mt={12}>
          <Typography align={'center'} variant={'h2'}>
            Explore Other Kive Products
          </Typography>
        </Box>
        <Carousel />
      </Container>

      {/* Join The Fun  */}
      <Social />
    </Box>
  )
}

export default withApp(About)
