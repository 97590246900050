/*
uses default constants per invocation

needs setup before spreading on model

example: const resetter = reset({ defaultState: DEFAULT_STATE })
*/

import { action, thunk } from 'easy-peasy'

import { DEFAULT_STATE as USER_STATE } from 'artkive/stores/ecom/stores/user.store'

const reset = ({ defaultState, keys = [] }) => ({
  // action binds to reset thunk for async execution
  __reset: action((state) => {
    const userKeys = [...Object.keys(USER_STATE.user), ...keys]

    const setState = ({ key, nested, value }) => {
      if (userKeys.includes(key)) return

      if (nested) {
        state[nested][key] = value
      } else {
        state[key] = value
      }
    }

    Object.entries(defaultState).forEach(([k, v]) => {
      if (!!v && typeof v === 'object' && typeof v.length === 'undefined') {
        Object.entries(v).forEach(([kk, vv]) => { setState({ key: kk, nested: k, value: vv }) })
      } else {
        setState({ key: k, value: v })
      }
    })
  }),

  // thunk acts as emitter and async promise for downstream synchronous usage
  reset: thunk(async (actions) => {
    console.log('store reset apply')
    await actions.__reset()
  }),
})

export default reset
