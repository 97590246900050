import React, { useMemo } from 'react'

import cl from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    paddingTop: ({ paddingTop }) => paddingTop, /* 16:9 Aspect Ratio */
  },
  media: {
    position: 'absolute',
    top: 0,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
}))

const ImageContainer = ({ src, ratio, className, children, ...rest }) => {
  const padding = useMemo(() => {
    return {
      paddingTop: `${(100 / ratio).toFixed(2)}%`,
    }
  }, [ratio])
  const classes = useStyles(padding)

  return (
    <div className={cl(classes.container, className)}>
      <img className={classes.media} src={src} {...rest} />
      {children}
    </div>
  )
}

export default ImageContainer
