import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add, Remove } from '@material-ui/icons'

import MarkdownText from '../MarkdownText'
import ContainedSection from '../PageSections/ContainedSection'
import useTracker from '../Tracker/useTracker'

const useAccordionStyles = makeStyles(() => ({
  root: {
    border: '1px solid #E8E8E8',
    padding: 8,
    boxShadow: '2px 4px 20px rgba(16, 35, 113, 0.1)',
  },
  rounded: {
    '&:last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    '&:first-child': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
  },

}))

const useStyles = makeStyles(({ breakpoints }) => ({
  title: {
    padding: 0,
    [breakpoints.up('md')]: {
      padding: '16px 0',
    },
  },
  summaryRoot: {
    '&.Mui-expanded': {
      minHeight: '48px',
    }
    ,
    '& $content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  summaryContent: {
    margin: '12px 0',
    '&.Mui-expanded': {
      margin: '12px 0',
    },
  },
  detailsRoot: {
    padding: '0 16px 16px',
  },
}))

const FaqsItem = ({ faq, index, isActive, toggleActive }) => {
  const accordionClasses = useAccordionStyles()
  const classes = useStyles()

  const handleChange = (e, expanded) => {
    toggleActive(expanded ? faq : null, index)
  }

  return (
    <Box my={3}>
      <Accordion
        expanded={isActive}
        onChange={handleChange}
        elevation={0}
        classes={{
          root: accordionClasses.root, rounded: accordionClasses.rounded,
        }}
      >
        <AccordionSummary
          expandIcon={isActive ? <Remove color={'primary'} fontSize={'large'} /> : <Add color={'primary'} fontSize={'large'} />}
          classes={{
            root: accordionClasses.summaryRoot,
            content: classes.summaryContent,
          }}
        >
          <Typography variant={'h3'} className={classes.title}>
            {faq.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.detailsRoot}>
          <Typography variant={'body1'}>
            <MarkdownText source={faq.body} />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

FaqsItem.propTypes = {
  faq: PropTypes.object,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  toggleActive: PropTypes.func,
}

const Faqs = ({ bgcolor, faqs, trackerEvent, ...rest }) => {
  const [activeFaq, setActiveFaq] = useState(null)
  const [trackAction] = useTracker()

  const handleToggle = (faq, index) => {
    !!faq && trackerEvent && trackAction(trackerEvent, {
      question_id: faq.id,
    })

    setActiveFaq(faq ? index : null)
  }

  return (
    <ContainedSection
      title={(
        <Typography align={'center'} gutterBottom variant={'h2'}>FAQs</Typography>
      )}
      bgcolor={bgcolor}
      {...rest}
    >
      {faqs.map((faq, index) => (
        <FaqsItem
          key={index}
          index={index}
          faq={faq}
          isActive={activeFaq === index}
          toggleActive={handleToggle}
        />
      ))}
    </ContainedSection>
  )
}

Faqs.propTypes = {
  bgcolor: PropTypes.string,
  trackerEvent: PropTypes.string,
  faqs: PropTypes.array.isRequired,
}

export default Faqs
