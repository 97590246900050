import React from 'react'

import { Box, Container } from '@material-ui/core'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const ContainerBox = ({ children, ...rest }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <Box mb={isMobile ? 0 : 10} justifyContent={'center'} flex={1} {...rest}>
      <Container>
        {children}
      </Container>
    </Box>
  )
}

export default ContainerBox
