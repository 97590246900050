import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Step, StepButton, StepLabel, Stepper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'wouter'

import theme from 'artkive/utils/theme'

const RouteLink = forwardRef((props, _ref) => <Link {...props} />)

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    paddingLeft: 0,
    paddingRight: 0,
    flex: 1,
    justifyContent: 'center',

    '& .MuiStepConnector-root': {
      maxWidth: spacing(3.5),
    },
  },
}))

const useStepLabelStyles = makeStyles(() => ({
  label: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  completed: {
    '&$label': {
      fontWeight: 600,
    },
  },
  iconContainer: {
    fontSize: '1.5rem',
  },
}))

const useStepIconStyles = makeStyles(({ palette }) => ({
  root: {
    fontSize: '2rem',
  },
  text: {
    fontWeight: 600,
    fill: palette.secondary.main,
  },
}))

const StepperBox = ({ steps, activeStep }) => {
  const classes = useStyles()
  const stepLabelClasses = useStepLabelStyles()
  const stepIconClasses = useStepIconStyles()

  return (
    <Stepper classes={classes} activeStep={activeStep} nonLinear>
      {steps.filter((step) => step.isVisible).map((step, index) => (
        <Step key={step.name} active={index === activeStep}>
          <StepButton
            component={RouteLink}
            to={index === 0 ? '/' : `/${step.alias}`}
            completed={step.isComplete}
          >
            <StepLabel
              classes={stepLabelClasses}
              StepIconProps={{
                classes: stepIconClasses,
              }}
            >
              {step.name}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  )
}

StepperBox.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  activeStep: PropTypes.number,
}

export default StepperBox
