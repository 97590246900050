import React, { useMemo } from 'react'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import addYears from 'date-fns/addYears'
import format from 'date-fns/format'

import UpgradeCardDetails from 'artkive/components/Checkout/UpgradeCardDetails'
import Image from 'artkive/components/Image'

import vipBox from 'images/ecom/checkout/vipBox.svg'
import vipTime from 'images/ecom/checkout/vipTime.svg'
import vipUpload from 'images/ecom/checkout/vipUpload.svg'
import vipIcon from 'images/ecom/VIPIcon.svg'

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    backgroundColor: '#FEF6E1',
    borderRadius: 8,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    [breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  description: {
    padding: '8px 16px 0 16px',
    fontSize: 16,
    color: '#707070',
  },
  boxRoot: {
    display: 'flex',
  },
  box: {
    width: 120,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  boxText: {
    textAlign: 'center',
    fontSize: 12,
  },
}))

const VipMembershipConfirmation = () => {
  const classes = useStyles()

  const subscriptionEndDate = useMemo(() => {
    return format(addYears(new Date(), 1), 'MM/dd/yy')
  }, [])

  return (
    <Box className={classes.root} py={2} mt={5}>
      <Box className={classes.title} px={2}>
        <Image src={vipIcon} style={{ width: 30, marginRight: 16 }} /> VIP Box Membership
      </Box>
      <Typography className={classes.description}>
        Your new VIP Box Membership plan will renew on {subscriptionEndDate}.
      </Typography>
      <UpgradeCardDetails link={<>What does my Membership include?</>}>
        <Box className={classes.boxRoot}>
          <Box className={classes.box}>
            <Image src={vipBox} style={{ width: 58 }} />
            <Typography className={classes.boxText}>
              2 Artkive boxes
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Image src={vipTime} style={{ width: 58 }} />
            <Typography className={classes.boxText}>
              2x quicker order processing
            </Typography>
          </Box>
          <Box className={classes.box}>
            <Image src={vipUpload} style={{ width: 58 }} />
            <Typography className={classes.boxText}>
              unlimited image storage
            </Typography>
          </Box>
        </Box>
      </UpgradeCardDetails>
    </Box>
  )
}

export default VipMembershipConfirmation
