import { useEffect, useRef } from 'react'

import useWindowLocation from 'common/hooks/useWindowLocation'

const useScrollToSlider = () => {
  const sliderRef = useRef()
  const { hash } = useWindowLocation()

  useEffect(() => {
    if (sliderRef.current && hash === '#slider') {
      setTimeout(() => {
        console.log('scroll to #slider', sliderRef.current.offsetTop)
        window.scrollTo(0, sliderRef.current.offsetTop - 100)
      }, 200)
      //
    }
  }, [])

  return sliderRef
}

export default useScrollToSlider
