import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Check } from '@material-ui/icons'
import { Link } from 'wouter'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

export const ProductDescriptionItem = ({ children, icon, ...rest }) => {
  return (
    <Box display={'flex'} my={1} {...rest}>
      {icon || <Check color={'primary'} />}
      <Typography style={{ marginLeft: 12, fontWeight: 500 }}>
        {children}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  header: {
    fontSize: '2.5rem',
    marginBottom: spacing(2),
    [breakpoints.down('lg')]: {
      fontSize: '2rem',
    },
    [breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  continue: {
    paddingLeft: spacing(9),
    paddingRight: spacing(9),
  },
  price: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: spacing(4),
    [breakpoints.down('sm')]: {
      fontSize: '2rem',
      justifyContent: 'center',
      marginTop: spacing(1),
    },
  },
  additional: {
    [breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))

const ProductDescriptionBox = ({
  title,
  subheader,
  price,
  children,
  additional,
  link,
}) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  const content = (
    <Box mb={1} mt={isMobile ? 8 : 1}>
      {children}
    </Box>
  )

  return (
    <>
      <Typography variant={'h2'} className={classes.header}>
        {title}
      </Typography>

      {subheader}
      {!isMobile && content}

      <Box className={classes.price}>
        {price}
      </Box>

      <Box
        mb={3}
        mt={isMobile ? 2 : 0}
        mx={isMobile ? 6 : 0}
        className={classes.additional}
      >
        {additional}
      </Box>

      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Button
          color={'primary'}
          component={Link}
          href={link}
          size={'large'}
          variant={'contained'}
          className={classes.continue}
        >
          Continue
        </Button>
      </Box>

      {isMobile && content}
    </>
  )
}

ProductDescriptionBox.propTypes = {
  title: PropTypes.node,
  subheader: PropTypes.node,
  price: PropTypes.node,
  children: PropTypes.node,
  additional: PropTypes.node,
  link: PropTypes.string,
}

export default ProductDescriptionBox
