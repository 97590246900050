import React from 'react'

import cl from 'classnames'
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CheckboxControl from 'artkive/components/Checkout/Sections/CheckboxControl'
import Qty from 'artkive/components/Qty'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import theme from 'artkive/utils/theme'

import copiesImage from 'images/ecom/packages/copies.svg'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #B2E2FF',
    borderRadius: 8,
    backgroundColor: '#F5F9FA',
    padding: '20px 24px',
    '&.disabled': {
      filter: 'saturate(0)',
      pointerEvents: 'none',
    },
  },
  toggle: {
    marginBottom: 24,
  },
  image: {
    width: 72,
    height: 72,
  },
  imageWrapper: {
    marginRight: 24,
  },
  productTitle: {
    fontWeight: 700,
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.colors.black,

    '.disabled &': {
      color: '#B7B7B7',
    },
  },
}))

const ExtraCopiesAddon = ({ product, price }) => {
  const classes = useStyles()
  const { setQty, setIsBookCopiesAvailable } = useVariableActions(product.type, product.uuid)
  const { qty, isBookCopiesAvailable } = useVariableState(product.type, product.uuid)

  return (
    <div>
      <CheckboxControl
        checked={isBookCopiesAvailable}
        onChange={(e) => setIsBookCopiesAvailable(e.target.checked)}
        label={'I Want Extra Book Copies'}
        className={classes.toggle}
      />

      <ListItem
        component={'div'}
        ContainerComponent={'div'}
        dense
        classes={{
          root: cl(classes.root, {
            disabled: !isBookCopiesAvailable,
          }),
        }}
      >
        <ListItemAvatar className={classes.imageWrapper}>
          <img src={copiesImage} className={classes.image} />
        </ListItemAvatar>
        <ListItemText
          primary={(
            <>
              <span>Extra Book Quantity</span><span>+&nbsp;${price}</span>
            </>
          )}
          secondary={(
            <Qty
              setQty={setQty}
              qty={qty}
              className={classes.qty}
              trackerEvent={GIFT_CARDS.AMOUNT_CHANGE}
              minQty={1}
              maxQty={100}
            />
          )}
          classes={{
            root: classes.textRoot,
            primary: classes.productTitle,
            secondary: classes.productSubtitle,
          }}
        />
      </ListItem>
    </div>
  )
}

export default ExtraCopiesAddon
