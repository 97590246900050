import React, { useState } from 'react'

import { Box, Button, Hidden, NoSsr, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Preview from 'artkive/components/Modals/Preview'
import { ART_BOOK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

import BookCoverPreview from 'images/ecom/books/BookCoverPreview.png'
import bubbles from 'images/ecom/get-started/bubbles.png'
import lines from 'images/ecom/get-started/lines_3.svg'

const useStyles = makeStyles(({ breakpoints }) => ({
  bookCover: {
    position: 'relative',
    zIndex: 1,
    [breakpoints.up('md')]: {
      '&::before': {
        position: 'absolute',
        content: "' '",
        background: `url(${bubbles}) no-repeat`,
        backgroundSize: '68px 131px',
        width: 68,
        height: 131,
        left: -25,
        bottom: -10,
        zIndex: -1,
      },
    },
  },
  getLines: {
    position: 'absolute',
    width: 75,
    right: -25,
    bottom: -10,
  },
  image: {
    width: 240,
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  buttonGutter: {
    position: 'relative',
    '&::after': {
      position: 'absolute',
      content: "' '",
      background: `url(${lines}) no-repeat`,
      backgroundSize: '67px 62px',
      transform: 'translate(100%, 0)',
      width: 67,
      height: 62,
      right: -24,
      top: 0,
      [breakpoints.down('sm')]: {
        right: -4,
        width: 96,
        height: 88,
        backgroundSize: '96px 88px',
      },
    },
  },
}))

const LookInsidePreview = () => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  return (
    <>
      <Box
        bgcolor={theme.colors.bg.sky}
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={'center'}
        alignItems={'center'}
        py={isMobile ? 8 : 6}
      >
        <Hidden mdUp>
          <Typography variant={'h2'} align={'center'}>
            Artkive Book Example
          </Typography>
        </Hidden>
        <Box className={classes.bookCover} mx={isMobile ? 2 : 8} my={isMobile ? 3 : 0} flexGrow={isMobile ? 1 : 0}>
          <picture>
            <img decoding={'async'} loading={'lazy'} src={BookCoverPreview} className={classes.image} />
          </picture>
        </Box>
        <Box maxWidth={240} position={'relative'}>
          <Hidden smDown>
            <Box mb={3}>
              <Typography variant={'h2'}>
                Artkive Book Example
              </Typography>
            </Box>
          </Hidden>
          <Button
            variant={'outlined'}
            color={'primary'}
            size={'large'}
            onClick={() => setIsOpen(true)}
            trackerEvent={ART_BOOK.SAMPLE_BOOK_VIEW}
            className={classes.buttonGutter}
          >
            Look Inside
          </Button>
        </Box>
      </Box>

      {/* book preview modal */}
      <NoSsr>
        <Preview
          maxWidth={isMobile ? false : 'xl'}
          fullScreen={isMobile}
          fullWidth={true}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        />
      </NoSsr>
    </>
  )
}

export default LookInsidePreview
