import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { useStoreActions, useStoreState } from 'easy-peasy'
import { useLocation } from 'wouter'

import { withNoSsr } from 'common/components/NoSsr'
import generatePath from 'common/utils/generatePath'
import CheckoutWrapper from 'artkive/components/Checkout/CheckoutWrapper'
import CheckoutProductPreview from 'artkive/components/OrderSummary/CheckoutProductPreview'
import { MEMBERSHIP_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import { buildCheckoutPrice, buildPlanProduct } from 'artkive/utils/subscription'
import * as track from 'artkive/utils/tracker'

import Payment from './Steps/Payment'

const trackPurchase = async ({ data, result, product }) => {
  const {
    id,
    in_trial_period,
    plan_amount,
    trial_amount,
  } = result.user_subscription_plan

  let discount = 0

  try {
    if (in_trial_period) discount = parseFloat(plan_amount) - parseFloat(trial_amount)
  } catch (err) {
    console.error('Error: track plan purchase discount eval failed to parse integer for discount', err)

    discount = 0
  }

  return track.purchase({
    amount: in_trial_period ? trial_amount : plan_amount,
    discount,
    orderId: id,
    name: data.name,
    productId: product.id,
    product: MEMBERSHIP_PRODUCT_TYPE,
    information: { email: data.email },
  })
}

const PlansCheckout = ({ params, productLinks, subscriptions, subscription_plans }) => {
  // state
  const [error, setError] = useState()
  const [, navigate] = useLocation()

  // redux
  const { setLoader } = useStoreActions(({ uiStore }) => uiStore)

  const {
    placeOrder,
    reset,
    setPayment,
    setPrice,
    setProduct,
    setConfirmation,
  } = useStoreActions(({ planStore }) => planStore)

  const { information: { email, phone } } = useStoreState(({ planStore }) => planStore)
  const confirmationLink = generatePath('/plans/confirmation')
  const searchParams = new URLSearchParams(location.search)

  const defaultEmail = searchParams.has('email') ? searchParams.get('email') : email

  const handlePayment = async (data) => {
    setLoader(true)

    try {
      setPayment(data)

      const res = await placeOrder({
        subscription_plan_id: product.id,
        email: data.email,
        card_name: data.name,
        credit_card: data.cardNumber.replace(/\s+/g, ''),
        card_expiration_date: data.cardExp,
        cvv: data.cardCvc,
        'g-recaptcha-response-data': data['g-recaptcha-response-data'],
      })

      if (res.error) {
        setError(res.error.response.data.message)
      } else {
        const { message, result } = res.response.data

        await trackPurchase({ data, message, product, reset, result })
        const { card_last_four } = result.user_subscription_plan

        await reset()
        await setConfirmation({ lastFour: card_last_four, message, ...product })

        navigate(`~${confirmationLink}`)
      }
    } finally {
      setLoader(false)
    }
  }

  const product = useMemo(() => {
    if (!params.uuid)
      return

    const selectedSubscriptionPlan = subscription_plans.find(({ id }) => id === +params.uuid)

    if (!selectedSubscriptionPlan) {
      return null
    }

    const selectedPlan = subscriptions.find(({ id }) => id === selectedSubscriptionPlan.subscription_id)

    return buildPlanProduct(selectedPlan, selectedSubscriptionPlan.version, selectedSubscriptionPlan)

  }, [subscription_plans, subscriptions, params.uuid])

  // on mount set product
  useEffect(async () => {
    if (!product) {
      navigate(`~${productLinks.landing}`)
      return null
    }

    await setProduct(product)
    await setPrice(buildCheckoutPrice(product))

    track.initiateCheckout({
      amount: product.price,
      product: MEMBERSHIP_PRODUCT_TYPE,
      productId: product.id,
    })
  }, [product])

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (params.has('email')) {
      setPayment({
        email: params.get('email'),
      })
    }

  }, [])

  if (!product) return null

  return (
    <CheckoutWrapper
      product={product}
      error={error}
      setError={setError}
      backTo={`~${productLinks.landing}`}
      details={(
        <CheckoutProductPreview product={product} ratio={1} />
      )}
    >
      <Payment
        defaultValues={{ email: defaultEmail, phone }}
        isLoading={false}
        onSubmit={handlePayment}
        product={product}
        withReviewStep={false}
      />
    </CheckoutWrapper>
  )
}

PlansCheckout.propTypes = {
  params: PropTypes.object,
  productLinks: PropTypes.object,
  subscriptions: PropTypes.array,
  subscription_plans: PropTypes.array,
}

export default withNoSsr(PlansCheckout)
