import React from 'react'
import PropTypes from 'prop-types'

import { Box, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainerBox from 'artkive/components/PageSections/ContainerBox'
import theme from 'artkive/utils/theme'

import ProductNotFoundImage from 'images/ecom/box/product_not_found.svg'
import DescriptionBubbles from 'images/ecom/get-started/bubbles.png'

import ProductCard from './ProductCard'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: '96px',
    paddingTop: 0,
    width: '100%',
  },
  mainSection: {
    backgroundColor: '#E3F2FD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
  },
  bubblesLeft: {
    position: 'absolute',
    width: 167,
    height: 320,
    left: -11,
    top: -16,
  },
  bubblesRight: {
    position: 'absolute',
    width: 167,
    height: 320,
    right: -11,
    bottom: -16,
  },
  bubblesMobile: {
    position: 'absolute',
    width: 63,
    height: 122,
    right: -20,
    top: 30,
  },
  productHeader: {
    textAlign: 'center',
    marginTop: spacing(8),
    marginBottom: spacing(6),
  },
}))

const ProductNotFound = ({ productData }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box pb={10} className={classes.mainSection}>
        <img src={ProductNotFoundImage} className={classes.img} />
        <Hidden smDown>
          <img src={DescriptionBubbles} className={classes.bubblesLeft} />
          <img src={DescriptionBubbles} className={classes.bubblesRight} />
        </Hidden>
        <Hidden mdUp>
          <img src={DescriptionBubbles} className={classes.bubblesMobile} />
        </Hidden>

        <Typography variant={'h2'} align={'center'}>
          Whoops! This Product Doesn't Exist.
        </Typography>
      </Box>
      <ContainerBox className={classes.root}>
        <Typography variant={'h4'} className={classes.productHeader}>
          Here is the start of Every Keepsake
        </Typography>

        <ProductCard boxPrice={productData.sale_price} />
      </ContainerBox>
    </div>
  )
}

ProductNotFound.propTypes = {
  productData: PropTypes.object.isRequired,
}

export default ProductNotFound
