import React, { useState } from 'react'

import cl from 'classnames'
import {
  Box,
  Hidden,
  IconButton,
  Slide,
  Typography,
} from '@material-ui/core'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FormatQuoteRounded from '@material-ui/icons/FormatQuoteRounded'

import Image from 'artkive/components/Image'
import { OUR_PROCESS } from 'artkive/constants/tracker/mainNavigation'
import theme from 'artkive/utils/theme'

import bgImage from 'images/ecom/process/team/bg.svg'

import { withClickTracker } from '../Tracker'

import { TEAM } from './TeamCarousel.constants'

// styled components
const ArrowBtn = withStyles(({ palette }) => ({
  root: {
    border: `1px solid ${palette.secondary.light}`,
  },
}))(IconButton)

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  thumbnailMember: {
    cursor: 'pointer',
    flexBasis: '110px',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    border: `2px solid white`,
    borderRadius: '100%',
  },
  thumbnailWrapper: {
    border: `2px solid transparent`,
    width: 52,
    height: 52,
    borderRadius: '50%',
  },
  active: {
    border: `2px solid ${theme.colors.secondary.dark}`,
  },
  thumbnailTitle: {
    fontSize: '0.75rem',
  },
  image: {
    zIndex: 9,
    borderRadius: '50%',
    position: 'relative',
    right: 0,
    maxHeight: '100%',
    maxWidth: 'unset',
    height: 'auto',
    [breakpoints.up('md')]: {
      position: 'absolute',
      right: '-50px',
      minWidth: '100%',
      minHeight: '100%',
      width: 'unset',
    },
    /* [breakpoints.up('lg')]: {
      minWidth: 450,
      minHeight: 450,
      height: 450,
    },
    [breakpoints.up('lg')]: {
      minWidth: 500,
      minHeight: 500,
    }*/
  },
  slides: {
    position: 'relative',
    minWidth: '100%',
    flexGrow: 1,
    minHeight: 755,
    [breakpoints.up('md')]: {
      minWidth: 'unset',
      minHeight: 500,
    },
  },
  sliderNav: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexBasis: 44,
  },
  imageBg: {
    background: `url(${bgImage})`,
    position: 'absolute',
    right: 42,
    top: 42,
    width: 75,
    height: 190,
    zIndex: 8,
    [breakpoints.up('md')]: {
      right: -15,
      top: 15,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    [breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
    },
    [breakpoints.up('xl')]: {
      height: 500,
    },
  },
  memberWrapper: {
    position: 'relative',
    backgroundColor: theme.colors.secondary.light,

    [breakpoints.up('md')]: {
      backgroundColor: 'white',
      minHeight: 300,
      minWidth: 250,
    },
    [breakpoints.up('lg')]: {
      minHeight: 450,
      minWidth: 400,
    },
    [breakpoints.up('xl')]: {
      minHeight: 500,
      minWidth: 450,
    },
  },
  memberDescription: {
    flexGrow: 1,
    backgroundColor: theme.colors.secondary.light,
    padding: `${spacing(3)}px ${spacing(4)}px`,
    [breakpoints.up('md')]: {
      padding: `${spacing(3)}px ${spacing(4)}px ${spacing(3)}px ${spacing(8)}px`,
    },
    [breakpoints.up('lg')]: {
      padding: `${spacing(8)}px ${spacing(12)}px`,
    },
  },
}))

const TrackTeamMemberBox = withClickTracker(Box)
const TrackArrowBtn = withClickTracker(ArrowBtn)

const TeamCarousel = () => {
  const classes = useStyles()
  const muiTheme = useTheme()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  // state
  const [activeMember, setActiveMember] = useState(TEAM[0].id)

  const getNextMember = (dir) => {
    const index = TEAM.findIndex((member) => member.id === activeMember)

    if (dir === 'prev') {
      if (index === 0) {
        setActiveMember(TEAM[TEAM.length - 1].id)
      } else {
        setActiveMember(TEAM[index - 1].id)
      }
    } else if (dir === 'next') {
      if (index >= (TEAM.length - 1)) {
        // if last index return first item id
        setActiveMember(TEAM[0].id)
      } else {
        // else return next index id
        setActiveMember(TEAM[index + 1].id)
      }
    }
  }

  return (
    <Box>
      <Box position={'relative'} overflow={'hidden'}>
        <Box display={'flex'} alignItems={'center'}>
          <Hidden smDown>
            <Box className={classes.sliderNav} mr={2}>
              <TrackArrowBtn onClick={() => getNextMember('prev')} trackerEvent={OUR_PROCESS.TEAM_SLIDER_CLICK}>
                <ChevronLeft />
              </TrackArrowBtn>
            </Box>
          </Hidden>

          <Box className={classes.slides}>
            {TEAM.map((member) => (
              <Slide
                key={member.id}
                in={activeMember === member.id}
                direction={isMobile ? 'right' : 'up'}
                timeout={300}
              >
                <Box
                  position={activeMember === member.id ? 'relative' : 'absolute'}
                  display={'flex'}
                  alignItems={'center'}
                  className={classes.wrapper}
                >
                  <Box className={classes.memberWrapper} p={isMobile ? 2 : 0}>
                    <Image src={member.image} className={classes.image} />
                    <Box className={classes.imageBg} />
                  </Box>

                  <Slide
                    in={activeMember === member.id}
                    direction={isMobile ? 'right' : 'up'}
                    timeout={{
                      enter: 600,
                      exit: 300,
                    }}
                    className={classes.memberDescription}
                  >
                    <Box>
                      <Box mb={2}><Typography gutterBottom variant={'h3'}>{member.title}</Typography></Box>
                      <Box mb={2}><FormatQuoteRounded color={'primary'} /></Box>
                      <Typography gutterBottom variant={'body1'}>{member.about}</Typography>
                      <Box mt={3}><Typography gutterBottom variant={'subtitle1'}>{member.name}</Typography></Box>
                    </Box>
                  </Slide>
                </Box>
              </Slide>
            ))}
          </Box>

          <Hidden smDown>
            <Box className={classes.sliderNav} ml={2}>
              <TrackArrowBtn onClick={() => getNextMember('next')} trackerEvent={OUR_PROCESS.TEAM_SLIDER_CLICK}>
                <ChevronRight />
              </TrackArrowBtn>
            </Box>
          </Hidden>
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'flex-start'} justifyContent={'center'} mt={6}>
        {TEAM.map((member) => (
          <TrackTeamMemberBox
            key={member.id}
            textAlign={'center'}
            className={classes.thumbnailMember}
            role={'button'}
            onClick={(e) => {
              e.preventDefault()
              setActiveMember(member.id)
            }}
            trackerEvent={OUR_PROCESS.TEAM_SLIDER_CLICK}
            mx={0.5}
          >
            <Box
              key={member.id}
              mx={'auto'}
              className={cl(classes.thumbnailWrapper, {
                [classes.active]: activeMember === member.id,
              })}
            >
              <Image
                src={member.thumbnail}
                className={cl(classes.thumbnail)}
              />
            </Box>
            <Box mt={1}>
              <Typography variant={'subtitle2'}>
                {member.name}
              </Typography>
              <Typography variant={'caption'} className={classes.thumbnailTitle}>
                {member.title}
              </Typography>
            </Box>
          </TrackTeamMemberBox>
        ))}
      </Box>
    </Box>
  )
}

export default TeamCarousel
