import Aren from 'images/ecom/process/team/Aren.png'
import ArenThumb from 'images/ecom/process/team/Aren-Thumbnail.png'
import Cody from 'images/ecom/process/team/Cody.png'
import CodyThumb from 'images/ecom/process/team/Cody-Thumbnail.png'
import Sam from 'images/ecom/process/team/Sam.png'
import SamThumb from 'images/ecom/process/team/Sam-Thumbnail.png'
import YuYi from 'images/ecom/process/team/YuYi.png'
import YuYiThumb from 'images/ecom/process/team/YuYi-Thumbnail.png'

export const TEAM = [
  {
    id: 1,
    image: YuYi,
    thumbnail: YuYiThumb,
    name: 'YuYi',
    title: 'Photographer',
    about: 'I have the coolest job. From 3D ladybugs to a self-portrait that rivals Picasso, I never know what I\'m going to discover inside an Artkive box. I get to capture each creation with our state-of-the-art tools and make an impact on a young artist\'s life every day.',
  },
  {
    id: 2,
    image: Aren,
    thumbnail: ArenThumb,
    name: 'Aren',
    title: 'Art Editor',
    about: 'I find myself obsessing over every little detail of a piece of art. I know I\'ve done my job when the artwork literally pops off of the page and you see things you never noticed before. I love when I hear that art looks even better after I\'ve edited it. That\'s just the best.',
  },
  {
    id: 3,
    image: Cody,
    thumbnail: CodyThumb,
    name: 'Cody',
    title: 'Book Builder',
    about: 'I studied graphic design, so book building is a real joy for me. There\'s a real art and science to it, and the stories that unfold inside of a book are amazing. Most parents have no idea just how much they\'ll learn about their kids through this process. It really is life-changing.',
  },
  {
    id: 4,
    image: Sam,
    thumbnail: SamThumb,
    name: 'Sam',
    title: 'Mosaic Designer',
    about: 'Designing a mosaic is part curation, part creative direction, and part intuition. I absolutely love it. My goal with every mosaic is that it looks beautiful as a whole, while highlighting each of the 25 pieces of art. Oh, and the wow factor. I live for the wow factor.',
  },
]
