import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  anchorOriginTopCenter: {
    [theme.breakpoints.down('sm')]: {
      top: 64,
    },
  },
}))

const AppSnackbars = ({ onClose, variant, message }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)

    typeof onClose === 'function' && onClose()
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={6000}
      onClose={handleClose}
      onClick={handleClose}
      open={open}
      classes={classes}
    >
      <Alert severity={variant} variant={'filled'}>
        {message}
      </Alert>
    </Snackbar>
  )
}

AppSnackbars.propTypes = {
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['error', 'success']).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
}

export default AppSnackbars
