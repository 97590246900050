import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ApplePayButton from 'common/components/ApplePayButton'

import { startApplePaySession } from './utils'

const ApplePay = ({ children, price, onAddressChange, onSubmit, setError, requestAddress, onClick, disabled }) => {
  const payClickHandler = useCallback(async () => {
    if (disabled) return
    if (typeof onClick === 'function') {
      const isValid = await onClick()
      if (isValid === false) return
    }

    startApplePaySession(requestAddress, price, onAddressChange, onSubmit, setError)
  }, [disabled, requestAddress, price, onSubmit, onAddressChange, setError, onClick])

  return (
    <ApplePayButton theme={'dark'} onClick={payClickHandler} disabled={disabled}>
      {children}
    </ApplePayButton>
  )
}

ApplePay.propTypes = {
  price: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  requestAddress: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ApplePay.defaultProps = {
  children: null,
  requestAddress: false,
  onClick: null,
  disabled: false,
}

export default ApplePay
