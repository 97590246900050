import { useEffect, useMemo, useState } from 'react'

import { PAGES_SCROLL } from 'artkive/constants/tracker/mainNavigation'

import useTracker from './useTracker'

const getDocHeight = () => {
  return Math.max(
    document.body.scrollHeight, document.documentElement.scrollHeight,
    document.body.offsetHeight, document.documentElement.offsetHeight,
    document.body.clientHeight, document.documentElement.clientHeight,
  )
}

const usePercentageScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    const calculateScrollDistance = () => {
      const scrollTop = window.scrollY // how much the user has scrolled by
      const winHeight = window.innerHeight
      const docHeight = getDocHeight()

      const totalDocScrollLength = docHeight - winHeight
      const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)

      setScrollPosition(scrollPostion)
    }
    const scrollHandler = () => {
      requestAnimationFrame(() => {
        calculateScrollDistance()
      })
    }
    document.addEventListener('scroll', scrollHandler)
    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  return [scrollPosition]
}

const buildThresholdArray = (steps) => Array.from(Array(steps).keys(), (i) => i / steps)

const usePageScrollTracker = () => {
  const [trackAction] = useTracker()

  const threshold = useMemo(() => buildThresholdArray(10), [])

  const [prevTrackPercentage, setPrevTrackPercentage] = useState(0)

  const [scrollPosition] = usePercentageScroll()

  useEffect(() => {
    const thresholdPosition = threshold.find((t) => t > scrollPosition / 100) || 1

    if (thresholdPosition > prevTrackPercentage) {
      setPrevTrackPercentage(thresholdPosition)
      trackAction(PAGES_SCROLL, {
        visited: thresholdPosition,
      })
    }
  }, [scrollPosition])

}

export default usePageScrollTracker
