import _debounce from 'lodash.debounce'
import _memoize from 'lodash.memoize'

export default function memoizeDebounce(func, wait, resolver, options = {}) {
  const debounceMemo = _memoize(
    (..._args) => _debounce(func, wait, options),
    resolver,
  )

  function wrappedFunction(...args) {
    return debounceMemo(...args)(...args)
  }

  wrappedFunction.flush = (...args) => {
    debounceMemo(...args).flush()
  }

  return wrappedFunction
}
