import React from 'react'

import { Box, Container, Grid, Hidden, Link, Typography } from '@material-ui/core'
import { Facebook, Instagram, Pinterest } from '@material-ui/icons'

import Image from 'artkive/components/Image'
import Swipe from 'artkive/components/Swipe'
import routes from 'artkive/utils/routes'

import Social1 from 'images/ecom/social/social-1.png'
import Social2 from 'images/ecom/social/social-2.png'
import Social3 from 'images/ecom/social/social-3.png'
import Social4 from 'images/ecom/social/social-4.png'
import Social5 from 'images/ecom/social/social-5.png'
import Social6 from 'images/ecom/social/social-6.png'

const ConfirmationSocialBox = () => {
  return (
    <Box py={8}>
      <Container>
        <Box mb={8}>
          <Typography align={'center'} gutterBottom variant={'h2'}>
            Let Your Kids Get Creative While You Sort
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={6} sm>
            <Box mb={2} textAlign={'center'}><Image src={Social1} /></Box>
          </Grid>
          <Grid item xs={6} sm>
            <Box mb={2} textAlign={'center'}><Image src={Social2} /></Box>
          </Grid>
          <Grid item xs={6} sm>
            <Box mb={2} textAlign={'center'}><Image src={Social3} /></Box>
          </Grid>
          <Grid item xs={6} sm>
            <Box mb={2} textAlign={'center'}><Image src={Social4} /></Box>
          </Grid>
          <Grid item xs={6} sm>
            <Box mb={2} textAlign={'center'}><Image src={Social5} /></Box>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={6} sm>
              <Box mb={2} textAlign={'center'}><Image src={Social6} /></Box>
            </Grid>
          </Hidden>
        </Grid>

        <Box fontSize={'large'} mb={4} mt={8} textAlign={'center'}>
          <Typography component={'div'}>
            <Swipe href={'#'} variant={'h6'} underline={'none'}>#ARTKIVE</Swipe>
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Box mr={2}>
            <Link href={routes.instagram} target={'_blank'}><Instagram fontSize={'large'} color={'primary'} /></Link>
          </Box>
          <Box mr={2}>
            <Link href={routes.facebook} target={'_blank'}><Facebook fontSize={'large'} color={'primary'} /></Link>
          </Box>
          <Box mr={2}>
            <Link href={routes.pinterest} target={'_blank'}><Pinterest fontSize={'large'} color={'primary'} /></Link>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ConfirmationSocialBox
