import React, { useState } from 'react'

import { Box, Dialog, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { TrackButton } from 'artkive/components/Tracker'
import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import { HOME_PAGE_EVENTS, NAVIGATION } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'
import { action } from 'artkive/utils/tracker'

import SeeUsInMotion from 'images/ecom/home/SeeUsInMotion.png'
import SeeUsInMotionX1 from 'images/ecom/home/SeeUsInMotion-x1.png'
import SeeUsInMotionX2 from 'images/ecom/home/SeeUsInMotion-x2.png'

const useStyles = makeStyles(({ palette }) => ({
  image: {
    position: 'relative',
    cursor: 'pointer',

    '&:hover $playBtn': {
      color: palette.grey.main,
    },
  },
  picture: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: palette.primary.main,
    '&:hover': {
      color: theme.colors.sky[900],
    },
  },
  playIcon: {
    width: 80,
    height: 80,
  },
  text: {
    maxWidth: '35rem',
  },
}))

const SeeUsInMotionSection = () => {
  const isMobile = useMobileBreakpoint()
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const muiTheme = useTheme()

  usePageScrollTracker()

  const handleSeeVideoOpen = () => {
    action(HOME_PAGE_EVENTS.PLAY_VIDEO_CLICK, { section: 'home_see_us' })

    setIsOpen(true)
  }

  return (
    <>
      <Box bgcolor={theme.colors.white.dark}>
        <Grid container>
          <Grid item xs={12} md={6} role={'button'} onClick={handleSeeVideoOpen} className={classes.image}>
            <picture>
              <source srcSet={`${SeeUsInMotion}`} media={muiTheme.breakpoints.up('lg').replace('@media ', '')} />
              <source
                srcSet={`${SeeUsInMotionX2}, ${SeeUsInMotion} 2x`}
                media={muiTheme.breakpoints.up('md').replace('@media ', '')}
              />
              <img
                srcSet={`${SeeUsInMotionX1}, ${SeeUsInMotionX2} 2x`}
                src={SeeUsInMotionX1}
                className={classes.picture}
              />
            </picture>

            <Box className={classes.playBtn}>
              <svg width={'104'} height={'104'} viewBox={'0 0 104 104'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}
                className={classes.playIcon}>
                <path d={'M24.375 24.375H79.625V79.625H24.375V24.375Z'} fill={'white'} />
                <path
                  d={'M51.9974 8.66406C28.0774 8.66406 8.66406 28.0774 8.66406 51.9974C8.66406 75.9174 28.0774 95.3307 51.9974 95.3307C75.9174 95.3307 95.3307 75.9174 95.3307 51.9974C95.3307 28.0774 75.9174 8.66406 51.9974 8.66406ZM43.3307 71.4974V32.4974L69.3307 51.9974L43.3307 71.4974Z'}
                  fill={'currentColor'} />
              </svg>

              <Typography component={'div'} color={'inherit'}>
                <strong>See How We Work</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <Box px={isMobile ? 2 : 10} py={4}>
              <Box mb={4}>
                <Typography gutterBottom variant={'h2'} align={isMobile ? 'center' : 'left'}>
                  We Exist To Help Kids Thrive.
                </Typography>
              </Box>
              <Typography gutterBottom className={classes.text}>
                Hey, we’re Artkive! We believe in the power of creativity and making memories last. Our passion,
                dedication, and love of what we do is what makes us the industry leader in helping families like yours
                preserve kid&apos;s art.
              </Typography>

              <Box mt={6} mb={4} display={'flex'} justifyContent={isMobile ? 'center' : 'left'}>
                <TrackButton variant={'outlined'} color={'primary'} size={'large'} href={routes.aboutUs} underline={'none'}
                  trackerEvent={NAVIGATION.ABOUT_US_CLICK}>
                  About Us
                </TrackButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* video modal */}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <iframe
          width={'560'}
          height={'315'}
          src={'https://www.youtube.com/embed/WkC6OZDsRDE'}
          title={'YouTube video player'}
          frameBorder={'0'}
          allow={'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'}
          allowFullScreen
        ></iframe>
      </Dialog>
    </>
  )
}

export default SeeUsInMotionSection
