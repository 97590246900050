import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Link,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { FormatQuoteRounded } from '@material-ui/icons'

import { MIN_BOX_BOOK_NUMBER_OF_PAGES } from 'common/utils/bookSize.constants'
import Faqs from 'artkive/components/Faqs'
import Image from 'artkive/components/Image'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import ProductPriceTypography from 'artkive/components/PageSections/ProductPriceTypography'
import { TrackButton } from 'artkive/components/Tracker'
import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import { ART_BOOK, GET_BOX_CLICK, NAVIGATION } from 'artkive/constants/tracker/mainNavigation'
import withApp from 'artkive/hoc/withApp'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import useScrollToSlider from 'artkive/hooks/useScrollToSlider'
import { defaultHowItWorks } from 'artkive/stores/ecom/constants/data'
import { HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Testimonial from 'images/ecom/books/ArtBooks-Testimonial2.png'
import Product from 'images/ecom/books/ArtBooksProduct.png'
import ProductX2 from 'images/ecom/books/ArtBooksProduct-x2.png'
import About1 from 'images/ecom/books/Books-Focus-1.png'
import About2 from 'images/ecom/books/Books-Focus-2.png'
import About3 from 'images/ecom/books/Books-Focus-3.png'
import Customer from 'images/ecom/books/CustomerAvatar.png'
import MosaicsProduct from 'images/ecom/books/Mosaics.jpg'
import Bubbles from 'images/ecom/brand/bubbles.svg'
import sprite from 'images/ecom/brand/sprite.svg'
import digital from 'images/ecom/home/digital.svg'

import ProductDescriptionSection from '../GetStarted/ProductDescriptionSection'
import { PRICING } from '../Pricing/Pricing.constants'

import { bookProductBullets, FAQS } from './Books.constants'
import DigitizedImagesAccessSection from './DigitizedImagesAccessSection'
import GetStartedWithBoxSection from './GetStartedWithBoxSection'
import LookInsidePreview from './LookInsidePreview'
import PriceSlider from './PriceSlider'
import ValueLabelComponent from './ValueLabelComponent'
import YouWillAlsoLove from './YouWillAlsoLove'

const Books = ({ pricing, mosaic_pricing }) => {
  usePageScrollTracker()
  const [sliderValue, setSliderValue] = useState(MIN_BOX_BOOK_NUMBER_OF_PAGES)
  const sliderRef = useScrollToSlider()

  const muiTheme = useTheme()
  const isMobile = useMobileBreakpoint()

  const handleSliderChange = (e, value) => setSliderValue(value)

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
      <ProductDescriptionSection
        image={(
          <picture>
            <source srcSet={Product} media={muiTheme.breakpoints.up('lg').replace('@media ', '')} />
            <img srcSet={`${ProductX2}, ${Product} 2x`} src={ProductX2} />
          </picture>
        )}
        productBg={theme.colors.bg.blue}
        descriptionBg={theme.colors.bg.yellow}
        overImage={(
          <Box position={'absolute'} top={'12%'} left={isMobile ? '16%' : '22%'} width={'15%'} zIndex={1}>
            <Image src={digital} />
          </Box>
        )}
      >
        <Typography variant={'h1'} align={isMobile ? 'center' : 'left'}>
          Art Books
        </Typography>

        <Box mb={3} mt={2}>
          <Typography variant={'body1'}>
            Publish the work of your little artist in their very own book! Start by filling an Artkive Box with art,
            then we'll transform it into a vibrant book that showcases your child's creativity.
          </Typography>
        </Box>

        <Hidden smUp>
          <Divider />

          <br />
        </Hidden>

        {bookProductBullets.map(({ text }, index) => (
          <ProductDescriptionItem key={index}>
            {text}
          </ProductDescriptionItem>
        ))}

        <Hidden smUp>
          <br />

          <Divider />
        </Hidden>

        <Box alignItems={'center'} display={'flex'} mt={2}>
          <Typography variant={'body1'}>
            <strong>
              Starting at <ProductPriceTypography>${PRICING.books.base}</ProductPriceTypography>{' '}
              for a {PRICING.books.minPages}-page Book
            </strong>
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant={'caption'}>
            * Price does not include Artkive Box
          </Typography>
        </Box>

        <Box display={'flex'} alignItems={'center'} justifyContent={isMobile ? 'center' : 'left'} mt={4}>
          <TrackButton
            color={'primary'}
            href={routes.boxCheckout}
            size={'large'}
            variant={'contained'}
            trackerEvent={GET_BOX_CLICK}
            trackerOptions={{
              section: 'get_books_banner',
            }}
          >
            Get My Box
          </TrackButton>
        </Box>
      </ProductDescriptionSection>

      <ContainedSection bgcolor={isMobile ? theme.colors.sky[300] : 'transparent'}>
        <Typography
          variant={'h2'}
          align={'center'}
          id={'slider'}
          ref={sliderRef}
        >
          How Much Will My Book Cost?
        </Typography>

        <Box textAlign={isMobile ? 'left' : 'center'} mt={3} mb={3} px={isMobile ? 0 : 30}>
          <Typography variant={'body1'}>
            The cost of your book is separate from your Artkive Box, and it depends on how many pieces of art you
            decide to send in your Box.
          </Typography>
        </Box>

        <Box textAlign={isMobile ? 'left' : 'center'} mb={4} px={isMobile ? 0 : 30}>
          <Typography variant={'body1'}>
            For questions about pricing, please{' '}
            <strong>
              <Link href={`mailto:${HELP_EMAIL}`}>Contact Us.</Link>
            </strong>
          </Typography>
        </Box>

        <Box display={'flex'} justifyContent={'center'} mt={isMobile ? 5 : 8}>
          <ValueLabelComponent value={sliderValue} pricing={pricing} />
        </Box>

        <Box px={isMobile ? 1 : 14} mt={isMobile ? 5 : 4} mb={isMobile ? 5 : 8}>
          <PriceSlider value={sliderValue} onChange={handleSliderChange} />
        </Box>

        <Box textAlign={'center'}>
          <TrackButton
            color={'primary'}
            href={routes.boxCheckout}
            size={'large'}
            variant={'contained'}
            trackerEvent={GET_BOX_CLICK}
            trackerOptions={{
              section: 'books_price_slider',
            }}
          >
            Get My Box
          </TrackButton>
        </Box>
      </ContainedSection>

      <div id={'preview'}></div>

      <ContainedSection>
        <Grid container>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              backgroundColor: muiTheme.palette.common.white,
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}

            direction={isMobile ? 'column-reverse' : 'row'}
          >
            <Box px={isMobile ? 4 : 8} py={4}>
              <Box mb={4}><FormatQuoteRounded color={'primary'} /></Box>
              <Typography variant={'body1'}>
                I just want to say thank you so much for what you do. I
                can’t even express how much I appreciate and love Jamie’s art book! It is worth every penny and
                more.
              </Typography>
              <Box alignItems={'center'} display={'flex'} mt={4} justifyContent={'space-between'}>
                <Box alignItems={'center'} display={'flex'}>
                  <Image src={Customer} style={{ maxWidth: '45px' }} />
                  <Box fontSize={'12px'} ml={2}>Nicole M. - Brooklyn, NY</Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              height={'100%'}
              style={{
                backgroundImage: `url(${Testimonial})`,
                backgroundSize: 'cover',
                minHeight: '300px',
                width: '100%',
              }}
            >
            </Box>
          </Grid>
        </Grid>
      </ContainedSection>

      <LookInsidePreview />

      <HowItWorks
        title={'How It Works'}
        variant={'image'}
        steps={defaultHowItWorks}
      >
        <Box justifyContent={'center'} display={'flex'} pt={10}>
          <Button
            color={'primary'}
            href={routes.checkout}
            size={'large'}
            style={{ paddingLeft: 72, paddingRight: 72 }}
            variant={'contained'}
          >
            Get My Box
          </Button>
        </Box>
      </HowItWorks>

      <ContainedSection
        title={(
          <Typography variant={'h2'} align={'center'}>
            There’s So Much Inside (And Outside, Too).
          </Typography>
        )}
        bgcolor={theme.colors.white.main}
      >
        <Box position={'relative'}>
          <Box position={'absolute'} top={'-50px'} right={'-10px'} zIndex={1}>
            <Image src={sprite} style={{ width: '64px', transform: 'scaleX(-1)' }} />
          </Box>
          <Box position={'absolute'} bottom={'60px'} left={'-10px'} zIndex={0}>
            <Image src={Bubbles} style={{ width: '80px', transform: 'scaleX(-1)' }} />
          </Box>
          <Grid container style={{ position: 'relative', zIndex: '0' }}>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'} px={isMobile ? 0 : 4} mb={isMobile ? 6 : 0}>
                <Box mb={3}><Image src={About1} /></Box>
                <Typography variant={'h3'}>
                  1. Customizable cover, in two sizes 8”x8” and 11”x8.5”
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'} px={isMobile ? 0 : 4} mb={isMobile ? 6 : 0}>
                <Box mb={3}><Image src={About2} /></Box>
                <Typography variant={'h3'}>
                  2. Artwork stands out on premium matte pages
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} md={4} item>
              <Box textAlign={'center'} px={isMobile ? 0 : 4}>
                <Box mb={3}><Image src={About3} /></Box>
                <Typography variant={'h3'}>
                  3. You can add custom captions on every piece
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ContainedSection>

      <DigitizedImagesAccessSection />

      <GetStartedWithBoxSection />

      <Faqs faqs={FAQS} trackerEvent={ART_BOOK.FAQ_ITEM_OPEN} />

      <YouWillAlsoLove image={MosaicsProduct}>
        <Box mb={2}>
          <Typography variant={'h3'}>Framed Mosaic</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            A bright and beautiful conversation piece that puts your child&apos;s creativity front and center.
          </Typography>
        </Box>
        <Box display={'flex'} mb={3} alignItems={'baseline'} justifyContent={isMobile ? 'center' : 'flex-start'}>
          <Typography variant={'body1'} align={'inherit'}>
            <strong>
              Starting at{' '}
              <ProductPriceTypography size={'small'}>${mosaic_pricing.small_product.price}</ProductPriceTypography>{' '}
              for {mosaic_pricing.small_product.name}
            </strong>
          </Typography>
        </Box>

        <TrackButton
          color={'primary'}
          size={'large'}
          href={routes.mosaics}
          variant={'contained'}
          trackerEvent={NAVIGATION.MOSAIC_CLICK}
          trackerOptions={{ section: 'mosaics_banner' }}
        >
          See Mosaics
        </TrackButton>
      </YouWillAlsoLove>
    </Box>
  )
}

Books.propTypes = {
  pricing: PropTypes.object.isRequired,
  mosaic_pricing: PropTypes.object.isRequired,
}

export default withApp(Books)
