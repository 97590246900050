import omit from 'lodash/omit'
export const DEFAULT_STORAGE_KEY = 'EasyPeasyStore'
export const DEFAULT_STORAGE_VERSION = 5

const persistKey = (targetPath) => {
  return '[' + DEFAULT_STORAGE_KEY + '][' + DEFAULT_STORAGE_VERSION + ']' + (targetPath.length > 0 ? '[' + targetPath.join('.') + ']' : '')
}

const globalKey = persistKey('')

const isSSR = typeof window === 'undefined'

const noopStorage = {
  getItem: function getItem() {
    return undefined
  },
  setItem: function setItem() {
    return undefined
  },
  removeItem: function removeItem() {
    return undefined
  },
}

const storage = isSSR ? noopStorage : sessionStorage

let persistentCache = null

const customStorage = {
  getItem: function getItem(targetPath) {
    const storedData = storage.getItem(globalKey)
    const data = storedData ? JSON.parse(storedData) : {}
    persistentCache = data
    return data[targetPath]
  },
  setItem: function setItem(targetPath, value) {

    persistentCache = {
      ...persistentCache,
      [targetPath]: value,
    }

    storage.setItem(globalKey, JSON.stringify(persistentCache))

    return undefined
  },
  removeItem: function removeItem(targetPath) {
    const data = omit(persistentCache, [targetPath])
    storage.setItem(globalKey, JSON.stringify(data))
    return undefined
  },
}

export default customStorage
