import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
  },
  arrow: {
    color: 'white',
    '&:before': {
      boxShadow: theme.shadows[2],
    },
  },
}))(Tooltip)

export default LightTooltip
