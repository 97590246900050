function generatePath(
  path,
  params = {},
) {
  return path
    .replace(/:(\w+)/g, (_, key) => {
      return params[key]
    })
    .replace(/(\/?)\*/, (_, prefix, __, str) => {
      const star = '*'

      if (params[star] == null) {
        // If no splat was provided, trim the trailing slash _unless_ it's
        // the entire path
        return str === '/*' ? '/' : ''
      }

      // Apply the splat
      return `${prefix}${params[star]}`
    })
}

export default generatePath
