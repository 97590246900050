import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

const CaptionSmallest = withStyles(({ breakpoints }) => ({
  root: {
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '0.75rem',
    lineHeight: 1.4,
    color: theme.colors.black,
    fontWeight: 500,
    [breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
  },
}))(Typography)

export default CaptionSmallest
