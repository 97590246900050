import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  Grid,
  Hidden,
  TextField,
  Typography,
} from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Close } from '@material-ui/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'

import Image from 'artkive/components/Image'
import { TrackLink, withClickTracker } from 'artkive/components/Tracker'
import useTracker from 'artkive/components/Tracker/useTracker'
import { DISCOUNT_BANNER } from 'artkive/constants/tracker/mainNavigation'
import usePromo from 'artkive/hooks/usePromo'
import routes from 'artkive/utils/routes'
import validations from 'artkive/utils/validations'

import Mosaics from 'images/ecom/books/Artbooks-Mosaics.png'

const TrackedTextField = withClickTracker(TextField, 'onFocus')
const TrackedCloseIcon = withClickTracker(Close)

const ExitIntent = ({ onClose, open }) => {
  const { formState: { errors }, handleSubmit, register } = useForm()
  const { promoActions, promoState } = usePromo({ formType: 'modal' })
  const { modalCookie } = useStoreState(({ cookieStore }) => cookieStore)
  const { setModalCookie } = useStoreActions(({ cookieStore }) => cookieStore)
  const [trackAction] = useTracker()

  const muiTheme = useTheme()
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'))

  const [isOpen, setIsOpen] = useState(open)

  const onSubmit = async ({ email }, event) => {
    await trackAction(DISCOUNT_BANNER.APPLY_CLICK)

    await promoActions.dispatchEmail({
      email,
      onDone: () => {
        handleClose()
        event.target.reset()
      },
      onFail: () => handleClose(),
    })
  }

  const handleClose = () => {
    setModalCookie()
    setIsOpen(false)

    if (typeof onClose === 'function') onClose()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (document.activeElement.tagName === 'INPUT' ||
        promoState.promo.validated ||
        document.location.hostname === '127.0.0.1') return

      if (!modalCookie) setIsOpen(true)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => { setIsOpen(open) }, [open])

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={isOpen}
      onClose={handleClose}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Hidden mdUp>
            <Image src={Mosaics} />
          </Hidden>

          <Hidden smDown>
            <Box
              style={{
                backgroundImage: `url(${Mosaics})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '400px',
              }}
              height={'100%'}
              width={'100%'}
            >
              &nbsp;
            </Box>
          </Hidden>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            justifyContent={'center'}
            p={isMobile ? 2 : 6}
            position={'relative'}
          >
            <TrackedCloseIcon
              color={'primary'}
              fontSize={'large'}
              onClick={handleClose}
              style={{ cursor: 'pointer', position: 'absolute', top: '1rem', right: '1rem' }}
              trackerEvent={DISCOUNT_BANNER.CLOSE_MODAL_CLICK}
            />

            <Box mb={2}>
              <Typography variant={'h2'}>Save $10!</Typography>
            </Box>

            <Box mb={4}>
              <Typography>Off Your Artkive Box Order</Typography>
            </Box>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box mb={4}>
                <TrackedTextField
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  name={'email'}
                  type={'email'}
                  fullWidth
                  label={'Your Email'}
                  {...register('email', validations.email)}
                  variant={'outlined'}
                  trackerEvent={DISCOUNT_BANNER.EMAIL_FIELD_CLICK}
                />
              </Box>

              <Button
                color={'primary'}
                fullWidth={true}
                type={'submit'}
                variant={'contained'}
              >
                Get My $10
              </Button>
            </form>

            <Box mt={1}>
              <Typography style={{ fontSize: '12px' }}>
                By entering your email address you agree to our <TrackLink
                  href={routes.privacy}
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  trackerEvent={DISCOUNT_BANNER.PRIVACY_POLICY_CLICK}
                >
                  Privacy Policy
                </TrackLink> and <TrackLink
                  style={{
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  href={routes.tou}
                  trackerEvent={DISCOUNT_BANNER.TERMS_AND_CONDITIONS_CLICK}
                >
                  Terms & Conditions
                </TrackLink>.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  )
}

ExitIntent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

ExitIntent.defaultProps = {
  open: false,
  onClose: null,
}

export default ExitIntent
