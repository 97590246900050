import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { get } from 'react-hook-form'
import cl from 'classnames'
import {
  Box,
  Button,
  Hidden,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'wouter'

import { ADDONS } from 'common/utils/conciergeProduct.constants'
import MobileHeader from 'artkive/components/Checkout/MobileHeader'
import { BackButton } from 'artkive/components/Header'
import MarkdownText from 'artkive/components/MarkdownText'
import { ProductDescriptionPrice } from 'artkive/components/OrderSummary/PriceWithDiscount/index'
import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import ProductDescriptionBox, { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import HelpTooltip from 'artkive/components/Tooltip/HelpTooltip'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import TrustIndexReviews, { TRUSTINDEX_VARIANT } from 'artkive/pages/Home/TrustIndexReviews'
import { fetchBoxPrice as fetchPrice } from 'artkive/stores/ecom/api/fetchPrice'
import validatePromoCode from 'artkive/stores/ecom/api/validatePromoCode'
import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'
import theme, { newColors } from 'artkive/utils/theme'
import * as track from 'artkive/utils/tracker'

import { getProductAddonByKind } from '../Checkout/utils'

import { ReturnArtTip, SubtitleTypography } from './GetStarted.style'
import ProductDescriptionSection from './ProductDescriptionSection'

const useStyles = makeStyles((muiTheme) => ({
  root: {
    backgroundColor: theme.colors.white.dark,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 0,
    paddingTop: 0,
    width: '100%',
  },
  singleSection: {
    justifyContent: 'center',
    paddingBottom: 0,
  },
  howItWorksImage: {
    maxWidth: 120,
    height: 'auto',
  },
  howItWorksDescription: {
    lineHeight: 1.6,
    fontWeight: 500,
  },
  circleIcon: {
    minWidth: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: '#DC87B9',
    marginTop: 6,
  },
  backBtn: {
    zIndex: 1,
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },
  reviewsContainer: {
    minHeight: '533px',
    [muiTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
      minHeight: '100vh',
    },
  },
  reviewsTitle: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  titleWrap: {
    marginBottom: '24px',
    [muiTheme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },
  trustIndexWrap: {
    '& .ti-widget': {
      '& .ti-widget-container': {
        marginBottom: '64px !important',
        [muiTheme.breakpoints.down('sm')]: {
          ['& .ti-reviews-container']: {
            maxWidth: '600px',
            margin: '0 auto',
          },
        },
        '& .ti-reviews-container .ti-load-more-reviews-container .ti-load-more-reviews-button': {
          backgroundColor: 'transparent !important',
          color: `${theme.colors.sky[700]} !important`,
          fontWeight: 700,
          padding: '8px 16px !important',
          border: `1px solid ${theme.colors.sky[500]} !important`,
          borderRadius: '6px',
          textTransform: 'capitalize !important',
        },
      },
      '& .ti-header-content': {
        margin: '0 auto !important',
        marginBottom: '12px !important',
        alignItems: 'center',
        justifyContent: 'center',

        '@media (max-width: 479px)': {
          '& .ti-header-logo, & .ti-header-rating-text.ti-mobile': {
            display: 'flex',
            flex: 'auto !important',
            justifyContent: 'center',
          },
          '& .ti-header-logo': {
            justifyContent: 'flex-end !important',
          },
          '& .ti-header-rating-text.ti-mobile:last-child': {
            textAlign: 'center',
            flex: '0 0 100% !important',
            marginTop: '8px',
          },
        },
        '& .ti-header-rating-reviews': {
          color: `${newColors.grey[700]} !important`,
          textTransform: 'capitalize',
          fontWeight: 500,
        },
      },
    },
  },
  trustIndexSubtitleWrap: {
    '& .ti-widget': {
      marginBottom: '16px',
      [muiTheme.breakpoints.up('md')]: {
        textAlign: 'left !important',
      },
      '& .ti-widget-container': {
        '& .ti-header': {
          display: 'flex',
          alignItems: 'center',
          ['& strong']: {
            fontSize: '16px !important',
            fontWeight: '500 !important',
            textDecoration: 'underline !important',
          },
          '& .ti-stars': {
            marginRight: '5px !important',
          },
          '& .ti-small-logo': {
            display: 'none !important',
          },
        },
      },
    },
  },
}))

const GetStarted = ({ productData, params, productLinks }) => {
  const classes = useStyles()
  const [price, setPrice] = useState({})
  const isMobile = useMobileBreakpoint()

  const { fullName, information, promoCode } = useVariableState(BOX_PRODUCT_TYPE, productData.uuid)

  const data = useMemo(() => {
    const additionalText = get(productData, 'properties.checkout.additional_text', [])
    return {
      back_link: get(productData, 'properties.back_link', '') || '/',
      cover_url: get(productData, 'cover_image.url', ''),
      price: get(productData, 'sale_price', 0),
      header: get(productData, 'properties.checkout.header', []),
      subheader: get(productData, 'properties.checkout.subheader', ''),
      mobile_bullet_header: get(productData, 'properties.checkout.mobile_bullet_header', ''),
      return_artwork: get(productData, 'properties.options.addon_sections')
        .some(({ items }) => items.kind === ADDONS.RETURN_ART),
      bullets: get(productData, 'properties.checkout.bullets', []),
      additional_text: additionalText,
      how_it_works: get(productData, 'properties.checkout.how_it_works', null),
      additionalPromoStars: new Array(additionalText.length + 1).fill('*').join(''),
    }
  }, [productData])

  const validatePromo = useCallback(async () => {
    let code = params.promo || promoCode
    const addOns = []
    try {
      try {
        const promoValidate = await validatePromoCode(code, productData.id, BOX_PRODUCT_TYPE, information.email)
        let addOn
        if (promoValidate?.discount_method === DISCOUNT_METHODS.FREE_USB) {
          addOn = getProductAddonByKind(productData, ADDONS.RETURN_USB)
        } else if (promoValidate?.discount_method === DISCOUNT_METHODS.FREE_SHIPPING) {
          addOn = getProductAddonByKind(productData, ADDONS.RETURN_ART)
        }
        if (addOn) {
          addOns.push({ uuid: addOn.uuid, kind: addOn.kind })
        }
      } catch {
        code = ''
      }

      const currentPricePayload = {
        promo_code: code,
        add_ons: addOns,
        concierge_product_id: productData.id,
        quantity: 1,
        email: information.email,
      }

      const priceResponse = await fetchPrice(currentPricePayload)
      setPrice(priceResponse)
    } catch (e) {
      console.error(e)
    }
  }, [params, productData, promoCode, information])

  // NOTE: this fetches price based on the promo code that was read from the URL or just was in the store
  useEffect(() => {
    validatePromo()
  }, [promoCode])

  useEffect(() => {
    const timer = setTimeout(() => {
      track.initiateCheckout({
        amount: price?.total,
        discount: price?.promo_discount,
        email: information.email,
        name: fullName ? fullName : undefined,
        product: 'box',
        promo: price?.promo_discount_details?.code,
      })
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const htmlElement = document.querySelector('html')
    if (htmlElement) {
      htmlElement.style.scrollBehavior = 'smooth'
    }

    return () => {
      if (htmlElement) {
        htmlElement.style.scrollBehavior = 'auto'
      }
    }
  }, [])

  const circleIcon = <div className={classes.circleIcon} />
  const checkoutLink = typeof location !== 'undefined' ? `${productLinks.checkout}${location.search}` : productLinks.checkout
  const isSingleSection = !data.how_it_works

  return (
    <Box className={cl(classes.root, { [classes.singleSection]: isSingleSection })}>
      <MobileHeader
        backButtonProps={{ href: data.back_link }}
        title={params.uuid ? 'Checkout' : 'Get My Box'}
      />
      <Hidden smDown>
        <BackButton href={data.back_link} className={classes.backBtn} />
      </Hidden>

      <ProductDescriptionSection
        image={(<img src={data.cover_url} />)}
        single={!data.how_it_works}
        productBg={theme.colors.bg.pink_dark}
        descriptionBg={theme.colors.secondary.light}
        height={isSingleSection ? '100vh' : 'auto'}
      >
        <ProductDescriptionBox
          single={!data.how_it_works}
          title={(
            <>
              {data.header[0]} <br /> {data.header[1]}
            </>
          )}
          price={
            <ProductDescriptionPrice fullPriceInfo={price} showEachSuffix={true} />
          }
          additional={(
            <>
              {!!data.additional_text.length && data.additional_text.map(({ text, tip }, index) => (
                <Typography style={{ fontSize: '14px' }} component={'div'} key={index}>
                  {text}
                  {!!tip && (
                    <HelpTooltip size={'medium'} title={'Artkive Box Price'}>
                      {tip}
                    </HelpTooltip>
                  )}
                </Typography>
              ))}

              {!!price.promo_discount_details?.code && (
                <Typography style={{ fontSize: '14px' }} component={'div'}>
                  {data.additionalPromoStars}Promo Code
                  {' '}<strong>{price.promo_discount_details.code.toUpperCase()}</strong> applied
                </Typography>
              )}
            </>
          )}
          link={checkoutLink}
          subheader={(
            <>
              <a href={'#reviews'}>
                <TrustIndexReviews
                  key={'trustindex-widget-subtitle'}
                  variant={TRUSTINDEX_VARIANT.GET_STARTED_SUBTITLE}
                  className={classes.trustIndexSubtitleWrap}
                />
              </a>
              <Hidden smDown>
                <Box lineHeight={1.6} mb={4}>
                  <SubtitleTypography>
                    <MarkdownText source={data.subheader} />
                  </SubtitleTypography>
                </Box>
              </Hidden>

              {data.return_artwork ? (
                <Hidden mdUp>
                  <Box display={'flex'} mt={0.25}>
                    {circleIcon}
                    <Box fontSize={'1rem'} fontWeight={600} ml={1.5} lineHeight={'1.5rem'}>
                      <MarkdownText source={data.subheader} />
                    </Box>
                  </Box>
                  <Box display={'flex'} mt={0.25}>
                    {circleIcon}
                    <Box fontSize={'1rem'} fontWeight={600} ml={1.5} lineHeight={'1.5rem'}>
                      You can choose to have your art returned in the next step.
                    </Box>
                  </Box>
                </Hidden>
              ) : (
                <Hidden mdUp>
                  <Box display={'flex'} mt={0.25} justifyContent={'center'}>
                    <Box fontSize={'1rem'} fontWeight={600} ml={1.5} lineHeight={'1.5rem'} textAlign={'center'}>
                      <MarkdownText source={data.subheader} />
                    </Box>
                  </Box>
                </Hidden>
              )}
            </>
          )}
        >
          {data.mobile_bullet_header && (
            <Hidden mdUp>
              <Box mb={3}>
                <Typography variant={'h5'} align={'center'}>
                  {data.mobile_bullet_header}
                </Typography>
              </Box>
            </Hidden>
          )}

          {data.bullets.map(({ text }, index) => (
            <ProductDescriptionItem key={index}>
              {text}
            </ProductDescriptionItem>
          ))}

          {data.return_artwork && (
            <Hidden smDown>
              <ReturnArtTip elevation={0}>
                You can choose to have your art returned in the next step.
              </ReturnArtTip>
            </Hidden>
          )}
        </ProductDescriptionBox>
      </ProductDescriptionSection>

      {data.how_it_works && (
        <HowItWorks
          title={data.how_it_works.title}
          steps={data.how_it_works.items}
          variant={data.how_it_works.variant}
        >
          <Box justifyContent={'center'} display={'flex'} pt={10}>
            <Button
              color={'primary'}
              component={Link}
              href={checkoutLink}
              size={'large'}
              style={{ paddingLeft: 72, paddingRight: 72 }}
              variant={'contained'}
            >
              Continue
            </Button>
          </Box>
        </HowItWorks>
      )}
      <ContainedSection
        disableGutters
        bgcolor={theme.colors.bg.pink_light}
        title={
          <Typography variant={'h2'} align={'center'} className={classes.reviewsTitle}>
            20 Million Memories Preserved, 500,000 Customers Served, and Counting
          </Typography>
        }
        containerClassName={classes.reviewsContainer}
        titleWrapClassName={classes.titleWrap}
        id={'reviews'}
      >
        <TrustIndexReviews
          // NOTE: Key is used to force component to unmount and load a correct mobile / desktop script
          key={isMobile ? 'mobile-trustindex-widget' : 'desktop-trustindex-widget'}
          variant={isMobile ? TRUSTINDEX_VARIANT.GET_STARTED_MOBILE : TRUSTINDEX_VARIANT.GET_STARTED_DESKTOP}
          className={classes.trustIndexWrap}
        />
        <Box justifyContent={'center'} display={'flex'}>
          <Button
            color={'primary'}
            component={Link}
            href={checkoutLink}
            size={'large'}
            style={{ paddingLeft: 72, paddingRight: 72 }}
            variant={'contained'}
          >
            Continue
          </Button>
        </Box>
      </ContainedSection>
    </Box>
  )
}

GetStarted.propTypes = {
  productData: PropTypes.object,
  params: PropTypes.object,
  productLinks: PropTypes.object,
}

export default GetStarted
