import { useEffect, useState } from 'react'

import {
  useStoreActions,
  useStoreRehydrated,
  useStoreState,
} from 'easy-peasy'

import { BOX_PRODUCT_TYPE } from 'artkive/stores/product.constants'

function usePromo({ formType, productName = BOX_PRODUCT_TYPE }) {
  const actions = useStoreActions(({ promoStore }) => promoStore)
  const rehydrated = useStoreRehydrated()
  const state = useStoreState(({ promoStore }) => promoStore)

  const [options, setOptions] = useState({ formType, product: productName })

  const dispatchEmail = async (args = {}) => await actions.sendPromoEmail({ ...options, ...args })

  useEffect(() => {
    setOptions({ formType, product: productName })
  }, [formType, productName])

  return {
    dispatchEmail,
    promoActions: { ...actions, dispatchEmail },
    promoState: state,
    rehydrated,
  }
}

export default usePromo
