import React from 'react'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { newColors } from 'artkive/utils/theme'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: newColors.violet[100],
    color: newColors.violet[700],
    fontWeight: 500,
    fontSize: 'inherit',
    lineHeight: 'inherit',
    whiteSpace:'nowrap',
    '&:hover': {
      backgroundColor: newColors.violet[400],
    },
  },
}))

const AccentLink = ({ className, href, children, ...rest }) => {
  const classes = useStyles()
  return (
    <Button
      variant={'text'}
      href={href}
      classes={classes}
      className={className}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default AccentLink
