import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import Input from 'common/components/Form/Input'
import SectionGrid, { GridSpacer } from 'artkive/components/Checkout/SectionGrid'
import SectionHeader from 'artkive/components/Checkout/SectionHeader'
import ContactInformationFragment from 'artkive/components/Checkout/Sections/ContactInformationFragment'
import PersonalNameFragment from 'artkive/components/Checkout/Sections/PersonalNameFragment'
import ShippingAddressFragment from 'artkive/components/Checkout/Sections/ShippingAddressFragment'
import ClearTextField from 'artkive/components/Forms/ClearTextField'
import PhoneNumberField from 'artkive/components/Forms/PhoneNumberField'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import validations from 'artkive/utils/validations'

import RecipientSelector from './RecipientSelector'

export const recipientContactFields = ['recipientFirstName', 'recipientLastName', 'recipientPhone', 'recipientEmail']

const RecipientInformationFragment = ({ options }) => {
  const { sendGiftToRecipient } = useVariableState(options.type, options.uuid)
  const { setSendGiftToRecipient } = useVariableActions(options.type, options.uuid)

  return (
    <>
      <SectionHeader>{options.gift.recipient_info_title}</SectionHeader>

      <SectionGrid>
        <Grid item={true} md={6} xs={12}>
          <Input
            name={'recipientInformation.recipientFirstName'}
            rules={validations.firstName}
            component={ClearTextField}
            label={'Recipient First Name'}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Input
            name={'recipientInformation.recipientLastName'}
            rules={validations.lastName}
            component={ClearTextField}
            label={'Recipient Last Name'}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Input
            name={'recipientInformation.recipientEmail'}
            type={'email'}
            rules={validations.email}
            label={'Recipient Email'}
            autoComplete={'none'}
            secondaryHelperText={options.gift.recipient_warning_text}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Input
            name={'recipientInformation.recipientPhone'}
            component={PhoneNumberField}
            label={'Recipient Phone Number (optional)'}
            autoComplete={'none'}
          />
        </Grid>
      </SectionGrid>

      <SectionHeader note={'Your personal message will be printed and included in the box.'}>
        {options.gift.personal_note_title}
      </SectionHeader>

      <SectionGrid>
        <Grid item={true} md={6} xs={12}>
          <Input name={'recipientInformation.giftMessageTo'} label={'To'} inputProps={{ maxLength: 255 }} />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <Input name={'recipientInformation.giftMessageFrom'} label={'From'} inputProps={{ maxLength: 255 }} />
        </Grid>
        <Grid item={true} xs={12}>
          <Input
            name={'recipientInformation.giftMessage'}
            multiline={true}
            minRows={4}
            label={'Personal Message'}
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </SectionGrid>

      <RecipientSelector
        title={options.gift.delivery_target_title}
        value={sendGiftToRecipient}
        onChange={setSendGiftToRecipient}
      />

      {sendGiftToRecipient ? (
        <>
          <SectionHeader>{options.gift.recipient_address_title}</SectionHeader>
          <ShippingAddressFragment countries={options.countries} prefix={'recipientInformation.'} />
        </>
      ) : (
        <>
          <SectionHeader>Your Contact Information</SectionHeader>

          <PersonalNameFragment />
          <GridSpacer />
          <ContactInformationFragment />

          <SectionHeader>Your Shipping Address</SectionHeader>
          <ShippingAddressFragment key={'purchaser'} countries={options.countries} />
        </>
      )}
    </>
  )
}

RecipientInformationFragment.propTypes = {
  options: PropTypes.object.isRequired,
}

export default RecipientInformationFragment
