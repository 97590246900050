import React from 'react'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import muiTheme from 'artkive/utils/muiTheme'

export const breakPoint = muiTheme.breakpoints.down('sm')

export const useFormStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'grid',
    gridAutoRows: 'min-content auto min-content',
    gap: theme.spacing(2.75),

    '& .MuiButton-root': {
      padding: theme.spacing(1.625, 2.75),
    },
  },

  formInner: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateRows: 'min-content min-content auto',
    minHeight: 364,

    [breakPoint]: {
      minHeight: 412,
    },
  },

  form: {
    display: 'grid',
    gap: theme.spacing(2.25),
    gridAutoRows: theme.spacing(6.5),
    gridTemplateRows: theme.spacing(6.5),
    alignSelf: 'end',
    alignItems: 'center',

    [breakPoint]: {
      gap: theme.spacing(1.75),
      gridAutoRows: theme.spacing(7),
      gridTemplateRows: `repeat(2, min-content)`,
    },
  },

  textField: {
    '& .MuiInputBase-root': {
      overflow: 'hidden',
    },

    '& .MuiInputBase-input': {
      padding: theme.spacing(2.05, 1.75),

      [breakPoint]: {
        padding: theme.spacing(1.5, 1.75),
      },
    },

    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: [
        `translate(${theme.spacing(1.5)}px, ${theme.spacing(2.25)}px)`,
        'scale(1)',
      ].join(' '),

      [breakPoint]: {
        transform: [
          `translate(${theme.spacing(1.5)}px, ${theme.spacing(1.75)}px)`,
          'scale(1)',
        ].join(' '),
      },
    },
  },
}))

export const Title = ({ children }) => {
  const classes = makeStyles(() => ({
    root: {
      fontSize: 28,
      [breakPoint]: {
        marginTop: '3rem',
        fontSize: 'revert',
      },
    },
  }))()

  return (
    <Typography variant={'h1'} align={'center'} className={classes.root}>
      {children}
    </Typography>
  )
}

export const SubTitle = ({ children }) => {
  return (
    <span style={{ textAlign: 'center', lineHeight: '160%' }}>{children}</span>
  )
}
