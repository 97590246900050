import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'

import ContainedSection from '../ContainedSection'

import HowItWorksIconVariant from './HowItWorksIconVariant'
import HowItWorksImageVariant from './HowItWorksImageVariant'

const HowItWorks = ({
  steps,
  title,
  variant,
  children,
  ...rest
}) => {
  const GridVariantComponent = useMemo(() => {
    switch (variant) {
      case 'image':
        return HowItWorksImageVariant
      default:
        return HowItWorksIconVariant
    }
  }, [variant])

  return (
    <ContainedSection
      title={(
        <Typography variant={'h2'} align={'center'}>
          {title}
        </Typography>
      )}
      {...rest}
    >
      <GridVariantComponent steps={steps} />

      {children}
    </ContainedSection>
  )
}

HowItWorks.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['image', 'icon']),
  children: PropTypes.node,
}

export default HowItWorks
