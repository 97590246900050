import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import SummaryDetailsBox from 'artkive/components/Checkout/SummaryDetailsBox'
import { useVariableState } from 'artkive/hooks/useVariableStore'
import { GIFT_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/giftCard.store'

const useStyles = makeStyles(({ palette }) => ({
  content: {
    color: palette.common.black,
    fontWeight: 500,
  },
}))

const ContactInformationSummary = ({ product }) => {
  const classes = useStyles()
  const { information } = useVariableState(product.type, product.uuid)

  return (
    <SummaryDetailsBox
      title={'Your Contact Information'}
      editHref={`/${GIFT_CHECKOUT_STEPS.INFORMATION}`}
    >
      <Typography variant={'body1'} className={classes.content}>
        {information.firstName} {information.lastName}<br />
        {[information.email, information.phone].filter(Boolean).join(', ')}<br />
      </Typography>
    </SummaryDetailsBox>
  )
}

ContactInformationSummary.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ContactInformationSummary
