import { HELP_EMAIL } from 'artkive/utils/company'

import Process from 'images/ecom/process/Process1.png'
import Process2 from 'images/ecom/process/Process2.png'
import Process3 from 'images/ecom/process/Process3.png'
import Process4 from 'images/ecom/process/Process4.png'

const MOSAIC_SIZES = {
  CLASSIC: 25,
  SMALL: 9,
}

export const FAQS = [
  {
    id: 1,
    title: 'Are there different color options for the mosaic frame?',
    body: 'Currently, the mosaic is only available in a white wooden frame with white matting.',
  },
  {
    id: 2,
    title: 'What size are the framed mosaics?',
    body: 'The regular mosaic frame dimensions are 26"x26", with a 24"x24" gallery print. The smaller mosaic is 14"x14" with a 12"x12" gallery print.',
  },
  {
    id: 3,
    title: 'Can I put my child\'s name in my framed mosaic?',
    body: 'Every artist deserves to be recognized! We can put your child’s name at the bottom right corner of their mosaic, at your request.',
  },
  {
    id: 4,
    title: 'How long does the mosaic process take?',
    body:
      `Once we receive your art, it will take about 4-5 weeks to send you a mosaic proof to
review. Once you approve your mosaic design, it takes 1-2 weeks to print, custom frame, package,
and ship your piece. Ask us about rushing your mosaic order at
[${HELP_EMAIL}](mailto:${HELP_EMAIL}) (please note there may be additional fees involved).`,
  },
]

export const homeMosaicsProductBullets = [
  { text: `Showcases ${MOSAIC_SIZES.CLASSIC} or ${MOSAIC_SIZES.SMALL} of your images depending on size` },
  { text: '26"x26" or 14"x14" solid wood, gallery frame and professional matting' },
  { text: 'Professional photography, editing of each piece of art, and a digital proof to review' },
  { text: 'Access to your digital images on our website and the iOS app.' },
]

export const mosaicsProductBullets = [
  ...homeMosaicsProductBullets,
  // {
  //   text: (
  //     <>
  //       <strong>Additionally!</strong> Access to the digital version of all your images on the website, and iOS App
  //     </>
  //   )
  // },
]

export const howItWorksSteps = [
  {
    image: Process,
    title: 'Order an Artkive Box & Send Us Your Art',
    body: 'We\'ll send you a box to fill with all your favorite art pieces and an order form to select a Book, Mosaic, or both.',
  },
  {
    image: Process2,
    title: 'Photography & Transform',
    body: 'We\'ll individually photograph every piece of artwork in your box and then edit each image to show off the artwork in its best light.',
  },
  {
    image: Process3,
    title: 'Pay For the Book or Mosaic',
    body: 'You\'ll receive an invoice based on the number of pieces you\'ve sent and the product you want to transform them into.',
  },
  {
    image: Process4,
    title: 'Design & Enjoy',
    body: 'Our creative team will thoughtfully design your Book or Mosaic and send you a proof to review, customize and approve for print.',
  },
]
