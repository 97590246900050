import React from 'react'
import PropTypes from 'prop-types'

import { useController } from 'react-hook-form'
import { FormHelperText, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { buildErrorMessage } from 'common/utils/validation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const StyledHelperText = withStyles(({ breakpoints, spacing }) => ({
  root: {
    marginLeft: spacing(1.5),
    margin: spacing(0, 0, 0.5),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0.5),
    },
  },
}))(FormHelperText)

const typeMapping = {
  text: TextField,
  email: TextField,
}

const mobileMargin = 'dense'

/**
 * New abstract component for ecom form inputs
 */
const Input = ({
  control,
  type = 'text',
  name,
  rules,
  component,
  label,
  helperText,
  secondaryHelperText,
  defaultValue = '',
  onBlur,
  ...rest
}) => {
  const Component = component || typeMapping[type] || typeMapping.text

  const isMobile = useMobileBreakpoint()
  const inputMargin = isMobile ? mobileMargin : undefined

  const {
    field,
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue })

  const helperTextValue = error ? buildErrorMessage(label || name, error) : helperText

  return (
    <>
      <Component
        {...field}
        inputRef={field.ref}
        type={type}
        error={!!error}
        helperText={helperTextValue}
        label={label}
        variant={'outlined'}
        fullWidth={true}
        margin={inputMargin}
        onBlur={
          onBlur
            ? (event) => onBlur(event, field.onBlur)
            : field.onBlur
        }
        {...rest}
      />
      {secondaryHelperText && <StyledHelperText>{secondaryHelperText}</StyledHelperText>}
    </>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object, // optional if FormProvider is used
  type: PropTypes.oneOf(['text', 'email']),
  rules: PropTypes.object,
  component: PropTypes.elementType,
  label: PropTypes.string,
  helperText: PropTypes.string,
  defaultValue: PropTypes.any,
  onBlur: PropTypes.func,
  secondaryHelperText: PropTypes.string,
}

export default Input
