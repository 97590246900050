import React from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

const isAllowed = (values) => {
  const [month] = values.formattedValue.split('/')

  return !(+month < 0 || +month > 12)
}

const ExpDate = ({ inputRef, ...rest }) => ((
  <NumberFormat
    {...rest}
    getInputRef={inputRef}
    format={'##/##'}
    inputMode={undefined}
    isAllowed={isAllowed}
  />
))

ExpDate.propTypes = {
  inputRef: PropTypes.func,
}

export default ExpDate
