import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Controller, useFormContext } from 'react-hook-form'
import { FormControl, Grid, InputLabel } from '@material-ui/core'

import Input from 'common/components/Form/Input'
import Select from 'common/components/Form/Select'
import allCountries from 'common/utils/data/countries'
import states from 'common/utils/data/states'
import Address from 'artkive/components/Address'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { USA_COUNTRY_KEY } from 'artkive/utils/addressValidationService'
import validations from 'artkive/utils/validations'

import SectionGrid from '../SectionGrid'

export const fields = ['address', 'address2', 'country', 'state', 'city', 'zipCode']

const ShippingAddressFragment = ({ countries, prefix = '' }) => {
  const isMobile = useMobileBreakpoint()
  const { setValue, unregister, watch, getValues } = useFormContext()
  const watchedCountry = watch(`${prefix}country`)

  const countryOptions = useMemo(() => {
    return countries && allCountries.filter(({ value }) => countries?.includes(value))
  }, [countries])
  const selectedCountry = countryOptions ? watchedCountry : USA_COUNTRY_KEY

  const handleAddressChange = (address) => {
    for (const field in address) {
      setValue(`${prefix}${field}`, address[field], {
        shouldValidate: true,
      })
    }
  }

  // TODO: maybe we don't need as we unregister each input separately via props
  useEffect(() => {
    return () => unregister(fields.map((field) => `${prefix}${field}`))
  }, [prefix])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (countryOptions?.length === 1) {
        setValue(`${prefix}country`, countryOptions[0].value)
      }
    }, 0)
    return () => clearTimeout(timeout)
  }, [countryOptions, prefix])

  const inputMargin = isMobile ? 'dense' : undefined

  return (
    <SectionGrid>
      {countryOptions && (
        <Grid item xs={12}>
          <FormControl variant={'outlined'} fullWidth={true} margin={inputMargin}>
            <InputLabel>Country</InputLabel>
            <Controller
              name={`${prefix}country`}
              defaultValue={''}
              shouldUnregister
              render={({ fieldState: { error }, field }) => (
                <Select
                  {...field}
                  error={error}
                  label={'Country'}
                  autoComplete={'shipping country'}
                  options={countryOptions}
                />
              )}
              rules={validations.country}
            />
          </FormControl>
        </Grid>
      )}

      <Grid item xs={12}>
        <Controller
          name={`${prefix}address`}
          defaultValue={''}
          shouldUnregister
          rules={validations.address}
          render={({ fieldState: { error }, field: { onChange, value, ...field } }) => (
            <Address
              InputProps={{ margin: inputMargin }}
              error={!!error}
              helperText={error?.message}
              onChange={handleAddressChange}
              defaultValue={value}
              {...field}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Input
          name={`${prefix}address2`}
          shouldUnregister={true}
          label={'Apartment, Suite, Locality, etc.'}
          autoComplete={'shipping address-line2'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <Input
          name={`${prefix}city`}
          shouldUnregister={true}
          rules={validations.city}
          label={'City/Town'}
          autoComplete={'shipping address-level2'}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControl variant={'outlined'} fullWidth={true} margin={inputMargin}>
          <InputLabel>State/Province/Region</InputLabel>
          <Controller
            name={`${prefix}state`}
            defaultValue={''}
            shouldUnregister
            render={({ fieldState: { error }, field }) => (
              <Select
                {...field}
                error={error}
                label={'State/Province/Region'}
                autoComplete={'shipping address-level1'}
                disabled={countryOptions && !selectedCountry}
                options={(states[selectedCountry] ?? [])}
              />
            )}
            rules={validations.state}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6} md={4}>
        <Input
          name={`${prefix}zipCode`}
          shouldUnregister={true}
          rules={validations.zipCode(getValues)}
          label={'ZIP/Postal Code'}
          autoComplete={'shipping postal-code'}
          // NOTE: this is required for Zip5+4
          inputProps={{ maxLength: 10 }}
        />
      </Grid>
    </SectionGrid>
  )
}

ShippingAddressFragment.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.string),
  prefix: PropTypes.string,
}

export default ShippingAddressFragment
