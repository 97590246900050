import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { Check } from '@material-ui/icons'

const BulletPointsList = ({ bullets }) => {
  return (
    <Box>
      {bullets.map((text, index) => (
        <Box display={'flex'} my={2} key={index}>
          <Check color={'primary'} />&nbsp;
          <Typography>
            {text}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

BulletPointsList.propTypes = {
  bullets: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
}

export default BulletPointsList
