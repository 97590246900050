import React, { memo, useEffect, useRef, useState } from 'react'

import { Box } from '@material-ui/core'

export const TRUSTINDEX_VARIANT = {
  HOME: 'home',
  GET_STARTED_MOBILE: 'get-started-mobile',
  GET_STARTED_DESKTOP: 'get-started-desktop',
  GET_STARTED_SUBTITLE: 'get-started-subtitle',
}

const TRUSTINDEX_WIDGET_IDS = {
  [TRUSTINDEX_VARIANT.HOME]: '9723b41373630430dd5bfbb1d7',
  [TRUSTINDEX_VARIANT.GET_STARTED_MOBILE]: 'd540da936893140855461be547f',
  [TRUSTINDEX_VARIANT.GET_STARTED_DESKTOP]: 'fa7f73e36b3d109d37566836576',
  [TRUSTINDEX_VARIANT.GET_STARTED_SUBTITLE]: '3a98cd33680c2336a25675e8260',
}

export const TrustIndexReviewsBox = memo(({ variant = TRUSTINDEX_VARIANT.HOME, className }) => {
  const wrapperRef = useRef()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://cdn.trustindex.io/loader.js?${TRUSTINDEX_WIDGET_IDS[variant]}`
    script.async = true
    script.defer = true
    wrapperRef.current.appendChild(script)
  }, [variant])

  return (
    <Box ref={wrapperRef} className={className} />
  )
})

const TrustIndexReviews = ({ variant, className }) => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    // TypeError: D.animate is not a function
    if (!('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent))) {
      console.log('polyfill animate')

      const script = document.createElement('script')
      script.src = 'https://cdn.jsdelivr.net/npm/web-animations-js@2.3.2'
      script.onload = () => {
        console.log('polyfill is ready')
        setIsReady(true)
      }
      document.head.appendChild(script)
    } else {
      setIsReady(true)
    }
  }, [])

  if (!isReady)
    return null

  return (
    <TrustIndexReviewsBox key={'trustscript'} variant={variant} className={className} />
  )
}

export default memo(TrustIndexReviews)
