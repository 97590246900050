import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core'

import { TrackButton, useTracker } from 'artkive/components/Tracker'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import { fetchBoxPrice } from 'artkive/stores/ecom/api/fetchPrice'
import routes from 'artkive/utils/routes'
import validations from 'artkive/utils/validations'

const Redeem = ({ isMobile, isOpen, handleClose }) => {
  const [error, setError] = useState()
  const [trackAction] = useTracker()

  const { formState: { errors }, handleSubmit, register } = useForm({
    defaultValues: {
      promo: '',
    },
  })

  const onSubmit = async ({ promo }) => {
    try {
      console.log('start')
      await trackAction(GIFT_CARDS.REDEEM_GIFT_CARD_CLICK)

      const data = await fetchBoxPrice({ quantity: 1, code: promo })

      if (data.promo_discount) {
        window.location.href = `${routes.checkout}?promo=${promo}`
      } else {
        setError('Invalid code please try again')
      }
    } catch (error) {
      setError('Invalid code please try again')
      return { error }
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby={'form-dialog-title'}>
      <Box textAlign={'center'} p={isMobile ? 2 : 6}>
        <DialogContent>
          <Typography align={'center'} variant={isMobile ? 'h6' : 'h3'}>Redeem a Gift Card</Typography>
          <Box mb={4} mt={2} textAlign={'center'}>
            <Typography >Please enter the code on the back of your card</Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={!!errors.promo || !!error}
              fullWidth={true}
              helperText={errors?.promo?.message || error}
              type={'text'}
              autoFocus
              label={'Gift Card Code'}
              name={'promo'}
              size={'medium'}
              variant={'outlined'}
              {...register('promo', validations.promo)}
            />
            <Box mt={4} textAlign={'center'}>
              <TrackButton align={'center'} color={'primary'} fullWidth={true} type={'submit'} variant={'contained'}>
                Redeem
              </TrackButton>
            </Box>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

Redeem.propTypes = {
  isOpen: PropTypes.bool,
  isMobile: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default Redeem
