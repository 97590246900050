import React, { useMemo } from 'react'

import {
  Box,
  Container,
  Grid, Hidden,
  Link,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Facebook,
  Instagram,
  Pinterest,
  YouTube,
} from '@material-ui/icons'

import {
  GET_BOX_CLICK,
  NAVIGATION,
  REFERRAL_MODAL,
  SOCIAL_MEDIA_CLICK,
} from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import useReferralCta from 'artkive/hooks/useReferralCta'
import { OLD_HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'
import theme, { newColors } from 'artkive/utils/theme'

import getIOSApp from 'images/ecom/footer/getIOSApp.svg'
import TikTokIconSrc from 'images/ecom/social/tiktok-icon-small.svg'

import { TrackButton, TrackLink } from '../Tracker'

const MenuLink = withStyles({
  root: {
    color: newColors.grey[700],
    marginRight: '1rem',
    padding: 0,
    fontWeight: 600,
    cursor: 'pointer',
  },
})(TrackLink)

const FooterLink = ({ children, ...props }) => (
  <Box mt={2}>
    <MenuLink {...props}>{children}</MenuLink>
  </Box>
)

const MenuHeader = withStyles(({ spacing }) => ({
  root: {
    color: theme.colors.black,
    letterSpacing: '1px',
    fontSize: '13px',
    fontWeight: '600',
    textTransform: 'uppercase',
    marginBottom: spacing(1),
  },
}))(Typography)

const useStyles = makeStyles(({ breakpoints }) => ({
  download: {
    marginLeft: 28,
    [breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    '& > img': {
      width: 120,
      height: 40,
    },
  },
  downloadSubLabel: {
    fontSize: 12,
  },
  subtitle: {
    maxWidth: 230,
  },
  social: {
    display: 'flex',
    justifyContent: 'center',

    '&>:not(:first-child)': {
      marginLeft: 16,
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()
  const openReferralModal = useReferralCta()

  const footerSectionOptions = useMemo(() => ({
    section: 'footer',
  }), [])

  return (
    <>
      <Paper elevation={0} style={{ position: 'relative', zIndex: '101' }}>
        <Container>
          <Box pb={4} pt={8}>
            <Grid container spacing={4}>
              <Grid item xs={6} sm>
                <Box textAlign={'left'}>
                  <MenuHeader>Artkive</MenuHeader>
                  <FooterLink
                    href={routes.pricing}
                    trackerEvent={NAVIGATION.PRICING_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Pricing
                  </FooterLink>
                  <FooterLink
                    href={routes.aboutUs}
                    trackerEvent={NAVIGATION.ABOUT_US_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    About Us
                  </FooterLink>
                  <FooterLink
                    href={routes.ourProcess}
                    trackerEvent={NAVIGATION.OUR_PROCESS_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Our Process
                  </FooterLink>
                  <FooterLink
                    href={routes.plans}
                    trackerEvent={NAVIGATION.MEMBERSHIP_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Membership
                  </FooterLink>
                  <FooterLink
                    key={'get-20'}
                    onClick={(event) => openReferralModal(event)}
                    trackerEvent={REFERRAL_MODAL.CTA_CLICK}
                  >
                    Get $20
                  </FooterLink>
                </Box>
              </Grid>
              <Grid item xs={6} sm>
                <Box textAlign={'left'}>
                  <MenuHeader>Products</MenuHeader>

                  <FooterLink
                    href={routes.books}
                    trackerEvent={NAVIGATION.ART_BOOK_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Art Books
                  </FooterLink>
                  <FooterLink
                    href={routes.mosaics}
                    trackerEvent={NAVIGATION.MOSAIC_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Framed Mosaics
                  </FooterLink>

                  <FooterLink
                    href={routes.giftCards}
                    trackerEvent={NAVIGATION.GIFT_CARD_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Gift Cards
                  </FooterLink>

                  <FooterLink href={routes.international} target={'_blank'}>Artkive International</FooterLink>
                </Box>
              </Grid>
              <Grid item xs={6} sm>
                <Box textAlign={'left'}>
                  <MenuHeader>Explore Kive Co.</MenuHeader>
                  <FooterLink href={routes.cardsByArtkive} target={'_blank'}>Cards by Artkive</FooterLink>
                  <FooterLink href={routes.photosByArtkive} target={'_blank'}>Photos by Artkive</FooterLink>
                  <FooterLink href={routes.lifekive} target={'_blank'}>Lifekive</FooterLink>
                </Box>
              </Grid>
              <Grid item xs={6} sm md={3}>
                <Box textAlign={'left'}>
                  <MenuHeader>Have Questions?</MenuHeader>
                  <FooterLink
                    href={`mailto:${OLD_HELP_EMAIL}`}
                    trackerEvent={NAVIGATION.EMAIL_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Email <strong style={{ color: newColors.grey[900] }}>{OLD_HELP_EMAIL}</strong>
                  </FooterLink>
                  <FooterLink
                    href={routes.faqs}
                    target={'_blank'}
                    trackerEvent={NAVIGATION.FAQS_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    FAQs
                  </FooterLink>
                  <FooterLink
                    href={routes.contact}
                    trackerEvent={NAVIGATION.CONTACT_US_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Contact Us
                  </FooterLink>
                  <FooterLink href={routes.accountOrders}>My Orders</FooterLink>
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <MenuHeader align={isMobile ? 'center' : 'right'} mb={1.5}>
                  Get Started
                </MenuHeader>
                <Box mt={1.5} mb={2} display={'flex'} justifyContent={isMobile ? 'center' : 'right'}>
                  <Typography
                    variant={'caption'}
                    component={'div'}
                    align={isMobile ? 'center' : 'right'}
                    color={'textPrimary'}
                    className={classes.subtitle}
                  >
                    Artkive Box includes everything you need to send in your artwork
                  </Typography>
                </Box>
                <Box textAlign={isMobile ? 'center' : 'right'} mt={2.5}>
                  <TrackButton
                    color={'primary'}
                    href={routes.boxCheckout}
                    size={'large'}
                    variant={'contained'}
                    trackerEvent={GET_BOX_CLICK}
                    trackerOptions={footerSectionOptions}
                  >
                    Get My Box
                  </TrackButton>
                </Box>
              </Grid>
            </Grid>
            <Box display={'flex'} flexDirection={isMobile ? 'column-reverse' : 'column'} textAlign={'center'}
              justifyContent={'center'}>
              <Box display={'inline-flex'} alignItems={'center'} justifyContent={'center'} mt={4}
                flexDirection={isMobile ? 'column' : 'row'}>
                <MenuHeader mt={1} component={'div'} mr={3}>Get The App</MenuHeader>
                <a href={routes.ios} className={classes.download} target={'_blank'} rel={'noreferrer'}>
                  <img src={getIOSApp} />
                </a>
              </Box>

              <Box display={'flex'} justifyContent={'center'} mt={4.5} className={classes.social}>
                <TrackLink
                  color={'secondary'}
                  href={routes.instagram}
                  target={'_blank'}
                  trackerEvent={SOCIAL_MEDIA_CLICK}
                  trackerOptions={{ ...footerSectionOptions, smType: 'instagram' }}
                >
                  <Instagram fontSize={'small'} />
                </TrackLink>
                <TrackLink
                  color={'secondary'}
                  href={routes.facebook}
                  target={'_blank'}
                  trackerEvent={SOCIAL_MEDIA_CLICK}
                  trackerOptions={{ ...footerSectionOptions, smType: 'facebook' }}
                >
                  <Facebook fontSize={'small'} />
                </TrackLink>
                <TrackLink
                  color={'secondary'}
                  href={routes.pinterest}
                  target={'_blank'}
                  trackerEvent={SOCIAL_MEDIA_CLICK}
                  trackerOptions={{ ...footerSectionOptions, smType: 'pinterest' }}
                >
                  <Pinterest fontSize={'small'} />
                </TrackLink>
                <TrackLink
                  color={'secondary'}
                  href={routes.tiktok}
                  target={'_blank'}
                  trackerEvent={SOCIAL_MEDIA_CLICK}
                  trackerOptions={{ ...footerSectionOptions, smType: 'tiktok' }}
                >
                  <img src={TikTokIconSrc} />
                </TrackLink>
                <TrackLink
                  color={'secondary'}
                  href={routes.youtube}
                  target={'_blank'}
                  trackerEvent={SOCIAL_MEDIA_CLICK}
                  trackerOptions={{ ...footerSectionOptions, smType: 'youtube' }}
                >
                  <YouTube fontSize={'small'} />
                </TrackLink>
              </Box>
            </Box>

            <Box mt={4} textAlign={'center'} fontSize={'14px'} color={'text.secondary'} lineHeight={1.4}>
              &copy; {(new Date().getFullYear())} Artkive All Rights Reserved.{' '}
              <Hidden mdUp><br /></Hidden>
              <Link href={routes.privacy}>
                <strong>Privacy Policy</strong>
              </Link>
              {' | '}
              <Link href={routes.tou}><strong>Terms of Use</strong></Link>
            </Box>
          </Box>
        </Container>
      </Paper>
    </>
  )
}

export default Footer
