/*
ECOM CONTEXT
core goal is to move the checkout, giftcard and
plans store into this central store and replace
the lower level uses with this single store.

this context allows us to move items here as we
maintain them and move towards a consolidated store
*/

import React, { memo, useEffect, useRef, useState } from 'react'

import { useStoreActions, useStoreState } from 'easy-peasy'
import staticLocationHook from 'wouter/static-location'
import browserLocationHook from 'wouter/use-location'

import useRehydratedEffect from 'common/components/NoSsr/useRehydratedEffect'
import EmailListForm from 'artkive/components/EmailListForm'
import Footer from 'artkive/components/Footer'
import Loader from 'artkive/components/Loader'
import ModalManager from 'artkive/components/Modals/ModalManager'
import Navbar from 'artkive/components/Navbar'
import { TrackerContextProvider } from 'artkive/components/Tracker'
import store from 'artkive/stores/ecom/ecom.store'
import {
  BOX_PRODUCT_TYPE,
  DEFAULT_PRODUCT_ID,
  PACKAGE_PRODUCT_TYPE,
  PRODUCT_TYPE_QUERY_FILED,
  PROMO_QUERY_FILED,
} from 'artkive/stores/product.constants'
import * as track from 'artkive/utils/tracker'

const isSSR = typeof window === 'undefined'
const useLocation = isSSR ? staticLocationHook('/') : browserLocationHook

const defaultTrackingMetaCreator = () => ({})

const withHydration = (Component, options) => memo((props = {}) => {
  const { trackingMetaCreator = defaultTrackingMetaCreator, injectStoreModel } = options

  injectStoreModel && injectStoreModel(store, props)

  const { setEnv } = useStoreActions(({ envStore }) => envStore)
  const { setPromoUrl, setTracking } = useStoreActions(({ promoStore }) => promoStore)
  // const { setBoxCheckoutAddonsUrl } = useVariableActions(BOX_PRODUCT_TYPE, props.params?.uuid)
  const { setUser } = useStoreActions(({ userStore }) => userStore)
  const setBoxCheckoutAddonsUrl = useStoreActions(
    ({ setBoxCheckoutAddonsUrl }) => setBoxCheckoutAddonsUrl,
  )

  const { ui } = useStoreState(({ uiStore }) => uiStore)
  const tracking = useStoreState(({ promoStore }) => promoStore.tracking)
  const [stateEnv] = useState(props.env)
  const [stateUser] = useState(props.user)
  const loadedPagePath = useRef(null)

  const [pathname] = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const productTrackingData = trackingMetaCreator(props)

  useRehydratedEffect(() => {
    const params = new URLSearchParams(location.search)
    const queryPromoCodeValue = params.get(PROMO_QUERY_FILED)
    let queryProductType = params.get(PRODUCT_TYPE_QUERY_FILED)
    // NOTE: in order
    setEnv(stateEnv)

    if (!queryProductType) {
      queryProductType = pathname.includes('packages') ? PACKAGE_PRODUCT_TYPE : BOX_PRODUCT_TYPE
    }

    setPromoUrl({
      code: queryPromoCodeValue,
      product_id: productTrackingData.productId || DEFAULT_PRODUCT_ID,
      productName: queryProductType,
    })

    setBoxCheckoutAddonsUrl()
    setUser(stateUser)

    loadedPagePath.current = pathname
    const trackingParams = track.extractUtmParameters(params) || tracking
    setTracking(trackingParams)
    track.load({ user: stateUser, ...trackingParams, ...productTrackingData })
  }, [])

  // NOTE: currently most of the pages use full page load for navigation
  useRehydratedEffect(() => {
    if (pathname !== loadedPagePath.current) {
      loadedPagePath.current = pathname
      track.page({ ...tracking, ...productTrackingData })
    }
  }, [pathname])

  const isEmailGrubberAvailable =
      !props.env.disableFooter &&
      props.params?.action !== 'checkout_confirmation'

  return (
    <TrackerContextProvider productTrackingData={productTrackingData}>
      {!stateEnv.disableNav && <Navbar />}

      <Loader show={ui.loader} />

      <Component {...props} />

      {isEmailGrubberAvailable && <EmailListForm zIndex={100} />}

      {!stateEnv.disableFooter && <Footer />}
      <ModalManager />
    </TrackerContextProvider>
  )
})

export default withHydration
