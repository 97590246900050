import { useCallback, useEffect, useMemo } from 'react'

import { get, useFormContext } from 'react-hook-form'

import memoizeDebounce from 'common/utils/memoizeDebounce'

import { flattenObject } from '../utils/flattenObject'

const getName = (name) => name

function useFormToState(state, action, { watch } = {}, { only = null, except = null } = {}) {
  const formWatch = watch || useFormContext().watch
  const debouncedSetInformation = useCallback(memoizeDebounce((name, value) => {
    action({ [name]: value })
  }, 300, getName), [action])

  const stateKeys = useMemo(() => {
    return Object.keys(flattenObject(state))
  }, [state])

  useEffect(() => {
    if (only?.length === 0) return

    const fields = Object.keys(flattenObject(state))
    const subscription = formWatch((value, { name }) => {
      if (except?.includes(name) || (only && !only.includes(name))) return

      if ((only?.includes(name) || !only) && fields.includes(name) && get(state, name) !== get(value, name)) {
        debouncedSetInformation(name, get(value, name))
      }
    })

    return () => subscription.unsubscribe()
  }, [formWatch, state, debouncedSetInformation, only, except, stateKeys])
}

export default useFormToState
