import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { makeStyles } from '@material-ui/core/styles'

import theme from 'artkive/utils/theme'

const useMenuItemStyles = makeStyles((muiTheme) => ({
  root: {
    backgroundColor: 'white',
    color: muiTheme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 20px',
    lineHeight: 1.75,
    fontWeight: 500,
    verticalAlign: 'middle',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: theme.colors.white.dark,
      color: theme.colors.secondary.dark,
    },
    [muiTheme.breakpoints.down('lg')]: {
      padding: '12px',
      fontSize: '1rem',
    },
    '&$active': {
      backgroundColor: theme.colors.white.dark,
      color: theme.colors.secondary.dark,
    },
  },
  active: {},
}))

export const MenuItem = forwardRef(({ className, href, ...props }, ref) => {
  const classes = useMenuItemStyles()
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(href === window.location.pathname)
  }, [])

  return (
    <MuiMenuItem
      ref={ref}
      className={cl(classes.root, className, {
        [classes.active]: isActive,
      })}
      href={href}
      {...props}
    />
  )
})

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: spacing(2),
  },
  list: {
    padding: 0,
    boxShadow: theme.shadows[3],
    width: 158,
    fontSize: '1.125rem',
  },
}))

const NavDropdownMenu = ({ children, anchor }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  return (
    <>
      {anchor({
        open,
        ref: anchorRef,
        'aria-controls': open ? 'menu-list-grow' : undefined,
        'aria-haspopup': 'true',
        onMouseEnter: () => setOpen(true),
        onMouseLeave:() => setOpen(false),
      })}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={{
          preventOverflow: {
            padding: -4,
            boundariesElement: 'viewport',
          },
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper
              elevation={0}
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id={'menu-list-grow'}
                  onKeyDown={handleListKeyDown}
                  className={classes.list}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

NavDropdownMenu.propTypes = {
  children: PropTypes.node,
  anchor: PropTypes.func,
}

export default NavDropdownMenu
