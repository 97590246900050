import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Hidden } from '@material-ui/core'

import Divider from 'artkive/components/Checkout/Divider'
import OrderPriceSummary from 'artkive/components/OrderSummary/OrderPriceSummary'
import Promo from 'artkive/components/Promo'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'

import GiftLineItem from './GiftLineItem'
import OrderDetailsAddons from './OrderDetailsAddons'

const OrderDetailsFragment = ({ options, product }) => {
  const { vipSubscription, gift } = useVariableState(options.type, options.uuid)
  const { setGift } = useVariableActions(options.type, options.uuid)

  const handleGiftChange = useCallback(({ target }) => {
    setGift(target.checked)
  }, [setGift])

  return (
    <>
      {options.gift && <GiftLineItem options={options} selected={gift} onChange={handleGiftChange} />}

      <OrderDetailsAddons options={options} product={product} />

      {product && (
        <Hidden mdUp>
          <Divider my={3.5} />

          <Promo product={product} disabled={vipSubscription} />

          <Divider my={2.5} />

          <OrderPriceSummary product={product} />
        </Hidden>
      )}
    </>
  )
}

OrderDetailsFragment.propTypes = {
  options: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
}

export default OrderDetailsFragment
