import React from 'react'
import PropTypes from 'prop-types'

import MUINoSSR from '@material-ui/core/NoSsr'
import { useStoreRehydrated } from 'easy-peasy'

const NoSsr = ({ children }) => {
  const rehydrated = useStoreRehydrated()

  if (!rehydrated)
    return null

  return (
    <MUINoSSR>
      {children}
    </MUINoSSR>
  )
}

NoSsr.propTypes = {
  children: PropTypes.node,
}

export default NoSsr
