import React from 'react'

import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined'
import CreateIcon from '@material-ui/icons/CreateOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined'
import MouseIcon from '@material-ui/icons/MouseOutlined'
import PaletteIcon from '@material-ui/icons/PaletteOutlined'
import PhotoCameraIcon from '@material-ui/icons/PhotoCameraOutlined'
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined'
import ScheduleIcon from '@material-ui/icons/Schedule'
import addDays from 'date-fns/addDays'
import isBefore from 'date-fns/isBefore'

import { HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'

import { ORDER_STATUS } from './OrderTracking.constants'

const statusDescriptionsList = [
  {
    icon: MouseIcon,
    title: 'Order Placed',
    description: () => (
      <>
        You’ve ordered your Artkive box and it’s on the way. Your box will typically arrive in seven business days.
      </>
    ),
    color: '#208E46',
    status: ORDER_STATUS.ORDER_PLACED,
  },
  {
    icon: ScheduleIcon,
    title: 'Waiting for Artwork',
    description: ({ tracking_number }) => (
      <>
        We&apos;ve shipped your box and we’re waiting for you to fill it with all the artwork you’d like to turn into a
        keepsake. Click <a href={'https://packing.artkivebox.com/'}>here</a> for help with organizing your art. Once you
        send back your art, it will typically take about a week to get to us.<br />
        {tracking_number && `Your tracking number is ${tracking_number}.`}
      </>
    ),
    color: '#DC87B9',
    status: ORDER_STATUS.WAITING_FOR_ART,
  },
  {
    icon: PaletteIcon,
    title: 'Artwork Received',
    description: () => (
      <>
        Your artwork has arrived at our studio safe and sound. We’re handing it over to one of our talented
        photographers.
      </>
    ),
    color: '#7A68AE',
    status: ORDER_STATUS.ART_RECEIVED,
  },
  {
    icon: PhotoCameraIcon,
    title: 'Artwork Photographed - Payment Pending',
    description: () => (
      <>
        Each piece of your artwork has been professionally photographed with love and care. You will receive a second
        payment invoice now that we&apos;ve counted all your pieces. Once your invoice is paid, we will continue with
        editing your images.
        <i>This is how your second payment is calculated.</i> <a href={routes.books}>See Pricing Slider</a>
      </>
    ),
    color: '#F49220',
    status: ORDER_STATUS.ART_PHOTOGRAPHED,
  },
  {
    icon: CreateIcon,
    title: 'Payment Complete - In Editing',
    description: () => (
      <>
        Your order has been paid for and is now in the queue to be edited by one of our designers. Your designer will
        edit your images to help them shine and make sure the images look great together.
      </>
    ),
    color: '#009FFF',
    status: ORDER_STATUS.PAYMENT_COMPLETE,
  },
  {
    icon: CloudUploadOutlinedIcon,
    title: 'Digital Images Uploaded',
    description: () => (
      <>
        Your images have been edited and are now uploaded to your Artkive account for you to view, share, and
        enjoy. <a href={`${window.app.clientUrl}/books`}>Login Here</a>
      </>
    ),
    color: '#208E46',
    status: ORDER_STATUS.DIGITAL_SENT,
  },
  {
    icon: ImportContactsIcon,
    title: 'Book is Ready for Review',
    description: () => (
      <>
        Hooray! We’re ready for you to sign off on your book proof so we can send it to print. Log into your Artkive
        account to review your book (you should have received an email with this info).&nbsp;
        <a href={`${window.app.clientUrl}/books`}>Login Here</a>
      </>
    ),
    color: '#208E46',
    status: ORDER_STATUS.EBOOK_DELIVERED,
  },
  {
    icon: PrintOutlinedIcon,
    title: 'Book Sent to Print',
    description: () => (
      <>
        Your beautiful book of art is being printed and will be shipped in the next few days. You can expect to receive
        your book 7-10 days after it is printed.
      </>
    ),
    color: '#DC87B9',
    status: ORDER_STATUS.BOOK_PRINTED,
  },
  {
    icon: MailOutlineOutlinedIcon,
    title: (order) => {
      if (order.ship_artwork_back && order.ship_usb_thumb) {
        return 'Your Art is Due to be Returned and USB Will be Sent'
      } else if (order.ship_artwork_back) {
        return 'Your Art is Due to be Returned'
      } else {
        return 'Your USB Will be Sent and Your Art is Due to be Recycled'
      }
    },
    description: (order) => {
      if (order.ship_artwork_back && order.ship_usb_thumb) {
        return (
          <>
            Your USB will arrive separate from your book but with similar timing.
          </>
        )
      } else if (order.ship_artwork_back) {
        return (
          <>
            Your returned art will arrive separate from your book but with similar timing.
            {order.return_tracking_number && `Your tracking number is ${order.return_tracking_number}.`}
          </>
        )
      } else {
        return null
      }
    },
    color: '#7A68AE',
    status: ORDER_STATUS.ART_RETURNED,
  },
  {
    icon: DeleteOutlineIcon,
    title: 'Your Art is Due to be Recycled',
    description: (order) => {
      const draftMessage = `#${order.id} I want my art returned.`

      return (
        <>
          Your art is slated to be recycled. Please&nbsp;
          <a href={`mailto:${HELP_EMAIL}?body=${draftMessage}`}>get in touch with us</a>&nbsp;
          immediately if you&apos;d like to change to having your art returned.
        </>
      )
    },
    color: '#DC87B9',
    status: ORDER_STATUS.PENDING_RECYCLE,
  },
]

const getAvailableStatus = (order) => {
  const statuses = [
    ORDER_STATUS.ORDER_PLACED,
    ORDER_STATUS.WAITING_FOR_ART,
    ORDER_STATUS.ART_RECEIVED,
    ORDER_STATUS.ART_PHOTOGRAPHED,
    ORDER_STATUS.PAYMENT_PENDING,
    ORDER_STATUS.PAYMENT_COMPLETE,
  ]

  if (order.physical_book) {
    statuses.push(
      ORDER_STATUS.EBOOK_DELIVERED,
      ORDER_STATUS.BOOK_PRINTED,
    )
  } else {
    statuses.push(
      ORDER_STATUS.DIGITAL_SENT,
    )
  }

  if (order.ship_artwork_back || order.ship_usb_thumb) {
    statuses.push(
      ORDER_STATUS.ART_RETURNED,
    )
  }

  if (!order.ship_artwork_back && !order.ship_usb_thumb) {
    statuses.push(
      ORDER_STATUS.PENDING_RECYCLE,
    )
  }

  return statusDescriptionsList.filter(({ status }) => statuses.includes(status)).map((step) => ({
    ...step,
    title: typeof step.title === 'function' ? step.title(order) : step.title,
    description: typeof step.description === 'function' ? step.description(order) : step.description,
  }))
}

export const getSinteticHistoryRow = (list) => {
  const terminationStatuses = [ORDER_STATUS.DIGITAL_SENT, ORDER_STATUS.BOOK_PRINTED]
  const lastActionIndex = list.findIndex(({ status }) => terminationStatuses.includes(status))
  if (lastActionIndex === -1) {
    return list
  }
  const lastAction = list[lastActionIndex]
  const lastStateDate = new Date(lastAction.transitioned_at)
  const newStatusDate = addDays(lastStateDate, -1)

  if (isBefore(newStatusDate, new Date())) {
    list.splice(lastActionIndex, 0, {
      status: ORDER_STATUS.ART_RETURNED,
      transitioned_at: newStatusDate,
    })
  }
  return list
}

export default getAvailableStatus
