import React from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 0,
    lineHeight: 'unset',
  },
  checkbox: {
    padding: 0,
    alignSelf: 'flex-start',
  },
  label: {
    marginLeft: theme.spacing(1.5),
    color: '#202126',
    fontWeight: 500,
  },
}))

const CheckboxControl = ({ label, checked, onChange, className, classes: givenClasses, ...rest }) => {
  const classes = useStyles()

  return (
    <FormControlLabel
      control={
        <Checkbox
          color={'primary'}
          checked={checked}
          onChange={onChange}
          {...rest}
          className={classes.checkbox}
        />
      }
      label={label}
      classes={{
        root: cl(classes.root, givenClasses.root),
        label: cl(classes.label, givenClasses.label),
      }}
      className={className}
    />
  )
}

CheckboxControl.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  classes: PropTypes.object,
}

CheckboxControl.defaultProps = {
  className: null,
  checked: null,
  onChange: null,
  classes: {},
}

export default CheckboxControl
