import React from 'react'
import PropTypes from 'prop-types'

import { Box, Grid, Link, Typography } from '@material-ui/core'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'

const ContactsDetails = ({ phone }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <Grid xs={12} md={6} item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Box mb={6}>
        <Box mb={1}>
          <Typography variant={'h2'}>Have Questions?</Typography>
        </Box>
        <Box mb={1}>
          <Typography>
            You can find detailed information on our{' '}
            <Link href={routes.faqs} target={'_blank'} style={{ fontWeight: isMobile ? 700 : 600 }}>FAQ Page</Link>
            .
          </Typography>
        </Box>
        <Typography>
          Get information about your order on{' '}
          <Link href={routes.accountOrders} target={'_blank'} style={{ fontWeight: isMobile ? 700 : 600 }}>
            My Orders Page
          </Link>
          .
        </Typography>
      </Box>
      <Box>
        <Box mb={1}>
          <Typography variant={'h2'}>Still Have Questions?</Typography>
        </Box>
        <Box mb={1}>
          <Typography>Email Us!{' '}
            <Link href={`mailto:${HELP_EMAIL}`} style={{ fontWeight: isMobile ? 700 : 600 }}>{HELP_EMAIL}</Link>
          </Typography>
        </Box>
        <Box mb={1}>
          <Typography>
            Text Us!{' '}
            <Link
              href={`sms:${phone.match(/^\+\d/) ? '' : '+1'}${phone.replace(/[^0-9]/g, '')}`}
              style={{ fontWeight: isMobile ? 700 : 600 }}
            >
              {phone}
            </Link>
          </Typography>
        </Box>
        <Typography>
          Schedule a Call!{' '}
          <Link href={routes.calendly} target={'_blank'} style={{ fontWeight: isMobile ? 700 : 600 }}>
            Artkive Calendly
          </Link>
        </Typography>
      </Box>
    </Grid>
  )
}

ContactsDetails.propTypes = {
  phone: PropTypes.string.isRequired,
}

export default ContactsDetails
