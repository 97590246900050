import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import MaskedInput from 'react-text-mask'
import { TextField } from '@material-ui/core'

const mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

const PhoneNumberField = forwardRef(({ InputProps, value, inputRef, ...props }, ref) => {
  return (
    <MaskedInput
      mask={mask}
      placeholderChar={'\u2000'}
      ref={ref}
      guide={false}
      render={(inputRef, field) => (
        <TextField
          inputRef={inputRef}
          InputProps={InputProps}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            inputMode: 'numeric',
          }}
          value={value}
          type={'text'}
          autoComplete={'tel-national'}
          {...field}
          {...props}
        />
      )}
    />
  )
})

PhoneNumberField.propTypes = {
  InputProps: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  inputRef: PropTypes.func,
}

export default PhoneNumberField
