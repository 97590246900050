import React from 'react'
import PropTypes from 'prop-types'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

import BasePriceWithDiscount from './BasePriceWithDiscount'
import calculateOrderSummaryPrices from './calculateOrderSummaryPrices'

const priceLabelClassName = 'priceLabelWithDiscount'
const priceClassName = 'priceProductDescription'
const discountBoxClassName = 'discountBoxProductDescription'
const discountLabelClassName = 'discountLabelProductDescription'

const ProductDescriptionPrice = ({
  fullPriceInfo,
  qty,
  enableUSBAddon,
  showEachSuffix,
}) => {
  const { finalPrice, discountLabel, price } = calculateOrderSummaryPrices({
    fullPriceInfo,
    qty,
    enableUSBAddon,
    showEachSuffix,
  })
  const isMobile = useMobileBreakpoint()
  const mainBoxDirection = isMobile ? 'column' : 'row'
  const mainBoxGap = isMobile ? '0' : '8px'

  const discountMethod = fullPriceInfo.promo_discount_details?.discount_method

  return (
    <BasePriceWithDiscount
      finalPrice={finalPrice}
      price={price}
      discountMethod={discountMethod}
      discountLabel={discountLabel}
      priceLabelClassName={priceLabelClassName}
      priceClassName={priceClassName}
      discountBoxClassName={discountBoxClassName}
      discountLabelClassName={discountLabelClassName}
      mainBoxDirection={mainBoxDirection}
      isProductDescription={true}
      mainBoxGap={mainBoxGap}
      alignItemsMainBox={'center'}
    />
  )
}

ProductDescriptionPrice.propTypes = {
  fullPriceInfo: PropTypes.object.isRequired,
  qty: PropTypes.number,
  enableUSBAddon: PropTypes.bool,
  showEachSuffix: PropTypes.bool,
}

export default ProductDescriptionPrice
