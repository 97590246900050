import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircle, Close } from '@material-ui/icons'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

const useStyles = makeStyles((muiTheme) => ({
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'relative',
  },
  addressSuggestion: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    cursor: 'pointer',
    padding: 12,
    borderRadius: 8,
  },
  title: {
    color: theme.colors.black,
    fontWeight: 500,
  },
  verifyBtn: {
    [muiTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const AddressValidation = ({ isOpen, onSubmit, setIsOpen, suggestion }) => {

  const isMobile = useMobileBreakpoint()
  const { user } = suggestion

  const classes = useStyles()

  // state
  const [active, setActive] = useState(1)


  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleSubmit = () => {
    setIsOpen(false)
    let addressObj = {
      isValid: true,
      email: user.email,
      phone: user.phone,
      firstName: user.firstName,
      lastName: user.lastName,
    }

    if (active === 0) {
      addressObj = {
        ...addressObj,
        address: user.address,
        address2: user.address2,
        city: user.city,
        state: user.state,
        zipCode: user.zipCode,
      }
    } else {
      addressObj = {
        ...addressObj,
        address: suggestion.address,
        address2: suggestion.address2,
        city: suggestion.city,
        state: suggestion.state,
        zipCode: suggestion.zipCode,
      }
    }

    onSubmit(addressObj)
  }

  const bgColor = (isActive) => isActive ? theme.colors.secondary.light : theme.colors.white.dark
  const iconColor = (isActive) => isActive ? 'primary' : 'secondary'
  const addressSuggestionLine1 = [
    suggestion.address,
    suggestion.address2 && suggestion.address2,
    suggestion.city,
    suggestion.state,
    suggestion.zipCode,
  ].filter(Boolean).join(', ')

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={handleClose}
      >
        <Box className={classes.wrapper} py={isMobile ? 3 : 6} px={isMobile ? 2 : 6}>
          <Close color={'secondary'} fontSize={'medium'} onClick={handleClose} className={classes.close} />

          <Box my={2} textAlign={'center'}>
            <Typography variant={'h6'}>Verify Your Address</Typography>
          </Box>

          <Grid container spacing={isMobile ? 2 : 4}>
            <Grid item xs={12} md={6}>
              <Box
                className={classes.addressSuggestion}
                bgcolor={bgColor(active === 0)}
                onClick={() => setActive(0)}
              >
                <Box mr={2}><CheckCircle color={iconColor(active === 0)} fontSize={'large'} /></Box>
                <Box>
                  <Typography className={classes.title}>You Entered</Typography>
                  {user && (
                    <Typography>
                      {user.address}, {user.address2 && `${user.address2},`} {user.city}, {user.state}, {user.zipCode}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                className={classes.addressSuggestion}
                bgcolor={bgColor(active === 1)}
                onClick={() => setActive(1)}
              >
                <Box mr={2}><CheckCircle color={iconColor(active === 1)} fontSize={'large'} /></Box>
                <Box>
                  <Typography className={classes.title}>Did You Mean?</Typography>
                  <Typography>
                    {addressSuggestionLine1}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box mt={3} textAlign={'center'}>
            <Button color={'primary'} onClick={handleSubmit} variant={'contained'} className={classes.verifyBtn}>
              Verify Address
            </Button>
          </Box>
        </Box>

      </Dialog>
    </>
  )
}

AddressValidation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  suggestion: PropTypes.object.isRequired,
}

export default AddressValidation
