import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { Done } from '@material-ui/icons'

import SectionHeader from 'artkive/components/Checkout/SectionHeader'
import ContentBox from 'artkive/components/Checkout/Sections/ContentBox'
import ProcessingTime from 'artkive/components/Checkout/Sections/ProcessingTime'
import UpgradeCardDetails from 'artkive/components/Checkout/UpgradeCardDetails'
import UpgradesCard from 'artkive/components/Checkout/UpgradesCard'
import Image from 'artkive/components/Image'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import formatPrice from 'artkive/utils/formatPrice'

import addonsIcon from 'images/ecom/addons.svg'
import recomendedUpdate from 'images/ecom/recomendedUpdate.svg'

import AddonItem from './AddonItem'

const PROTECTION_LOSS_AMOUNT = 350

const ShippingProtectionItem = ({ children }) => (
  <Box display={'flex'} alignItems={'center'} mb={1} pl={1}>
    <Done color={'primary'} />
    <Box ml={1.5}>
      <Typography vairant={'p'}>{children}</Typography>
    </Box>
  </Box>
)

ShippingProtectionItem.propTypes = {
  children: PropTypes.node,
}

const OrderDetailsAddons = ({ options, product, dense }) => {
  const {
    setProcessingTime,
    setShippingProtection,
    setAddon,
  } = useVariableActions(product.type, product.uuid)

  const {
    freeAddons,
    processing,
    shippingProtection,
    shippingProtectionPrice,
    getAddon,
    price: globalPrice,
    disabledAddons,
  } = useVariableState(product.type, product.uuid)

  const lockedAddons = { ...freeAddons, ...disabledAddons }

  const shippingAddon = useMemo(() => ({
    name: 'Add Shipping Protection',
    type: 'SHIPPING_PROTECTION',
    price: shippingProtectionPrice,
  }), [shippingProtectionPrice])

  const handleProcessingChange = useCallback((p) => {
    setProcessingTime(p.name)
  }, [setProcessingTime])

  let addonsFragment

  if (dense) {
    addonsFragment = (
      <>
        {options.addon_sections.map(({ header, items }, index) => (
          <UpgradesCard
            icon={<Image src={addonsIcon} style={{ width: 24 }} />}
            title={header}
            key={index}
          >
            {items.map((item) => (
              <AddonItem
                key={item.uuid}
                setAddon={setAddon}
                getAddon={getAddon}
                item={item}
                lockedAddons={lockedAddons}
                dense={dense}
                component={'div'}
                formatPrice={formatPrice}
                discountInfo={globalPrice.promo_discount_details}
                highlightable={false}
              />
            ))}
          </UpgradesCard>
        ))}
      </>
    )
  } else {
    addonsFragment = (
      <div>
        {options.addon_sections.map(({ header, items }, index) => (
          <ContentBox key={index}>
            <SectionHeader type={'addon'}>{header}</SectionHeader>

            {items.map((item) => (
              <AddonItem
                key={item.uuid}
                setAddon={setAddon}
                getAddon={getAddon}
                item={item}
                lockedAddons={lockedAddons}
                dense={dense}
                discountInfo={globalPrice.promo_discount_details}
                formatPrice={formatPrice}
              />
            ))}
          </ContentBox>
        ))}
      </div>
    )
  }

  return (
    <>
      {addonsFragment}

      {options.processing_time && (
        <ProcessingTime
          processing={processing}
          onChange={handleProcessingChange}
          dense={dense}
          discountInfo={globalPrice.promo_discount_details}
        />
      )}

      {options.shipping_protection && (
        <UpgradesCard
          icon={<Image src={recomendedUpdate} style={{ width: 24 }} />}
          title={'Recommended Upgrade'}
        >
          <AddonItem
            item={shippingAddon}
            getAddon={() => shippingProtection}
            setAddon={({ checked }) => setShippingProtection(checked)}
            formatPrice={formatPrice}
            discountInfo={globalPrice.promo_discount_details}
            highlightable={false}
            dense={true}
          />

          <UpgradeCardDetails link={<>What&apos;s This?</>}>
            <ShippingProtectionItem>
              ${PROTECTION_LOSS_AMOUNT} Loss Guarantee: coverage for the very rare occurrence of
              package loss during UPS shipping of your art.
            </ShippingProtectionItem>
            <ShippingProtectionItem>
              On-Site Backup: We&apos;ll securely keep digital copies of all
              your art images for added protection.
            </ShippingProtectionItem>
            <ShippingProtectionItem>
              Upgraded Return Shipping: Once we&apos;ve sent your product,
              we&apos;ll expedite the return shipment of your art (if you&apos;ve purchased return art).
            </ShippingProtectionItem>
          </UpgradeCardDetails>
        </UpgradesCard>
      )}
    </>
  )
}

OrderDetailsAddons.propTypes = {
  product: PropTypes.object,
  options: PropTypes.object,
  dense: PropTypes.bool,
}

OrderDetailsAddons.defaultProps = {
  dense: false,
}

export default OrderDetailsAddons
