import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Container,
  Grid,
  NoSsr,
  Paper,
  Typography,
} from '@material-ui/core'

import withApp from 'artkive/hoc/withApp'
import theme from 'artkive/utils/theme'

import GiftCardsImage from 'images/ecom/information/box-cta-300.jpeg'
import FramedMosaicsImage from 'images/ecom/information/framed-mosaics-cta-300.jpeg'

const ProductCard = ({ buttonColor, href, text, src }) => (
  <Grid xs={12} sm={6} mb={2} item>
    <Paper elevation={0} variant={'outlined'}>
      <Box p={6}>
        <img
          alt={text}
          src={src}
          style={{ borderRadius: '50%', maxWidth: '200px', width: '200px' }}
        />

        <Box mt={2}>
          <Button href={href} variant={'contained'} style={{
            backgroundColor: buttonColor,
            color: '#ffffff',
          }}>
            {text}
          </Button>
        </Box>
      </Box>
    </Paper>
  </Grid>
)

ProductCard.propTypes = {
  buttonColor: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  src: PropTypes.string,
}

const ProductCards = () => (
  <>
    <ProductCard
      buttonColor={'#dd5c79'}
      href={Routing.ecom_mosaics()}
      text={'Framed Mosaics'}
      src={FramedMosaicsImage}
    />

    <ProductCard
      buttonColor={'#1a82c7'}
      href={Routing.ecom_giftcards()}
      text={'Gift Cards'}
      src={GiftCardsImage}
    />
  </>
)

export const Information = () => {
  const { body, title } = useMemo(() => {
    if (typeof window === 'undefined') return {}

    const queryParameters = new URLSearchParams(window.location.search)
    const body = queryParameters.get('body') || 'Welcome! View some of our other amazing products below!'
    const title = queryParameters.get('title') || 'Explore Artkive'

    return { body, title }
  }, [])

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
      <Container maxWidth={'md'}>
        <Box pt={8} mb={4} align={'center'}>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <Typography variant={'h2'}>
                {title}
              </Typography>

              <Typography variant={'body1'}>
                {body}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box align={'center'}>
          <Grid container spacing={2}>
            <NoSsr>
              <ProductCards />
            </NoSsr>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default withApp(Information)
