import React from 'react'

import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

import lines from 'images/ecom/get-started/lines_3.svg'

const useStyles = makeStyles(({ breakpoints }) => ({
  bg: {
    background: 'linear-gradient(0deg, rgba(255,242,209,1) calc(50% - 68px), rgba(227,242,253,1) calc(50% - 68px))',
    [breakpoints.down('sm')]: {
      background: 'unset',
    },
  },
  container: {
    boxShadow: theme.shadows[5],
  },
  getLines: {
    position: 'absolute',
    width: 98,
    right: -35,
    bottom: 32,
  },
  content: {
    position: 'relative',
    [breakpoints.up('md')]: {
      maxWidth: 528,
    },
  },
  image: {
    objectFit: 'cover',
    height: 'auto',
    width: '100%',
    maxWidth: 'unset',
    [breakpoints.up('md')]: {
      height: '400px',
      width: '500px',
    },
  },
}))

const YouWillAlsoLove = ({ image, children }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <ContainedSection
      title={(
        <Typography align={'center'} gutterBottom variant={'h2'}>
          You’ll Also Love
        </Typography>
      )}
      className={classes.bg}
    >
      <Box display={'flex'} className={classes.container} flexDirection={isMobile ? 'column' : 'row'}>
        <img src={image} className={classes.image} />
        <Grid sm item
          style={{
            backgroundColor: 'white',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            px={isMobile ? 2 : 8}
            pt={4}
            pb={isMobile ? 6 : 4}
            className={classes.content}
            textAlign={isMobile ? 'center' : 'left'}
          >
            {children}
            <Hidden smDown>
              <img src={lines} className={classes.getLines} />
            </Hidden>
          </Box>
        </Grid>
      </Box>

    </ContainedSection>
  )
}

export default YouWillAlsoLove
