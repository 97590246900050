import { useEffect } from 'react'

import { capitalize } from '@material-ui/core/utils'

import { identifyUser } from 'artkive/utils/tracker'

const handleKlaviyoFormSubmit = (event) => {
  const button = event.target
  if (button.tagName !== 'BUTTON' || !button.classList.contains('needsclick')) return

  const form = button.closest('form')
  if (!form || !form.classList.contains('klaviyo-form')) return

  const emailInput = form.querySelector('input[name="email"]')
  if (!emailInput) return

  const phone = form.querySelector('input[name="phone-number"]')?.value
  let firstName = form.querySelector('input[autocomplete="given-name"]')?.value
  firstName = firstName && capitalize(firstName)
  let lastName = form.querySelector('input[autocomplete="family-name"]')?.value
  lastName = lastName && capitalize(lastName)
  const name = [firstName, lastName].filter(Boolean).join(' ')
  identifyUser({ email: emailInput.value, name, firstName, lastName, phone })
}

const useKlaviyoFormCatch = () => {
  useEffect(() => {
    document.addEventListener('click', handleKlaviyoFormSubmit)
    return () => document.removeEventListener('click', handleKlaviyoFormSubmit)
  }, [])
}

export default useKlaviyoFormCatch
