import React from 'react'
import PropTypes from 'prop-types'

import BasePriceWithDiscount from './BasePriceWithDiscount'
import calculateProductPreviewPrices from './calculateProductPreviewPrices'

const priceLabelClassName = 'priceLabelWithDiscount'
const priceClassName = 'price'
const discountBoxClassName = 'discountBox'
const discountLabelClassName = 'discountLabel'
const mainBoxDirection = 'column'

const PreviewPrice = ({
  fullPriceInfo,
  qty,
  enableUSBAddon,
  showEachSuffix,
}) => {
  const { finalPrice, discountLabel, price } = calculateProductPreviewPrices({
    fullPriceInfo,
    qty,
    enableUSBAddon,
    showEachSuffix,
  })

  const discountMethod = fullPriceInfo.promo_discount_details?.discount_method

  return (
    <BasePriceWithDiscount
      finalPrice={finalPrice}
      price={price}
      discountMethod={discountMethod}
      discountLabel={discountLabel}
      priceLabelClassName={priceLabelClassName}
      priceClassName={priceClassName}
      discountBoxClassName={discountBoxClassName}
      discountLabelClassName={discountLabelClassName}
      mainBoxDirection={mainBoxDirection}
    />
  )
}

PreviewPrice.propTypes = {
  fullPriceInfo: PropTypes.object.isRequired,
  qty: PropTypes.number,
  enableUSBAddon: PropTypes.bool,
  showEachSuffix: PropTypes.bool,
}

export default PreviewPrice
