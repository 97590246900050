import React, { useState } from 'react'
import PropTypes from 'prop-types'

import cl from 'classnames'
import { Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useIsomorphicLayoutEffect from 'artkive/hooks/useIsomorphicLayoutEffect'
import theme from 'artkive/utils/theme'

const useMenuLinkStyles = makeStyles(() => ({
  link: {
    color: theme.colors.black,
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: 1.4,
    padding: 0,
  },

  primaryLink: {
    color: theme.colors.primary.main,
    fontSize: 22,
    lineHeight: 1.4,
    fontWeight: 700,
  },
  active: {
    color: theme.colors.sky[700],
  },
}))

const MenuLink = ({ href, children, className, isPrimary = false, ...props }) => {
  const classes = useMenuLinkStyles()
  const [isActive, setIsActive] = useState(false)

  useIsomorphicLayoutEffect(() => {
    setIsActive(href === window.location.pathname)
  })

  return (
    <Link
      href={href}
      className={cl(className, isPrimary ? classes.primaryLink : classes.link, {
        [classes.active]: isActive,
      })}
      {...props}
    >
      {children}
    </Link>
  )
}

MenuLink.propTypes = {
  isPrimary: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
}

export default MenuLink
