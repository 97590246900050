import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

export const GridSpacer = withStyles(({ breakpoints, spacing }) => ({
  root: {
    [breakpoints.up('sm')]: {
      marginTop: spacing(1),
    },
  },
}))(({ classes }) => <div className={classes.root}></div>)

const SectionGrid = ({ children, smSpacing = 0 }) => {
  const isMobile = useMobileBreakpoint()

  return (
    <Grid container={true} spacing={isMobile ? smSpacing : 2}>
      {children}
    </Grid>
  )
}

SectionGrid.propTypes = {
  children: PropTypes.node.isRequired,
  smSpacing: PropTypes.number,
}

export default SectionGrid
