import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Grid, Hidden, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'

import lines from 'images/ecom/get-started/lines_2.png'
import Lines2 from 'images/ecom/get-started/lines_3.svg'
import Product from 'images/ecom/get-started/product_2.png'

const useStyles = makeStyles(({ palette }) => ({
  productImage: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  box: {
    backgroundColor: palette.common.white,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  productImageWrapper: {
    position: 'relative',
  },
  lines: {
    position: 'absolute',
    width: 64,
    height: 64,
    top: -35,
    right: 25,
  },
  getLines: {
    position: 'absolute',
    width: 75,
    top: -50,
    left: 300,
  },
}))

const ProductCard = ({ boxPrice }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Paper elevation={1}>
      <Grid container={true}>
        <Grid item={true} xs={12} md={5} className={classes.productImageWrapper}>
          <img src={lines} className={classes.lines} />
          <img src={Product} className={classes.productImage} />
        </Grid>
        <Grid item xs={12} md={7} className={classes.box}>
          <Box px={isMobile? 2 : 8} py={4}>
            <Box mb={2}>
              <Typography variant={'h5'} align={isMobile ? 'center' : 'justify'}>
                Artkive Box
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography align={isMobile ? 'center' : 'justify'}>
                24”x18”x4” crush-proof Artkive Box that includes everything you need to send in your artwork.
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant={'h4'} align={isMobile ? 'center' : 'justify'}>
                ${boxPrice}
              </Typography>
            </Box>

            <Box
              display={'flex'}
              justifyContent={isMobile? 'center': 'flex-start'}
              position={'relative'}
            >
              <Button
                color={'primary'}
                href={routes.boxCheckout}
                variant={'contained'}
                style={{ paddingLeft: 70, paddingRight: 70 }}
              >
                Get My Box
              </Button>

              <Hidden smDown={true}>
                <img src={Lines2} className={classes.getLines} />
              </Hidden>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

ProductCard.propTypes = {
  boxPrice: PropTypes.number.isRequired,
}

export default ProductCard
