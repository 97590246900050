import { useEffect } from 'react'

import flattenErrors from 'common/utils/flattenErrors'

export function scrollToError(errors) {
  const flatErrors = flattenErrors(errors)

  const elements = Object.keys(flatErrors).map((name) => document.getElementsByName(name)[0]).filter((el) => !!el)
  elements.sort((a, b) => b.scrollHeight - a.scrollHeight)
  elements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' })

  // window.scrollTo(0, error.ref.current.offsetTop - 100);
}

export default (errors) => useEffect(() => {
  scrollToError(errors)
}, [errors])
