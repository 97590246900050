import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Edit } from '@material-ui/icons'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { Link } from 'wouter'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const RouteLink = forwardRef((props, _ref) => <Link {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#F5FAFE',
    borderRadius: 8,
    margin: '8px 0',
    border: '1px solid #C3E8FF',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.white,
      border: 'none',
      boxShadow: '0px 4px 10px rgba(16, 35, 113, 0.08)',
    },
  },
  content: {
    padding: '0 24px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flex: 1,
    '& .MuiTypography-h6': {
      color: theme.palette.secondary.dark,
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
  },
  arrow: {
    padding: 4,
  },
}))

const usePreviewStyles = makeStyles(({ breakpoints }) => ({
  container: {
    width: '100%',
  },
  root: {
    paddingLeft: 24,
    height: 56,
    marginTop: 4,
    paddingBottom: 4,
    [breakpoints.down('sm')]: {
      paddingLeft: 12,
    },
  },
}))

// TODO: merge with MobileDetailsBox
const SummaryDetailsBox = ({ children, title, editHref = '' }) => {
  const classes = useStyles()
  const previewClasses = usePreviewStyles()
  const [isShown, setIsShown] = useState(false)
  const isMobile = useMobileBreakpoint()

  return (
    <Card variant={'outlined'} className={classes.root} elevation={0}>
      <ListItem
        component={'div'}
        ContainerComponent={'div'}
        classes={previewClasses}
        role={'button'}
        onClick={() => setIsShown(!isShown)}
      >
        <div className={classes.title}>
          <Typography variant={'h6'}>{title}</Typography>
        </div>

        <ListItemSecondaryAction>
          {isMobile ? (
            <IconButton className={classes.arrow} onClick={() => setIsShown(!isShown)}>
              {isShown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          ) : (
            <Button
              variant={'text'}
              color={'primary'}
              size={'medium'}
              startIcon={<Edit />}
              component={RouteLink}
              href={editHref}
            >
              Edit
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      {isMobile ? (
        <Collapse in={isShown} timeout={'auto'} unmountOnExit>
          <Box px={1.5}>
            {children}
          </Box>

          <Box display={'flex'} justifyContent={'center'} mb={1}>
            <Button
              variant={'text'}
              color={'primary'}
              component={RouteLink}
              href={editHref}
              size={'medium'}
              startIcon={<Edit />}
            >
              Edit
            </Button>
          </Box>
        </Collapse>
      ) : (
        <CardContent className={classes.content}>
          {children}
        </CardContent>
      )}
    </Card>
  )
}

SummaryDetailsBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  editHref: PropTypes.string,
}

export default SummaryDetailsBox
