import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { KeyboardDatePicker as MUIDatePicker } from '@material-ui/pickers'
import { parseISO } from 'date-fns'

import formatter from 'common/utils/format'

function isValidDate(d) {
  return d instanceof Date && !isNaN(d)
}

const DatePicker = forwardRef(({ value, onChange, variant, onBlur, format, ...props }, ref) => {
  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      onChange(formatter(date, 'date'))
      onBlur && onBlur()
    } else if(date === null) {
      onChange(null)
      onBlur && onBlur()
    }
  }

  return (
    <MUIDatePicker
      InputLabelProps={{
        shrink: true,
      }}
      inputVariant={variant}
      format={format}
      value={value && parseISO(value)}
      onChange={handleDateChange}
      inputRef={ref}
      onBlur={onBlur}
      {...props}
    />
  )
})

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  variant: PropTypes.string,
  onBlur: PropTypes.func,
  format: PropTypes.string,
}

DatePicker.defaultProps = {
  value: null,
  variant: null,
  onBlur: null,
  format: 'MM-dd-yyyy',
}

export default DatePicker
