import * as Sentry from '@sentry/react'
import {
  action,
  computed,
} from 'easy-peasy'
import Cookies from 'js-cookie'

const initialCookies = (() => {
  if (typeof window === 'undefined') return {}

  const cookies = Cookies.get()
  const res = {}

  Object.entries(cookies).forEach(([key, value]) => res[key] = { value })

  return res
})()

const MODAL_COOKIE_NAME = 'box-email-for-discount'
const MODAL_COOKIE_VALUE = 'true'

const setStoreCookie = (state, { key, options, value }) => {
  try {
    Cookies.set(key, value, options)

    state.cookies[key] = { options, value }
  } catch (err) {
    console.error(err)

    Sentry.captureException(err)
  }
}

const cookieStore = {
  // state
  cookies: initialCookies,

  // computed
  getCookie: computed(({ cookies }) => (key) => cookies[key]),

  modalCookie: computed(({ cookies }) => cookies[MODAL_COOKIE_NAME]),

  // actions
  removeCookie: action((state, { key }) => {
    Cookies.remove(key)

    const update = {}

    Object.entries(state.cookies)
      .filter(([k]) => k !== key)
      .forEach(([k, v]) => update[k] = v)

    state.cookies = update
  }),

  setCookie: action((state, { key, options, value }) => {
    setStoreCookie(state, { key, options, value })
  }),

  setModalCookie: action((state, payload = {}) => {
    const {
      options = { expires: 7 },
      value = MODAL_COOKIE_VALUE,
    } = payload

    setStoreCookie(state, { key: MODAL_COOKIE_NAME, options, value })
  }),
}

export default cookieStore
