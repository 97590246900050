import React from 'react'

import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import MarkdownText from 'artkive/components/MarkdownText'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

import ArrowStepImage from 'images/ecom/arrowSteps.svg'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  gridWrapper: {
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gridItem: {
    position: 'relative',
    padding: '0 32px',
    marginTop: spacing(6),
    flexGrow: 1,
    flexBasis: 0,
    [breakpoints.down('sm')]: {
      '&:first-child': {
        marginTop: spacing(4.5),
      },
    },
  },
  gridItemAfter: {
    position: 'absolute',
    left: '0',
    top: '60px',
    width: 120,
    transform: 'translate(-50%, -50%)',
    [breakpoints.down('md')]: {
      width: 100,
    },
  },
  howItWorksImage: {
    maxHeight: 110,
    minHeight: 90,
    width: 'auto',
  },
  howItWorksDescription: {
    lineHeight: 1.6,
    fontWeight: 500,
  },
}))

const HowItWorksIconVariant = ({ steps }) => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <Grid container className={classes.gridWrapper}>
      {steps.map((step, index) => (
        <Box key={index} className={classes.gridItem}>
          <Box textAlign={'center'}>
            <Box mb={2}><img src={step.image?.url || step.image} className={classes.howItWorksImage} /></Box>
            <Box mb={2} height={isMobile ? 'auto' : 64} alignItems={'center'} display={'flex'}
              justifyContent={'center'}>
              <Typography gutterBottom variant={'h3'} align={'center'}>
                {step.title}
              </Typography>
            </Box>
            <Typography className={classes.howItWorksDescription}>
              <MarkdownText source={step.body} />
            </Typography>
          </Box>
          {index !== 0 && (
            <Hidden smDown>
              <img src={ArrowStepImage} className={classes.gridItemAfter} />
            </Hidden>
          )}
        </Box>
      ))}
    </Grid>
  )
}

export default HowItWorksIconVariant
