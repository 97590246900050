import React from 'react'
import PropTypes from 'prop-types'

import { useForm } from 'react-hook-form'
import {
  Box,
  Container,
  Grid,
  NoSsr,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ThumbUp } from '@material-ui/icons'
import { useStoreState } from 'easy-peasy'
import ToggleIcon from 'material-ui-toggle-icon'

import { DISCOUNT_BANNER } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import usePromo from 'artkive/hooks/usePromo'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'
import validations from 'artkive/utils/validations'

import { withClickTracker } from '../Tracker'
import TrackButton from '../Tracker/TrackButton'
import TrackLink from '../Tracker/TrackLink'

import EmailStickyCta from './EmailStickyCta'

const TrackedTextField = withClickTracker(TextField, 'onFocus')

const useStyles = makeStyles(() => ({
  title: {
    color: 'white',
    fontSize: '1.4rem',
    fontWeight: 'bold',
  },
  subtitle: {
    color: 'white',
    fontSize: '1.2rem',
  },
  discountApplied: {
    bottom: '0',
    position: 'absolute',
    left: '0',
    marginTop: '-15px',
    minHeight: '130px',
    right: '0',
    textAlign: 'center',
    top: '50%',
    zIndex: '1',
  },
  discountAppliedText: {
    color: theme.colors.white.dark,
    fontSize: '0.95em',
    padding: '0.2rem 0 0.75rem',
  },
  emailInputHint: {
    color: 'white',
    fontSize: '0.7rem',
    lineHeight: '1.3',
  },
  btn: {
    minWidth: 112,
    height: '56px',
    borderRadius: '0 8px 8px 0',
    whiteSpace: 'nowrap',
  },
}))

const EmailListForm = ({ zIndex = 100 }) => {
  const classes = useStyles()
  const { formState: { errors }, handleSubmit, register } = useForm()
  const isMobile = useMobileBreakpoint()

  const { promoActions, promoState } = usePromo({ formType: 'footer' })
  const { modalCookie } = useStoreState(({ cookieStore }) => cookieStore)

  const onSubmit = async ({ email }, event) => {
    await promoActions.dispatchEmail({
      email,
      onDone: () => event.target.reset(),
    })
  }

  return (
    <>
      <EmailStickyCta
        showPrompt={!!modalCookie}
        showValidated={!!promoState.promoValidated}
        zIndex={zIndex - 1}
      />

      <Box bgcolor={theme.colors.primary.dark} py={6} style={{
        position: 'relative',
        zIndex: zIndex + 1,
      }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box textAlign={isMobile ? 'center' : 'left'}>
                <Box mb={1}>
                  <Typography variant={'body1'} className={classes.title}>
                    Get $10 Off Your First Order!
                  </Typography>
                </Box>

                <Box>
                  <Typography variant={'body1'} className={classes.subtitle}>
                    Just sign up for our email list.
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <NoSsr>
              <Grid item xs={12} md={6}>
                <Grid
                  item
                  xs={12}
                  style={{
                    visibility: promoState.promoValidated ? 'visible' : 'hidden',
                    position: 'relative',
                    userSelect: promoState.promoValidated ? 'none' : 'all',
                  }}
                >
                  <Box className={classes.discountApplied}>
                    <ToggleIcon
                      on={!!promoState.promoValidated}
                      onIcon={<ThumbUp />}
                      offIcon={<></>}
                      style={{ color: 'white', height: '40px', width: '100%' }}
                    />

                    <Typography className={classes.discountAppliedText}>
                      Your discount has been applied
                    </Typography>

                    <TrackButton
                      color={'primary'}
                      href={routes.boxCheckout}
                      variant={'contained'}
                      trackerEvent={DISCOUNT_BANNER.CTA_CLICK}
                    >
                      Save Now
                    </TrackButton>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    visibility: promoState.promoValidated ? 'hidden' : 'visible',
                    userSelect: promoState.promoValidated ? 'all' : 'none',
                  }}
                >
                  <Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TrackedTextField
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        name={'email'}
                        fullWidth
                        placeholder={'Email'}
                        {...register('email', validations.email)}
                        variant={'outlined'}
                        type={'email'}
                        InputProps={{
                          style: { backgroundColor: 'white', paddingRight: '0', overflow: 'hidden' },
                          endAdornment: <TrackButton
                            color={'primary'}
                            type={'submit'}
                            variant={'contained'}
                            className={classes.btn}
                          >
                            Save $10
                          </TrackButton>,
                        }}
                        trackerEvent={DISCOUNT_BANNER.EMAIL_FIELD_CLICK}
                      />
                    </form>

                    <Box mt={1}>
                      <Typography className={classes.emailInputHint}>
                        By providing your email, you agree to receive emails
                        from Artkive. View our <TrackLink
                          href={routes.privacy}
                          style={{
                            fontSize: '0.7rem',
                            fontWeight: '500',
                          }}
                          trackerEvent={DISCOUNT_BANNER.PRIVACY_POLICY_CLICK}
                        >Privacy Policy</TrackLink>. We promise never to spam or
                        give your email away and you can unsubscribe anytime.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </NoSsr>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

EmailListForm.propTypes = {
  zIndex: PropTypes.number.isRequired,
}

export default EmailListForm
