import { useCallback } from 'react'

import { useTheme } from '@material-ui/core/styles'

const usePicturesBreakpoints = () => {
  const muiTheme = useTheme()

  const mediaUp = useCallback((breakpoint) => {
    return muiTheme.breakpoints.up(breakpoint).replace('@media ', '')
  }, [])

  const mediaDown = useCallback((breakpoint) => {
    return muiTheme.breakpoints.down(breakpoint).replace('@media ', '')
  }, [])

  return {
    mediaDown, mediaUp,
  }
}

export default usePicturesBreakpoints
