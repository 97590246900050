import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Container,
  Grid,
  Hidden,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStoreActions } from 'easy-peasy'
import { Link } from 'wouter'

import { RETURN_ART_PRICE, USB_PRICE } from 'common/utils/conciergeDigitalInvoiceItem.constants'
import Faqs from 'artkive/components/Faqs'
import Redeem from 'artkive/components/Modals/Redeem'
import HowItWorks from 'artkive/components/PageSections/HowItWorks'
import { TrackButton, withClickTracker } from 'artkive/components/Tracker'
import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { HELP_EMAIL } from 'artkive/utils/company'
import { AMOUNT_OF_ART } from 'artkive/utils/giftCard'
import theme from 'artkive/utils/theme'

import Hero from 'images/ecom/giftcards/GC-Hero.png'
import HowItWorks1 from 'images/ecom/giftcards/GC-HowItWorks-1.svg'
import HowItWorks2 from 'images/ecom/giftcards/GC-HowItWorks-2.svg'
import HowItWorks3 from 'images/ecom/giftcards/GC-HowItWorks-3.svg'
import HeroMobile from 'images/ecom/giftcards/GiftCards-Hero-Mobile.jpg'

import GiftCardIncludeSection, { IncludesListing } from './GiftCardIncludeSection'

const FAQS = [
  {
    id: 1,
    title: 'When will my gift card arrive?',
    body: 'It will likely arrive to your gift recipient within 5-7 business days. Gift cards come wrapped in a small white box with Artkive branded ribbon and your (optional) personal note.',
  },
  {
    id: 2,
    title: 'How do I redeem an Artkive gift card?',
    body:
      `Select the Redeem button above or click Get Started. At the first step of check out, in the promo code field,
enter the characters under the scratch-off on the back of your gift card or in your e-card. There will be two optional
add-ons for having your art returned ($${RETURN_ART_PRICE}) and getting a USB of your images ($${USB_PRICE}). A credit
card will be required if you select those items.`,
  },
  {
    id: 3,
    title: 'Can I use a promo code along with my gift card?',
    body: `Our ${AMOUNT_OF_ART}-piece gift cards cannot be combined with other promotional offers.`,
  },
  {
    id: 4,
    title: `What if I want to send more than the ${AMOUNT_OF_ART} pieces included with my gift card?`,
    body:
      `You can certainly send in more than ${AMOUNT_OF_ART} pieces of art! The gift card will cover
the first ${AMOUNT_OF_ART} pieces, and you will be invoiced for anything beyond ${AMOUNT_OF_ART}.
If you'd like help estimating the cost of the additional art, please email us at ${HELP_EMAIL}.`,
  },
  {
    id: 5,
    title: 'Does the gift card expire?',
    body: 'No, Artkive gift cards have no expiration date.',
  },
]

const giftCardsHowItWorks = [
  {
    image: HowItWorks1,
    title: 'Redeem Gift Card Online',
    body: 'Your gift card recipient can redeem their gift card during the box checkout and/or by clicking the link above to receive an Artkive Box.',
  }, {
    image: HowItWorks2,
    title: `Send Us ${AMOUNT_OF_ART} Art Pieces`,
    body:
      `Using the included Artkive Box & prepaid label, your recipient will send us ${AMOUNT_OF_ART}
pieces of art for their book.`,
  }, {
    image: HowItWorks3,
    title: 'Receive Book',
    body: 'After your gift card recipient has approved a digital proof of their book, we will print & ship it to them.',
  },
]

const GIFT_CARD_TYPES = {
  physical: 'physical',
  egift: 'egift',
}

const TrackTypography = withClickTracker(Typography)


const useStyles = makeStyles(({ breakpoints }) => ({
  productSection: {
    backgroundColor: '#D3EFFB',
    backgroundImage: `url(${HeroMobile})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    [breakpoints.up('sm')]: {
      backgroundImage: `url(${Hero})`,
      backgroundPosition: 'left top',
    },
    [breakpoints.up('md')]: {
      backgroundPosition: 'center',
    },
  },
  productContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: '400px',
    position: 'relative',
    [breakpoints.up('md')]: {
      alignItems: 'center',
      minHeight: '450px',
    },
  },
}))

const GiftCards = () => {
  usePageScrollTracker()
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()
  const { fetchProducts } = useStoreActions(({ giftCardStore }) => giftCardStore)

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    fetchProducts()
  }, [])

  const handleModalOpen = useCallback(() => {
    setModalOpen(true)
  }, [setModalOpen])

  return (
    <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>

      {/* I CAN BE YOUR HERO BABAY */}
      <Box className={classes.productSection}>
        <Container className={classes.productContainer}>
          <Box pt={isMobile ? 2 : 0} pb={6} textAlign={'center'} color={theme.colors.primary.dark}>
            <Typography color={'inherit'} variant={'h2'}>
              Give the complete Artkive experience!
            </Typography>
          </Box>

          <Hidden smDown>
            <Paper elevation={0} style={{ width: '100%', position: 'absolute', bottom: '-30%' }}>
              <Box px={4} py={6}>
                <IncludesListing />

                {/* Redeem Button */}
                <Box mt={5} textAlign={'center'}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography display={'inline'}>
                        Looking to redeem your giftcard? &nbsp;
                      </Typography>

                      <TrackTypography
                        display={'inline'}
                        color={'primary'}
                        onClick={handleModalOpen}
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        variant={'subtitle2'}
                        trackerEvent={GIFT_CARDS.REDEEM_GIFT_CARD_OPEN}
                      >
                        Click Here
                      </TrackTypography>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Hidden>
        </Container>
      </Box>
      {/* I CAN BE YOUR HERO BABAY */}

      <Hidden mdUp>
        <GiftCardIncludeSection onClick={handleModalOpen} />
      </Hidden>

      <Hidden smDown>
        <Box my={10} py={4}>&nbsp;</Box>
      </Hidden>

      {/* Everybody got choices */}
      <Box mt={4} textAlign={'center'}>
        <Box mb={6}>
          <Typography gutterBottom variant={'h3'}>What type of gift card would you like?</Typography>
        </Box>
        <Container>
          <Grid container spacing={2}>
            <Hidden smDown><Grid item md={2}></Grid></Hidden>

            <Grid item xs={12} md={4}>
              <Paper
                elevation={0}
                style={{
                  border: '0 none',
                  height: '100%',
                }}>
                <Box p={isMobile ? 2 : 6}>
                  <Box mb={4}>
                    <Typography gutterBottom variant={'h6'}>
                      Physical Card
                    </Typography>
                  </Box>

                  <Typography style={{ minHeight: '100px' }}>
                    Arrives within 5 business days, in a gift box complete with Artkive ribbon.
                  </Typography>

                  <TrackButton
                    color={'primary'}
                    fullWidth
                    style={{ maxWidth: '250px' }}
                    variant={'contained'}
                    trackerEvent={GIFT_CARDS.GET_PHYSICAL_CLICK}
                    component={Link}
                    to={`/giftcards/${GIFT_CARD_TYPES.physical}/checkout`}
                  >
                    Get Physical
                  </TrackButton>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper elevation={0} style={{ cursor: 'pointer', height: '100%' }}>
                <Box p={isMobile ? 2 : 6}>
                  <Box mb={4}>
                    <Typography gutterBottom variant={'h6'}>
                      e-Gift Card
                    </Typography>
                  </Box>

                  <Typography style={{ minHeight: '100px' }}>
                    Sent via email to you or directly to your recipient — your choice.
                  </Typography>

                  <TrackButton
                    color={'primary'}
                    fullWidth
                    style={{ maxWidth: '250px' }}
                    variant={'contained'}
                    trackerEvent={GIFT_CARDS.GET_EGIFT_CARD_CLICK}
                    component={Link}
                    to={`/giftcards/${GIFT_CARD_TYPES.egift}/checkout`}
                  >
                    Get e-Gift Card
                  </TrackButton>
                </Box>
              </Paper>
            </Grid>

            <Hidden smDown><Grid item md={2}></Grid></Hidden>
          </Grid>
        </Container>
      </Box>

      <Box mt={10}>
        <HowItWorks
          variant={'icon'}
          title={'How Gift Cards Work'}
          steps={giftCardsHowItWorks}
        />
      </Box>

      {/* faqs */}
      <Faqs faqs={FAQS} trackerEvent={GIFT_CARDS.FAQ_ITEM_OPEN} />
      {/* <Box bgcolor={theme.colors.white.dark} pb={8} textAlign='center'>
        <Swipe href={routes.faqs} targer='_blank' underline='none' variant='h6' >Read All FAQs</Swipe>
      </Box> */}

      {/* redeem modal */}
      <Redeem isMobile={isMobile} isOpen={modalOpen} handleClose={() => setModalOpen(false)} />
    </Box>
  )
}

export default GiftCards
