import request from 'common/utils/request'

export const fetchBoxPrice = async (payload) => {
  const { data: { data } } = await request.post(Routing.pricing_api_v2_orders_box_index(), { concierge: payload })

  return data
}

export const fetchPackagePrice = async (payload) => {
  const { data: { data } } = await request.post(Routing.pricing_api_v2_orders_packages(), payload)

  return data
}
