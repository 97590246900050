import React from 'react'

import { Box, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { MIN_AMOUNT_OF_PICS, MIN_PRICE, USB_PRICE } from 'common/utils/conciergeDigitalInvoiceItem.constants'
import { HELP_EMAIL, OLD_HELP_EMAIL } from 'artkive/utils/company'
import routes from 'artkive/utils/routes'

const useStyles = makeStyles({
  paddingTop: {
    '&::before': {
      content: "' '",
      display: 'block',
      paddingTop: '80px',
      visibility: 'hidden',
    },
  },
})

const PriorBody = () => {
  const classes = useStyles()

  return (
    <Box>
      <span id={'previous-terms-of-use'} className={classes.paddingTop} />
      <Typography align={'center'} variant={'h3'}>Previous Terms of Use May 24, 2022 - July 10, 2024</Typography>
      <br />
      <Typography marginBottom={true}>
            THIS IS A BINDING LEGAL CONTRACT. EACH USER (AS DEFINED HEREIN) SHOULD CAREFULLY READ THESE TERMS OF USE
            ('TERMS OF USE') BEFORE USING, VIEWING OR ACCESSING THE COMPLIMENTARY SERVICES OR PAID SERVICES, INCLUDING
            BUT NOT LIMITED TO ANY DOWNLOADABLE, TEXT MESSAGED, AND EMAILED CONTENT CONTAINED THEREIN OR PROVIDED IN
            CONNECTION THERWITH (collectively the “Services”). THE USER AGREES TO BE BOUND BY THESE TERMS OF USE, AND BE
            LIABLE TO THE KIVE COMPANY, A DELAWARE CORPORATION (THE “COMPANY”), FOR ANY NONCOMPLIANCE WITH THESE TERMS
            OF USE. IF THE USER DOES NOT AGREE TO THESE TERMS OF USE, USER SHOULD CLOSE THE WINDOW OR TAB FOR
            ARTKIVEAPP.COM AND NOT USE THE WEBSITE OR APP.
      </Typography>
      <br />
      <hr />
      <br />
      <Typography marginBottom={true}>
            IMPORTANT PAYMENT TERMS:
      </Typography>

      <p>
            By signing up for the Artkive Box service and sending in your art, you agree
            to the following terms as stated below. To summarize the most important items:
      </p>

      <ol>
        <li>
              You are charged based on how many pieces of art you send in your box.
              Each piece of art sent in will be photographed and included in your order.
        </li>

        <li>
              If you decide you don't want to print a book, once the art has been photographed,
              you are responsible for paying the "Digitization Fee" which is the minimum required
              payment. See pricing <a href={'https://www.artkiveapp.com/digitization'}>here</a>. Your
              images will be provided to you on a USB drive.
        </li>

        <li>
              If you do not make timely payment within 30 days of receiving your invoice, Artkive
              reserves the right to charge your method of payment the "Digitization Fee." You may
              then add books and pay the incremental fee for those later if desired.
        </li>

        <li>
              If we are unable to collect payment, your account will go to a collections agency.
              At that time, you are responsible for all allowable late payment fees and collection
              fees (Collection fees are the lesser of approximately 30% of the total owed or the
              maximum allowable fee as defined by CA state laws).
        </li>
      </ol>

      <br />

      <ol>
        <li><u>Services.</u></li>
        <ol type={'a'}>
          <li>“Complimentary Services:”</li>
          <ol type={'i'}>
            <li>The “Website,” located at <Link href={routes.home}>"www.artkiveapp.com"</Link>, provides Users with
                  information about the Company's offerings.
            </li>
            <li>The “App,” when downloaded to a User's mobile devise provides Users with limited free storage of
                  images, the ability to share and receive images, and the ability to purchase certain products and
                  services.
            </li>
            <li>Company “Newsletters” provide Users with additional information about the Company's offerings and
                  other promotional material.
            </li>
          </ol>
          <li>“Paid Services:”</li>
          <ol type={'i'}>
            <li>
                  A “Membership” provides some or all of the following, depending on the specific Membership chosen:
                  access to additional storage, discounts on books or boxes, additional savings, book credits, and
                  expedited order processing through the Company's Website or App conditioned on a Subscriber's payment
                  of the applicable monthly or annual fee (“Fee”).
            </li>
            <li>
                  An “Artkive Box” provides Users (as defined below) the ability to ship art to the Company and
                  receive the additional products and services described in subsections (iii)-(vi) below. It is
                  available through the Website and App conditioned on a payment of applicable fees: the first payment
                  to receive the box (“Initial Fee”) and subsequent payment for the balance based on the quantity of
                  images and products and/or services elected (“Final Fee”).
            </li>
            <li>
                  An “Artkive Book” is a printed book from images uploaded to the App or digitized through using the
                  Artkive Box and/or Other Artkive Services conditioned on a User's payment of the applicable fee.
            </li>
            <li>
                  A “Mosaic” is a print or framed print of multiple images uploaded to the App or digitized through
                  using the Artkive Box conditioned on a User's payment of the applicable fee.
            </li>
            <li>
                  “Digitization” is the process whereby the Company photographs the art provided to Company in an
                  Artkive Box. This services is only available to Artkive Customers that have purchased an Artkive Box.
            </li>
            <li>
                  A “USB Drive” is a USB drive containing the artwork that has been digitized by the Company. This
                  product is only available to Artkive Customers that have purchased an Artkive Box and Digitization.
            </li>
            <li>
                  “Gift Cards” are certificates entitling the Artkive Customer or
                  an Artkive Customer recipient to apply certain credits or funds
                  to a future order. “Gift Cards” may be used for a specific product
                  or service as noted on the physical or electronic gift card and as
                  offered by the Company.
            </li>
            <li>
                  “Other Artkive Services” are a collection of other services offered to customers that include, but are
                  not limited to, Artkive Exchange and Discounted Packages associated with the Artkive Box Services. The
                  payment for such services will be presented at the Company’s Website, and any fees associated with
                  Other Artkive Services that are not initially collected will be published at the Company’s Website and
                  in email or printed information provided to the Customer following their purchase.
            </li>
          </ol>
        </ol>

        <li>
          <u>Users.</u> The users consist of the following roles (collectively the “Users,” and each individually
              a “User”):
        </li>
        <ol type={'a'}>
          <li>
                A “Viewer” may view publicly available content on the Website general information about Kive and its
                Services and may receive and view shared images from other Users through the App's sharing capabilities.
          </li>
          <li>
                A “Reader” is an individual that has opted-in to receive the Newsletters from Company via email or
                other means of communication.
          </li>
          <li>
                An “Artkive Customer” is an individual that purchases an Artkive Box, Artkive Book, Mosaic, Book
                Credit, Gift Card, or Other Artkive Services.
          </li>
          <li>
                A “Member” is an individual that purchases a monthly or annual Kive Membership.
          </li>
        </ol>

        <li><u>Refer A Friend Program.</u>

          <Typography>
                By participating in the Artkive Refer a Friend program, you represent that
                you agree to these terms and conditions. To qualify for a qualifying discount
                on an Artkive Box purchase, the referred person must (i) be a new customer,
                and (ii) use a referral link (or referrer's email address) to obtain the
                promotional code. Referring customers will receive a referral credit redeemable at
            {' '}<Link href={routes.home}>"www.artkiveapp.com"</Link>{' '}
                after the referred friend makes a purchase. Refer a Friend
                credits can be applied to book purchases, the second payment of Artkive boxes,
                and Framed Mosaics.
          </Typography>
        </li>

        <ol type={'a'}>
          <li>
                Referral credits expire one year after issue. Your credit balance can be found
                in the Artkive app or by logging into your account on a non-mobile device. Your
                current balance can be found at My Artkive/Account/Book Credits.
          </li>

          <li>
                In the event the referred friend cancels their order, the referral credit awarded
                to the referring customer will be rescinded. You may not earn credit for referring
                yourself with your referral code. Referral credit cannot be applied to previous
                purchases, is not redeemable for cash and is non-transferable. Artkive Refer a
                Friend credits may not be redeemed on the following: memberships, gift cards, or
                Artkive Box shipping kits (initial purchase). Other exclusions may apply.
          </li>

          <li>
                Artkive is not responsible for communication errors, including distribution of
                Refer a Friend Credits, due to a change of email address or other changes in
                contact information. Artkive reserves the right, at its sole discretion and
                without prior notice, to suspend, change or terminate the program; to modify,
                limit or suspend the use of referral credits; or to modify or change redemption
                procedures.
          </li>
        </ol>

        <br />

        <Typography>
              - To Contact the Artkive Refer a Friend Program: Please send an email to <Link
            href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</Link>
        </Typography>

        <li><u>License.</u></li>
        <ol type={'a'}>
          <li>
                The Company hereby grants to Users a revocable, limited, non-assignable, non-sublicensable,
                nonexclusive license for the duration of User's current viewing session to access, view and use the
                Website and App, subject to the limitations set forth in these Terms of Use.
          </li>
          <li>
                The Company hereby grants to Readers a revocable, limited, non-assignable, non-sublicensable,
                nonexclusive license to receive Newsletters, subject to the limitations set forth in these Terms of Use.
          </li>
          <li>The Company hereby grants to Artkive Customers and Members a revocable, limited, non-assignable,
                non-sublicensable, nonexclusive license to use the Website and App to upload and share art and purchase
                the Artkive Box, Artkive Book, Artkive Mosaic Book Credits and Gift Cards, subject to the limitations
                set forth in these Terms of Use.
          </li>
          <li>The Company hereby grants to Members, for a period beginning on the date of purchase and expiring upon
                cancellation by the Member or Termination by the Company, a revocable, limited, non-assignable,
                non-sublicensable, nonexclusive license to access a Membership, subject to the limitations set forth in
                these Terms of Use.
          </li>
          <li>The licenses granted in subsections (a)-(d) above may be terminated immediately and without notice by
                the Company. All rights not expressly granted in these Terms of Use are reserved. User agrees that the
                license granted in these Terms of Use is personal to User, and User may not assign or transfer any
                license granted under these Terms of Use, or transfer any rights or delegate any duties under these
                Terms of Use, to any third party. Any attempt to assign, transfer, or delegate any of User's rights,
                duties, or obligations under these Terms of Use shall be void. User shall indemnify and hold Company
                harmless for any unauthorized use of the Services, including but not limited to violations of the
                provisions of Section 7(a).
          </li>
        </ol>

        <li><u>Limitations.</u></li>
        <ol type={'a'}>
          <li>You must be at least eighteen (18) years old to access the Services. If you are not at least eighteen
                years old, you are not permitted to access the Services for any reason. User may not circumvent any
                technological measures or features of the Services that are intended to or effectively control access to
                the Services, or any other protected content or information included in the Services.
          </li>
          <li>The Website may contain robot exclusion headers. User agrees to not use any robot, spider, crawler,
                scraper or other automated means to access the Website for any purpose without the Company's express
                prior written consent.
          </li>
          <li>User further agrees to not interfere or attempt to interfere with the proper working of the Website or
                App or any activities conducted on the Website or App.
          </li>
          <li>The Company reserves the right to refuse the use of or access to any Company Services for any User for
                any reason in the Company's sole discretion.
          </li>
        </ol>

        <li><u>Payment, Expiration, and Refund of Fees.</u></li>
        <ol type={'a'}>
          <li>
                Artkive Box. Pricing for the Artkive Box and quantity of
                pictures shall be published on the Website. Upon purchase,
                Users shall be billed an Initial Fee. Upon Digitization,
                the Company shall invoice User for the balance remaining
                based on the quantity of images and the type of products
                and/or services ordered. Artkive Boxes order may be cancelled
                or changed as follows: (i) on the same day they are purchased,
                Artkive Box orders may be cancelled with a $5 cancellation fee,
                that shall be deducted from the refund, or (ii) prior to Digitization,
                Artkive Customers may update the Artkive Box order to cancel
                related Artkive Book, Mosaic and/or USB Drive orders, subject
                to payment in full of the Digitization Fee and any remaining
                products and services orders. A User's Artkive Box is valid for
                one (1) year from the date of purchase.
          </li>
          <li>
                Artkive Book. Users may purchase Artkive Books through the Artkive Box as described in subsection (a)
                above, through uploads through the App according to the rates published on the App and Website, and
                through Other Artkive Services. Purchases made through the App shall be billed in full upon purchase.
                Once ordered through the App, there are no refunds for Artkive Books.
            <ol>
              <li>Mosaic. Users may purchase Mosaic through the Artkive Box as described in subsection (a) above or
                    through uploads through the App according to the rates published on the App and Website. Purchases
                    made through the App shall be billed in full upon purchase. Once ordered through the App, there are
                    no refunds for Mosaics.
              </li>
            </ol>
          </li>
          <li>Digitization and USB Drives. Artkive Customers may restrict their Artkive Box purchase to Digitization
                and USB drive purchases. The “Digitization Fee“ is ${MIN_PRICE} for up to {MIN_AMOUNT_OF_PICS} pieces
                of art and then an additional $1.25 per piece of art thereafter. USB Drives can be purchased according
                to the rates published on the App and Website (typically ${USB_PRICE} each). Artkive Customers shall be
                billed for Digitization and USB Drives as set forth in subsection (a) above. Except for cancellations
                of the Artkive Box made on the same day of the order, there are no refunds for Digitization. USB drive
                orders can be cancelled prior to Digitization and such cancellation is entitled to a full refund for
                the cost of the USB Drive.
          </li>
          <li>
                Gift Cards. Upon purchase, Artkive Customers shall be billed
                in full for the applicable dollar value of the Gift Card. The
                dollar value of Gift Cards shall never expire. There are no
                refunds for Gift Cards.
          </li>
          <li>
                Other Artkive Services. Pricing for Other Artkive Services shall be published on the Website or
                affiliated sites. Upon purchase, Users shall be billed a Fee. Some services may have an Additional Fee
                based on quantity of art associated with the Services and/or type of Services provided. All purchases
                made through Other Artkive Services are non-refundable, unless otherwise explicitly noted.
            <ol>
              <li>
                    An “Exclusive Book Discount'' is a discounted book purchased by a User in advance of the Company
                    receiving the Customer’s Artkive Box. This product is only available to Artkive Customers that have
                    already purchased an Artkive Box (additional fees may apply if User sends Company more items than
                    previously purchased).
              </li>
              <li>
                    “Artkive Exchange” is a service that provides Users the ability to purchase a book upfront and then
                    ship the art for their prepaid book to the Company using their own packaging and shipping label.
                    Upon the Company receiving the User’s Artkive Exchange shipment, the Company will photograph, edit,
                    and create a book based on the prepaid book size purchased by the User (additional fees may apply if
                    User sends Company more items than previously purchased).
              </li>
              <li>
                    “Artkive International” is a service that provides Users outside of the United States the ability to
                    purchase a book upfront and then ship the art for their book to the Company using their own
                    packaging and shipping label. Upon the Company receiving the User’s Artkive International shipment,
                    the Company will photograph, edit, and create a book based on the prepaid book size purchased by the
                    User (additional fees may apply if User sends Company more items than previously purchased).
              </li>
            </ol>
          </li>
          <li>
                Memberships. Upon purchase, Members shall be billed a Fee
                consisting of either (i) the entire annual payment (“Annual Fee”)
                or (ii) the first monthly payment (“Monthly Fee”) according to the
                rates and Membership tiers published on the Website or App.
                Thereafter, the Members who choose to pay monthly shall be
                automatically billed the Monthly Fee on a monthly basis until
                \termination. Members who choose to pay annually shall be
                automatically billed the Annual Fee on an annual basis until
                termination. The Company may increase the Monthly Fee and Annual
                Fee due in connection with your Membership by providing thirty (30)
                days' notice. Monthly Fees and Annual Fees are non-refundable.

            <ol>
              <li>
                    If a Membership is selected that provides book credits, your book
                    credits expire 12 months after signing up, but new credits are issued
                    at the start of each annual billing period.
              </li>
            </ol>
          </li>
          <li>If payment is not made within 30 days, we reserve the right to process the minimum balance due using
                the original method of payment. The minimum balance due is the cost of digitization.
          </li>
          <li>
                Shipping Protection Package
            <ol>
              <li>The Company cannot and does not guarantee that your Art will not be accidentally damaged while in
                    the possession of the shipping carrier, nor does the Company assume the risk of loss for your Art
                    while it is in transit. Instead, the risk of loss remains with you during transit, and you bear all
                    responsibility of loss or damage of your original content until it is received by the Company.
              </li>
              <li>If you have purchased the Shipping Protection Package, your shipment to Artkive is guaranteed with
                    the $500 in additional protection, which offers limited protection for complete package loss or
                    misdirection while in the possession of the shipping carrier. The return shipment from the Company
                    to you is guaranteed with one year on-site digital backup as added safety protection in the unlikely
                    event of complete package loss or misdirection while in the possession of the shipping carrier. You
                    further acknowledge and agree that the safe handling guarantee is a reasonable approximation of the
                    damages that would be suffered by you from the loss of your entire order and is not a penalty.
              </li>
              <li>The Shipping Protection Package is not insurance and the Company is not an insurer. You
                    acknowledge that the Shipping Protection Package is optional, and if you choose the Shipping
                    Protection Package, it only covers complete package loss or misdirection while in the possession of
                    the shipping carrier. The Shipping Protection Package is not a substitute for improper packing and
                    the plan will not cover damage to art that was not packed according to Artkive's instructions.
              </li>
            </ol>
          </li>
        </ol>
        <li>
          <u>Mobile Message Service Terms and Conditions.</u>
        </li>
        <ol type={'a'}>
          <li>
                The Artkive mobile message service (the "Service") is operated by the Kive Co. (“The Kive Co.”, “we”, or
                “us”). Your use of the Service constitutes your agreement to these terms and conditions (“Mobile
                Terms”). We may modify or cancel the Service or any of its features without notice. To the extent
                permitted by applicable law, we may also modify these Mobile Terms at any time and your continued use of
                the Service following the effective date of any such changes shall constitute your acceptance of such
                changes.
                We do not charge for the Service, but you are responsible for all charges and fees associated with text
                messaging imposed by your wireless provider. Message and data rates may apply.
          </li>
          <li> Text messages may be sent using an automatic telephone dialing system or other technology. Your
                consent to receive autodialed marketing text messages is not required as a condition of purchasing any
                goods or services. If you have opted in, the Service provides updates, alerts, and information (e.g.,
                order updates, account alerts, etc.)], promotions, specials, and other marketing offers (e.g., cart
                reminders)] from The Kive Co. via text messages through your wireless provider to the mobile number you
                provided. Message frequency varies. Text the single keyword command STOP to 657-888-0278 to cancel at
                any time. You'll receive a one-time opt-out confirmation text message. If you have subscribed to other
                Kive Co. mobile message programs and wish to cancel, except where applicable law requires otherwise, you
                will need to opt out separately from those programs by following the instructions provided in their
                respective mobile terms. For Service support or assistance, text HELP to 657-888-0278 or email
          {HELP_EMAIL}.
          </li>
          <li> We may change any short code or telephone number we use to operate the Service at any time and will
                notify you of these changes. You acknowledge that any messages, including any STOP or HELP requests, you
                send to a short code or telephone number we have changed may not be received and we will not be
                responsible for honoring requests made in such messages.
          </li>
          <li> The wireless carriers supported by the Service are not liable for delayed or undelivered messages.
                You agree to provide us with a valid mobile number. You agree to maintain accurate, complete, and
                up-to-date information with us related to your receipt of messages.
          </li>
          <li> To the extent permitted by applicable law, you agree that we will not be liable for failed, delayed,
                or misdirected delivery of any information sent through the Service, any errors in such information,
                and/or any action you may or may not take in reliance on the information or Service.
          </li>
        </ol>

        <li>
          <u>Changes to Terms of Use.</u>
          <Typography>The Company reserves the right, in its sole discretion, to modify, update, or otherwise revise
                these Terms of Use at any time. Such revisions shall be effective immediately upon posting revised Terms
                of Use on the Website or App. By using the Website or App after the Company has posted any
                modifications, updates or revisions, User agrees to be bound by such revised Terms of Use. User shall
                have the right to immediately terminate use of the Services, if any modification, update, or other
                change to these Terms of Use is not acceptable to User. In addition to these Terms of Use, additional
                terms may govern use of certain materials and events associated with the Company and Services, and User
                agrees to be bound by such terms.</Typography>
        </li>

        <li><u>Intellectual Property Ownership.</u></li>
        <ol type={'a'}>
          <li>User acknowledges and agrees that the trademarks of the Company, including but not limited to the logo
                and “ARTKIVE” wordmark (the “Marks”), the Website, the App, and the look and feel of and content made
                accessible by the Services (“Other Company IP”), to the extent protectable, are proprietary, original
                works of authorship of the Company, or licensors of the Company, protected under United States and
                worldwide copyright, trademark, and trade secret laws of general applicability. User further
                acknowledges and agrees that all right, title and interest in and to the Marks and Other Company IP and
                shall remain with the Company, or its licensors. User agrees not to contest or infringe these rights,
                directly or indirectly, at any time. Without the prior written consent of the Company, User's
                modification of the content, use of the content on any other website or networked computer environment,
                or use of the content for any purpose other than personal, non-commercial use, violates the copyrights,
                trademarks or other intellectual property rights of the Company or its licensors, and is prohibited.
                Except as expressly provided under these Terms of Use, User may not use the Marks, the Other Company IP,
                or any other trademarks or copyrighted materials appearing within the Service, including without
                limitation any logos, without the express prior written consent of the owner of the mark or copyright.
          </li>
          <li>We welcome your comments about the Services. However, it is against our policy to review or consider
                any unsolicited submissions or suggestions because we want to avoid the possibility of future
                misunderstandings in the event that ideas developed by the Company might seems similar to the ideas
                submitted to us. Accordingly, we request that you refrain from sending us any original creative ideas,
                suggestions or materials, particularly those that are confidential or personal to you. If you send us
                any ideas, suggestions of material, it shall become property of the Company, and we will not be subject
                to any obligation of confidence nor liable for any use or disclosure of any submission.
          </li>
          <li>Upon uploading or posting photographic images, videos or any descriptive text about a photograph or
                video within our App or on any Company Instagram, Facebook, or social media page (“User Content”), User
                hereby grants to the Company a non-exclusive, revocable, worldwide, royalty free license to display the
                User Content in connection with Company's provision of the Services. Such license shall be deemed
                granted as of the moment of creation without the necessity of any further action on the part of either
                party.
          </li>
          <li>In the event Company has requested permission from a User to publish and distribute any specific User
                Content, immediately upon User's confirmation that permission is granted, User hereby grants to the
                Company a non-exclusive, revocable, worldwide, royalty-free license to use, copy, sublicense through
                multiple tiers, publish reproduce, prepare derivative works distribute and display such User Content and
                any derivative works thereof. Revocation of such license shall not apply to Company's use of the User
                Content to provide the User with use of the Company Services or to any Company uses of the User Content
                prior to the revocation.
          </li>
          <li>User represents and warrants to the Company with respect to the User Content that the User Content,
                and the license of rights in and to the User Content, do not infringe or violate any copyrights, trade
                secrets, or other intellectual or proprietary rights of any third party, and that posting any User
                Content will not violate any applicable laws, rules, or regulations. User shall indemnify and hold
                harmless the Company, and its officers, directors, employees, shareholders, members, managers, and
                agents, from and against any and all claims, demands, liabilities, and actions, including the payment of
                all legal expenses, including reasonable attorney's fees and costs, arising out of or connected with any
                claim that the User Content or any portion of the User Content infringes the rights of any third party
                or any other damages arising from any use of, or reliance upon, the User Content by a third party,
                including but not limited to other Users of these Services. User Content includes but it not limited to
                all data and content, including without limitation, all photographs, images, graphics, video recordings,
                drawings, written descriptions, remarks, and narratives submitted by Users to the Company.
          </li>
          <li>
                Artkive reserves the right to not photograph any art or object
                it deems offensive which may include, but is not limited to, items
                that are racist, prejudice, violent, pornographic, or otherwise deemed
                objectionable by the team at Artkive
          </li>
          <li>User agrees not to challenge the Company's rights in and to the Services, or to take any action
                inconsistent with the provisions of this Section 7 of these Terms of Use.
          </li>
        </ol>

        <li><u>Links to Other Websites and Promotions.</u></li>

        <ol type={'a'}>
          <li>The Website and App may contain links to other websites (“Linked Websites”). The Linked Websites are
                provided for User's convenience and information and, as such, User accesses them at its own risk.
                Without limiting the generality of the foregoing, the Company is not responsible and shall have no
                liability for any viruses or other illicit code that may be downloaded through a link found on the
                Website, or by accessing a Linked Website.
          </li>
          <li>In the event you elect to participate in any third party promotions or engage in third party services
                that may have been introduced or otherwise promoted on the Website, App or Newsletter, any
                correspondences or participation with such third parties, including the delivery of and the payment for
                products, services or content, are solely between you and each such third party.
          </li>
        </ol>

        <li>
          <u>Interruptions to the Services.</u>
          <Typography>User acknowledges that the functionality of this Website and App, and links to join the
                Newsletter are supported by third parties, and that interruptions to and failure of these sites may
                occur from time-to-time. Access to the Services and their functions may from time-to-time be unavailable
                to User, whether because of technical failures or interruptions, intentional downtime for service or
                changes to the Services, or otherwise, all of which are outside of the control of the Company. User
                agrees that any modification of the Services, and any material interruption or unavailability of access
                to the Services caused by third parties shall not constitute a default of any obligations of the Company
                under these Terms of Use, and, the Company shall have no liability of any nature to User for any such
                modifications, interruptions, unavailability, or failure of access. In the event the User experiences a
                material interruption or is unable to access content, please contact Company at: <a
            href={`mailto:${OLD_HELP_EMAIL}`}>{OLD_HELP_EMAIL}</a></Typography>
        </li>

        <li>
          <u>Notification of Potential Infringement.</u>
          <Typography>If any User believes its copyright, trademark or other property rights have been infringed by
                any content included or posted within the Services, User should immediately send a notification to the
                Company's designated agent (“Designated Agent”), as identified below. To be effective, the notification
                must include: (i) A physical or electronic signature of a person authorized to act on behalf of the
                owner of an exclusive right that is allegedly infringed; (ii) Identification of the copyrighted work
                claimed to have been infringed; (iii) Information reasonably sufficient to permit the Company to contact
                the complaining party, such as address, telephone number and, if available, an electronic mail address
                at which the complaining party may be contacted; (iv) Identification of the material that is claimed to
                be infringing or to be subject to infringing activity and that is to be removed and information
                reasonably sufficient to permit the Company to locate the material; (v) A statement that the complaining
                party has a good faith belief that use of the material in the manner complained of is not authorized by
                the copyright owner, agent, or the law; and (vi) A statement that the information in the notification is
                accurate and, under penalty of perjury, that the complaining party is authorized to act on behalf of the
                owner of an exclusive right that is allegedly infringed. Pursuant to the Digital Millennium Copyright
                Act (“DMCA”), 17 U.S.C. § 512(c), the Company's Designated Agent for notice of claims of copyright
                infringement can be reached as indicated below. The Designated Agent shall be: Ruth McKee, Artkive,
                15800 Arminta Street, Van Nuys, CA 91406.</Typography>
        </li>

        <li>
          <u>Representations, Warranties, and Covenants.</u>
          <Typography>User represents, warrants, and covenants the following: (a) User shall not use its account to
                breach the security or gain access to the account of any other User and (b) These Terms of Use, upon use
                of the Services, will be valid, binding and enforceable in accordance with its terms with respect to
                User.</Typography>
        </li>

        <li>
          <u>Confidential Information.</u>
          <Typography>User understands that the tools, processes, strategies, materials and information presented
                within the Services are copyrighted and proprietary, so User agrees not to record, duplicate,
                distribute, teach or train from our information in any manner whatsoever without express written
                permission. Any information provided by the Company to User, or information used by User to access the
                Services, including without limitation, any user identification or password to the Website or App,
                (collectively “Confidential Information”) shall be maintained by User as confidential and available
                exclusively for User as provided in these Terms of Use. User shall not disclose any Confidential
                Information to anyone, except as ordered by a court of competent jurisdiction or as otherwise required
                by law. User shall not disclose any Confidential Information pursuant to a court order or as required by
                law until User has given the Company ten (10) days prior written notice and an opportunity to oppose
                such disclosure. Notwithstanding the foregoing, User may disclose User Content originally submitted by
                User.</Typography>
        </li>

        <li><u>Disclaimers.</u></li>
        <ol type={'a'}>
          <li>THE LICENSE GRANTED TO USER UNDER THESE TERMS OF USE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
                BASIS, WITHOUT ANY WARRANTY OF ANY NATURE. THE COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
                WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE.
          </li>
          <li>Company makes no representation that materials on this site are appropriate or available for use in
                locations outside the United States and accessing them from territories where their contents are illegal
                is prohibited. Those who choose to access this site from other locations do so on their own initiative
                and are responsible for compliance with local laws.
          </li>
          <li>Company does not warrant that (i) the content is accurate, reliable or correct, (ii) the Services will
                be available at any particular time or location, (iii) any defects or errors will be corrected, or (iv)
                the content is free of viruses or other harmful components. Your use of these Services is solely at your
                own risk. Where any such exclusion of warranties are not permitted in your jurisdiction, such exclusion
                does not apply to you.
          </li>
        </ol>

        <li><u>Limitation of Liability.</u></li>
        <ol type={'a'}>
          <li>THE COMPANY'S ENTIRE AND CUMULATIVE LIABILITY TO USER, OR ANY OTHER PARTY, FOR ANY LOSS OR DAMAGES
                RESULTING FROM ANY CLAIMS, DEMANDS, OR ACTIONS ARISING OUT OF OR RELATING TO THESE TERMS OF USE, THE
                services OR CONTENT SHALL NOT EXCEED AN AMOUNT EQUAL TO $50.00 OR THE AMOUNT PAID FOR A PROGRAM
                PURCHASED THROUGH THE WEBSITE.
          </li>
          <li>WITHOUT LIMITING AND NOTWITHSTANDING THE FOREGOING, IN NO EVENT SHALL THE COMPANY BE LIABLE TO USER
                FOR ANY LOSS OF BUSINESS OR ANTICIPATORY PROFITS OR ANY INDIRECT, SPECIAL, INCIDENTAL, GENERAL,
                EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, EVEN IF FORESEEABLE OR IF THE COMPANY HAS
                BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS, DAMAGE OR EXPENSE.
          </li>
          <li>ALTHOUGH THE COMPANY WILL USE COMMERCIALLY REASONABLE EFFORTS TO TAKE ALL ACTIONS IT DEEMS APPROPRIATE
                TO REMEDY AND AVOID data and internet interuptions, THE COMPANY CANNOT GUARANTEE THAT SUCH EVENTS WILL
                NOT OCCUR. ACCORDINGLY, THE COMPANY DISCLAIMS ANY AND ALL LIABILITY RESULTING FROM OR RELATED TO SUCH
                EVENTS.
          </li>
        </ol>

        <li><u>Termination.</u></li>
        <ol type={'a'}>
          <li>The Company may at any time and without notice terminate User's license upon the occurrence of any of
                the following events: (i) User requests in writing to the Company that its license be terminated; (ii)
                User defaults under any material term or condition of these Terms of Use, or (iii) Company has
                determined a termination is in the Company's best interest.
          </li>
          <li>Readers may terminate by selecting the option to unsubscribe in any email communication they receive
                or by providing the Company with written notice.
          </li>
          <li>Members may terminate (i) in the event Company fails to cure a material breach of its agreement to
                provide the license set forth in Sections 3(c) or 3(d) within ten (10) days' written notice.
          </li>
          <li>Upon termination, unless otherwise agreed, all licenses granted and all services provided to User
                under these Terms of Use shall terminate at the end of the paid period. Specifically, the Company may
                terminate User's access to the Services. However, Members' user identifications and passwords are not
                typically disabled so as to facilitate future purchases. So long as the Company has not disabled the
                user identification or password, which it may do in its sole and absolute discretion, future purchases
                will not require former Members to create new user identifications or passwords.
          </li>
        </ol>

        <li>
          <u>California Residents.</u>
          <Typography>If this site charges for services, products, content, or information, pricing information will
                be posted as part of the ordering process for this site. We maintain specific contact information
                including an e-mail address for notifications of complaints and for inquiries regarding pricing policies
                in accordance with California Civil Code Section 1789.3. All correspondence should be addressed to our
                agent for notice at the following address:</Typography>
          <Typography>Notification of Consumer Rights Complaint or Pricing Inquiry: 3505 The Alameda, Santa Clara,
                CA 95050.</Typography>
          <Typography>You may contact us with complaints and inquiries regarding pricing and we will investigate
                those matters and respond to the inquiries.</Typography>
          <Typography>The Complaint Assistance Unit of the Division of Consumer Services of the Department of
                Consumer Affairs may be contacted in writing at 1020 N. Street, #501, Sacramento, CA 95814, or by
                telephone at 1-916-445-1254.</Typography>
        </li>

        <li>
          <u>Defamation; Communications Decency Act Notice.</u>
          <Typography>This site is a provider of "interactive computer services" under the Communications Decency
                Act, 47 U.S.C. Section 230, and as such, our liability for defamation and other claims arising out of
                any postings to this site by third parties is limited as described therein. We are not responsible for
                content or any other information posted to this site by third parties. We neither warrant the accuracy
                of such postings or exercise any editorial control over such posts, nor do we assume any legal
                obligation for editorial control of content posted by third parties or liability in connection with such
                postings, including any responsibility or liability for investigating or verifying the accuracy of any
                content or any other information contained in such postings.</Typography>
        </li>

        <li>
          <u>General.</u>
          <Typography>These Terms of Use shall be governed by and construed in accordance with the laws of the state
                of California and User voluntarily submits and consents to, and waive any defense to the jurisdiction of
                courts located in Los Angeles County, state of California, as to all matters relating to or arising from
                these Terms of Use. The Company and User agree that a breach or violation of Sections 3, 4, 7, 11 and 12
                of these Terms of Use will result in immediate and irreparable injury and harm to the Company, where, in
                addition to any and all remedies of law and other consequences under these Terms of Use, Company shall
                have the right to an injunction, specific performance or other equitable relief to prevent the violation
                of the obligation under these Terms of Use. The waiver by either party of, or the failure of either
                party to take action with respect to, any breach of any term, covenant or condition contained in these
                Terms of Use shall not be deemed to be a waiver of such term, covenant or condition, or subsequent
                breach of the same, or any other term, covenant or condition contained in these Terms of Use. If any
                action is brought by either party against the other party regarding the subject matter of these Terms of
                Use, the prevailing party shall be entitled to recover, in addition to any other relief granted,
                reasonable attorney's fees, costs, and expenses of litigation. Any provision of these Terms of Use which
                is determined by a court of competent jurisdiction to be invalid or otherwise unenforceable shall not
                invalidate or make unenforceable any other provision of these Terms of Use. The provisions of Sections 4
                and 7-18 of these Terms of Use shall survive the termination of these Terms of Use.</Typography>
        </li>
      </ol>
    </Box>
  )
}

export default PriorBody
