import React from 'react'

import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import usePageScrollTracker from 'artkive/components/Tracker/usePageScrollTracker'
import withApp from 'artkive/hoc/withApp'

import Link, { EmailLink } from './Link'

const useStyles = makeStyles(({ breakpoints }) => ({
  listWrapper: {
    lineHeight: '120%',
  },
  table: {
    overflowX: 'auto',
    margin: '15px 15px 15px -80px',

    [breakpoints.up('md')]: {
      marginLeft: -20,
      marginRight: 0,
    },
  },
}))

const Privacy = () => {
  const classes = useStyles()
  usePageScrollTracker()

  /* eslint-disable max-len */
  return (
    <Box py={10}>
      <Container>
        <Box>
          <Typography align={'center'} variant={'h2'}>Privacy Policy</Typography>
          <Typography align={'center'} variant={'body2'} style={{ fontStyle: 'italic' }}>Updated June 17, 2024</Typography>
          <ol className={classes.listWrapper}>
            <li>
              <u>INTRODUCTION</u>
              <p>The Kive Company, a Delaware corporation (“us,” “we,” “our,” “Kive” or “Company”) is committed to respecting the privacy rights of the Users of its services. We created this privacy policy (“Privacy Policy”) to give you confidence as you visit and use the services, and to demonstrate our commitment to fair information practices and the protection of privacy. This Privacy Policy is only applicable to the Company’s services, as made available primary via its websites and applications, and not to any other websites that you may be able to access from the websites or applications, each of which may have data collection, storage, and use practices and policies that differ materially from this Privacy Policy. By viewing our websites, using our applications, opting-in to our newsletters/marketing and promotional emails or SMS/text messaging service or purchasing any of our paid services, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
            </li>
            <li>
              <u>INFORMATION COLLECTION PRACTICES</u>
              <ol>
                <li>
                  <u>Types of Users.</u>
                  <ol type={'a'}>
                    <li>
                      A “Viewer” may view publicly available content on one of our websites or applications.
                    </li>
                    <li>
                      A “Reader” is an individual who has opted-in to receive a newsletter/marketing and promotional emails and/or SMS/text messaging from the Company or who has become an Artkive Customer.
                    </li>
                    <li>
                      An “Artkive Customer” is an individual that purchases a paid service from the Company.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Types of Information Collected.</u>
                  <ol type={'a'}>
                    <li>
                      We may track and collect the following categories of information when you visit our Site: (1) IP addresses; (2) domain servers; (3) types of computers accessing the Site; (4) types of web browsers used to access the Site; and (5) your wireless carrier (collectively “Traffic Data”). Traffic Data is anonymous information that does not personally identify you but is helpful for marketing purposes or for improving your experience on the Site. We also use “cookies” to customize content specific to your interests, to ensure that you do not see the same advertisement repeatedly, to track whether you complete a purchase, to remind you to complete your purchase, to store your password so you do not have to re-enter it each time you visit the websites and applications, and to administer other features on website and application that enhance your experience (see Section 4 below for more information about cookies, their usage and your ability to control and decline cookies that are not necessary).
                    </li>
                    <li>
                      In order to become a Viewer, Reader, or Artkive Customer (as defined in the Terms of Use), you may be required to provide us with certain information that personally identifies you (“Personal Information”). Personal Information includes but is not limited to your name, phone number, e-mail address, and physical address (“Contact Data”) and credit card and billing information (“Financial Data”). In the event you process a credit card transaction to purchase a paid service, we will collect the date and amount of each order, and purchased service type, price, and quantity (“Transaction Data”) which will be associated with the last four digits of the credit card you utilized (collectively, “Transaction History”). In addition, we may collect account log-in, financial account, debit card, or credit card number in combination with any required security or access code, password, or credentials allowing access to an account, which may be considered “Sensitive Personal Information” in certain jurisdictions. If you communicate with us by e-mail, text or chat or upload User Content pursuant to Kive’s Terms of Use, any information provided in such communication may be collected as Personal Information.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Uses of Information Collected.</u>
                  <ol type={'a'}>
                    <li>
                      We use Contact Data to (i) provide newsletters, marketing a promotional emails, and text messages to Readers and Artkive Customers, (ii) to create and manage accounts for Artkive Customers, (iii) provide customer service to Artkive Customers and any Viewers and Readers who contact us, (iv) analyze our Viewers’, Readers’ and Artkive Customers’ usage of our websites, applications and services, (v), send Readers and Artkive Customers information about the Company and its services and cart abandonment reminders, (vi) to contact Readers and  Artkive Customers for other legitimate business purposes.
                    </li>
                    <li>
                      We use Financial Data to charge Artkive Customers for the paid services ordered. We do not store or retain any of your Financial Data, except for the last four digits of your credit card and your billing address zip code.  Your Financial Data, along with Contact Data and Transaction Data, is processed directly by a trusted third-party processor through an API integration.
                    </li>
                    <li>
                      We use your Transaction History to accurately track the orders you have placed and answer any questions you may have in connection with any charges made to your credit cards.
                    </li>
                    <li>
                      We use Transaction Data and Traffic Data for the purpose of providing, analyzing and improving the service.
                    </li>
                    <li>
                      We use Sensitive Personal Information as reasonably necessary and proportionate to process the purchase of and perform the services, to prevent, detect, and investigate security incidents, and to perform other functions that may be required by law.
                    </li>
                    <li>
                      We may combine information we collect and/or receive about you to provide you with a personalized experience and to analyze and improve our services.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Retention of Information Collected.</u>
                  <p>
                    We will only retain your Personal Information and other data for as long as necessary to fulfill the purposes we collected it for, including for the purposes of our legitimate business interests and satisfying any legal or reporting requirements.  For clarity, retention periods may be extended if we are required to preserve your information or data because of litigation, investigations and other similar proceedings, or if a longer retention period is required or permitted by applicable law.
                  </p>
                </li>
                <li>
                  <u>Third Party Data Processors.</u>
                  <ol type={'a'}>
                    <li>
                      <p>
                        Kive engages certain third parties that may process data submitted to Kive to perform certain business-related functions and to increase the functionality of our services.  For example, we use third parties for credit card processing and shipping purchases in the provision of our services.  Third party companies provide various other services to us, such as monitoring and analyzing how our services are used or performing, processing payments, fulfillment, maintaining our databases, and assisting us with our communications with you. When we engage another company to perform such functions, we may provide them with information in connection with their performance of such functions. These third parties may analyze the data we provide, combine that data with publicly available data, and provide us with access to their analysis, reports and other deliverables. The chart below lists our third party data processors (or type of data processor), a description of the service they provide, types of Users from whom the processors may process data, and the type of data processed by each processor. The chart may be updated by Kive from time to time:
                      </p>
                      <div className={classes.table}>
                        <table>
                          <thead>
                            <tr>
                              <th colSpan={'1'}></th>
                              <th colSpan={'3'}>User Type</th>
                              <th colSpan={'4'}>Categories of Data</th>
                            </tr>
                            <tr>
                              <th></th>
                              <th>Viewer</th>
                              <th>Reader</th>
                              <th>Artkive Customer</th>
                              <th>Traffic Data and cookies</th>
                              <th>Contact Data</th>
                              <th>Financial Data</th>
                              <th>Sensitive Personal Information</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Google LLC (for analytics)</th>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Facebook, Inc. (for analytics)</th>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Amazon Web Services, Inc. (for database storage)</th>
                              <td></td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Mixpanel, Inc. (for analytics)</th>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Authorize.Net (for payment processing)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                            </tr>
                            <tr>
                              <th>Heartland Payment Systems (for payment processing)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                            </tr>
                            <tr>
                              <th>The Rocket Science Group DBA Mailchimp (for newsletters)</th>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>USPS, FedEx, UPS (for shipping)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Certain Company-trusted United States-based fulfillment companies (for order fulfillment)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Certain Company-trusted United States-based printers (for printing)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>Klaviyo, Inc. (for newsletters and SMS/text messaging)</th>
                              <td></td>
                              <td>X</td>
                              <td></td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <th>PayPal, Inc. (for payment processing)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                            </tr>
                            <tr>
                              <th>Global Payments Inc. (for payment processing)</th>
                              <td></td>
                              <td></td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                            </tr>
                            <tr>
                              <th>Twilio, Inc. (analytics through Segment)</th>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td>X</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </li>
                    <li>
                      To the extent these third parties have access to any of your data, and especially your Personal Information or a combination of data that is deemed to be personally identifiable, please know that they are contractually (i) limited to only use this data to perform specific tasks on our behalf and (ii) obligated not to disclose or use your information for any other purpose.
                    </li>
                    <li>
                      For your information, Google LLC recommends installing the Google Analytics Opt-out Browser Add-on - <Link href={'https://tools.google.com/dlpage/gaoptout'} /> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.  For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <Link href={'https://www.google.com/intl/en/policies/privacy'} />
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <u>YOUR RIGHTS AND THE COMPANY’S LEGAL BASIS FOR COLLECTING PERSONAL INFORMATION</u>
              <ol>
                <li>
                  <u>User Rights.</u> Users who wish to correct, update, change, or erase the Personal Information or Sensitive Information they submit to Company may do so through their account or by contacting the Company in writing.
                </li>
                <li>
                  <u>Information Requests.</u> Upon request, we will provide you with information about whether we hold any of your Personal Information or Sensitive Personal Information. Again, you may access, correct or request deletion of your personal information by logging into your account, or by contacting us. We will respond to your request within 30 days.
                </li>
                <li>
                  <u>The Company’s Legal Basis for Processing Your Personal Information and Sensitive Personal Information.</u> We process Personal Information and Sensitive Information of our Users for the following reasons:
                </li>
                <ol type={'a'}>
                  <li>
                    Processing Personal Information and Sensitive Personal Information is necessary for entering into and performing a contract with you.
                  </li>
                  <li>
                    Processing Personal Information and Sensitive Personal Information is necessary for the purposes of our legitimate business interests. Either we, or a third party, will need to process your Personal Information and Sensitive Personal Information for the purposes of our (or a third party's) legitimate interests, provided we have established that those interests are not overridden by your rights and freedoms, including your right to have your Personal Information and Sensitive Personal Information protected.  Our legitimate interests include responding to requests and inquiries from you or a third party, optimizing our website and customer experience, informing you about our products and services and ensuring that our operations are conducted in an appropriate and efficient manner.
                  </li>
                  <li>
                    Processing Personal Information and Sensitive Personal Information is necessary to obtain your consent. In some circumstances, we may ask for your consent to process your Personal Information and Sensitive Personal Information in a particular way.  To the extent that we are processing your Personal Information and Sensitive Personal Information based on your consent, you have the right to withdraw your consent at any time.  You can do this by contacting us through the methods provided below
                  </li>
                </ol>
              </ol>
            </li>
            <li>
              <u>USE OF COOKIES AND OTHER TECHNOLOGIES</u>
              <ol>
                <li>
                  <u>Use of Cookies.</u> We use and engage certain providers to use cookies, web beacons, and similar tracking technologies (collectively, “cookies”) in order to provide our websites, applications and services, to gather information about your usage patterns when you navigate the websites and applications, to enhance your personalized experience, and to understand usage patterns to improve our websites, applications and services. Cookies are small amounts of data that are stored on your browser, device, or the page you are viewing. More information about cookies and how they work is available at <Link href={'http://www.allaboutcookies.org/'}>All About Cookies</Link>.
                </li>
                <li>
                  <u>Accepting and Declining Cookies.</u> You have the ability to accept or decline cookies that are not necessary. You can both modify your browser setting and use our cookie management tool to decline cookies. If you choose to decline cookies, some interactive features of our sites and services that depend on cookies may not work, but you will be able to perform basic navigation through the site.  You can adjust your preferences at any time using our cookies management tool.
                </li>
                <li>
                  <u>Do Not Track.</u> Given the divergent practices of organizations that offer browsers and the lack of a standard in the marketplace, we generally do not respond to DNT signals at this time.
                </li>
              </ol>
            </li>
            <li>
              <u>COMMUNICATIONS</u>
              <ol>
                <li>
                  <u>Opting-Out.</u> If you have opted-in to receiving newsletters or the SMS/text messaging service or purchased any of the Company’s paid services, we may use your Personal Information to contact you with newsletters, texts, marketing or promotional materials, and other information that may be of interest to you. You may also opt-out of receiving any, or all, of these communications from us by contacting help@artkiveapp.com or following the unsubscribe link or instructions provided in any email or text we send.  For the SMS/text messaging service, you may also opt-out by texting the single keyword command STOP to +18333597668. You'll receive a one-time opt-out confirmation text message. No further text messages will be sent to your mobile device, unless initiated by you. Please note that opting-out will not prevent the Company from contacting Artkive Customers regarding issues with your payment, your violations of the Terms of Use or Privacy Policy, notices required under the Terms of User or Privacy Policy, the termination of services, or other valid business purposes related to the services being provided.
                </li>
                <li>
                  <u>Compliance with Opting-Out.</u> In compliance with the Controlling the Assault of Non-Solicited Pornography And Marketing Act of 2003 (CAN-SPAM Act), Company will honor email unsubscribe requests within ten (10) days of receipt of such request.  In addition, Company honors opt-outs pursuant to the Telephone Consumer Protection Act (TCPA).
                </li>
              </ol>
            </li>
            <li>
              <u>SECURITY OF YOUR PERSONAL INFORMATION</u>
              <ol>
                <li>
                  <p>
                    <u>Industry Standards.</u> The security of your Personal Information and Sensitive Personal Information is important to us. We follow generally accepted industry standards to help protect your Personal Information including without limitation:
                  </p>
                  <ol type={'a'}>
                    <li>
                      limiting access to your Personal Information and Sensitive Personal Information to those of our employees who require it to provide services to you;
                    </li>
                    <li>
                      requiring employees to sign confidentiality agreements to protect customer and other confidential information;
                    </li>
                    <li>
                      ensuring that third-party service providers sign confidentiality agreements to maintain the confidentiality of your Personal Information and not to use it for any unauthorized purposes; and
                    </li>
                    <li>
                      storing your Personal Information and Sensitive Personal Information in secure computer systems which protect it from unauthorized access or use.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>No Guarantee.</u>  No method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, while we strive to protect your Personal Information and Sensitive Personal Information, we cannot guarantee its absolute security.
                </li>
                <li>
                  <u>Passwords.</u>  If a password is used to protect your account and Personal Information, it is your responsibility to keep your password confidential.
                </li>
                <li>
                  <u>Exceptions.</u> Except as otherwise provided in this Privacy Policy, we will keep your Personal Information and Sensitive Personal Information private and will not share it with third parties, unless such disclosure is necessary to: (a) comply with a court order or other legal process; (b) protect our rights or property; or (c) enforce our Terms of Use.
                </li>
                <li>
                  <u>Lost or Stolen Information.</u> You must promptly notify us if your username or password is lost, stolen, or used without permission. In such an event, we will remove that username or password from your account and update our records accordingly.
                </li>
              </ol>
            </li>
            <li>
              <u>LINKS</u>
              <p>
                We may link to websites, including those of our subsidiaries and third-party content providers, which have different privacy policies and practices from those disclosed here. We assume no responsibility for the policies or practices of such linked sites, and encourage you to become acquainted with them prior to use.
              </p>
            </li>
            <li>
              <u>SHARING AND TRANSFERRING INFORMATION</u>
              <ol>
                <li>
                  <u>Our Right to Share Information In Certain Circumstances.</u> We may share Personal Information and Sensitive Personal Information pursuant to subpoenas, court orders, or other requests (including criminal and civil matters) if we have a good faith belief that the response is required by law. We may also share information when we have a good faith belief it is necessary to prevent fraud or other illegal activity, to prevent imminent bodily harm, or to protect ourselves and you from people violating our terms of us or this Privacy Policy. This may include sharing information with other companies, lawyers, courts or other government entities.
                </li>
                <li>
                  <u>Transfers of Information Across International Borders.</u> To facilitate Kive’s global operations, we may store, transfer and access the information and Personal Information you submit to the Company around the world, including the United States, Canada and other countries in which Kive or its third party service providers have operations. This Privacy Policy shall apply even if Kive transfers such information or personal data to other countries. By consenting to this Privacy Policy and using our services (or, for the avoidance of any doubt, providing Personal Information to the Company in any context), you consent to the transfer of your information and Personal Information among these facilities, including those located outside your home country.
                </li>
                <li>
                  <u>Data Transferred to United States.</u> For the avoidance of any doubt, if you are located outside United States and choose to provide information to us, please note that our current practice is to transfer the information, including Personal Information, to United States, where it is processed.  Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                </li>
              </ol>
            </li>
            <li>
              <u>YOUR CALIFORNIA PRIVACY RIGHTS</u>
              <p>
                We provide information to California residents regarding their privacy rights and our collection, use and disclosure of their personal information, as required by the California Consumer Privacy Act (“CCPA”). This section does not apply to publicly available information or other personal information that is exempt from or not subject to the CCPA.
              </p>
              <p>
                In general, California residents have the following rights with respect to their personal information (subject to certain limitations and exceptions):
              </p>
              <ul>
                <li>
                  Opt out of sales and sharing: to opt-out of our sale and sharing of their personal information.
                </li>
                <li>
                  Limit use of sensitive personal information: to limit uses or disclosures of sensitive personal information to those authorized by the CCPA.
                </li>
                <li>
                  Deletion: to request deletion of their personal information.
                </li>
                <li>
                  To know/access: to know what personal information we have collected about them, including the categories of personal information, the categories of sources from which the personal information is collected, the business or commercial purpose for collecting, selling, or sharing personal information, the categories of third parties to whom we disclose personal information, and the specific pieces of personal information we have collected about them.
                </li>
                <li>
                  Correction: to request correction of inaccurate personal information.
                </li>
                <li>
                  Non-discrimination: to not be subject to discriminatory treatment for exercising their rights under the CCPA.
                </li>
              </ul>
              <p>
                California residents may submit CCPA requests to know (access), delete and correct their personal information through one of the following methods: emailing or calling the Company, as set forth in the Contact Us section below.
              </p>
            </li>
            <li>
              <u>CHILDREN’S PRIVACY</u>
              <p>
                Only persons age 18 or older have permission to access our service. We do not knowingly collect personally identifiable information from children. If you are a parent or guardian and you learn that your child has, somehow, provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child, we take steps to remove that information from our servers.
              </p>
            </li>
            <li>
              <u>YOUR CANADIAN PRIVACY RIGHTS</u>
              <p>
                For our Canadian users, please know that this Privacy Policy is made under the Personal Information Protection and Electronic Documents Act. It is a complex Act and provides Canadian users with privacy control rights that are similar to the other rights set forth in this privacy policy.  We will honor requests for a copy of the data we collect, account deletion, and data correction.  We will also comply with opt-out requests as set forth herein. If there are other types of information you no longer agree with us processing, please contact us. For more general inquiries, the Information and Privacy Commissioner of Canada oversees the administration of the privacy legislation in the private sector. The Commissioner also acts as a kind of ombudsman for privacy disputes. The Information and Privacy Commissioner can be reached at: <Link href={'www.priv.gc.ca/en'} />
              </p>
            </li>
            <li>
              <u>EEA COMPLIANCE</u>
              <ol>
                <li>
                  <p>
                    <u>Compliance with EEA AND UK Requirements.</u> If you are located in the European Economic Area (“EEA”) or the United Kingdom (“UK”), we will comply with applicable legal requirements providing adequate protection for the transfer of Personal Information to recipients in countries outside of the EEA and UK, including the USA. In all such cases, we will only transfer your personal data if we have put in place appropriate safeguards with respect to the transfer, for example the EU standard contractual clauses.
                  </p>
                </li>
                <li>
                  <u>Requests Safeguards by EEA Users.</u> If you are located in the EEA or UK, you may request a copy of the safeguards that we have put in place in respect of any applicable transfers of personal data by contacting us as described in the Contact Us section below.
                </li>
                <li>
                  <p>
                    <u>EEA/UK User Rights.</u> If you are located in the EEA or UK, you may have the following rights in respect of your Personal Information that we hold:
                  </p>
                  <ol type={'a'}>
                    <li>
                      Right of access. The right to obtain access to your Personal Information along with certain information.
                    </li>
                    <li>
                      Right of portability. The right to receive your Personal Information in a commonly used format and to have it ported to another data controller.
                    </li>
                    <li>
                      Right to rectification. The right to obtain rectification of your personal information without undue delay where that personal information is inaccurate or incomplete.
                    </li>
                    <li>
                      Right to erasure. The right to obtain the erasure of your Personal Information without undue delay in certain circumstances, such as where the Personal Information is no longer necessary in relation to the purposes for which it was collected or processed.
                    </li>
                    <li>
                      Right to restriction. The right to obtain the restriction of the processing undertaken by us on your Personal Information in certain circumstances, such as where the accuracy of the Personal Information is contested by you, for a period enabling us to verify the accuracy of that Personal Information.
                    </li>
                    <li>
                      Right to object. The right to object, on grounds relating to your particular situation, to the processing of your Personal Information, and to object to processing of your Personal Information for direct marketing purposes, to the extent it is related to such direct marketing.
                    </li>
                  </ol>
                </li>
                <li>
                  <u>Exercising Rights.</u> For additional information, assistance with any problems accessing your information or if you wish to exercise one of these rights set forth in Section 12.3, please contact us at <EmailLink />.
                </li>
                <li>
                  <u>Complaints.</u> If you live or work in the EU or UK, you are also entitled to lodge a complaint with your applicable supervisory authority. A list of relevant authorities in the EEA and the European Free Trade Area can be accessed here: <Link href={'https://edpb.europa.eu/about-edpb/board/members_en'} />.
                </li>
              </ol>
            </li>
            <li>
              <u>CHANGES TO THIS PRIVACY POLICY</u>
              <p>
                We reserve the right to change the terms of this Privacy Policy at any time. Such revisions shall be effective immediately upon posting the revised Privacy Policy. We encourage you to review this policy whenever you visit our site.
              </p>
            </li>
            <li>
              <u>CONTACT US</u>
              <p>
                If you have any questions about this Privacy Policy, please contact us
                at <EmailLink /> or (747) 212 – 0337.
              </p>
            </li>
            <li>
              <u>SMS TERMS OF SERVICE</u>
              <p>
                Click <Link href={'https://artkive-sxyrjp.myklpages.com/p/mobile-terms-of-service'}>HERE</Link> to view our SMS Terms of Service
              </p>
            </li>
          </ol>
        </Box>
      </Container>
    </Box>
  )
  /* eslint-enable max-len */
}

export default withApp(Privacy)
