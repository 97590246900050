import React, { forwardRef, useCallback } from 'react'

import { useFormContext } from 'react-hook-form'
import { TextField } from '@material-ui/core'

import replaceEmojis from 'artkive/utils/replaceEmojis'

const ClearTextField = forwardRef((props, ref) => {
  const { onChange, name, ...rest } = props
  const { setValue } = useFormContext()

  const handleChange = useCallback((e) => {
    const value = e.target.value
    setValue(
      name,
      replaceEmojis(value),
      {
        shouldValidate: false,
      },
    )
  }, [setValue, name])

  return (
    <TextField {...rest} name={name} onChange={handleChange} ref={ref} />
  )
})

ClearTextField.displayName = 'ClearTextField'

export default ClearTextField
