import React from 'react'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    border: '0 solid #E6F1FC',
    borderTopWidth: 1,
    marginBottom: spacing(3.5),
    paddingTop: spacing(2.5),

    [breakpoints.up('md')]: {
      borderTopWidth: 0,
      marginBottom: spacing(5),
      paddingTop: 0,
    },
  },
}))

const ContentBox = ({ children, margin, ...rest }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  )
}

export default ContentBox
