import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const ErrorBoundary = ({ children, env }) => {
  const [environ] = useState(env)

  useEffect(() => {
    if (environ.sentry_dsn) {
      Sentry.init({
        enabled: ['production', 'development'].includes(environ.aws_env),
        integrations: [new Integrations.BrowserTracing()],
        dsn: environ.sentry_dsn,
        release: environ.commit_sha,
        environment: environ.aws_env || 'Unknown Environment',
        ignoreErrors:[
          'Non-Error promise rejection captured',
        ],
        denyUrls: [
          'https://s.pinimg.com',
        ],
      })
    }
  }, [])

  return (
    <Sentry.ErrorBoundary>
      {children}
    </Sentry.ErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  env: PropTypes.object.isRequired,
}

export default ErrorBoundary
