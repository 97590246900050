import React, { useState } from 'react'

import { Box, Link } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: '14px',
  },
}))

const UpgradeCardDetails = ({ link, children }) => {
  const [isShown, setIsShown] = useState(false)
  const isMobile = useMobileBreakpoint()

  const classes = useStyles()
  return (
    <>
      <Box pb={isShown ? 2 : 0} mt={2.25}>
        <Link onClick={() => setIsShown(!isShown)} className={classes.link}>
          {link}
        </Link>
      </Box>
      <Collapse in={isShown} timeout={'auto'} unmountOnExit>
        <Box px={isMobile ? 0 : 2}>
          {children}
        </Box>
      </Collapse>
    </>
  )
}

export default UpgradeCardDetails
