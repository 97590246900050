const globalConfigs = typeof window === 'undefined' ? { clientUrl: 'https://account.artkiveapp.com' } : window.app

export default {
  aboutUs: '/about-artkive-keepsake-app',
  artOfMe: 'https://artofme.artkiveapp.com/',
  international: 'https://international.artkivebox.com/',
  books: '/keepsakes/art-book-for-kids',
  boxCheckout: '/box-checkout',
  checkout: '/checkout',
  checkoutConfirmation: '/checkout-confirmation',
  contact: '/contact',
  orderTracking: '/order-tracking',
  digital: '/digitization',
  facebook: 'https://www.facebook.com/artkiveapp/',
  faqs: 'https://artkiveboxhelp.zendesk.com/hc/en-us',
  lifekiveFaqs: 'https://faq.lifekive.com',
  giftCards: '/giftcards',
  giftCardCheckout: '/giftcard-checkout',
  giftCardConfirmation: '/giftcard-confirmation',
  home: '/',
  instagram: 'https://www.instagram.com/artkivebox/',
  ios: 'https://itunes.apple.com/us/app/artkive-save-kids-art/id549980508?mt=8',
  lifekive: 'https://www.lifekive.com/',
  cardsByArtkive: 'https://www.cards.artkivebox.com/',
  albumsByArtkive: 'https://www.albumsbyartkive.com/',
  photosByArtkive: 'https://www.photosbyartkive.com/',
  login: globalConfigs.clientUrl,
  accountOrders: `${globalConfigs.clientUrl}/orders`,
  accountDetails: `${globalConfigs.clientUrl}/account`,
  accountPhotos: `${globalConfigs.clientUrl}/photos`,
  plans: '/plans',
  pricing: '/pricing',
  plansCheckout: '/plans/checkout',
  plansConfirmation: '/plans/confirmation',
  mosaics: '/keepsakes/kids-art-frames',
  ourProcess: '/kids-art-display-how-to',
  pinterest: 'https://www.pinterest.com/ARTKIVE/',
  tiktok: 'https://www.tiktok.com/@artkivebox',
  privacy: '/privacypolicy',
  tou: '/tou',
  twitter: 'https://twitter.com/artkivebox?lang=en',
  youtube: 'https://www.youtube.com/channel/UC2cgBQDf9YFr7AbVceWKG5g',
  nft: 'https://nft.artkivebox.com/',
  blog: 'https://blog.artkiveapp.com/',
  calendly: 'https://calendly.com/artkive',
}
