import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { NoSsr } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { Route, Switch, useLocation } from 'wouter'

import { withNoSsr } from 'common/components/NoSsr'
import useClosure from 'common/hooks/useClosure'
import { GENERAL_ERROR_MESSAGE } from 'common/utils/data/messages'
import CheckoutWrapper from 'artkive/components/Checkout/CheckoutWrapper'
import Divider from 'artkive/components/Checkout/Divider'
import CheckoutProductPreview from 'artkive/components/OrderSummary/CheckoutProductPreview'
import VipMembershipCard from 'artkive/components/OrderSummary/VipMembershipCard'
import PaymentOptions from 'artkive/components/PaymentOptions'
import Promo from 'artkive/components/Promo'
import { TrackerContext } from 'artkive/components/Tracker'
import useTracker from 'artkive/components/Tracker/useTracker'
import { BOX_CHECKOUT } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import { BOX_CHECKOUT_STEPS } from 'artkive/stores/ecom/stores/box.store'
import { BOX_PRODUCT_TYPE, PAYMENT_KINDS } from 'artkive/stores/product.constants'

import OrderDetails from './Steps/OrderDetails'
import RecipientInformation from './Steps/RecipientInformation'
import ShippingAndPayment from './Steps/ShippingAndPayment'
import BoxOrderDetailsSummary from './BoxOrderDetailsSummary'
import GiftSummary from './GiftSummary'
import MobileGiftSummary from './MobileGiftSummary'
import MobileOrderDetails from './MobileOrderDetails'
import OrderDetailsAddons from './OrderDetailsAddons'
import {
  buildCheckoutUtmParameters,
  buildPaymentMethod,
  mapAppleContactToAddress,
  mapStateToAddress,
  normalizePhoneNumber,
  orderBox,
  trackOrderPurchase,
  useProductMeta,
} from './utils'

const Checkout = withNoSsr(({
  productData,
  params,
  shippingProtectionPrice,
  productLinks,
  enableRecaptcha = false,
}) => {
  const [error, setError] = useState()
  const [formValidation, setFormValidation] = useState(null)
  const [trackAction] = useTracker()
  const isMobile = useMobileBreakpoint()

  const {
    cacheTrackingData,
    subscribe,
    unsubscribe,
  } = useContext(TrackerContext)

  const [pathname, navigate] = useLocation()

  const setLoader = useStoreActions(({ uiStore }) => uiStore.setLoader)

  const {
    clearAddons,
    reset,
    setPayment,
    setProduct,
    setStep,
    setStepActive,
    setShippingProtectionPrice,
    fetchProcessing,
    setProductMeta,
    fetchPrice,
  } = useVariableActions(BOX_PRODUCT_TYPE, productData.uuid)

  const {
    validate,
    checkPromoForFutureUse,
    setPromo,
    tryToReusePromo,
    clearTracking,
  } = useStoreActions(({ promoStore }) => promoStore)

  const tracking = useStoreState(({ promoStore }) => promoStore.tracking)

  const {
    activeProcessing,
    activeStep,
    fullName,
    information,
    price,
    product,
    processing,
    promoCode,
    qty,
    vipSubscription,
    shippingProtection,
    visibleSteps,
    freeOrder,
    addOns,
    hasSubscription,
    gift,
    recipientInformation,
    sendGiftToRecipient,
  } = useVariableState(BOX_PRODUCT_TYPE, productData.uuid)

  // const isVipMembershipAvailable = !user.user_subscription_plan?.version ||
  //   !user.user_subscription_plan?.version === 'COMP'
  const isVipMembershipAvailable = false

  const data = useProductMeta(productData, productLinks, { isVipMembershipAvailable })

  const isSidebarOrderDetailsAvailable = data.isSomeOrderDetailsAvailable && !data.isOrderDetailsAvailable
  const showPaymentOptions = price.total && (
    data.forceExpressCheckoutOnFirstPage || (
      (!data.gift || activeStep.index > 0)
        && (!data.hasSubscriptionAddon || (!hasSubscription && activeStep.index > 0))
    )
  )
  const shouldRequestAddress = data.shipping_address && !(gift && sendGiftToRecipient)

  const handleOrderDetails = async () => {
    trackAction(BOX_CHECKOUT.NEXT_CLICK, { price })
    // set current step as valid
    setStep({ stepName: BOX_CHECKOUT_STEPS.ORDER_DETAILS, payload: { isComplete: true } })
    navigate(gift ? `/${BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS}` : `/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`)
  }

  const handleRecipientInformation = async () => {
    trackAction(BOX_CHECKOUT.NEXT_CLICK, { price })
    // set current step as valid
    setStep({ stepName: BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS, payload: { isComplete: true } })
    navigate(`/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`)
  }

  const handleSuccessPurchase = async (res, paymentKind, shippingInformation) => {
    const order = res.response.data.data.concierge_group

    trackOrderPurchase(order, paymentKind, shippingInformation, tracking)

    try {
      await Promise.all([validate(), reset(), clearTracking()])
      await clearAddons()
      await checkPromoForFutureUse()
    } catch (error) {
      console.error('Checkout processing exception', JSON.stringify(error))
      try {
        Sentry.captureException(error)
      } catch (sentryError) {
        console.error('Sentry exception', JSON.stringify(sentryError))
      }
    } finally {
      // NOTE: as we don't refresh the page segment tracking works correctly
      const trackingData = btoa(JSON.stringify({ id: order.id, total: order.pricing_object.total }))
      const redirectParams = new URLSearchParams({ data: trackingData }).toString()
      navigate(`~${productLinks.confirmation}?${redirectParams}`)
    }
  }

  const placeOrder = async (shippingInformation, payment) => {
    setLoader(true)

    const recipientFields = gift
      ? {
        email: recipientInformation.recipientEmail,
        name: [recipientInformation.recipientFirstName, recipientInformation.recipientLastName].filter(Boolean).join(' '),
        phone_number: normalizePhoneNumber(recipientInformation.recipientPhone),
        gift_recipient: recipientInformation.giftMessageTo,
        gift_sender: recipientInformation.giftMessageFrom,
        gift_message: recipientInformation.giftMessage,
        send_gift_to_recipient: sendGiftToRecipient,
      } : {}
    const recaptchaResponse = payment['g-recaptcha-response-data']

    try {
      const res = await orderBox({
        ...buildCheckoutUtmParameters(tracking),
        concierge: {
          quantity: qty,
          concierge_product_id: data.id,
          billing_zip: payment?.billingZipCode,
          concierge_processing_id: activeProcessing?.id ?? 1,
          promo_code: promoCode,
          shipping_protection: shippingProtection,
          ...shippingInformation,
          add_ons: addOns,
          gift,
          recipient: recipientFields,
        },
        payment: payment?.paymentData,
        'g-recaptcha-response-data': recaptchaResponse ? recaptchaResponse : undefined,
      })

      if (res.error) {
        setError(res.error?.response?.data?.message || GENERAL_ERROR_MESSAGE)
        // NOTE: this allows to read all error messages details from back-end
        console.log(JSON.stringify(res.error?.response?.data?.error || {}))
      } else {
        handleSuccessPurchase(res, payment.paymentData.method, shippingInformation)
      }

      setLoader(false)
      return res
    } finally {
      setLoader(false)
    }
  }

  const handleCreditCardCheckout = async (payment) => {
    setPayment(payment)

    trackAction(BOX_CHECKOUT.NEXT_CLICK, { price })

    const paymentData = buildPaymentMethod(payment, freeOrder)
    const shippingInformation = {
      ...mapStateToAddress(gift && sendGiftToRecipient ? recipientInformation : information),
      phone_number: normalizePhoneNumber(information.phone),
      email: information.email,
      name: fullName,
    }

    return placeOrder(shippingInformation, { paymentData, ...payment })
  }

  const handleApplePayClick = useCallback(() => {
    trackAction(BOX_CHECKOUT.APPLE_PAY_CLICK, { price })
    return formValidation?.()
  }, [price, formValidation])

  const handleAddressChange = useClosure(async ({ administrativeArea, postalCode }) => {
    const payload = {
      concierge_product_id: product.id,
      add_ons: addOns,
      concierge_processing_id: (activeProcessing || processing.find((p) => p.default))?.id,
      promo_code: promoCode,
      quantity: qty,
      shipping_protection: shippingProtection,
      shipping_state: administrativeArea,
      shipping_zip: postalCode,
    }
    const data = await fetchPrice(payload)
    return data
  }, [processing, product, addOns, activeProcessing, promoCode, qty, shippingProtection])

  const handleApplePayCheckout = useCallback(async ({ billingContact, shippingContact, token }) => {
    const shippingAddress = gift && sendGiftToRecipient
      ? mapStateToAddress(recipientInformation)
      : mapAppleContactToAddress(shippingContact)
    const shippingInformation = {
      ...shippingAddress,
      email: shippingContact.emailAddress,
      name: `${billingContact.givenName} ${billingContact.familyName}`,
    }
    const paymentData = { method: PAYMENT_KINDS.APPLE_PAY, token }

    return placeOrder(shippingInformation, { paymentData, billingZipCode: billingContact.postalCode })
  }, [placeOrder, setLoader])

  const prepareEventData = useClosure(() => ({
    formType: 'information',
    kind: 'checkout',
    product: product.type,
    email: information.email,
    phone: information.phone,
    firstName: information.firstName,
    lastName: information.lastName,
  }), [information, product])

  // Show steps for express checkout experience
  useEffect(() => {
    setStep({ stepName: BOX_CHECKOUT_STEPS.ORDER_DETAILS, payload: { isVisible: data.isOrderDetailsAvailable } })
    setStep({
      stepName: BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS,
      payload: { isVisible: data.isOrderDetailsAvailable && gift },
    })
  }, [data.isOrderDetailsAvailable, gift])

  useEffect(() => {
    // TODO: maybe instead of renaming shipping step into order details we should show payment info on the 1st step
    // or have a composite step that shows both of them
    const name = data.forceExpressCheckoutOnFirstPage
      ? 'Order Details'
      : data.shipping_address && (!gift || isMobile)
        ? 'Shipping and Billing'
        : 'Payment'
    setStep({
      stepName: BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT,
      payload: { name },
    })
  }, [data.shipping_address, gift, isMobile])

  useEffect(() => {
    setProductMeta({ addons: data.addon_sections.flatMap(({ items }) => items) })

    cacheTrackingData(prepareEventData(), true)

    fetchProcessing() // TODO: maybe pass down as a prop on SSR

    const { promo } = params
    // set shipping protection price based on params passed from rails erb
    setShippingProtectionPrice(shippingProtectionPrice)
    // set product pricing based on params passed from rails erb
    setProduct({
      id: data.id,
      uuid: data.uuid,
      title: data.name,
      msrp: 0,
      price: productData.sale_price,
      image: data.preview_url || data.cover_url,
      isTaxable: data.taxable,
    })

    const payload = {
      code: promo || promoCode,
      product_id: data.id,
      uuid: data.uuid,
      productName: BOX_PRODUCT_TYPE,
    }
    // set promo
    if (promo) {
      setPromo(payload)
    } else {
      tryToReusePromo(payload)
    }

    const subscribeHandler = () => {
      cacheTrackingData(prepareEventData())
    }

    subscribe('blur', subscribeHandler)

    return () => {
      unsubscribe('blur', subscribeHandler)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    let alias = pathname.replace('/', '').toLowerCase()
    const routeStepIndex = visibleSteps.findIndex((step) => step.alias.toLowerCase() === alias)

    const defaultStepAlias = data.isOrderDetailsAvailable ?
      BOX_CHECKOUT_STEPS.ORDER_DETAILS : BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT

    // Check prev step completed
    if (routeStepIndex === -1 || !visibleSteps[routeStepIndex - 1]?.isComplete) {
      navigate(`/${defaultStepAlias}`, { replace: true })
      setStepActive({ stepName: defaultStepAlias })
    } else {
      setStepActive({ stepName: alias || defaultStepAlias })
    }
  }, [pathname]) // NOTE: on step change

  if (!activeStep) return null

  return (
    <CheckoutWrapper
      product={product}
      error={error}
      setError={setError}
      backTo={data.back_link}
      paymentOptions={showPaymentOptions && (
        <NoSsr>
          <PaymentOptions
            price={price}
            onApplePayCheckout={handleApplePayCheckout}
            onAddressChange={handleAddressChange}
            setError={setError}
            requestAddress={shouldRequestAddress}
            onApplePayClick={handleApplePayClick}
            disableApplePay={hasSubscription}
          />
        </NoSsr>
      )}
      details={(
        <>
          <Switch>
            <Route path={`/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}`}>
              {isMobile ? (
                <>
                  <MobileOrderDetails product={product} />
                  {!data.forceExpressCheckoutOnFirstPage && gift && <MobileGiftSummary options={data} />}
                </>
              ) : (
                <>
                  <CheckoutProductPreview product={product} options={data} />
                  {isVipMembershipAvailable && data.vip_membership && (
                    <>
                      <VipMembershipCard product={product} processing={processing} />
                      <Divider />
                    </>
                  )}

                  {isSidebarOrderDetailsAvailable && (
                    <OrderDetailsAddons options={data} product={product} dense />
                  )}

                  {data.isOrderDetailsAvailable && (
                    <>
                      <Divider my={3} />
                      <BoxOrderDetailsSummary product={product} />
                    </>
                  )}

                  {gift && data.isOrderDetailsAvailable && <GiftSummary options={data} />}
                </>
              )}
            </Route>
            <Route path={`/${BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS}`}>
              {isMobile ? (
                <MobileOrderDetails product={product} />
              ) : (
                <>
                  <CheckoutProductPreview product={product} options={data} />

                  {isVipMembershipAvailable && data.vip_membership && (
                    <>
                      <VipMembershipCard product={product} processing={processing} />
                      <Divider />
                    </>
                  )}

                  {data.isOrderDetailsAvailable && (
                    <>
                      <Divider my={3} />
                      <BoxOrderDetailsSummary product={product} />
                    </>
                  )}
                </>
              )}
            </Route>
            <Route path={`/:rest*`}>
              <CheckoutProductPreview product={product} options={data} />

              {isVipMembershipAvailable && data.vip_membership && (
                <VipMembershipCard product={product} processing={processing} />
              )}
            </Route>
          </Switch>

          {!isMobile && (
            <>
              <Divider my={4} />

              <Promo product={product} disabled={vipSubscription} />
            </>
          )}
        </>
      )}
    >
      <Switch>
        <Route path={data.isOrderDetailsAvailable ? `/${BOX_CHECKOUT_STEPS.SHIPPING_AND_PAYMENT}` : '/:rest*'}>
          <ShippingAndPayment
            isLoading={false}
            onSubmit={handleCreditCardCheckout}
            product={product}
            options={data}
            setError={setError}
            captcha={enableRecaptcha}
            setFormValidation={setFormValidation}
          />
        </Route>

        {gift && data.isOrderDetailsAvailable && (
          <Route path={`/${BOX_CHECKOUT_STEPS.RECIPIENT_DETAILS}`}>
            <RecipientInformation
              options={data}
              onSubmit={handleRecipientInformation}
              continueButtonText={'Continue'}
              setFormValidation={setFormValidation}
            />
          </Route>
        )}

        {data.isOrderDetailsAvailable && (
          <Route path={`/:rest*`}>
            <OrderDetails
              product={product}
              options={data}
              steps={visibleSteps}
              onSubmit={handleOrderDetails}
              setFormValidation={setFormValidation}
              continueButtonText={'Continue'}
            />
          </Route>
        )}
      </Switch>
    </CheckoutWrapper>
  )
})

Checkout.propTypes = {
  params: PropTypes.object.isRequired,
  shippingProtectionPrice: PropTypes.number.isRequired,
  productData: PropTypes.object.isRequired,
  productLinks: PropTypes.object.isRequired,
  enableRecaptcha: PropTypes.bool.isRequired,
}

export default memo(Checkout)
