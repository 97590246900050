import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ApplePay from 'artkive/components/ApplePay'
import { isApplePayAvailable } from 'artkive/components/ApplePay/utils'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { colors } from 'artkive/utils/theme'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  container: {
    paddingBottom: spacing(4),
    [breakpoints.down('sm')]: {
      paddingBottom: `${(1 + 74 + 22) + 32}px`,
    },
  },
  buttonsArea: {
    padding: `0 ${spacing(3)}px ${spacing(3)}px`,
    border: `1px solid ${colors.grey.med}`,
    borderRadius: '10px',
    margin: `${spacing(2.5)}px ${spacing(3)}px 0`,
    [breakpoints.down('sm')]: {
      margin: `0 ${spacing(2)}px 0`,
      borderRadius: 0,
      borderWidth: '1px 0 0',
      width: `calc(100% - ${spacing(4)}px)`,
      padding: `0 0 ${spacing(1)}px`,
    },
  },
  buttonsAreaWrapper: {
    paddingTop: spacing(3.5),
    [breakpoints.down('sm')]: {
      backgroundColor: colors.sky[300],
      position: 'fixed',
      width: '100%',
      left: 0,
      bottom: 0,
      zIndex: 4,
    },
  },
  button: {
    maxWidth: '342px',
    width: '100%',
    [breakpoints.down('sm')]: {
      maxWidth: 'unset',
    },
  },
  areaTitleContainer: {
    position: 'relative',
    top: `-${spacing(1.5)}px`,
    [breakpoints.down('sm')]: {
      top: -spacing(1),
    },
  },
  areaTitle: {
    display: 'inline-block',
    backgroundColor: colors.white.dark,
    padding: '0 14px 0',
    [breakpoints.down('sm')]: {
      backgroundColor: colors.sky[300],
    },
  },
}))

const PaymentOptions = ({
  price,
  onApplePayCheckout,
  onAddressChange,
  setError,
  onApplePayClick = null,
  requestAddress,
  disableApplePay = false,
}) => {
  const isApplePay = useMemo(() => isApplePayAvailable(), [])
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  // NOTE: comment the next line to debug on linux
  if (!isApplePay) return null

  return (
    <Box className={classes.container}>
      <Typography variant={'body1'} align={'center'}>or</Typography>
      <Box className={classes.buttonsAreaWrapper}>
        <Box className={classes.buttonsArea}>
          <Box display={'flex'} justifyContent={'space-around'} className={classes.areaTitleContainer}>
            <Typography variant={isMobile ? 'caption' : 'body1'} className={classes.areaTitle}>
              {disableApplePay ? 'Express Checkout Not Available' : 'Express Checkout Option'}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'space-around'}>
            <Box className={classes.button}>
              <ApplePay
                price={price}
                onSubmit={onApplePayCheckout}
                onAddressChange={onAddressChange}
                setError={setError}
                onClick={onApplePayClick}
                requestAddress={requestAddress}
                disabled={disableApplePay}
              >
                Buy with
              </ApplePay>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

PaymentOptions.propTypes = {
  price: PropTypes.object.isRequired,
  onApplePayCheckout: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  requestAddress: PropTypes.bool.isRequired,
  onApplePayClick: PropTypes.func,
  disableApplePay: PropTypes.bool,
}

export default PaymentOptions
