import { useStoreActions, useStoreState } from 'easy-peasy'

import { STORE_NAME as BOX_STORE_NAME } from 'artkive/stores/ecom/stores/box.store'

import {
  BOX_PRODUCT_TYPE,
  GIFT_CAD_PRODUCT_TYPE,
  MEMBERSHIP_PRODUCT_TYPE,
  PACKAGE_PRODUCT_TYPE,
} from '../stores/product.constants'

const buildVariableStoreName = (prefix, id) => [prefix, id].filter(Boolean).join('_')

export const findVariableStoreName = (type, id) => {
  switch (type) {
    case BOX_PRODUCT_TYPE:
      return buildVariableStoreName(BOX_STORE_NAME, id)
    case PACKAGE_PRODUCT_TYPE:
      return buildVariableStoreName('packageStore', id)
    case MEMBERSHIP_PRODUCT_TYPE:
      return 'planStore'
    case GIFT_CAD_PRODUCT_TYPE:
      return 'giftCardStore'
    default:
      return null
  }
}

function useVariableStore(type, productId) {
  const actions = useStoreActions((actions) => {
    const modelName = findVariableStoreName(type, productId)
    return modelName ? (actions[modelName] || actions[BOX_STORE_NAME]) : {}
  })

  const state = useStoreState((state) => {
    const modelName = findVariableStoreName(type, productId)
    return modelName ? (state[modelName] || state[BOX_STORE_NAME]) : {}
  })

  return { actions, state }
}

function _useVariableActions(...rest) {
  return useVariableStore(...rest).actions
}

export const useVariableActions = _useVariableActions

function _useVariableState(...rest) {
  return useVariableStore(...rest).state
}

export const useVariableState = _useVariableState

export default useVariableStore
