import React from 'react'

import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import ProductPriceTypography from 'artkive/components/PageSections/ProductPriceTypography'
import { TrackButton } from 'artkive/components/Tracker'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { AMOUNT_OF_ART } from 'artkive/utils/giftCard'
import theme from 'artkive/utils/theme'

import sprite from 'images/ecom/brand/sprite.svg'
import bubbles from 'images/ecom/get-started/bubbles.png'
import EGiftCardProduct from 'images/ecom/pricing/EGiftCardProduct.png'
import PhisicalCardProduct from 'images/ecom/pricing/PGiftCardProduct.png'

const GIFT_CARD_TYPES = {
  physical: 'physical',
  egift: 'egift',
}

export const physicalProductBullets = [
  { text: `Includes ${AMOUNT_OF_ART}-piece Artkive Box Package with one hardcover book` },
  { text: 'A beautifully packaged Gift Card + a personal note' },
]

export const eGiftProductBullets = [
  { text: `Includes a ${AMOUNT_OF_ART}-piece Artkive Box package with one hardcover book` },
  { text: 'A festive email delivered on the date of your choosing. Easy!' },
]

const useStyled = makeStyles(({ breakpoints }) => ({
  item: {
    position: 'relative',
    zIndex: 0,
  },
  bubblesGutter: {
    position: 'absolute',
    width: 62,
    height: 125,
    left: -30,
    bottom: -30,
    zIndex: -1,
  },
  container: {
    [breakpoints.down('sm')]: {
      paddingBottom: 28,
    },
  },
  spriteGutter: {
    position: 'absolute',
    top: -30,
    right: -10,
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    width: 64,
    height: 64,
    zIndex: 0,
    [breakpoints.up('md')]: {
      width: 75,
      height: 75,
      top: -70,
      right: -70,
    },
  },
}))

const GiftCardsSection = ({ pricing }) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyled()

  return (
    <Box
      pt={isMobile ? 8 : 12}
      pb={isMobile ? 5 : 12}
      textAlign={'center'}
      bgcolor={isMobile ? theme.colors.white.main : 'inherit'}
    >
      <Box mb={isMobile ? 2 : 5}>
        <Typography variant={'h2'}>
          Additional Offerings
        </Typography>
      </Box>
      <Container classes={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box px={isMobile ? 0 : 3} py={3}>
              <Box mb={4} className={classes.item}>
                <Hidden smDown>
                  <img src={bubbles} className={classes.bubblesGutter} />
                </Hidden>
                <Image src={PhisicalCardProduct} />
                <Hidden mdUp>
                  <img src={sprite} className={classes.spriteGutter} />
                </Hidden>
              </Box>

              <Box mb={isMobile ? 2 : 3}>
                <Typography gutterBottom variant={'h3'}>
                  Physical Card
                </Typography>
              </Box>

              <Box px={isMobile ? 0 : 8} textAlign={'left'}>
                {physicalProductBullets.map(({ text }, index) => (
                  <ProductDescriptionItem key={index}>
                    {text}
                  </ProductDescriptionItem>
                ))}
              </Box>
              <Box mt={3} mb={4}>
                <ProductPriceTypography>
                  ${pricing.physical.price}
                </ProductPriceTypography>
              </Box>
              <TrackButton
                color={'primary'}
                fullWidth
                href={`/giftcards/${GIFT_CARD_TYPES.physical}/checkout`}
                style={{ maxWidth: '250px' }}
                variant={'contained'}
                trackerEvent={GIFT_CARDS.GET_PHYSICAL_CLICK}
              >
                Get Physical Card
              </TrackButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box px={isMobile ? 0 : 3} py={3}>
              <Box mb={4} className={classes.item}>
                <Hidden mdUp>
                  <img src={bubbles} className={classes.bubblesGutter} />
                </Hidden>
                <Image src={EGiftCardProduct} />
                <Hidden smDown>
                  <img src={sprite} className={classes.spriteGutter} />
                </Hidden>
              </Box>
              <Box mb={isMobile ? 2 : 3}>
                <Typography gutterBottom variant={'h3'}>
                  E-Gift Card
                </Typography>
              </Box>

              <Box px={isMobile ? 0 : 8} textAlign={'left'}>
                {eGiftProductBullets.map(({ text }, index) => (
                  <ProductDescriptionItem key={index}>
                    {text}
                  </ProductDescriptionItem>
                ))}
              </Box>
              <Box mt={3} mb={4}>
                <ProductPriceTypography>
                  ${pricing.egift.price}
                </ProductPriceTypography>
              </Box>
              <TrackButton
                color={'primary'}
                fullWidth
                href={`/giftcards/${GIFT_CARD_TYPES.egift}/checkout`}
                style={{ maxWidth: '250px' }}
                variant={'contained'}
                trackerEvent={GIFT_CARDS.GET_EGIFT_CARD_CLICK}
              >
                Get E-Gift Card
              </TrackButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default GiftCardsSection
