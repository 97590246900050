import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CheckboxControl from 'artkive/components/Checkout/Sections/CheckboxControl'
import GiftIcon from 'artkive/components/GiftIcon'
import theme, { newColors } from 'artkive/utils/theme'

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(1.5, 2),
    marginBottom: spacing(3.5),
    background: theme.colors.bg.pink_light,
    border: `1px solid ${newColors.violet[300]}`,
    borderColor: ({ selected }) => newColors.violet[selected ? 500 : 300],
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000',
    [breakpoints.up('sm')]: {
      padding: spacing(1.5, 3),
      marginBottom: spacing(5),
    },
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.bg.pink_dark}`,
    backgroundColor: theme.colors.white.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: spacing(1.5),
  },
  checkbox: {
    marginRight: 0,
  },
  checkboxLabel: {
    marginLeft: spacing(1),
  },
  labelText: {
    fontWeight: 500,
  },
}))

const GiftLineItem = ({ options, selected, onChange }) => {
  const classes = useStyles({ selected })

  return (
    <Box className={classes.root}>
      <Box display={'flex'} alignItems={'center'}>
        <div className={classes.icon}>
          <GiftIcon />
        </div>
        <Typography variant={'body2'} color={'inherit'} style={{ fontWeight: 'bold' }}>
          {options.gift.title}
        </Typography>
      </Box>
      <Box>
        <CheckboxControl
          checked={selected}
          onChange={onChange}
          classes={{ root: classes.checkbox, label: classes.checkboxLabel }}
          label={
            <Typography variant={'body2'} color={'inherit'} className={classes.labelText}>Yes!</Typography>
          }
        />
      </Box>
    </Box>
  )
}

GiftLineItem.propTypes = {
  options: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default GiftLineItem
