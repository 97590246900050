import formatPrice from 'artkive/utils/formatPrice'
import { DISCOUNT_METHODS } from 'artkive/utils/promoCode'

const calculateAddonViewPrices = ({ fullPriceInfo = {}, enableUSBAddon = false, isOrderSummary = false }) => {
  const { promo_discount_details, base_price = 0 } = fullPriceInfo
  const { discount_method: discountMethod, amount = 0 } = promo_discount_details || {}
  let finalPrice
  let discountLabel
  let price

  switch (discountMethod) {
    case DISCOUNT_METHODS.PERCENTAGE:
      finalPrice = `+ ${formatPrice(base_price * (1 - amount))}`
      price = formatPrice(base_price)
      discountLabel = `-${amount * 100}%`
      break
    case DISCOUNT_METHODS.FREE_USB:
      finalPrice = `+ ${formatPrice(base_price)}`
      if (enableUSBAddon) {
        if (isOrderSummary) {
          finalPrice = null
          price = formatPrice(base_price)
        }
        discountLabel = 'FREE'
      }
      break
    default:
      finalPrice = `+ ${formatPrice(base_price)}`
      discountLabel = null
  }

  return {
    finalPrice,
    price,
    discountLabel,
  }
}

export default calculateAddonViewPrices
