import React from 'react'
import PropTypes from 'prop-types'

import BasePriceWithDiscount from './BasePriceWithDiscount'
import calculateAddonViewPrices from './calculateAddonViewPrices'
import calculateProductPreviewPrices from './calculateProductPreviewPrices'

const priceLabelClassName = 'priceLabelMobileOrderDetailsAddon'
const priceClassName = 'priceMobileOrderDetailsAddon'
const discountBoxClassName = 'discountBoxMobileOrderDetailsAddon'
const discountLabelClassName = 'discountLabelMobileOrderDetailsAddon'
const mainBoxDirection = 'column'

const MobileOrderDetailsAddonPrice = ({
  fullPriceInfo,
  qty,
  enableUSBAddon,
  productInAddon,
  showEachSuffix,
}) => {
  const calculatePrice = productInAddon
    ? calculateProductPreviewPrices
    : calculateAddonViewPrices
  const { finalPrice, discountLabel, price } = calculatePrice({
    fullPriceInfo,
    qty,
    enableUSBAddon,
    productInAddon,
    showEachSuffix,
    isOrderSummary: true,
  })
  const discountMethod = fullPriceInfo.promo_discount_details?.discount_method

  return (
    <BasePriceWithDiscount
      finalPrice={finalPrice}
      price={price}
      discountLabel={discountLabel}
      priceLabelClassName={priceLabelClassName}
      productInAddon={productInAddon}
      enableUSBAddon={enableUSBAddon}
      priceClassName={priceClassName}
      discountMethod={discountMethod}
      discountBoxClassName={discountBoxClassName}
      discountLabelClassName={discountLabelClassName}
      mainBoxDirection={mainBoxDirection}
      isMobileOrderDetailsAddon={true}
    />
  )
}

MobileOrderDetailsAddonPrice.propTypes = {
  fullPriceInfo: PropTypes.object.isRequired,
  qty: PropTypes.number,
  enableUSBAddon: PropTypes.bool,
  productInAddon: PropTypes.bool,
  showEachSuffix: PropTypes.bool,
}

export default MobileOrderDetailsAddonPrice
