import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import request from 'common/utils/request'
import { OrderProductPreview } from 'artkive/components/OrderSummary'
import Qty from 'artkive/components/Qty'
import withApp from 'artkive/hoc/withApp'
import formatNumber from 'artkive/utils/formatNumber'
import theme from 'artkive/utils/theme'

import BoxPurchaseDetails from './BoxPurchaseDetails'
import PageLayout from './PageLayout'

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    justifyContent: 'center',
    height: '100%',
  },
  qty: {
    padding: '0',
    justifySelf: 'flex-end',
  },
  detailsContainer: {
    maxWidth: '520px',
    position: 'relative',
    width: '100%',
    padding: '0 1rem',
    [breakpoints.up('md')]: {
      padding: '2rem',
    },
  },
  form: {
    borderRadius: '4px',
    maxWidth: '520px',
    padding: '0 1rem',
    position: 'relative',
    width: '100%',
    [breakpoints.up('md')]: {
      border: '1px solid #EBEBEB',
      background: '#FFFFFF',
      padding: '2rem',
    },
  },
  confirmation: {
    borderTop: `1px solid ${theme.colors.grey[300]}`,
    paddingTop: 24,
    textAlign: 'center',
  },
  confirmationText: {
    color: theme.colors.green[700],
  },
}))

const BoxCancellation = ({ order, params }) => {
  const { product, quantity: initialQty, cancellable } = order
  const { email } = params

  const classes = useStyles()
  const [error, setError] = useState()
  const [qty, setQty] = useState(initialQty)
  const [isOrderUpdated, setIsOrderUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const isDisabled = qty === initialQty || isLoading

  const handleQtyUpdateConfirm = useCallback(async () => {
    setIsLoading(true)
    try {
      await request.post(Routing.api_v2_concierge_refunds({ id: order.id }), {
        email,
        concierge_refund: { quantity: initialQty - qty },
      })

      setIsOrderUpdated(true)
    } catch (error) {
      setError(error.response.data.message || error.message)
    } finally {
      setIsLoading(false)
    }
  }, [email, order, initialQty, qty])

  const renderContent = useCallback(() => {
    if (!cancellable) {
      return <BoxPurchaseDetails />
    }

    if (isOrderUpdated) {
      return (
        <Box className={classes.detailsContainer}>
          <Box px={2}>
            <Typography variant={'h2'} align={'center'}>
              You will Receive {qty} Artkive Box
            </Typography>
          </Box>

          <Box mt={3}>
            <Typography variant={'caption'} align={'center'} component={'div'}>
              We will add a{' '}
              <strong>${formatNumber(product.sale_price * (initialQty - qty))} Credit</strong>{' '}
              to your second payment invoice.
            </Typography>
          </Box>

          <Box mt={6} display={'flex'} justifyContent={'center'}>
            <Button
              color={'primary'}
              size={'large'}
              href={'/'}
              variant={'contained'}
            >
              Go To Home Page
            </Button>
          </Box>
        </Box>
      )
    }

    return (
      <Box className={classes.form}>
        <Box textAlign={'center'} mb={4} px={2}>
          <Typography variant={'h2'} gutterBottom>
            How many Boxes do you need?
          </Typography>
          <Typography variant={'caption'}>
            {initialQty} Artkive Boxes are ordered
          </Typography>
        </Box>

        <OrderProductPreview
          title={product.name}
          price={`$${product.sale_price * qty}`}
          image={product.image}
        >
          <Qty
            setQty={setQty}
            qty={qty}
            maxQty={initialQty}
            className={classes.qty}
          />
        </OrderProductPreview>

        {!isDisabled && (
          <Box className={classes.confirmation} mt={3}>
            <Typography className={classes.confirmationText}>
              Your Credit: ${formatNumber(product.sale_price * (initialQty - qty))}
            </Typography>
          </Box>
        )}

        <Box display={'flex'} justifyContent={'center'} mb={3} mt={6}>
          <Button
            color={'primary'}
            size={'large'}
            variant={'contained'}
            disabled={isDisabled}
            fullWidth
            onClick={handleQtyUpdateConfirm}
          >
            Update
          </Button>
        </Box>

        <Typography variant={'caption'}>
          * You can include art for multiple books/children in the same
          box. If you would like to reduce the quantity of boxes in your
          order, adjust the number above and click “Update”. We will add a
          ${formatNumber(product.sale_price)} credit to your second payment invoice for every box you
          remove from your original order.
        </Typography>
      </Box>
    )
  }, [qty, order, handleQtyUpdateConfirm, isLoading])

  return (
    <PageLayout error={error} setError={setError}>
      {renderContent()}
    </PageLayout>
  )
}

BoxCancellation.propTypes = {
  order: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
}

export default withApp(BoxCancellation)
