import {
  action,
  computed,
  thunk,
} from 'easy-peasy'

import request from 'common/utils/request'
import reset from 'artkive/stores/ecom/helpers/reset'
import { MEMBERSHIP_PRODUCT_TYPE } from 'artkive/stores/product.constants'

import { DEFAULT_STATE as USER_STATE } from './user.store'

const DEFAULT_STATE = {
  information: { ...USER_STATE.user },
  payment: {},
  promoCode: '',
  product: { type: MEMBERSHIP_PRODUCT_TYPE, isTaxable: false },
  price: { shipping_price: 0, subtotal: 0, tax: 0, total: 0 },
  qty: 1,
  confirmation: {},
}

const resetter = reset({ defaultState: DEFAULT_STATE })

const planStore = {
  // state
  ...DEFAULT_STATE,

  // computed
  activeStep: computed(() => ({
    name: 'Checkout',
    index: 0,
    isActive: true,
    isComplete: false,
    isVisible: true,
  })),

  fullName: computed(({ information }) => [information.firstName, information.lastName].filter(Boolean).join(' ')),

  steps: computed(() => ([])),
  visibleSteps: computed((state) => state.steps.filter((step) => !!step.isVisible)),
  total: computed((state) => state.qty * state.product.price),

  // actions
  setInformation: action((state, payload) => {
    state.information = { ...state.information, ...payload }
  }),

  setPayment: action((state, payload) => {
    state.payment = { ...state.payment, ...payload }
  }),

  setProduct: action((state, payload) => {
    state.product = { ...payload }
  }),

  setConfirmation: action((state, payload) => {
    state.confirmation = { ...payload }
  }),

  setPrice: action((state, payload) => {
    state.price = { ...state.price, ...payload }
  }),

  // thunks
  placeOrder: thunk(async (actions, payload) => {
    try {
      const response = await request.post(Routing.api_v1_create_profile_subscription(), { ...payload })

      return { response }
    } catch (error) {
      return { error }
    }
  }),

  ...resetter,
}

export default planStore
