import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import { useVariableActions, useVariableState } from 'artkive/hooks/useVariableStore'
import theme from 'artkive/utils/theme'

import vipIcon from 'images/ecom/VIPIcon.svg'

import CheckboxControl from '../Checkout/Sections/CheckboxControl'
import UpgradeCardDetails from '../Checkout/UpgradeCardDetails'
import UpgradesCard from '../Checkout/UpgradesCard'
import VipBoxMembershipDetails from '../Checkout/VIPBoxMembershipDetails'
import Image from '../Image'

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#FDE4A5',
  },
  content: {},
  listItemRoot: {
    padding: 0,
  },
  listRoot: {
    padding: 0,
  },
  refundText: {
    color: theme.colors.green.main,
  },
  subtotal: {
    color: theme.colors.black,
    fontWeight: 500,
  },
  label: {
    color: theme.colors.black,
    fontWeight: 500,
  },
}))

const VipMembershipCard = ({ product, processing }) => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()
  const { vipSubscription } = useVariableState(product.type, product.uuid)
  const { setVipSubscription } = useVariableActions(product.type, product.uuid)

  return (
    <UpgradesCard
      icon={<Image src={vipIcon} style={{ width: 24 }} />}
      title={'VIP Box Membership'}
      classes={{
        header: classes.header,
        content: classes.content,
      }}
    >
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} px={2}>
        <Box
          alignItems={isMobile ? 'flex-start' : 'center'}
          display={'flex'}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <CheckboxControl
            checked={vipSubscription}
            onChange={(e) => {
              setVipSubscription(e.target.checked)
            }}
            label={(
              <Box fontWeight={'fontWeightBold'} className={classes.label}>
                Join now and get this Artkive Box for <strong>FREE</strong>
              </Box>
            )}
          />
        </Box>
        <Box fontWeight={'fontWeightBold'} textAlign={'right'}>$39/year</Box>
      </Box>
      <UpgradeCardDetails link={<>How Much I Would Save?</>}>
        <VipBoxMembershipDetails product={product} processing={processing} />
      </UpgradeCardDetails>
    </UpgradesCard>
  )
}

VipMembershipCard.propTypes = {
  product: PropTypes.object,
  processing: PropTypes.array,
}

export default VipMembershipCard
