import React, { useMemo, useState } from 'react'

import { useSwipeable } from 'react-swipeable'
import {
  Box,
  Grid,
  Radio,
  Slide,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'artkive/components/Image'
import { TrackLink } from 'artkive/components/Tracker'
import { ABOUT_US } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'

import CardsPreview from 'images/ecom/about/CardsPreview.jpg'
import LifekivePreview from 'images/ecom/about/LifekivePreview.jpg'
import PhotosPreview from 'images/ecom/about/PhotosPreview.jpg'
import CardsLogo from 'images/ecom/brand/CardsLogo.png'
import LifekiveLogo from 'images/ecom/brand/LifekiveLogo.png'
import PhotosByArtkiveLogo from 'images/ecom/brand/PhotosByArtkiveLogo.png'

const cardHeightBreakpoints = { md: 350, sm: 632, xs: 737 }

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    minHeight: cardHeightBreakpoints.md,
    position: 'relative',

    [breakpoints.down('sm')]: {
      minHeight: cardHeightBreakpoints.sm,
    },

    [breakpoints.down('xs')]: {
      minHeight: cardHeightBreakpoints.xs,
    },
  },
  imagePreviewContainer: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  previewImage: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  // NOTE: card height is fixed in order to have proper layout for carousel navigation and other components on the card
  card: {
    boxShadow: '5px 10px 30px 0px rgba(18, 38, 109, 0.10)',
    backgroundColor: 'white',
    justifyContent: 'center',
    display: 'flex',
    height: cardHeightBreakpoints.md,

    [breakpoints.down('sm')]: {
      '& > .MuiBox-root': {
        minHeight: cardHeightBreakpoints.sm - 290,
      },
    },

    [breakpoints.down('xs')]: {
      '& > .MuiBox-root': {
        minHeight: cardHeightBreakpoints.xs - 290,
      },
    },
  },
  preview: {
    [breakpoints.down('sm')]: {
      flexBasis: 'unset',
      height: 290,
    },
  },
}))

const Carousel = () => {
  const isMobile = useMobileBreakpoint()
  const classes = useStyles()

  const [slide, setSlide] = useState(0)
  // NOTE: at practice it add extra 3px to the image hight
  const slides = useMemo(() => [
    {
      href: routes.cardsByArtkive,
      trackerOptions: { product: 'cards_by_artkive' },
      img: <Image src={CardsLogo} style={{ height: 64, width: 'auto' }} />,
      preview: CardsPreview,
      text: (
        <Typography>
          Want to do something special with all of your greeting cards? Trying to find the perfect
          wedding or milestone birthday gift? Introducing Cards by Artkive! We'll photograph as
          many cards as you can fit in our Cards by Artkive box and turn them into a keepsake book.
        </Typography>
      ),
    },
    {
      href: routes.photosByArtkive,
      trackerOptions: { product: 'photos_by_artkive' },
      img: <Image src={PhotosByArtkiveLogo} style={{ height: 54, width: 'auto' }} />,
      preview: PhotosPreview,
      text: (
        <Typography>
          Send as many physical photos as you can fit in the box and we’ll transform them into a beautiful keepsake
          book. Preserve your photos and make them easy to share with friends and family for years to come.
        </Typography>
      ),
    },
    {
      href: routes.lifekive,
      trackerOptions: { product: 'life_kive' },
      img: <Image src={LifekiveLogo} style={{ height: 64, width: 'auto' }} />,
      preview: LifekivePreview,
      text: (
        <Typography>
          Gift your loved one Lifekive! Every week for a year, Lifekive emails your loved one a
          prompt, they respond with a story and optional photos. At the end of the year, those
          stories are turned into a beautiful keepsake book. The perfect way to honor their legacy
          and uncover stories you've never heard.
        </Typography>
      ),
    },
  ], [])

  const handleNext = () => {
    if (slide >= slides.length - 1) return

    setSlide(slide + 1)
  }

  const handlePrev = () => {
    if (slide <= 0) return

    setSlide(slide - 1)
  }

  const handlers = useSwipeable({ onSwipedLeft: handleNext, onSwipedRight: handlePrev })

  return (
    <Box pt={isMobile ? 6 : 8} pb={6}>
      <Box {...handlers} className={classes.container}>
        {slides.map((slideInfo, index) => (
          <Slide in={slide === index} direction={'left'} key={slideInfo.href}>
            <Box position={'absolute'}>
              <Grid container direction={isMobile ? 'column-reverse' : 'row'} >
                <Grid item xs={12} md={8} className={classes.card}>
                  <Box
                    px={isMobile ? 2 : 10}
                    pt={isMobile ? 3.5 : 6}
                    pb={isMobile ? 4 : 2.5}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    <Box mb={3}>
                      <TrackLink
                        href={slideInfo.href}
                        target={'_blank'}
                        trackerEvent={ABOUT_US.OTHER_PROJECTS_CLICK}
                        trackerOptions={slideInfo.trackerOptions}
                      >
                        {slideInfo.img}
                      </TrackLink>
                    </Box>
                    {slideInfo.text}
                    <Box pt={3}>
                      <TrackLink
                        href={slideInfo.href}
                        target={'_blank'}
                        trackerEvent={ABOUT_US.OTHER_PROJECTS_CLICK}
                        trackerOptions={slideInfo.trackerOptions}
                      >
                        <Typography variant={'button'} style={{ lineHeight: 1.4 }}>View More</Typography>
                      </TrackLink>
                    </Box>
                    <Box pt={1} display={'flex'} justifyContent={'space-around'} alignItems={'end'} flexGrow={1}>
                      <div>
                        {slides.map((_, nestedIndex) => (
                          <Radio
                            key={`${slideInfo.href}-${index}-${nestedIndex}`}
                            color={'primary'}
                            checked={index === nestedIndex}
                            size={'small'}
                            onClick={() => index !== nestedIndex && setSlide(nestedIndex)}
                          />
                        ))}
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} classes={{ root: classes.preview }}>
                  <div className={classes.imagePreviewContainer}>
                    <img className={classes.previewImage} src={slideInfo.preview} />
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Slide>
        ))}
      </Box>
    </Box>
  )
}

export default Carousel
