import { useCallback, useContext } from 'react'

import { useStoreState } from 'easy-peasy'

import { action } from 'artkive/utils/tracker'

import { TrackerContext } from './TrackerContext'

/**
 * @param {boolean} withUtm = true
 */
const useTracker = (withUtm = true) => {
  const tracking = useStoreState(({ promoStore }) => promoStore.tracking)
  const { getTrackingMeta } = useContext(TrackerContext)

  const wrappedTrackingAction = useCallback((event, options) => {
    const params = getTrackingMeta(options)
    if (withUtm) {
      Object.assign(params, tracking)
    }
    action(event, params)
  }, [withUtm, tracking, getTrackingMeta])

  return [wrappedTrackingAction]
}

export default useTracker
