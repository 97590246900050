import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Badge,
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import EditIcon from '@material-ui/icons/Edit'
import { useLocation } from 'wouter'

import theme from 'artkive/utils/theme'

const usePreviewStyles = makeStyles({
  container: {
    width: '100%',
  },
  root: {
    paddingLeft: 12,
    minHeight: 56,
  },
})

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  root: {
    borderRadius: 8,
    marginTop: ({ mt }) => mt ? spacing(mt) : 'unset',

    [breakpoints.down('sm')]: {
      boxShadow: '0px 4px 10px rgba(16, 35, 113, 0.08)',
    },
  },
  header: {
    backgroundColor: theme.colors.yellow.main,
  },
  content: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FEF6E1',
  },
  textRoot: {
    marginLeft: ({ badge }) => badge ? spacing(2) : 0,
  },
  textPrimary: {
    textTransform: 'capitalize',
    fontSize: '1rem',
    color: palette.secondary.dark,
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: 14,
    fontWeight: 600,
  },
  container: {
    width: '100%',
  },
  itemRoot: {
    paddingLeft: 12,
  },
  image: {
    width: 54,
    height: 40,
    objectFit: 'cover',
    borderRadius: 2,
  },
  qtyBadge: {
    backgroundColor: theme.colors.purple.dark,
    color: theme.colors.white.main,
  },
  arrow: {
    padding: 4,
  },
  collapsedContent: {
    padding: '0 12px',
    fontWeight: 500,
    fontSize: '14px',
  },
}))

const MobileDetailsBox = ({ mt, editHref, badgeProps, primaryText, secondaryText, children }) => {
  const classes = useStyles({ mt, badge: !!badgeProps })
  const previewClasses = usePreviewStyles()
  const [isShown, setIsShown] = useState(false)
  const [, navigate] = useLocation()

  return (
    <Paper elevation={0} className={classes.root} mt={mt}>
      <ListItem
        component={'div'}
        ContainerComponent={'div'}
        classes={previewClasses}
        dense
      >
        {badgeProps && (
          <ListItemAvatar>
            <Badge badgeContent={badgeProps.value} classes={{ badge: classes.qtyBadge }}>
              <img src={badgeProps.image} className={classes.image} />
            </Badge>
          </ListItemAvatar>
        )}
        <ListItemText
          primary={primaryText}
          secondary={secondaryText}
          classes={{
            root: classes.textRoot,
            primary: classes.textPrimary,
            secondary: classes.textSecondary,
          }}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => setIsShown(!isShown)} className={classes.arrow}>
            {isShown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={isShown} timeout={'auto'} unmountOnExit classes={{ root: classes.collapsedContent }}>
        {children}
        <Box display={'flex'} justifyContent={'center'} my={1.5}>
          <Button onClick={() => navigate(editHref)} color={'primary'} startIcon={<EditIcon />}>
            Edit
          </Button>
        </Box>
      </Collapse>
    </Paper>
  )
}

MobileDetailsBox.propTypes = {
  editHref: PropTypes.string.isRequired,
  primaryText: PropTypes.node.isRequired,
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  badgeProps: PropTypes.shape({ value: PropTypes.number.isRequired, image: PropTypes.string.isRequired }),
  secondaryText: PropTypes.node,
  children: PropTypes.node,
}

export default MobileDetailsBox
