import React from 'react'

import {
  Box,
  Container,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  Facebook,
  Instagram,
  Pinterest,
} from '@material-ui/icons'

import Image from 'artkive/components/Image'
import { SOCIAL_MEDIA_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'

import Social1 from 'images/ecom/social/social-1.png'
import Social2 from 'images/ecom/social/social-2.png'
import Social3 from 'images/ecom/social/social-3.png'
import Social4 from 'images/ecom/social/social-4.png'
import Social5 from 'images/ecom/social/social-5.png'
import Social6 from 'images/ecom/social/social-6.png'
// images
import TikTokIconSrc from 'images/ecom/social/tiktok-icon.svg'

import { TrackLink } from '../Tracker'

// styled components
const BoxGradient = withStyles(() => ({
  root: {
    background: 'linear-gradient(0deg, rgba(255,242,209,1) 0%, rgba(255,242,209,1) 35%, rgba(245,249,250,1) 35%, rgba(245,249,250,1) 100%)',
  },
}))(Box)

const Social = () => {
  const isMobile = useMobileBreakpoint()

  return (
    <BoxGradient py={isMobile ? 8 : 12}>
      <Container>
        <Grid container spacing={isMobile ? 2 : 4}>
          <Hidden smDown>
            <Grid item xs={12} md={3}>
              <Box textAlign={'center'}><Image src={Social1} /></Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6} style={{
            justifyContent: 'center', display: 'flex', flexDirection: 'column',
          }}>
            <Box>
              <Typography align={'center'} gutterBottom variant={'h2'}>Share The Joy!</Typography>
              <Box fontSize={'large'} mb={4} textAlign={'center'}>
                <Typography variant={'body1'}>We love to see how much you love Artkive.</Typography>
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Box mr={2}>
                  <TrackLink
                    href={routes.instagram}
                    target={'_blank'}
                    trackerEvent={SOCIAL_MEDIA_CLICK}
                    trackerOptions={{ smType: 'instagram', section: 'social_banner' }}
                  >
                    <Instagram fontSize={'large'} color={'primary'} />
                  </TrackLink>
                </Box>
                <Box mr={2}>
                  <TrackLink
                    href={routes.facebook}
                    target={'_blank'}
                    trackerEvent={SOCIAL_MEDIA_CLICK}
                    trackerOptions={{ smType: 'facebook', section: 'social_banner' }}
                  >
                    <Facebook fontSize={'large'} color={'primary'} />
                  </TrackLink>
                </Box>
                <Box mr={2}>
                  <TrackLink
                    href={routes.pinterest}
                    target={'_blank'}
                    trackerEvent={SOCIAL_MEDIA_CLICK}
                    trackerOptions={{ smType: 'pinterest', section: 'social_banner' }}
                  >
                    <Pinterest fontSize={'large'} color={'primary'} />
                  </TrackLink>
                </Box>
                <Box mr={2}>
                  <TrackLink
                    href={routes.tiktok}
                    target={'_blank'}
                    trackerEvent={SOCIAL_MEDIA_CLICK}
                    trackerOptions={{ smType: 'pinterest', section: 'social_banner' }}
                  >
                    <img src={TikTokIconSrc} />
                  </TrackLink>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={6} md={3}>
              <Box textAlign={'center'} ><Image src={Social1} /></Box>
            </Grid>
          </Hidden>
          <Grid item xs={6} md={3}>
            <Box textAlign={'center'} ><Image src={Social2} /></Box>
          </Grid>

          <Grid item xs={6} md={3}>
            <Box textAlign={'center'} ><Image src={Social3} /></Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box textAlign={'center'} ><Image src={Social4} /></Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box textAlign={'center'} ><Image src={Social5} /></Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box textAlign={'center'} ><Image src={Social6} /></Box>
          </Grid>
        </Grid>
      </Container>
    </BoxGradient>
  )
}

export default Social
