import React from 'react'

import { Route, Router, Switch } from 'wouter'
import staticLocationHook from 'wouter/static-location'
import browserLocationHook from 'wouter/use-location'

import EmailListForm from 'artkive/components/EmailListForm'
import Footer from 'artkive/components/Footer'
import Navbar from 'artkive/components/Navbar'
import withApp from 'artkive/hoc/withApp'
import routes from 'artkive/utils/routes'

import Plans from '../Plans/Plans'
import PlansCheckout from '../PlansCheckout/PlansCheckout'
import PlansConfirmation from '../PlansConfirmation/PlansConfirmation'

const isSSR = typeof window === 'undefined'

const rootBase = ''

const productLinks = {
  landing: routes.plans,
  checkout: routes.plansCheckout,
  confirmation: routes.plansConfirmation,
}

const PlansCheckoutNestedRouting = (props) => {
  const useLocation = isSSR ? staticLocationHook(props.railsContext.pathname.replace(rootBase, '')) : browserLocationHook

  return (
    <Switch>
      <Route path={`${productLinks.landing}/:uuid/checkout`}>
        {(params) => (
          <Router base={`${productLinks.landing}/${params.uuid}/checkout`} hook={useLocation}>
            <PlansCheckout {...props} params={params} productLinks={productLinks} />
          </Router>
        )}
      </Route>
      <Route path={productLinks.landing}>
        <Navbar />
        <Plans {...props}  productLinks={productLinks} />
        <EmailListForm zIndex={100} />
        <Footer />
      </Route>
      <Route path={productLinks.confirmation}>
        <Navbar />
        <PlansConfirmation {...props} productLinks={productLinks} />
        <Footer />
      </Route>
    </Switch>
  )
}

const PlansCheckoutApp = withApp(PlansCheckoutNestedRouting)

export default PlansCheckoutApp
