import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ImageIcon from '@material-ui/icons/Image'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import { ProductDescriptionItem } from 'artkive/components/PageSections/ProductDescriptionBox'
import { TrackButton } from 'artkive/components/Tracker'
import { GET_BOX_CLICK } from 'artkive/constants/tracker/mainNavigation'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import routes from 'artkive/utils/routes'
import theme from 'artkive/utils/theme'

import Product from 'images/ecom/get-started/BoxProduct.png'
import ProductMobile from 'images/ecom/get-started/BoxProduct-mobile.png'

const useStyles = makeStyles(({ breakpoints }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 800,
    background: 'linear-gradient(90deg, rgba(237,235,244,1) 50%, rgba(226,222,237,1) 50%);',
    [breakpoints.down('sm')]: {
      height: 'auto',
      background: 'rgba(237,235,244,1)',
    },
  },
  imageWrapper: { },
  description: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 120px 0 0',
    [breakpoints.down('sm')]: {
      padding: '32px 16px 64px',
    },
  },
  image: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
    maxWidth: 'unset',
    [breakpoints.up('lg')]: {
      paddingLeft: 30,
    },
  },
  artBackTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.primary.dark,
    lineHeight: .8,
  },
}))

const productBullets = [
  { text: '24”x18”x4” crush-proof Artkive Box (holds 300-400 pieces of art)' },
  { text: 'Re-sealable art protection bag' },
  { text: 'Order form to select your Art Books and/or Framed Mosaics, plus tips and tricks to organize your art' },
  { text: 'Prepaid shipping label to send back your box from anywhere in the contiguous U.S.' },
]

const GetStartedWithBoxSection = () => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()
  const muiTheme = useTheme()

  const boxProps = {}

  if (isMobile) boxProps.pt = 0

  return (
    <ContainedSection className={classes.wrapper} disableGutters={isMobile} py={0} {...boxProps}>
      <Grid container direction={isMobile ? 'column-reverse' : 'row'}>
        <Grid item xs md={6} className={classes.description}>
          <Typography variant={'h2'} align={isMobile ? 'center' : 'left'}>
            Get Started <br /> With An Artkive Box
          </Typography>

          <Box lineHeight={1.6} mb={3} mt={isMobile ? 1 : 3}>
            <Typography variant={'subtitle1'} align={isMobile ? 'center' : 'left'}>
              Includes everything you need to send in your artwork
            </Typography>
          </Box>

          {productBullets.map(({ text }, index) => (
            <ProductDescriptionItem key={index}>
              {text}
            </ProductDescriptionItem>
          ))}

          <Box display={'flex'} justifyContent={isMobile ? 'center' : 'left'} mt={isMobile ? 4 : 5}>
            <TrackButton
              color={'primary'}
              href={routes.boxCheckout}
              size={'large'}
              variant={'contained'}
              trackerEvent={GET_BOX_CLICK}
              trackerOptions={{
                section: 'get_mosaics_banner',
              }}
            >
              Get My Box
            </TrackButton>
          </Box>

          <Box mt={isMobile ? 6 : 8}>
            <Typography variant={'body1'} gutterBottom className={classes.artBackTitle}>
              <ImageIcon /> <strong>Want to get your artwork back?</strong>
            </Typography>
            <Typography variant={'caption'} component={'div'}>
              You can choose to have your art returned during checkout.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs md={6} className={classes.imageWrapper}>
          <picture>
            <source
              srcSet={`${Product}`}
              media={muiTheme.breakpoints.up('md').replace('@media ', '')}
            />
            <img src={ProductMobile} className={classes.image} />
          </picture>
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

export default GetStartedWithBoxSection
