import React from 'react'

import { Box, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import ContainedSection from 'artkive/components/PageSections/ContainedSection'
import useMobileBreakpoint from 'artkive/hooks/useMobileBreakpoint'
import theme from 'artkive/utils/theme'

import GH from 'images/ecom/home/featured/GH.png'
import Goop from 'images/ecom/home/featured/Goop.png'
import Oprah from 'images/ecom/home/featured/Oprah.png'
import Parents from 'images/ecom/home/featured/Parents.png'
import RealSimple from 'images/ecom/home/featured/RealSimple.png'
import Today from 'images/ecom/home/featured/Today.png'
import USAToday from 'images/ecom/home/featured/USAToday.png'
import VanityFair from 'images/ecom/home/featured/VanityFair.png'

const useStyles = makeStyles(({ breakpoints }) => ({
  box: {
    display: 'flex',
    maxHeight: 90,
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px 0',
    [breakpoints.down('sm')]: {
      maxHeight: 70,
      margin: '12px 8px',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: 90,
    [breakpoints.down('sm')]: {
      maxHeight: 70,
    },
    // maxHeight: '100%',
  },
}))

const AsFeaturedOnSection = () => {
  const classes = useStyles()
  const isMobile = useMobileBreakpoint()

  return (
    <ContainedSection bgcolor={theme.colors.white.main}>
      <Box mb={isMobile ? 5 : 6} textAlign={'center'}>
        <Typography variant={'h2'}>As Featured On</Typography>
      </Box>
      <Grid container justifyContent={'space-evenly'}>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={Today} className={classes.image} />
        </Grid>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={Oprah} className={classes.image} />
        </Grid>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={Parents} className={classes.image} />
        </Grid>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={RealSimple} className={classes.image} />
        </Grid>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={GH} className={classes.image} />
        </Grid>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={Goop} className={classes.image} />
        </Grid>

        <Grid item sm={6} md={3} className={classes.box}>
          <img src={USAToday} className={classes.image} />
        </Grid>
        <Grid item sm={6} md={3} className={classes.box}>
          <img src={VanityFair} className={classes.image} />
        </Grid>
      </Grid>
    </ContainedSection>
  )
}

export default AsFeaturedOnSection
