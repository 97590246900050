import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'

import withApp from 'artkive/hoc/withApp'
import theme from 'artkive/utils/theme'

const SharedPictureDetails = ({ picture }) => {
  const isMissingData = !picture.child?.name && !picture.label?.name && !picture.title

  if (isMissingData) return null

  return (
    <Grid item xs={12} align={'left'}>
      <Paper elevation={0} variant={'outlined'}>
        <Box p={6}>
          {
            picture.child?.name && (
              <Typography variant={'h6'}>
                {picture.child.name}
              </Typography>
            )
          }

          {
            picture.label?.name && (
              <Typography variant={'body1'}>
                {picture.label.name}
              </Typography>
            )
          }

          {
            picture.title && (
              <Typography variant={'body1'}>
                {picture.title}
              </Typography>
            )
          }
        </Box>
      </Paper>
    </Grid>
  )
}

SharedPictureDetails.propTypes = {
  picture: PropTypes.object,
}

const SharedPicture = ({ picture }) => (
  <Box bgcolor={theme.colors.white.dark} minHeight={'100vh'}>
    <Container maxWidth={'sm'}>
      <Box pt={3} mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} align={'center'}>
            <img
              alt={picture.description || 'Kid Picture'}
              id={'shared-picture'}
              src={picture.image.preview}
              width={'100%'}
            />
          </Grid>

          <SharedPictureDetails picture={picture} />
        </Grid>
      </Box>
    </Container>
  </Box>
)

SharedPicture.propTypes = {
  picture: PropTypes.object,
}

export default withApp(SharedPicture)
