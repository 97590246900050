import React from 'react'
import PropTypes from 'prop-types'

import { Box, Container, Grid, Paper, Typography } from '@material-ui/core'

import Image from 'artkive/components/Image'
import { withClickTracker } from 'artkive/components/Tracker'
import { GIFT_CARDS } from 'artkive/constants/tracker/mainNavigation'
import { AMOUNT_OF_ART } from 'artkive/utils/giftCard'

import Included from 'images/ecom/giftcards/GC-Include-1.svg'
import Included2 from 'images/ecom/giftcards/GC-Include-2.svg'
import Included3 from 'images/ecom/giftcards/GC-Include-3.svg'


export const IncludesListing = () => (
  <>
    <Box mb={4} textAlign={'center'}><Typography variant={'h6'}>Gift Cards Include:</Typography></Box>

    <Grid container spacing={2}>
      <Grid xs={12} md={4} item>
        <Box alignItems={'center'} display={'flex'}>
          <Box mr={2}><Image src={Included} style={{ maxWidth: '90px' }} /></Box>
          <Typography>Artkive box with prepaid shipping label</Typography>
        </Box>
      </Grid>

      <Grid xs={12} md={4} item>
        <Box alignItems={'center'} display={'flex'}>
          <Box mr={2}><Image src={Included2} style={{ maxWidth: '90px' }} /></Box>
          <Typography>Photography &amp; editing of {AMOUNT_OF_ART} pieces of art</Typography>
        </Box>
      </Grid>

      <Grid xs={12} md={4} item>
        <Box alignItems={'center'} display={'flex'}>
          <Box mr={2}><Image src={Included3} style={{ maxWidth: '90px' }} /></Box>
          <Typography>A beautiful custom hardcover book</Typography>
        </Box>
      </Grid>
    </Grid>
  </>
)

const TrackTypography = withClickTracker(Typography)

const GiftCardIncludeSection = ({ onClick }) => {
  return (
    <div>
      <Box>
        <Container>
          <Paper elevation={0}>
            <Box px={4} py={6}>
              <IncludesListing />
            </Box>

            {/* Redeem Button */}
            <Box pb={5} textAlign={'center'}>
              <Grid item xs={12}>
                <Box>
                  <Typography>
                    Looking to redeem your giftcard? &nbsp;
                  </Typography>

                  <br />

                  <TrackTypography
                    color={'primary'}
                    onClick={onClick}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    variant={'subtitle2'}
                    trackerEvent={GIFT_CARDS.REDEEM_GIFT_CARD_OPEN}
                  >
                    Click Here
                  </TrackTypography>
                </Box>
              </Grid>
            </Box>
          </Paper>
        </Container>
      </Box>
    </div>
  )
}

GiftCardIncludeSection.propTypes = {
  onClick: PropTypes.func,
}

export default GiftCardIncludeSection
