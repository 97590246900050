import React, { useContext } from 'react'

import { Grid } from '@material-ui/core'

import Input from 'common/components/Form/Input'
import PhoneNumberField from 'artkive/components/Forms/PhoneNumberField'
import { TrackerContext } from 'artkive/components/Tracker/TrackerContext'
import validations from 'artkive/utils/validations'

import { PHONE_NUMBER_TOS_HINT } from '../Information.constants'
import SectionGrid from '../SectionGrid'

const ContactInformationFragment = () => {
  const { fire } = useContext(TrackerContext)

  return (
    <SectionGrid>
      <Grid item xs={12} md={6}>
        <Input
          name={'email'}
          rules={validations.email}
          label={'Email'}
          type={'email'}
          autoComplete={'email'}
          onBlur={(event, handler) => {
            handler(event)
            fire('blur')
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Input
          name={'phone'}
          component={PhoneNumberField}
          label={'Phone Number'}
          secondaryHelperText={PHONE_NUMBER_TOS_HINT}
          onBlur={(event, handler) => {
            handler(event)
            fire('blur')
          }}
        />
      </Grid>
    </SectionGrid>
  )
}

export default ContactInformationFragment
